import '../Table.scss'

import { Button, Counter, Status } from '@components'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'

import { PastLoad } from '../../../common/types'
import { displayCityAndState, formatCurrency } from '../../../common/utils'
import { DetailSectionItem } from '../../DetailSectionItem'
import { RecommendedLoadIndicator } from '../../Loads/RecommendedLoadIndicator'
import BOLModal from '../../PastLoads/BOLModal'
import { TextWithTooltip } from '../../TextWithTooltip'

export const IndicatorIfRecommendedLoad = ({ row }: any) =>
  row.original.isRecommended ? <RecommendedLoadIndicator /> : null

export const Details = ({ row }: any) => {
  const item = row.original

  return (
    <span>
      <span>
        Weight:{' '}
        <span className='font-semibold' data-testid='test-load-weight-id'>
          {item.loadWeight}
        </span>
      </span>
      <div>
        Miles:{' '}
        <span className='font-semibold' data-testid='test-miles-id'>
          {item.miles}
        </span>
      </div>
    </span>
  )
}

export const Origin = ({ row }: any) => (
  <span>
    {displayCityAndState(row.original.shipper?.city, row.original.shipper?.stateProvinceRegion)}
  </span>
)

export const Destination = ({ row }: any) => (
  <span>
    {displayCityAndState(row.original.consignee?.city, row.original.consignee?.stateProvinceRegion)}
  </span>
)

export const LoadId = ({ row }: any) => {
  const item = row.original

  return (
    <div className='load-count-container load-row-button'>
      {item.children?.length ? (
        <div {...row.getToggleRowExpandedProps()} className='load-count-box'>
          <div className='counter'>{item.children.length}</div>
          <div className='text'>{item.id}</div>
          {row.isExpanded ? (
            <ChevronUpIcon className='expand-icon' data-testid='chevron-up' />
          ) : (
            <ChevronDownIcon className='expand-icon' data-testid='chevron-down' />
          )}
        </div>
      ) : (
        <div className='font-semibold'>{item.id}</div>
      )}
    </div>
  )
}

export const LoadIdWithReference = ({ row }: any) => {
  const item = row.original

  return (
    <div>
      <div className='font-semibold'>{item.id}</div>
      <div className='ref-id'>
        <span className='mr-1'>Ref:</span>
        <TextWithTooltip limit={10}>{item.customerReferenceId}</TextWithTooltip>
      </div>
    </div>
  )
}

export const StatusPill = ({ value }: any) => (
  <span className='flex'>
    <Status status={value} />
  </span>
)

export const PastLoadRate = ({ row }: any) => {
  const item = row.original

  return (
    <div className='flex flex-col'>
      <span>
        Carrier Price: <span className='font-semibold'>${item.price}</span>
      </span>
      <span>
        Accessorial: <span className='font-semibold'>${item.accessorial}</span>
      </span>
      <span>
        Total: <span className='font-semibold'>${item.totalAmount}</span>
      </span>
    </div>
  )
}

export const BOLDownload = ({ row }: any) => {
  const [isVisible, setVisible] = useState(false)
  const load = row.original as PastLoad
  const download = () => window.open(load.bol[0], '_blank')
  const openModal = () => setVisible(true)
  const onClick = () => {
    if (load.bol.length > 1) {
      openModal()
    } else if (load.bol.length) {
      download()
    }
  }

  if (!load) return null

  return (
    <>
      <div className='hidden md:block'>
        <Button sm disabled={!load.bol.length} preIcon='download' type='link' onClick={onClick} />
      </div>
      <div className='block md:hidden'>
        <Button
          mobileFullWidth
          className='mt-4'
          dataTestId={`bol-button-${load.id}`}
          disabled={!load.bol.length}
          preIcon='download'
          type='link'
          onClick={onClick}
        >
          download
        </Button>
      </div>
      <BOLModal {...{ isVisible, setVisible, bolUrls: load.bol, loadId: load.id }} />
    </>
  )
}

export const Date = ({ value }: any) => (
  <div data-testid='date'>{value ? dayjs(value).format('MM/DD/YY') : '—'}</div>
)

export const DateTime = ({ value }: any) => (
  <div data-testid='datetime'>{value ? dayjs(value).format('MM/DD/YYYY hh:mm A') : '—'}</div>
)

export const Price = ({ value }: any) =>
  value ? (
    <div className='font-semibold'>{formatCurrency(value)}</div>
  ) : (
    <div className='ml-4'>—</div>
  )

// to display a dash if there's no value
export const Text = ({ value }: any) => <div data-testid='text'>{value ?? '—'}</div>

export const NoWrapText = ({ value }: any) => (
  <div className='whitespace-nowrap' data-testid='text'>
    {value ?? '—'}
  </div>
)

export const QuoteDetails = ({
  value: { totalQuotes, submittedQuotes, pendingQuotes, rejectedQuotes },
}: {
  value: {
    totalQuotes: number
    submittedQuotes: number
    rejectedQuotes: number
    pendingQuotes: number
  }
}) => (
  <div className='flex flex-col gap-0.5 whitespace-nowrap'>
    <div className='grid grid-cols-2 gap-4'>
      <div className='text-dark-gray'>Total Quotes</div>
      <div className='font-semibold'>{totalQuotes || '—'}</div>
    </div>
    <div className='grid grid-cols-2 gap-4'>
      <div className='text-dark-gray'>Submitted</div>
      <div className='font-semibold'>{submittedQuotes || '—'}</div>
    </div>
    <div className='grid grid-cols-2 gap-4'>
      <div className='text-dark-gray'>Pending</div>
      <div className='font-semibold'>{pendingQuotes || '—'}</div>
    </div>
    <div className='grid grid-cols-2 gap-4'>
      <div className='text-dark-gray'>Rejected</div>
      <div className='font-semibold'>{rejectedQuotes || '—'}</div>
    </div>
  </div>
)

export const BidsDetails = ({
  value: { fuelSetting, distanceUnit },
}: {
  value: {
    fuelSetting: string
    distanceUnit: string
  }
}) => (
  <div className='flex flex-col gap-1'>
    <DetailSectionItem className='my-0' title='Fuel'>
      {fuelSetting}
    </DetailSectionItem>
    <DetailSectionItem className='my-0' title='Distance Units'>
      {distanceUnit}
    </DetailSectionItem>
  </div>
)

export const RFPStatus = ({ value = '' }: { value?: string }) => {
  const bg = useMemo(() => {
    switch (value.toLowerCase()) {
      case 'invitation rejected':
        return 'bg-error text-white'
      case 'invitation accepted':
        return 'bg-tender-accepted'
      case 'in progress':
        return 'bg-exo-orange text-white'
      case 'rates submitted':
        return 'bg-bright-blue text-white'
      case 'bid evaluation':
        return 'bg-purple text-white'
      case 'awarded':
        return 'bg-success text-white'
      default:
        return ''
    }
  }, [value])

  return <Status background={bg} className='text-xs' status={value} />
}

export const Money = ({ value }: any) => {
  if (value === undefined) {
    return <div>—</div>
  }

  const numberValue = typeof value === 'number' ? value : parseFloat(value)

  return (
    <div>
      {numberValue >= 0 ? formatCurrency(numberValue) : `-${formatCurrency(numberValue * -1)}`}
    </div>
  )
}

export const Count = ({ value }: { value: number }) => <Counter count={value} />
