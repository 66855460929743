import { AppLogoImage, Button } from '@components'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { getDocumentTitle } from '../../common/utils'
import { useAppThunkDispatch } from '../../redux/store'
import { trackDriverCheckIn } from '../../redux/trackingSlice'

export const DriverCheckInScreen = () => {
  const [searchParams] = useSearchParams()
  const loadId = searchParams.get('loadId') || ''
  const checkInType = searchParams.get('type') || ''
  const checkInResponse = searchParams.get('response') || ''
  const navigate = useNavigate()

  const [displayText, setDisplayText] = useState('')
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    document.title = getDocumentTitle('Driver Check In')
    if (
      !loadId ||
      !['pickupEta', 'deliveryEta', 'deliveryConfirmation', 'pickupConfirmation'].includes(
        checkInType,
      ) ||
      !['yes', 'no'].includes(checkInResponse)
    )
      navigate('/')

    if (checkInType === 'pickupEta') {
      if (checkInResponse == 'yes')
        setDisplayText(
          'Thank you for marking yourself on track for pickup. If you have questions, call 702-840-3285 ext. 1 for Carrier Sales.',
        )
      else if (checkInResponse === 'no')
        setDisplayText(
          'You have confirmed that you will not make your pickup within the specified appointment or time window. You must call 702-840-3285 ext. 1 to reschedule your pickup.',
        )
    } else if (checkInType === 'deliveryEta') {
      if (checkInResponse === 'yes')
        setDisplayText(
          'Thank you for marking yourself on track for delivery. If you have questions, call 702-840-3285 ext. 1 for Carrier Sales.',
        )
      else if (checkInResponse === 'no')
        setDisplayText(
          'You have confirmed that you will not make your delivery within the specified appointment or time window. You must call 702-840-3285 ext. 1 to reschedule your delivery.',
        )
    } else if (checkInType === 'pickupConfirmation') {
      if (checkInResponse === 'yes')
        setDisplayText(
          'Thank you for confirming your pickup. If you have questions, call 702-840-3285 ext. 1 for Carrier Sales.',
        )
      else if (checkInResponse === 'no')
        setDisplayText(
          'We have made a note that you are not picked up yet. We will check back later today. If you have questions, call 702-840-3285 ext. 1 for Carrier Sales.',
        )
    } else if (checkInType === 'deliveryConfirmation') {
      if (checkInResponse === 'yes')
        setDisplayText(
          'Thank you for confirming your delivery. If you have questions, call 702-840-3285 ext. 1 for Carrier Sales.',
        )
      else if (checkInResponse === 'no')
        setDisplayText(
          'We have made a note that you are not delivered up yet. We will check back later today. If you have questions, call 702-840-3285 ext. 1 for Carrier Sales.',
        )
    }
    dispatch(trackDriverCheckIn({ loadId, checkInType, checkInResponse }))
  }, [])

  return (
    <Container>
      <AppLogoImage className='w-20 md:w-24 mb-8' />
      <h1 className='mb-4 text-2xl font-bold'>Your response has been recorded.</h1>
      <h2 className='mb-2'>{displayText}</h2>
      <Button className='mt-8' type='warn' onClick={() => navigate('/')}>
        Return to Home
      </Button>
    </Container>
  )
}

const Container = tw.div`
  flex
  flex-col
  items-center
  justify-center
  h-full
  text-center
  mx-8
`
