import { Button, Status } from '@components'
import React, { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { emptyFile } from '../../../common/constants'
import { FileProps } from '../../../common/types'
import { FileUpload } from '../../FileUpload'
import { FileViewer } from '../../FileViewer'

const getUploadedFileUrl = async (base64: string, mimeType: string): Promise<string> => {
  const buffer = await fetch(base64).then(b => b.arrayBuffer())
  const blob = new Blob([buffer], { type: mimeType })
  return URL.createObjectURL(blob)
}

type DocumentItemProps = {
  setItem: (file: FileProps) => void
  title: string
  required?: boolean
  status: string
  className?: string
  value: FileProps
  onDelete?: React.MouseEventHandler<HTMLDivElement>
}

export const DocumentItem = ({
  setItem,
  title,
  required = false,
  status,
  className = '',
  value = emptyFile,
  onDelete,
}: DocumentItemProps) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [url, setUrl] = useState('')

  const { name, fileData, mimeType } = value

  useEffect(() => {
    getUploadedFileUrl(fileData, mimeType).then(res => setUrl(res))
  }, [fileData])

  const renderText = () =>
    name ? (
      <FileName onClick={() => setModalVisible(true)}>{name}</FileName>
    ) : (
      <div className='text-base font-bold mt-4'>Upload {title}</div>
    )

  const renderButton = () => (
    <ButtonContainer $value={name}>
      {name ? (
        <Button lg mobileFullWidth short className='ml-1' preIcon='delete' onClick={onDelete}>
          delete
        </Button>
      ) : (
        <FileUpload onUpload={(file: FileProps) => setItem(file)}>
          <Button lg mobileFullWidth short preIcon='plus-circle' type='warn'>
            upload
          </Button>
        </FileUpload>
      )}
    </ButtonContainer>
  )

  return (
    <div>
      <Container $value={name} className={className}>
        <Wrap $value={name}>
          <div className='flex items-center'>
            <Status className='mr-2' status={required ? 'required' : 'optional'} />
            <Status status={status} />
          </div>
          {renderText()}
          <div className='text-dark-gray'>{name ? '' : 'Max Size: 50 MB'}</div>
        </Wrap>
        <MobileLine $value={name} />
        {renderButton()}
      </Container>
      <FileViewer
        isModalVisible={isModalVisible}
        mimeType={mimeType}
        setModalVisible={setModalVisible}
        url={url}
      />
    </div>
  )
}

const Container = tw.div<{ $value: string }>`
  rounded-md
  flex
  flex-col
  md:flex-row
  md:justify-between
  md:items-center
  md:h-[140px]
  ${({ $value }) => ($value ? 'bg-white border border-brand-light' : 'bg-brand-light')}
`

const MobileLine = tw.div<{ $value: string }>`
  block
  md:hidden
  h-0.5
  w-full
  ${({ $value }) => ($value ? 'bg-brand-light border-l-4 border-success' : 'bg-white')}
`

const Wrap = tw.div<{ $value: string }>`
  h-full
  flex
  flex-col
  justify-center
  px-6
  py-4
  md:py-0
  ${({ $value }) => ($value ? 'border-l-4 border-success rounded-tl-md md:rounded-l-md' : 'pl-7')}
`

const FileName = tw.div`
  text-base
  font-bold
  mt-4
  hover:text-link
  transition-all
  cursor-pointer
`

const ButtonContainer = tw.div<{ $value: string }>`
  h-full
  px-6
  py-4
  md:py-0
  rounded-bl-md
  md:rounded-bl-none
  flex
  items-center
  justify-center
  border-l-4
  border-transparent
  md:border-l-2
  ${({ $value }) => ($value ? 'border-success md:border-brand-light' : 'md:border-white')}
`
