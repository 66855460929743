import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { RootState } from '../common/types'
import { keysToSnake } from '../common/utils'
import { getContacts, setContacts } from './contactsSlice'

type LoadRecommendationsSettings = {
  sendEmailOffersContactIds: Array<number>
  sendSmsOffersContactIds: Array<number>
}

type LoadRecommendationsSettingsState = {
  loading: {
    savingSettings: boolean
  }
  settings: LoadRecommendationsSettings
}

const initialState: LoadRecommendationsSettingsState = {
  loading: {
    savingSettings: false,
  },
  settings: {
    sendEmailOffersContactIds: [],
    sendSmsOffersContactIds: [],
  },
}

export const updateLoadRecommendationsSettings = createAsyncThunk(
  'loadRecommendationsSettings/setLoadRecommendationsSettings',
  async (_, { dispatch, getState }) => {
    const loadRecommendationsSettings = (getState() as RootState).loadRecommendationsSettings
      .settings

    return await api
      .put(
        '/accounts/api/carrier/load-recommendation-settings/',
        keysToSnake(loadRecommendationsSettings),
      )
      .then(() => {
        const contacts = (getState() as RootState).contacts.contacts
        dispatch(
          setContacts(
            contacts.map(contact => ({
              ...contact,
              sendEmailOffers: loadRecommendationsSettings.sendEmailOffersContactIds?.includes(
                contact.id,
              ),
              sendSmsOffers: loadRecommendationsSettings.sendSmsOffersContactIds?.includes(
                contact.id,
              ),
            })),
          ),
        )

        // if update succeeded, we already have latest state, so don't override it
        return null
      })
      .catch(() => {
        const contacts = (getState() as RootState).contacts.contacts

        // if update failed, we need to revert to previous state which resides in company and contacts slices
        return {
          sendSmsOffersContactIds: contacts
            .filter(contact => contact.sendSmsOffers)
            .map(contact => contact.id),
          sendEmailOffersContactIds: contacts
            .filter(contact => contact.sendEmailOffers)
            .map(contact => contact.id),
        }
      })
  },
)

export const loadRecommendationsSettingsSlice = createSlice({
  name: 'loadRecommendationsSettings',
  initialState,
  reducers: {
    includeContact(
      state,
      { payload }: { payload: { contactId: number; notificationType: string } },
    ) {
      if (payload.notificationType === 'sms') {
        if (state.settings.sendSmsOffersContactIds.includes(payload.contactId)) return
        state.settings.sendSmsOffersContactIds.push(payload.contactId)
      } else if (payload.notificationType === 'email') {
        if (state.settings.sendEmailOffersContactIds.includes(payload.contactId)) return
        state.settings.sendEmailOffersContactIds.push(payload.contactId)
      }
    },
    excludeContact(
      state,
      { payload }: { payload: { contactId: number; notificationType: string } },
    ) {
      if (payload.notificationType === 'sms')
        state.settings.sendSmsOffersContactIds = state.settings.sendEmailOffersContactIds.filter(
          contactId => contactId !== payload.contactId,
        )
      else if (payload.notificationType === 'email')
        state.settings.sendEmailOffersContactIds = state.settings.sendEmailOffersContactIds.filter(
          contactId => contactId !== payload.contactId,
        )
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateLoadRecommendationsSettings.pending, state => {
        state.loading.savingSettings = true
      })
      .addCase(updateLoadRecommendationsSettings.fulfilled, (state, { payload }) => {
        state.loading.savingSettings = false

        if (payload) {
          state.settings = {
            ...state.settings,
            ...payload,
          }
        }
      })
      .addCase(updateLoadRecommendationsSettings.rejected, state => {
        state.loading.savingSettings = false
        toast.error('There was a problem updating your settings. Please try again later.')
      })
      .addCase(getContacts.fulfilled, (state, { payload }) => {
        state.settings.sendSmsOffersContactIds = payload.results
          .filter(contact => contact.sendSmsOffers)
          .map(contact => contact.id)
        state.settings.sendEmailOffersContactIds = payload.results
          .filter(contact => contact.sendEmailOffers)
          .map(contact => contact.id)
      })
  },
})

export default loadRecommendationsSettingsSlice.reducer

export const { includeContact, excludeContact } = loadRecommendationsSettingsSlice.actions
