import { Button } from '@components'
import { Popover } from '@headlessui/react'
import tw from 'tailwind-styled-components'

import { CarrierCompanySearch } from '../../pages/LoginScreen/CarrierCompanySearch'
import { useAppSelector } from '../../redux/store'

export const CarrierCompanyPicker = () => {
  const carrierCompaniesCount = useAppSelector(state => state.user.carrierCompaniesCount)
  const companyDetail = useAppSelector(state => state.company.companyDetail)
  const tokenLoading = useAppSelector(state => state.user.loading.getUserToken)
  const companyDetailLoading = useAppSelector(state => state.company.loading.fetchCompanyDetail)

  if (carrierCompaniesCount < 2) return null

  return (
    <PickerPopover>
      <Popover.Button>
        <Button
          className='flex'
          loading={!!tokenLoading || companyDetailLoading}
          postIcon='arrow-right-left'
        >
          {/*<CompanyName>wtf</CompanyName>*/}
          <CompanyName>{companyDetail.name}</CompanyName>
        </Button>
      </Popover.Button>
      <PanelContainer>
        <CarrierCompanySearch popover />
      </PanelContainer>
    </PickerPopover>
  )
}

const CompanyName = tw.div`
  max-w-[25vw]
  overflow-ellipsis
  truncate
  lg:max-w-fit
`

const PickerPopover = tw(Popover)`
  relative
`

const PanelContainer = tw(Popover.Panel)`
    absolute
    top-[calc(100%+10px)]
    right-0
    flex-col
    bg-white
    rounded-lg
    shadow-lg
    border
    border-light-gray
    overflow-hidden
    p-3
    w-[250px]
`
