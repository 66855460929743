type LegalAddress = {
  id?: number
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  postalCode: string
  countryCode?: string
}

export type AccountBalance = {
  balance: number
  type: 'ACCOUNT_BALANCE' | 'AVAILABLE_BALANCE' | 'ACCRUED_INTEREST_MTD'
}

export type BankAccount = {
  accessStatus: string
  accountNumber: string
  balances: AccountBalance[]
  bankRouting: string
  id?: number
  postedTransactions: PostedTransaction[]
  pendingTransactions: PendingTransaction[]
  postedTransactionsNextPageToken: string
  cards: Record<'virtual' | 'physical', Card>
  status: string
  updatedAt: string
}

export type ExternalBankAccount = {
  id: number
  name: string
  logo: string
  nickname: string
  accountNumberMask: string
  availableBalance?: number | null // in cents
  isFetchingBalance?: boolean
  isGettingLinkToken?: boolean
  linkToken?: string
  isDeleting?: boolean
  relinkRequired?: boolean
  error?: string
}

export type CardStatus = 'ACTIVE' | 'UNACTIVATED' | 'SUSPENDED' | 'TERMINATED'

export type Card = {
  cardStatus: CardStatus
  createdAt: string
  form: 'VIRTUAL' | 'PHYSICAL'
  id: number
  person: number
  lastFourDigits: string
  reissuedFrom?: number
  reissueReason?: string
  shippingAddress: LegalAddress
  statusReason: string
  updatedAt: string
}

export type NewBusiness = {
  id?: number
  entityName: string | null
  tradeNames: string[]
  formationDate: string | null
  formationState: string | null
  structure: string | null
  phoneNumber: string | null
  ein: string | null
  legalAddress: LegalAddress
}

export type NewPerson = {
  id: number // 0-based index, referenced by front-end only
  firstName: string
  middleName: string
  lastName: string
  ownershipPercentage: number
  isManagingPerson: boolean
  dob: string
  ssn: string
  email: string
  phoneNumber: string
  legalAddress: LegalAddress
}

export type BankingBusiness = {
  id: number
  legalAddress: LegalAddress
  persons: BankingPerson[]
  isVerified: boolean
  isVerificationPending: boolean
  createdAt: string
  updatedAt: string
  entityName: string
  tradeNames: string[]
  formationDate: string
  formationState: string
  ein: string
  phoneNumber: string
  structure: string
  kybAttempts: number
  status: string
  verificationStatus: VerificationStatus
  caseStatus: string
  acknowledgedPatriotAct: boolean
}

export type BankingDisclosure = {
  id: number
  type: string
  document: string
}

export type BankingPerson = {
  id: number
  legalAddress: LegalAddress
  ssn: string
  firstName: string
  middleName: string
  lastName: string
  dob: string
  ownershipPercentage: number
  isManagingPerson: boolean
  email: string
  phoneNumber: string
  status: string
  verificationStatus: VerificationStatus
  business: number
}

export const emptyOwner = () =>
  ({
    firstName: '',
    middleName: '',
    lastName: '',
    ownershipPercentage: 0,
    isManagingPerson: false,
    dob: '',
    ssn: '',
    email: '',
    phoneNumber: '',
    legalAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
    },
  }) as NewPerson

export const DocumentTypes = [
  'SC_ACCOUNT_AGREEMENT',
  'ACH_AUTHORIZATION',
  'CARDHOLDER_AGREEMENT',
  'E_SIGN',
  'KYC_DATA_COLLECTION',
  'PRIVACY_NOTICE',
  'REG_CC',
  'REG_DD',
  'TERMS_AND_CONDITIONS',
] as const

export type Document = {
  id: string
  documentUrl: string
  type: (typeof DocumentTypes)[number]
}

export type AgreementsHTML = {
  eSign: string | null
  achAuthorization: string | null
  cardholderAgreement: string | null
  scAccountAgreement: string | null
  kycDataCollection: string | null
  privacyNotice: string | null
  regCc: string | null
  termsAndConditions: string | null
  regDd: string | null
}

export type BaseTransaction = {
  amount: number
  dcSign: string
  description: string
  effectiveDate: Date
  status: string
  subtype: string
  syncteraId: number
  transactionTime: Date
  type: string
  uuid: string
}

export type PostedTransaction = BaseTransaction & {
  balance: number
  postedDate: string
}

export type PendingTransaction = BaseTransaction & {}

export type Statement = {
  id: number
  endDate: string
}

export type VerificationStatus =
  | 'UNVERIFIED'
  | 'PENDING'
  | 'PROVISIONAL'
  | 'ACCEPTED'
  | 'REVIEW'
  | 'REJECTED'
