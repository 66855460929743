import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { differenceBy, keyBy } from 'lodash-es'
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import {
  BankAccount,
  BankingBusiness,
  Card,
  Document,
  ExternalBankAccount,
  PendingTransaction,
  PostedTransaction,
  RootState,
  Statement,
  TransferType,
} from '../common/types'
import { keysToCamel, keysToSnake } from '../common/utils'
import {
  deleteBankingPerson,
  signDisclosures,
  upsertBankingBusiness,
  upsertBankingPerson,
} from './bankingOnboardingSlice'

const initialNotificationSettings = () => ({
  lowBalanceEmail: null,
  lowBalanceText: null,
  depositEmail: null,
  depositText: null,
  insufficientFundsEmail: null,
  insufficientFundsText: null,
})

type NotificationSettings = {
  lowBalanceEmail: boolean | null
  lowBalanceText: boolean | null
  depositEmail: boolean | null
  depositText: boolean | null
  insufficientFundsEmail: boolean | null
  insufficientFundsText: boolean | null
}

type BankingState = {
  account: BankAccount
  activationUrl: string
  business: BankingBusiness | null
  businessId: string
  documents: Document[]
  externalAccounts: ExternalBankAccount[]
  hasActiveSession: boolean
  loading: {
    account: boolean
    externalAccounts: boolean
    deleteExternalAccount: boolean
    getLinkToken: boolean
    activationUrl: boolean
    isHandlingPlaidSuccessCallback: boolean
    business: boolean
    documents: boolean
    getStatement: number
    listStatements: boolean
    notifications: boolean
    notificationSettings: boolean
    owners: boolean
    pendingTransactions: boolean
    postedTransactions: boolean
    statements: boolean
    setPinUrl: boolean
    widgetToken: boolean
    updateCard: boolean
    sendACH: boolean
    validateOTP: boolean
  }
  notificationSettingsOnScreen: NotificationSettings
  notificationSettingsOnServer: NotificationSettings
  selectedExternalAccountId: number | null
  setPinUrl: string
  statements: Statement[]
  widgetTokens: Record<number, string>
  linkToken: string
}

const initialState: BankingState = {
  account: {
    accessStatus: '',
    accountNumber: '',
    balances: [],
    bankRouting: '',
    cards: {
      virtual: {
        cardStatus: 'UNACTIVATED',
        createdAt: '',
        form: 'VIRTUAL',
        id: 0,
        person: 0,
        lastFourDigits: '',
        shippingAddress: {
          addressLine1: '',
          city: '',
          state: '',
          postalCode: '',
        },
        statusReason: '',
        updatedAt: '',
      },
      physical: {
        cardStatus: 'UNACTIVATED',
        createdAt: '',
        form: 'PHYSICAL',
        id: 0,
        person: 0,
        lastFourDigits: '',
        shippingAddress: {
          addressLine1: '',
          city: '',
          state: '',
          postalCode: '',
        },
        statusReason: '',
        updatedAt: '',
      },
    },
    postedTransactions: [],
    pendingTransactions: [],
    postedTransactionsNextPageToken: '',
    status: '',
    updatedAt: '',
  },
  activationUrl: '',
  business: null,
  businessId: '',
  documents: [],
  externalAccounts: [],
  hasActiveSession: false,
  loading: {
    account: false,
    externalAccounts: false,
    deleteExternalAccount: false,
    getLinkToken: false,
    isHandlingPlaidSuccessCallback: false,
    activationUrl: false,
    business: false,
    documents: false,
    getStatement: 0,
    listStatements: false,
    notifications: true,
    notificationSettings: true,
    owners: false,
    pendingTransactions: false,
    postedTransactions: true,
    statements: false,
    setPinUrl: false,
    widgetToken: false,
    updateCard: false,
    sendACH: false,
    validateOTP: false,
  },
  notificationSettingsOnScreen: initialNotificationSettings(),
  notificationSettingsOnServer: initialNotificationSettings(),
  selectedExternalAccountId: null,
  setPinUrl: '',
  statements: [],
  widgetTokens: {},
  linkToken: '',
}

const bankingAsyncThunk = <T, Args = void>(
  thunkName: string,
  asyncRequest: (args: Args) => Promise<T>,
) =>
  createAsyncThunk(`banking/${thunkName}`, async (args: Args, { rejectWithValue }) => {
    try {
      return asyncRequest(args)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  })

const getBankingErrorMessage = (payload: any, fallback: string) => {
  const errorMessage = getErrorString(payload, fallback)
  return errorMessage === 'Something went wrong with our banking partner' ? fallback : errorMessage
}

export const accountAvailableBalance = createSelector(
  (state: RootState) => state.banking.account.balances,
  balances => (balances.find(balance => balance.type === 'AVAILABLE_BALANCE')?.balance ?? 0) / 100,
)

export const getExternalAccountSelector = (externalAccountId?: number) =>
  createSelector(
    (state: RootState) => state.banking.externalAccounts,
    (state: RootState) => state.banking.selectedExternalAccountId,
    (externalAccounts, selectedExternalAccountId) =>
      externalAccounts.find(acc => acc.id === (externalAccountId || selectedExternalAccountId)),
  )

export const getBankingAccounts = bankingAsyncThunk(
  'getBankingAccounts',
  async (): Promise<BankAccount[]> => {
    const { data } = await api.get('/finance/api/accounts/')
    return data.results.map((account: any) =>
      keysToCamel({
        ...account,
        cards: keyBy(account.cards, 'form'),
      }),
    )
  },
)

export const getBankingBusinesses = bankingAsyncThunk(
  'getBankingBusinesses',
  async (): Promise<BankingBusiness[]> => {
    const { data } = await api.get('/finance/api/businesses/')
    const results = data.results.map((business: any) => keysToCamel(business))

    return results.map((business: any) => ({
      ...business,
      persons: business.persons.map(({ ssnMasked: ssn, ...person }: any) => ({
        ssn,
        ...person,
      })),
    }))
  },
)

export const getExternalAccountBalance = bankingAsyncThunk(
  'getExternalAccountBalance',
  async (accountId: number): Promise<number> =>
    await api
      .get(`/finance/api/external-accounts/${accountId}/balance/`)
      .then(({ data: { balance } }) => balance as number),
)

export const deleteExternalAccount = bankingAsyncThunk(
  'deleteExternalAccount',
  async (accountId: number): Promise<boolean> =>
    await api.delete(`/finance/api/external-accounts/${accountId}/`),
)

export const getNotificationSettings = bankingAsyncThunk(
  'getNotificationSettings',
  async (): Promise<NotificationSettings> => {
    const { data } = await api.get('/finance/api/notification-settings/')
    return keysToCamel(data)
  },
)

export const getDocuments = bankingAsyncThunk('getDocuments', async (): Promise<Document[]> => {
  const { data } = await api.get('/finance/api/disclosures/')
  return data.results.map((document: any) => ({
    ...document,
    documentUrl: document.document,
  }))
})

export const getPostedTransactions = bankingAsyncThunk(
  'getPostedTransactions',
  async (): Promise<{ results: PostedTransaction[]; nextPageToken: string }> => {
    // TODO: implement pagination. After we have done so, we should uncomment out the following line,
    // to pass the token for the next page of results in this call.
    // const page_token = (getState() as RootState).banking.account.postedTransactionsNextPageToken
    const page_token = ''
    const from_date = '2023-02-01' // TODO: update when we implement searching and pagination
    const to_date = '2024-12-31' // TODO: update when we implement searching and pagination

    const { data } = await api.get('/finance/api/posted-transactions/', {
      params: {
        start_date: from_date,
        end_date: to_date,
        page_token: page_token,
      },
    })
    return keysToCamel(data)
  },
)

export const getPendingTransactions = bankingAsyncThunk(
  'getPendingTransactions',
  async (): Promise<PendingTransaction[]> => {
    // We deliberately show all pending transactions from all time, this time span is meant to cover that.
    const from_date = '2023-02-01'
    const to_date = '2099-12-31'

    const { data } = await api.get('/finance/api/pending-transactions/', {
      params: {
        from_date: from_date,
        to_date: to_date,
      },
    })
    return keysToCamel(data).results
  },
)

export const listStatements = bankingAsyncThunk(
  'listStatements',
  async (): Promise<Statement[]> => {
    const { data } = await api.get('/finance/api/statements/')
    return keysToCamel(data)
  },
)

export const getActivationUrl = bankingAsyncThunk(
  'getActivationUrl',
  async (cardId: number): Promise<string> => {
    const { data } = await api.get(`/finance/api/cards/${cardId}/activation-url/`)
    return data.url
  },
)

export const getSetPinUrl = bankingAsyncThunk(
  'getSetPinUrl',
  async (cardId: number): Promise<string> => {
    const { data } = await api.get(`/finance/api/cards/${cardId}/pin-url/`)
    return data.url
  },
)

export const getStatement = bankingAsyncThunk(
  'getStatement',
  async (statementId: number): Promise<any> => {
    const { data } = await api.get(`/finance/api/statements/${statementId}`)
    return data
  },
)

export const getWidgetToken = bankingAsyncThunk(
  'getWidgetToken',
  async (cardId: number): Promise<{ cardId: number; token: string }> => {
    const { data } = await api.get(`/finance/api/cards/${cardId}/inspection-token/`)
    return {
      cardId,
      token: data['client_token'],
    }
  },
)

export const submitPlaidLinkSuccessCallback = bankingAsyncThunk(
  'submitPlaidLinkSuccessCallback',
  async (plaidPayload: {
    public_token: string
    metadata: PlaidLinkOnSuccessMetadata
  }): Promise<{ addedAccounts: ExternalBankAccount[]; deletedAccountIds: number[] }> => {
    const { data } = await api.post(
      '/finance/api/external/plaid-link-success-callback/',
      plaidPayload,
    )
    return keysToCamel(data)
  },
)

export const getLinkToken = bankingAsyncThunk(
  'getLinkToken',
  async (externalAccountId?: number): Promise<string> => {
    let url = '/finance/api/external/plaid-link-token/'

    if (externalAccountId) url += `${externalAccountId}/`

    return api.get(url).then(({ data }) => data.token)
  },
)

export const updateCard = bankingAsyncThunk(
  'updateCard',
  async (payload: { id: number; cardStatus?: string; statusReason?: string }): Promise<Card> => {
    const { data } = await api.patch(`/finance/api/cards/${payload.id}/`, keysToSnake(payload))
    return keysToCamel(data)
  },
)

export const sendACH = bankingAsyncThunk(
  'sendACH',
  async (payload: {
    amountInPennies: number
    accountId: number
    externalAccountId: number
    transferType: TransferType
  }): Promise<boolean> => {
    const response = await api.post('/finance/api/transfer-funds/', keysToSnake(payload))
    return response.status === 200
  },
)

export const getExternalAccounts = createAsyncThunk(
  'banking/getExternalAccounts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/finance/api/external-accounts/')
      return keysToCamel(response.data.results) as ExternalBankAccount[]
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateNotificationSettings = createAsyncThunk(
  'banking/updateNotificationSettings',
  async (_, { getState, rejectWithValue }) => {
    try {
      const payload = keysToSnake((getState() as RootState).banking.notificationSettingsOnScreen)
      return api.patch('/finance/api/notification-settings/', payload)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const bankingSlice = createSlice({
  name: 'banking',
  initialState,
  reducers: {
    setNotificationSettings(state, { payload }: { payload: Partial<NotificationSettings> }) {
      state.notificationSettingsOnScreen = { ...state.notificationSettingsOnScreen, ...payload }
    },
    setSelectedExternalAccountId(state, { payload }: { payload: number | null }) {
      state.selectedExternalAccountId = payload
    },
    updateCardState(
      state,
      { payload }: { payload: Pick<Card, 'form' | 'cardStatus' | 'statusReason'> },
    ) {
      const key = payload.form === 'PHYSICAL' ? 'physical' : 'virtual'
      if (!state.account.cards[key]) return

      state.account.cards[key] = {
        ...state.account.cards[key],
        ...payload,
      }
    },
    setBankingBusiness(state, { payload }: { payload: Partial<BankingBusiness> }) {
      state.business = {
        ...state.business,
        ...(payload as BankingBusiness),
      }
    },
    setAcknowledgedPatriotAct(state, { payload }: { payload: boolean }) {
      if (state.business) {
        state.business.acknowledgedPatriotAct = payload
      }
    },
    setHasActiveSession(state, { payload }: { payload: boolean }) {
      state.hasActiveSession = payload
    },
    resetLinkToken(state) {
      state.linkToken = ''
      state.loading.getLinkToken = false
      state.externalAccounts = state.externalAccounts.map(acc => ({
        ...acc,
        isGettingLinkToken: false,
        linkToken: '',
      }))
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateCard.pending, state => {
        state.loading.updateCard = true
      })
      .addCase(updateCard.fulfilled, (state, { payload }) => {
        state.loading.updateCard = false
        state.account.cards[payload.form === 'PHYSICAL' ? 'physical' : 'virtual'] = payload
      })
      .addCase(updateCard.rejected, (state, { payload }) => {
        state.loading.updateCard = false
        toast.error(getBankingErrorMessage(payload, 'Failed to update card'))
      })
      .addCase(getBankingAccounts.pending, state => {
        state.loading.account = true
      })
      .addCase(getBankingAccounts.fulfilled, (state, { payload }) => {
        state.loading.account = false
        if (payload.length) {
          state.account = {
            ...state.account,
            ...payload[0],
          }
        }
      })
      .addCase(getBankingAccounts.rejected, (state, { payload }) => {
        state.loading.account = false
        toast.error(getBankingErrorMessage(payload, 'Failed to load bank account'))
      })
      .addCase(getExternalAccounts.pending, state => {
        state.loading.externalAccounts = true
      })
      .addCase(getExternalAccounts.fulfilled, (state, { payload }) => {
        state.loading.externalAccounts = false
        state.externalAccounts = payload
      })
      .addCase(getExternalAccounts.rejected, (state, { payload }) => {
        state.loading.externalAccounts = false
        toast.error(getBankingErrorMessage(payload, 'Failed to load external accounts'))
      })
      .addCase(getBankingBusinesses.pending, state => {
        state.loading.business = true
      })
      .addCase(getBankingBusinesses.fulfilled, (state, { payload }) => {
        state.loading.business = false
        // replace ssnMasked with ssn
        state.business = payload.length ? payload[0] : null
      })
      .addCase(getBankingBusinesses.rejected, (state, { payload }) => {
        state.loading.business = false
        toast.error(getBankingErrorMessage(payload, 'Failed to load banking business'))
      })
      .addCase(getPostedTransactions.pending, state => {
        state.loading.postedTransactions = true
      })
      .addCase(getPostedTransactions.fulfilled, (state, { payload }) => {
        state.loading.postedTransactions = false
        state.account.postedTransactions = payload.results
        state.account.postedTransactionsNextPageToken = payload.nextPageToken
      })
      .addCase(getPostedTransactions.rejected, (state, { payload }) => {
        state.loading.postedTransactions = false
        toast.error(getBankingErrorMessage(payload, 'Failed to get posted transactions'))
      })
      .addCase(getPendingTransactions.pending, state => {
        state.loading.pendingTransactions = true
      })
      .addCase(getPendingTransactions.fulfilled, (state, { payload }) => {
        state.loading.pendingTransactions = false
        state.account.pendingTransactions = payload
      })
      .addCase(getPendingTransactions.rejected, (state, { payload }) => {
        state.loading.pendingTransactions = false
        toast.error(getBankingErrorMessage(payload, 'Failed to get pending transactions'))
      })
      .addCase(listStatements.pending, state => {
        state.loading.listStatements = true
      })
      .addCase(listStatements.fulfilled, (state, { payload }) => {
        state.statements = payload
        state.loading.listStatements = false
      })
      .addCase(listStatements.rejected, (state, { payload }) => {
        state.loading.listStatements = false
        toast.error(getBankingErrorMessage(payload, 'Failed to get statements'))
      })
      .addCase(getStatement.pending, (state, { meta }) => {
        state.loading.getStatement = meta.arg
      })
      .addCase(getStatement.fulfilled, (state, { payload }) => {
        state.loading.getStatement = 0
        window.open(payload.document)
      })
      .addCase(getStatement.rejected, (state, { payload }) => {
        state.loading.getStatement = 0
        toast.error(getBankingErrorMessage(payload, 'Failed to get statement'))
      })
      .addCase(getDocuments.pending, state => {
        state.loading.documents = true
      })
      .addCase(getDocuments.fulfilled, (state, { payload }) => {
        state.documents = payload
        state.loading.documents = false
      })
      .addCase(getDocuments.rejected, (state, { payload }) => {
        state.loading.documents = false
        toast.error(getBankingErrorMessage(payload, 'Failed to get documents'))
      })
      .addCase(getActivationUrl.pending, state => {
        state.loading.activationUrl = true
      })
      .addCase(getActivationUrl.fulfilled, (state, { payload }) => {
        state.loading.activationUrl = false
        state.activationUrl = payload
      })
      .addCase(getActivationUrl.rejected, state => {
        state.loading.activationUrl = false
        toast.error('Failed to get activation URL')
      })
      .addCase(getSetPinUrl.pending, state => {
        state.loading.setPinUrl = true
      })
      .addCase(getSetPinUrl.fulfilled, (state, { payload }) => {
        state.loading.setPinUrl = false
        state.setPinUrl = payload
      })
      .addCase(getSetPinUrl.rejected, (state, { payload }) => {
        state.loading.setPinUrl = false
        toast.error(getBankingErrorMessage(payload, 'Failed to get set pin URL'))
      })
      .addCase(getNotificationSettings.pending, state => {
        state.loading.notificationSettings = true
      })
      .addCase(getNotificationSettings.fulfilled, (state, { payload }) => {
        state.notificationSettingsOnServer = payload
        state.notificationSettingsOnScreen = payload
        state.loading.notificationSettings = false
      })
      .addCase(getNotificationSettings.rejected, (state, { payload }) => {
        state.loading.notificationSettings = false
        toast.error(getBankingErrorMessage(payload, 'Failed to load notification settings'))
      })
      .addCase(updateNotificationSettings.pending, state => {
        state.loading.notificationSettings = true
      })
      .addCase(updateNotificationSettings.fulfilled, state => {
        toast.success('Saved')
        state.loading.notificationSettings = false
        state.notificationSettingsOnServer = state.notificationSettingsOnScreen
      })
      .addCase(updateNotificationSettings.rejected, (state, { payload }) => {
        state.loading.notificationSettings = false
        toast.error(getBankingErrorMessage(payload, 'Failed to save update.'))
      })
      .addCase(getWidgetToken.pending, state => {
        state.loading.widgetToken = true
      })
      .addCase(getWidgetToken.fulfilled, (state, { payload }) => {
        state.widgetTokens[payload.cardId] = payload.token
        state.loading.widgetToken = false
      })
      .addCase(getWidgetToken.rejected, (state, { payload }) => {
        state.loading.widgetToken = false
        toast.error(getBankingErrorMessage(payload, 'Failed to load marqeta widget token'))
      })
      .addCase(submitPlaidLinkSuccessCallback.pending, state => {
        state.loading.isHandlingPlaidSuccessCallback = true
      })
      .addCase(submitPlaidLinkSuccessCallback.fulfilled, (state, { payload }) => {
        state.loading.isHandlingPlaidSuccessCallback = false

        // Payload contains an array of deleted account ids and an array of added accounts
        const accountsWithoutDeleted = differenceBy(
          state.externalAccounts,
          payload.deletedAccountIds.map(id => ({ id })),
          'id',
        )

        // We need to remove deleted accounts from the state and add new ones
        state.externalAccounts = [...accountsWithoutDeleted, ...payload.addedAccounts]
      })
      .addCase(submitPlaidLinkSuccessCallback.rejected, (state, { payload }) => {
        state.loading.isHandlingPlaidSuccessCallback = false
        toast.error(getBankingErrorMessage(payload, 'Failed to handle success callback'))
      })
      .addCase(sendACH.pending, state => {
        state.loading.sendACH = true
      })
      .addCase(sendACH.fulfilled, state => {
        state.loading.sendACH = false
      })
      .addCase(sendACH.rejected, (state, { payload }) => {
        state.loading.sendACH = false
        toast.error(getBankingErrorMessage(payload, 'Failed to initiate transfer'))
      })
      .addCase(deleteBankingPerson.fulfilled, (state, { payload }) => {
        if (state.business) {
          state.business = {
            ...state.business,
            persons: payload,
          }
        }
      })
      .addCase(upsertBankingBusiness.fulfilled, (state, { payload }) => {
        state.business = payload
      })
      .addCase(upsertBankingPerson.fulfilled, (state, { payload }) => {
        if (state.business) {
          state.business = {
            ...state.business,
            persons: payload,
          }
        }
      })
      .addCase(signDisclosures.fulfilled, (state, { payload }) => {
        if (state.business) {
          state.business.verificationStatus = payload
        }
      })
      .addCase(getExternalAccountBalance.pending, (state, { meta: { arg } }) => {
        const account = state.externalAccounts.find(acc => acc.id === arg)
        if (account) {
          account.isFetchingBalance = true
        }
      })
      .addCase(getExternalAccountBalance.rejected, (state, { meta: { arg } }) => {
        const account = state.externalAccounts.find(acc => acc.id === arg)
        if (account) {
          account.isFetchingBalance = false
        }
      })
      .addCase(getExternalAccountBalance.fulfilled, (state, { payload, meta: { arg } }) => {
        const account = state.externalAccounts.find(acc => acc.id === arg)
        if (account) {
          account.availableBalance = payload
          account.isFetchingBalance = false
        }
      })
      .addCase(deleteExternalAccount.pending, (state, { meta: { arg } }) => {
        const account = state.externalAccounts.find(acc => acc.id === arg)
        if (account) {
          account.isDeleting = true
        }
      })
      .addCase(deleteExternalAccount.rejected, (state, { meta: { arg } }) => {
        const account = state.externalAccounts.find(acc => acc.id === arg)
        if (account) {
          account.isDeleting = false
        }
      })
      .addCase(deleteExternalAccount.fulfilled, (state, { meta: { arg } }) => {
        state.externalAccounts = state.externalAccounts.filter(acc => acc.id !== arg)
        if (state.selectedExternalAccountId === arg) {
          state.selectedExternalAccountId = null
        }
      })
      .addCase(getLinkToken.pending, (state, { meta }) => {
        if (meta.arg) {
          const idx = state.externalAccounts.findIndex(acc => acc.id === meta.arg)
          if (idx !== -1) {
            state.externalAccounts.splice(idx, 1, {
              ...state.externalAccounts[idx],
              isGettingLinkToken: true,
            })
          }
        } else {
          state.loading.getLinkToken = true
        }
      })
      .addCase(getLinkToken.rejected, (state, { meta }) => {
        if (meta.arg) {
          const idx = state.externalAccounts.findIndex(acc => acc.id === meta.arg)
          if (idx !== -1) {
            state.externalAccounts.splice(idx, 1, {
              ...state.externalAccounts[idx],
              isGettingLinkToken: false,
            })
          }
        } else {
          state.loading.getLinkToken = false
        }
        toast.error('Could not initiate Plaid Link. Please try again.')
      })
      .addCase(getLinkToken.fulfilled, (state, { payload }) => {
        // we're not setting loading to false here because we want to wait for the user to finish plaid flow
        state.linkToken = payload
      })
  },
})

export const {
  setAcknowledgedPatriotAct,
  setBankingBusiness,
  setHasActiveSession,
  setNotificationSettings,
  setSelectedExternalAccountId,
  updateCardState,
  resetLinkToken,
} = bankingSlice.actions

export default bankingSlice.reducer
