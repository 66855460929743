import { Tooltip } from '@components'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import equal from 'fast-deep-equal'
import { Column, Row } from 'react-table'

import { RecoveryDetail } from '../../common/types/factoring'
import { formatCurrency } from '../../common/utils'
import { DateTime, Money, Table } from '../../components/Table'
import { getRecoveryDetails, setLimit, setOffset } from '../../redux/carrierCompanyRecoveriesSlice'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { RecoveryDetailsTableMobile } from './RecoveryDetailsTableMobile'

const columns: Column<RecoveryDetail>[] = [
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: DateTime,
  },
  { Header: 'Load ID', accessor: 'loadId' },
  { Header: 'Reference #', accessor: 'referenceNum' },
  { Header: 'Description', accessor: 'description' },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ row }: { row: Row<RecoveryDetail> }) => {
      const amount = row.original.amount
      return (
        <div className='flex justify-center items-center'>
          <div className='relative'>
            {row.original.hasAmountTooltip && (
              <Tooltip
                direction='up'
                content={
                  <div>
                    <span>Only a partial recovery was made on this factored load</span>
                  </div>
                }
              >
                <InformationCircleIcon className='absolute -top-1 right-12' />
              </Tooltip>
            )}
            <span>{amount < 0 ? `-${formatCurrency(amount)}` : formatCurrency(amount)}</span>
          </div>
        </div>
      )
    },
  },
  {
    Header: 'Outstanding',
    accessor: 'balance',
    Cell: Money,
  },
  {
    Header: '',
    accessor: val => val,
    id: 'id-link',
    Cell: ({ row }: { row: Row<RecoveryDetail> }) => (
      // discover what route should this be
      <a className='text-link' href={`/loads/${row.original.loadId}`}>
        {'Load >'}
      </a>
    ),
  },
]

export type RecoveryDetailsProps = {
  recoveryId: number
}

export const RecoveryDetailsTableDesktop = (props: RecoveryDetailsProps) => {
  const dispatch = useAppThunkDispatch()
  const rows = useAppSelector(state => state.carrierCompanyRecoveries.recoveries, equal)
  const count = useAppSelector(state => state.carrierCompanyRecoveries.count.recoveries)
  const offset = useAppSelector(state => state.carrierCompanyRecoveries.offset)

  const updateOffset = (offset: number) => {
    dispatch(setOffset(offset))
    dispatch(getRecoveryDetails({ id: props.recoveryId }))
  }
  const setSize = () => {
    dispatch(setLimit(100))
    dispatch(getRecoveryDetails({ id: props.recoveryId }))
  }

  return (
    <Table
      noFilters
      columns={columns}
      count={count}
      data={rows}
      emptyText={'No recoveries detail history with us yet'}
      isPaginated={false}
      loading={false}
      mobileComponent={(recovery: RecoveryDetail) => <RecoveryDetailsTableMobile item={recovery} />}
      offset={offset}
      setOffset={updateOffset}
      setSize={setSize}
      size={100}
      tableClassName='shrink'
    />
  )
}
