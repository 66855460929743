import './TextWithTooltip.scss'

import Tooltip from 'react-tooltip-lite'

import { classNames } from '../../common/utils'

export const TextWithTooltip = ({
  children = '',
  limit = 14,
  className = '',
}: {
  children: string
  limit?: number
  className?: string
}) => (
  <div className={classNames('w-12', className)}>
    {(children ?? '').length > limit ? (
      <Tooltip content={<div className='tooltip-content-container'>{children}</div>}>
        <div className='tooltip-content'>{children}</div>
      </Tooltip>
    ) : (
      <div>{children}</div>
    )}
  </div>
)
