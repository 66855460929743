import { broadcastLogin } from '@common'
import { Button, TextInput } from '@components'
import { useEffect } from 'react'

import { isLocal, isPreview } from '../../common/constants'
import { formatPhone } from '../../common/utils'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { patchCredentials, setLoginStep, verifyOTP } from '../../redux/userSlice'
import { Link } from '../Login'

export const ValidateOTPForm = () => {
  const dispatch = useAppThunkDispatch()

  const credentials = useAppSelector(state => state.user.login.credentials)
  const error = useAppSelector(state => state.user.login.error)
  const loading = useAppSelector(state => state.user.login.loading)

  useEffect(() => {
    if (isLocal || isPreview) dispatch(patchCredentials({ otp: '987654' }))
  }, [])

  const validateCode = async () => {
    const response = await dispatch(verifyOTP())
    if (response.meta.requestStatus === 'rejected') return
    if (response.payload.carrierCompanyId) broadcastLogin()
  }

  return (
    <>
      <div className='md:mx-8'>
        <span className='text-dark-gray'>
          Please enter the verification code that we have sent to{' '}
        </span>
        <span className='font-semibold'>{credentials.email || formatPhone(credentials.phone)}</span>
      </div>
      <TextInput
        autoFocus
        className='mt-6 mb-6'
        id='code'
        label='Verification Code'
        labelStyle='md:text-gray-500 text-white'
        minLength={6}
        name='code'
        value={credentials.otp}
        onChange={(otp: string) => dispatch(patchCredentials({ otp }))}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') validateCode()
        }}
      />
      {error && <div className='text-red-500 font-semibold text-center pb-6'>{error}</div>}
      <Button
        fullWidth
        lg
        className='mb-6'
        disabled={credentials.otp.length != 6}
        loading={loading}
        type='warn'
        onClick={validateCode}
      >
        Log In
      </Button>
      <Link onClick={() => dispatch(setLoginStep('LOGIN'))}>Go back and try again</Link>
    </>
  )
}
