import { LatitudeLongitude } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { api } from '../api/api'
import { keysToCamel } from '../common/utils'

type LocationsState = {
  loading: {
    locations: boolean
    routeDistance: boolean
    cities: boolean
  }
  locations: Array<any>
  routeDistance: number
  routePolylines: Array<any>
  autocompleteLocations: Array<any>
  cities: (LatitudeLongitude & { city: string; state: string; country: string })[]
  popularCities: [string, string, string, number, number][]
  requestTimestamps: {
    cities: number | null
  }
}

const initialState: LocationsState = {
  loading: {
    locations: false,
    routeDistance: false,
    cities: false,
  },
  locations: [],
  routeDistance: 0,
  routePolylines: [],
  autocompleteLocations: [],
  cities: [],
  popularCities: [],
  requestTimestamps: {
    cities: null,
  },
}

export const geocode = createAsyncThunk(
  'locations/geocode',
  async (payload: { address: string; citiesOnly: boolean }) => {
    const { address, citiesOnly } = payload
    const response = await api.get('/locations/api/here-map-geocode-proxy/', {
      params: { query: encodeURI(address) },
    })
    // We use HereMaps for our location api, this is a link to the api docs:
    // https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/result-types.html
    if (citiesOnly) {
      return response.data.items.filter((item: any) => item.resultType === 'locality')
    }
    return response.data.items
  },
)

export const getAutocompleteLocations = createAsyncThunk(
  'locations/getAutocompleteLocations',
  async (query: string) =>
    api
      .get('/locations/location-front-end-autocomplete/', { params: { q: query } })
      .then(({ data }) => data.results),
)

export const getPopularCities = createAsyncThunk('locations/getPopularCities', async () =>
  api.get('/locations/geocode/popular-cities-for-autocomplete-v5/').then(({ data }) => data),
)

export const getCities = createAsyncThunk(
  'locations/getCities',
  async ({ query, countries = [] }: { query: string; countries?: string[] }) => {
    const requestTimestamp = Date.now()
    const response = await api.get('/locations/geocode/search/', {
      params: { query, countries_filter: countries.join(',') },
    })

    return { requestTimestamp, cities: keysToCamel(response.data) }
  },
)

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(geocode.pending, state => {
        state.loading.locations = true
      })
      .addCase(geocode.fulfilled, (state, { payload }) => {
        state.loading.locations = false
        state.locations = payload
      })
      .addCase(geocode.rejected, state => {
        state.loading.locations = false
      })
      .addCase(getAutocompleteLocations.fulfilled, (state, { payload }) => {
        state.autocompleteLocations = keysToCamel(payload)
      })
      .addCase(getCities.pending, state => {
        state.loading.cities = true
      })
      .addCase(getCities.fulfilled, (state, { payload }) => {
        const { requestTimestamp, cities } = payload
        // NOTE: this request is intentionally un-debounced for a more responsive feel
        // To handle race conditions - we store/compare timestamps from when the request was started
        if (!state.requestTimestamps.cities || requestTimestamp > state.requestTimestamps.cities) {
          state.loading.cities = false
          state.cities = cities
          state.requestTimestamps.cities = requestTimestamp
        }
      })
      .addCase(getCities.rejected, state => {
        state.loading.cities = false
      })
      .addCase(getPopularCities.pending, state => {
        state.loading.cities = true
      })
      .addCase(getPopularCities.fulfilled, (state, { payload }) => {
        state.loading.cities = false
        state.popularCities = payload
      })
      .addCase(getPopularCities.rejected, state => {
        state.loading.cities = false
      })
  },
})

export default locationsSlice.reducer
