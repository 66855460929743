import tw from 'tailwind-styled-components'

import { CheckboxSelectItem } from '../CheckboxSelectItem'

export type CheckboxSelectElement = {
  title: string
  description: string
  value?: string
  disabled?: boolean
  cta?: {
    text: string
    url: string
  }
}

export type CheckboxSelectProps = {
  data: Array<CheckboxSelectElement>
  selected: number | string
  setData: Function
  className?: string
  noDescription?: boolean
}

export const CheckboxSelect = ({
  data,
  selected,
  setData,
  className = '',
  noDescription = false,
}: CheckboxSelectProps) => (
  <Container className={className}>
    {data.map((el, index) => (
      <CheckboxSelectItem
        key={index}
        index={index}
        isSelected={[el.value, index].includes(selected)}
        item={el}
        noDescription={noDescription}
        setData={setData}
      />
    ))}
  </Container>
)

const Container = tw.div`
  flex
  flex-col
  gap-2
  md:flex-row
  justify-between
`
