import tw from 'tailwind-styled-components'

export const ExpandButton = ({
  children,
  onClick,
  disabled = false,
}: {
  children: string | string[]
  onClick: () => void
  disabled?: boolean
}) => (
  <Container $disabled={disabled} onClick={() => !disabled && onClick()}>
    {children}
  </Container>
)

const Container = tw.div<{ $disabled: boolean }>`
  font-bold
  text-xs
  flex
  justify-end
  mt-2
  text-border-gray
  transition-all
  ml-auto
  ${({ $disabled }) => !$disabled && 'text-link cursor-pointer hover:underline'}
`
