/**
 * Masks in this file are to be used to format a string to conform into a specified pattern.
 * The way to use is by calling the resolve function, or pass directly to the mask prop in the TextInput component.
 * See https://imask.js.org/guide.html for documentation
 *
 * Example: phoneMask.resolve('8888888888') -> +1 (888) 888-8888
 */

import { merge } from 'lodash-es'
import { IMask } from 'react-imask'

// Turns a string '8888888888' into '+1 (888) 888-8888'
export const getPhoneMaskOptions = () => ({ mask: '+1 (000) 000-0000', lazy: false })

export const formatPhone = (value?: string): string =>
  !value ? '' : IMask.createMask(getPhoneMaskOptions()).resolve(String(value))

// Turns a string '100000' into '$ 100,000'
export const getCurrencyMaskOptions = (withCurrencySign = true) => ({
  mask: `${withCurrencySign ? '$' : ''}num`,
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
      radix: '.',
      scale: 2,
      padFractionalZeros: true,
    },
  },
})

/**
 * Extended mask for dollar currency which pads 0s at the end for display purposes only
 * Turns a string '100000' into '$ 100,000.00'
 */
export const formatCurrency = (value?: string | number, withCurrencySign = true): string =>
  typeof value === 'undefined' || value === null
    ? ''
    : IMask.createMask(
        merge(getCurrencyMaskOptions(withCurrencySign), {
          blocks: {
            num: {
              padFractionalZeros: true,
            },
          },
        } as Partial<IMask.AnyMaskedOptions>),
      ).resolve(String(value))

// example of usage: parseDate(new Date(reservation.expiresAt)).date
export const parseDate = (date: Date) => ({
  date: date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }),
  time: date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }),
})
