import { Button, Modal, Status } from '@components'
import { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { setOnboardingDetails, setOnboardingStep } from '../../../redux/userSlice'
import { DetailSectionRow } from '../../DetailSectionRow'
import { DetailsSectionTitle } from '../../DetailsSectionTitle'
import { Hint } from '../../Hint'
import {
  Box,
  DetailSectionRowBold,
  DetailSectionRowPill,
  DetailSectionRowStatus,
} from '../components'

const Item = ({ title, className = '' }: { title: string; className?: string }) => (
  <div className={`flex items-center text-dark-gray ${className}`}>
    <BulletPoint />
    <div>{title}</div>
  </div>
)

export const Qualification = () => {
  const [isVisible, setVisible] = useState(false)
  const dispatch = useAppThunkDispatch()

  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)

  const { qualification, qualificationConfirmed } = onboardingDetails
  const { carrierDetails, certData, operation } = qualification

  const setConfirmed = (qualificationConfirmed: boolean) => {
    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        qualificationConfirmed,
      }),
    )
  }

  const resetForm = () => {
    dispatch(setOnboardingStep(null))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className='border border-brand-light rounded-lg p-4 md:p-6 form'>
        <div className='font-semibold border-b border-brand-light pb-4'>
          To complete our setup process you will need the following:
        </div>
        <div className='flex flex-col md:flex-row justify-between mt-4 text-left'>
          <div>
            <Item className='mb-4' title='MC or DOT Number' />
            <Item className='mb-4' title='Company contact information' />
            <Item className='mb-4 md:mb-0' title='W9' />
          </div>
          <div>
            <Item className='mb-4' title='$100,000 Cargo Coverage' />
            <Item className='mb-4' title='$1,000,000 Auto Liability' />
            <Item title='Active Common or Contract Authority' />
          </div>
        </div>
      </div>
      <DetailsContainer>
        <Hint
          text='Please check if all information listed below is correct &amp; confirm it at the end of this
          list.'
        />
        <DetailsSectionTitle title='U.S. DOT Information' />
        <DetailSectionRowPill title='DOT Number'>{carrierDetails.dotNumber}</DetailSectionRowPill>
        <DetailSectionRowBold title='Legal Name'>{carrierDetails.name}</DetailSectionRowBold>
        <DetailSectionRowBold title='Address'>{carrierDetails.address}</DetailSectionRowBold>
        <DetailSectionRow title='Operating Status'>
          <Status status={operation?.operatingStatus} />
        </DetailSectionRow>
        <DetailsSectionTitle title='Pulse Requirements' />
        <div className='flex flex-col md:flex-row md:justify-between'>
          <DetailSectionRowStatus
            className='w-full md:w-1/2 mb-0 md:mb-4'
            title='DOT Available to Register?'
          >
            yes
          </DetailSectionRowStatus>
          <DetailSectionRowStatus className='w-full md:w-1/2' title='Insurance on File'>
            {certData?.['@status'] === 'OK' ? 'yes' : 'no'}
          </DetailSectionRowStatus>
        </div>
        <Box className={qualificationConfirmed ? 'border-success' : 'border-link'}>
          <div className='font-semibold mb-4 md:mb-0'>
            Does this information
            <br />
            match your company?
          </div>
          <div className='flex items-center'>
            {!qualificationConfirmed ? (
              <>
                <Button short className='mr-5' type='secondary' onClick={() => setVisible(true)}>
                  no
                </Button>
                <Button short type='warn' onClick={() => setConfirmed(true)}>
                  yes
                </Button>
              </>
            ) : (
              <>
                <CancelConfirmButton onClick={() => setConfirmed(false)}>
                  cancel
                </CancelConfirmButton>
                <Button short preIcon='check-circle' touchable={false} type='success'>
                  yes
                </Button>
              </>
            )}
          </div>
        </Box>
      </DetailsContainer>
      <Modal isVisible={isVisible} setVisible={setVisible}>
        <div className='p-4 md:p-6 text-center'>
          <ModalHeading>carrier qualification</ModalHeading>
          <div className='py-6 text-2xl'>Information is not matching</div>
          <div className='text-dark-gray'>
            You have stated that information we have does not match.
            <br />
            Please go back and provide the correct MC / DOT Number
          </div>
          <ModalButtonContainer>
            <Button
              lg
              mobileFullWidth
              short
              className='md:mr-4 mb-4 md:mb-0'
              type='secondary'
              onClick={() => setVisible(false)}
            >
              cancel
            </Button>
            <Button lg mobileFullWidth onClick={resetForm}>
              Provide new Eligibility number
            </Button>
          </ModalButtonContainer>
        </div>
      </Modal>
    </div>
  )
}

const BulletPoint = tw.div`
  bg-link
  h-2
  mr-2
  rounded-full
  start-dot
  w-2
`

const CancelConfirmButton = tw.button`
  cursor-pointer
  font-semibold
  hover:opacity-70
  md:px-4
  mr-5
  px-2
  py-2
  transition-all
  uppercase
`

const DetailsContainer = tw.div`
  border
  border-brand-light
  form
  md:p-6
  md:pb-0
  md:pt-2
  mt-4
  p-4
  pb-0
  pt-2
  relative
  rounded-lg
  text-left
`

const ModalHeading = tw.div`
  font-semibold
  text-dark-gray
  text-sm
  uppercase
`

const ModalButtonContainer = tw.div`
  flex
  flex-col
  items-center
  md:flex-row
  md:justify-between
  mt-6
`
