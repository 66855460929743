import { CatchError, formatAxiosErrorToPayload } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { SalesRep } from '../common/types'

type ContactCarrierRepState = {
  detail: SalesRep
  detailLoading: boolean
}

const initialState: ContactCarrierRepState = {
  detail: {
    name: '',
    email: '',
    phone: '',
  },
  detailLoading: false,
}

export const getCarrierManagerDetail = createAsyncThunk(
  'messageCarrierRep/getCarrierManagerDetail',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/accounts/api/carrier/manager-detail/')
      return response.data as SalesRep
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const messageCarrierRepSlice = createSlice({
  name: 'messageCarrierRep',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCarrierManagerDetail.pending, state => {
        state.detailLoading = true
      })
      .addCase(getCarrierManagerDetail.fulfilled, (state, action) => {
        state.detail = action.payload
      })
      .addCase(getCarrierManagerDetail.rejected, state => {
        state.detailLoading = true
        toast.error('Error getting carrier rep')
      })
  },
})

export default messageCarrierRepSlice.reducer
