import { broadcastLogin, validateEmail, validatePhoneNumber } from '@common'
import { Button } from '@components'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { trackEvent } from '../../common/tracking'
import { getDocumentTitle, validateAccountNumber, validateFullName } from '../../common/utils'
import {
  Agreement,
  ARInformation,
  ContactInformation,
  DocumentsUpload,
  PaymentInformation,
  Qualification,
} from '../../components/Onboarding/steps'
import { ProgressIndicator } from '../../components/ProgressIndicator'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import {
  createCarrierCompany,
  resetOnboarding,
  setOnboardingDetails,
  setOnboardingStep,
} from '../../redux/userSlice'

type OnboardingStep = {
  title: string
  component: JSX.Element
}

export const OnboardingSteps: OnboardingStep[] = [
  { title: 'Verification', component: <Qualification /> },
  { title: 'Company', component: <ARInformation /> },
  { title: 'Contacts', component: <ContactInformation /> },
  { title: 'Payment', component: <PaymentInformation /> },
  { title: 'Documents', component: <DocumentsUpload /> },
  { title: 'Agreement', component: <Agreement /> },
]

const indicatorOnboardingSteps = OnboardingSteps.map(step => step.title)

export const OnboardingScreen = () => {
  const location = useLocation()
  const dispatch = useAppThunkDispatch()
  const [cookies] = useCookies(['referral_code'])

  const buttonLoading = useAppSelector(state => state.user.onboarding.buttonLoading)
  const currentStep = useAppSelector(state => state.user.onboarding.currentStep) || 0
  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)

  const handleSubmit = () => {
    dispatch(createCarrierCompany())
      .unwrap()
      .then(() => {
        location.state = { from: '/capacity' }
        broadcastLogin()
      })
      .catch(() => {})
  }

  useEffect(() => {
    document.title = getDocumentTitle('Haul with us')
    const referralCode = cookies['referral_code']
    if (referralCode) {
      dispatch(setOnboardingDetails({ ...onboardingDetails, referralCode }))
    }
  }, [])

  const disabledNext = () => {
    const {
      qualificationConfirmed,
      accountReceivableInformation,
      paymentInformation,
      documents,
      contacts,
      signing,
    } = onboardingDetails

    switch (currentStep) {
      case 0:
        return !qualificationConfirmed
      case 1:
        return (
          !accountReceivableInformation?.entityName ||
          !accountReceivableInformation.secNumber ||
          !accountReceivableInformation.address ||
          !accountReceivableInformation.city ||
          !accountReceivableInformation.state ||
          !accountReceivableInformation.postalCode ||
          !documents.w9.name
        )
      case 2:
        return (
          !validateFullName(contacts.primaryContact.name) ||
          !validatePhoneNumber(contacts.primaryContact.phone, true) ||
          !validateEmail(contacts.primaryContact.email, true) ||
          !validateEmail(contacts.primaryContact.emailRemit, true) ||
          contacts.individualContacts.some(
            (x: any) =>
              !validateFullName(x.name) ||
              !validatePhoneNumber(x.phone, true) ||
              !validateEmail(x.email, true) ||
              !validateEmail(x.emailRemit, true) ||
              !x.types.length,
          ) ||
          contacts.primaryContact.name.split(' ').length < 2
        )
      case 3:
        return (
          (paymentInformation?.paymentMethod === 'ACH' &&
            (!paymentInformation?.name ||
              !paymentInformation?.accountType ||
              !paymentInformation?.routingNumber ||
              !paymentInformation?.accountNumber ||
              !paymentInformation?.accountNumberConfirm ||
              paymentInformation?.accountNumber !== paymentInformation?.accountNumberConfirm ||
              !validateAccountNumber(paymentInformation?.accountNumber))) ||
          (paymentInformation?.paymentMethod === 'CHECK' &&
            (!paymentInformation?.name ||
              !paymentInformation?.businessName ||
              !paymentInformation?.address ||
              !paymentInformation?.city ||
              !paymentInformation?.state ||
              !paymentInformation?.postalCode)) ||
          (paymentInformation?.paymentMethod === 'FACTORING' &&
            (!paymentInformation?.name || !paymentInformation?.factoringCompanyId))
        )
      case 4:
        return !documents.certificate.name || !documents.insurance.length
      case 5:
        return (
          !signing.signed ||
          (!onboardingDetails.invitedBy && (!signing.firstName || !signing.lastName))
        )
      default:
        return false
    }
  }

  const onNext = () => {
    // scroll to top of page
    document.querySelector('.page-container')?.scrollTo(0, 0)

    if (currentStep === 3 && onboardingDetails.isInterestedInFactoring) {
      const [firstName, ...remainder] = onboardingDetails.contacts.primaryContact.name.split(' ')
      const lastName = remainder.slice(-1)[0]

      trackEvent('Factoring Interest Sent', {
        'Email-Address': onboardingDetails.contacts.primaryContact.email,
        'First-Name': firstName,
        'Last-Name': lastName,
        'Phone-Number': onboardingDetails.contacts.primaryContact.phone,
      })
    }
    dispatch(setOnboardingStep(currentStep + 1))
  }

  const onBack = () => {
    if (buttonLoading) return

    // scroll to top of page
    document.querySelector('.page-container')?.scrollTo(0, 0)
    dispatch(setOnboardingStep(currentStep - 1))
  }

  const renderNavigationButtons = () => (
    <div className='flex items-center justify-end'>
      <Button
        lg
        className='mr-2 md:mr-6'
        disabled={buttonLoading}
        innerClassName='px-2 md:px-6'
        type='secondary'
        onClick={() => onBack()}
      >
        Back
      </Button>
      {currentStep === 5 ? (
        <Button
          lg
          mobileFullWidth
          disabled={disabledNext() || buttonLoading}
          innerClassName='px-4 md:px-12'
          loading={buttonLoading}
          preIcon='arrow-right'
          type='success'
          onClick={() => !buttonLoading && handleSubmit()}
        >
          Sign Up
        </Button>
      ) : (
        <Button
          lg
          mobileFullWidth
          disabled={disabledNext()}
          innerClassName='px-4 md:px-12'
          loading={buttonLoading}
          preIcon='arrow-right'
          type='primary'
          onClick={onNext}
        >
          Next
        </Button>
      )}
    </div>
  )

  return (
    <div className='flex flex-col overflow-auto grow'>
      <PageContainer>
        <ProgressIndicator
          className='form'
          currentStep={currentStep}
          steps={indicatorOnboardingSteps}
        />
        {onboardingDetails.invitedBy && (
          <div className='text-center mt-2 bg-brand-light p-2 rounded-lg'>
            A special welcome from {onboardingDetails.invitedBy}!
          </div>
        )}
        <div className='text-center mt-2 md:mt-6'>{OnboardingSteps[currentStep].component}</div>
      </PageContainer>
      <div className='bg-lighter-blue w-screen p-4 md:p-6 self-end h-18 md:h-22'>
        <div className='flex justify-between'>
          <Button
            lg
            mobileFullWidth
            disabled={buttonLoading}
            innerClassName='px-4'
            type='danger'
            onClick={() => !buttonLoading && dispatch(resetOnboarding())}
          >
            Exit
          </Button>
          {renderNavigationButtons()}
        </div>
      </div>
    </div>
  )
}

const PageContainer = tw.div`
  page-container
  overflow-auto
  flex
  flex-col
  grow
  items-center
  pb-24
  pt-6
`
