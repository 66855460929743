import dayjs from 'dayjs'

import { DBFilters, Filters } from '../types'
import { classNames } from './classNames'

export const formatFilters = (filters: Filters): DBFilters => {
  const filtersObj = <DBFilters>{
    origin_city: filters.origin.city || null,
    origin_state: filters.origin.state || null,
    destination_city: filters.destination.city || null,
    destination_state: filters.destination.state || null,
    from_date: filters.dateRange[0] ? dayjs(filters.dateRange[0]).format('YYYY-MM-DD') : null,
    to_date: filters.dateRange[1] ? dayjs(filters.dateRange[1]).format('YYYY-MM-DD') : null,
    equipment_type: filters.equipmentType || null,
    deadhead_origin_miles: parseInt(filters.deadHeadOriginMiles?.replace(/[^0-9]/g, '')) || null,
    deadhead_destination_miles:
      parseInt(filters.deadHeadDestinationMiles?.replace(/[^0-9]/g, '')) || null,
    load_id: parseInt(filters.loadId) || null,
    bookable: filters.bookable || null,
    invoice_number: filters.invoice || null,
    check_number: filters.check || null,
  }

  Object.keys(filtersObj).forEach(key => {
    if (filtersObj[key as keyof DBFilters] === null) {
      delete filtersObj[key as keyof DBFilters]
    }
  })

  return filtersObj
}

export const getRowClassNames = (
  isEven: boolean,
  id: number,
  clickable: boolean,
  selectedId?: number,
): string =>
  classNames(
    isEven ? 'bg-white' : 'bg-lighter-gray',
    id === selectedId ? 'bg-brand-light' : '',
    clickable ? 'cursor-pointer' : '',
  )
