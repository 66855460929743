import './InfiniteTable.scss'

import { Loader } from '@components'
import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

import { classNames } from '../../common/utils'
import { InfiniteScroller } from '../InfiniteScroller'

export const InfiniteTable = ({
  headerItems = [],
  loading,
  items,
  count,
  fetchData,
  children,
  targetId,
  emptyText,
  type = 'regular',
  loaderClassName,
}: {
  headerItems?: Array<string>
  loading: boolean
  items: Array<any>
  count: number
  fetchData: Function
  children: ReactNode
  targetId: string
  emptyText?: string | false
  type?: string
  loaderClassName?: string
}) => {
  const renderHeader = () => (
    <Header>
      {headerItems.map((item, i) => (
        <div
          key={item}
          className={classNames('table-item', i === headerItems.length - 1 ? 'text-right' : '')}
        >
          {item}
        </div>
      ))}
    </Header>
  )

  return (
    <div className='infinite-table'>
      {renderHeader()}
      {!items.length && !loading && (
        <div className='text-dark-gray my-4 text-center'>{emptyText || 'No data'}</div>
      )}
      <div className={`scroll-view-${type} relative`} id={targetId}>
        {loading && <Loader className={loaderClassName} />}
        <InfiniteScroller
          count={count}
          fetchData={fetchData}
          length={items.length}
          targetId={targetId}
        >
          {children}
        </InfiniteScroller>
      </div>
    </div>
  )
}

const Header = tw.div`
   w-full 
   px-6 
   py-4 
   justify-between 
   border-b 
   border-lighter-gray 
   gap-2 
   md:flex
   hidden
`
