import { formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { CompanyDocument } from '../common/types'
import { keysToCamel } from '../common/utils'

const initialState: {
  loadStatements: CompanyDocument[]
  loading: {
    loadStatementsList: boolean
  }
} = {
  loadStatements: [],
  loading: {
    loadStatementsList: false,
  },
}

export const getLoadStatements = createAsyncThunk(
  'documents/getLoadStatements',
  async (_, { rejectWithValue }) =>
    api
      .get(`/accounts/api/carrier/load-statement/`)
      .then(({ data }) => keysToCamel(data) as { count: number; results: CompanyDocument[] })
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getLoadStatements.pending, state => {
        state.loading.loadStatementsList = true
      })
      .addCase(getLoadStatements.fulfilled, (state, { payload }) => {
        state.loading.loadStatementsList = false
        state.loadStatements = payload.results
      })
      .addCase(getLoadStatements.rejected, (state, { payload }) => {
        state.loading.loadStatementsList = false
        toast.error(getErrorString(payload, 'Failed to load your documents'))
      })
  },
})

export default documentsSlice.reducer
