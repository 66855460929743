import '@react-pdf-viewer/core/lib/styles/index.css'

import { LoaderSpinner, Modal } from '@components'
import { Viewer as PDFViewer } from '@react-pdf-viewer/core'
import tw from 'tailwind-styled-components'

export const FileViewer = ({
  isModalVisible,
  mimeType,
  setModalVisible,
  url,
}: {
  isModalVisible: boolean
  mimeType: string
  setModalVisible: (isVisible: boolean) => void
  url: string
}) => (
  <Modal className='w-[60vw]' isVisible={isModalVisible} setVisible={setModalVisible}>
    <ModalContent>
      {mimeType === 'application/pdf' ? (
        <PDFViewer fileUrl={url} renderLoader={() => <LoaderSpinner />} />
      ) : (
        <div className='flex items-center justify-center h-full'>
          <img alt='file preview' src={url} />
        </div>
      )}
    </ModalContent>
  </Modal>
)

const ModalContent = tw.div`
  h-[90vh]
  w-full
`
