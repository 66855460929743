import { Button, TextInput } from '@components'
import { useEffect, useMemo, useState } from 'react'

import { getCredentials, validateEmail } from '../../common/utils'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { checkContact, patchCredentials, sendOTP, setLoginStep } from '../../redux/userSlice'

export const SendOTPForm = () => {
  const dispatch = useAppThunkDispatch()
  const credentials = useAppSelector(state => state.user.login.credentials)
  const error = useAppSelector(state => state.user.login.error)
  const loading = useAppSelector(state => state.user.login.loading)
  const currentEmail = useAppSelector(state => state.user.login.credentials.email)

  const [userId, setUserId] = useState('')
  const sanitizePhone = (userId: string) => userId.replace(/\D/g, '').slice(-10)

  useEffect(() => {
    if (currentEmail) setUserId(currentEmail)
    else {
      const { email, phone, isEmail } = getCredentials()
      setUserId(isEmail ? email : phone)
    }
  }, [])

  useEffect(() => {
    if (validateEmail(userId)) {
      dispatch(patchCredentials({ email: userId, phone: '' }))
    } else {
      const phone = sanitizePhone(userId)
      if (phone.length === 10) {
        dispatch(patchCredentials({ phone, email: '' }))
      }
    }
  }, [userId])

  const formIsValid = useMemo(
    () => credentials.phone.length === 10 || validateEmail(credentials.email),
    [credentials.email, credentials.phone],
  )

  const handleClickAction = async () => {
    const response = await dispatch(checkContact())

    if (response.meta.requestStatus === 'rejected') return

    // email/phone exists in contact
    if (response.payload) dispatch(sendOTP())
    // email/phone does not exist
    else dispatch(setLoginStep('ONBOARD'))
  }

  return (
    <div className='w-full'>
      <p className='text-xs mb-8 text-gray-600'>
        Whether you are new or returning, enter your information below to continue
      </p>
      <TextInput
        required
        className='mb-6'
        id='contact'
        label='Email or Phone Number'
        labelStyle='text-gray-500 '
        name='contact'
        type='email'
        value={userId}
        onChange={input => setUserId(input)}
      />
      {!loading && error && (
        <div className='text-red-500 font-semibold text-center pb-6'>{error}</div>
      )}
      <Button
        fullWidth
        lg
        disabled={loading || !formIsValid || !!error}
        loading={loading}
        type='warn'
        onClick={handleClickAction}
      >
        Continue
      </Button>
    </div>
  )
}
