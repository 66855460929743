import { Checkbox, TextInput } from '@components'
import { TrashIcon } from '@heroicons/react/24/outline'
import React from 'react'

import { getPhoneMaskOptions, validateEmail, validateFullName } from '../../../common/utils'
import { DetailsSectionTitle } from '../../DetailsSectionTitle'

const types = [
  'POD Requests',
  'Dispatch',
  'Accounting',
  'Claims',
  'SMS Load Offers',
  'Email Load Offers',
]
const typesRequiredForPrimary = ['Dispatch']

import '../Onboarding.scss'

import { validatePhoneNumber } from '@common'

type ContactFormProps = {
  contact: any
  isPrimary?: boolean
  setItem: Function
  title?: string
  onDelete?: React.MouseEventHandler<SVGSVGElement>
}

export const ContactForm = ({
  contact,
  isPrimary = false,
  setItem,
  title,
  onDelete = () => {},
}: ContactFormProps) => (
  <div className='border border-brand-light rounded-lg mt-6 pb-2 text-left'>
    {title && (
      <div className='border-b border-brand-light px-6 py-6 flex items-center justify-between'>
        <div className='font-semibold text-lg'>{title}</div>
        <TrashIcon className='icon text-error w-5 h-5' onClick={onDelete} />
      </div>
    )}
    <div className='px-6'>
      <DetailsSectionTitle {...(!isPrimary && { required: true })} title='type' />
      <div className='flex mt-4 flex-wrap'>
        {types.map(type => (
          <div
            key={type}
            className='flex items-center my-2 cursor-pointer hover:opacity-80 mr-3'
            style={{ minWidth: '180px' }}
            onClick={() => {
              let types = []
              if (contact?.types?.includes(type))
                types = contact?.types?.filter((item: string) => item !== type)
              else types = [...(contact?.types || []), type]
              setItem('types', types)
            }}
          >
            <Checkbox
              disabled={isPrimary && typesRequiredForPrimary.includes(type)}
              title={type}
              isChecked={[
                ...(contact?.types ?? []),
                ...(isPrimary ? typesRequiredForPrimary : []),
              ].includes(type)}
            />
          </div>
        ))}
      </div>
      <DetailsSectionTitle required title='contact details' />
      <div className='flex flex-col md:flex-row mt-4'>
        <TextInput
          required
          className='w-full md:w-2/3 md:mr-2 mb-4'
          dataTestId='fullNameInput'
          label='Full name'
          placeholder='eg. (John Taylor)'
          value={contact?.name}
          error={
            contact?.name?.length > 0 &&
            Boolean(!validateFullName(contact?.name)) &&
            'Full name is required'
          }
          onChange={(name: string) => setItem('name', name)}
        />
        <TextInput
          required
          className='mb-4 w-full md:w-1/3'
          dataTestId='phoneInput'
          error={!validatePhoneNumber(contact?.phone) && 'Provide a valid phone number'}
          label='Phone'
          maskOptions={getPhoneMaskOptions()}
          placeholder='__________'
          type='tel'
          value={contact?.phone}
          onChange={(phone: string) => setItem('phone', phone)}
        />
      </div>
      <TextInput
        required
        className='mb-4'
        dataTestId='emailInput'
        label='Email address'
        placeholder='eg. (name@mail.com)'
        type='email'
        validator={value => value.length > 0 && validateEmail(value)}
        value={contact?.email}
        error={
          contact?.email?.length && !validateEmail(contact.email) && 'Provide a valid email address'
        }
        onChange={(email: string) => setItem('email', email)}
      />
      <TextInput
        required
        className='mb-4'
        dataTestId='emailRemitInput'
        label='Remit email address'
        placeholder='eg. (name@mail.com)'
        type='email'
        validator={value => value.length > 0 && validateEmail(value)}
        value={contact?.emailRemit}
        error={
          contact?.emailRemit?.length &&
          !validateEmail(contact.emailRemit) &&
          'Provide a valid email address'
        }
        onChange={(emailRemit: string) => setItem('emailRemit', emailRemit)}
      />
    </div>
  </div>
)
