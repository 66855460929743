import { broadcastLogin, useAuthBroadcast } from '@common'
import { LoaderLogo, RouteErrorBoundary } from '@components'
import { noop } from 'lodash-es'
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { api } from '../../api/api'
import { defaultPath, isLocal } from '../../common/constants'
import { resetTracking, trackEvent, trackPageView } from '../../common/tracking'
import DriverCheckInScreen from '../../pages/DriverCheckInScreen'
import { FactorALoadScreen } from '../../pages/FactoringCreditCheck'
import FeedbackScreen from '../../pages/FeedbackScreen'
import FuelSignupScreen from '../../pages/FuelSignupScreen'
import { RecoveryDetailsScreen } from '../../pages/LoadFactoringDetailScreen/RecoveryDetailsScreen'
import LoginScreen from '../../pages/LoginScreen'
import NotFoundScreen from '../../pages/NotFoundScreen'
import ReferralRedirectScreen from '../../pages/ReferralRedirectScreen'
import { setHasActiveSession } from '../../redux/bankingSlice'
import { getCompanyDetail, setCompanyDetail } from '../../redux/companySlice'
import { getCarrierManagerDetail } from '../../redux/contactCarrierRepSlice'
import { getLatestCFR } from '../../redux/factoringSlice'
import { getPopularCities } from '../../redux/locationsSlice'
import { persistor, useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { trackReferralLinkOpening } from '../../redux/trackingSlice'
import {
  acceptCustomerInvite,
  checkIfUserIsAuthenticated,
  getCarrierCompanyList,
  getUser,
  logout,
  setCompanyId,
  setLoginStep,
} from '../../redux/userSlice'
import { Header } from '../Header'
import { Sidebar } from '../Sidebar'
import { AppContent } from './AppContent'

// lazy load pages which are not used on initial load, and contain heavy components
const BankingScreen = lazy(() => import('../../pages/BankingScreen'))
const ContactCarrierRepScreen = lazy(() => import('../../pages/ContactCarrierRepScreen'))
const DocumentsScreen = lazy(() => import('../../pages/DocumentsScreen'))
const FactoringReferralsScreen = lazy(() => import('../../pages/FactoringReferralsScreen'))
const FactoringScreen = lazy(() => import('../../pages/FactoringScreen'))
const FuelScreen = lazy(() => import('../../pages/FuelScreen'))
const LoadFactoringDetailScreen = lazy(() => import('../../pages/LoadFactoringDetailScreen'))
const CapacityAndPreferredLanesScreen = lazy(
  () => import('../../pages/CapacityAndPreferredLanesScreen'),
)
const LoadDetailsScreen = lazy(() => import('../../pages/LoadDetailsScreen'))
const LoadsScreen = lazy(() => import('../../pages/LoadsScreen'))
const ManageContactsScreen = lazy(() => import('../../pages/ManageContactsScreen'))
const MyLoadsScreen = lazy(() => import('../../pages/MyLoadsScreen'))
const MyQuotesScreen = lazy(() => import('../../pages/MyQuotesScreen'))
const PaymentMethodsScreen = lazy(() => import('../../pages/PaymentMethodsScreen'))
const PaymentsScreen = lazy(() => import('../../pages/PaymentsScreen'))
const SignRateConScreen = lazy(() => import('../../pages/SignRateConScreen'))
const TestPanel = lazy(() => import('../../pages/TestPanel'))
const TrackingScreen = lazy(() => import('../../pages/TrackingScreen'))
const TransferFundsScreen = lazy(() => import('../../pages/TransferFundsScreen'))
const ManageEquipmentScreen = lazy(() => import('../../pages/ManageEquipmentScreen'))
const RecommendationsSettingsScreen = lazy(
  () => import('../../pages/RecommendationsSettingsScreen'),
)
const ProjectDetailsScreen = lazy(() => import('../../pages/ProjectDetailsScreen'))
const RFPDetailsScreen = lazy(() => import('../../pages/RFPDetailsScreen'))

const PrivateRoute = () => {
  const dispatch = useAppThunkDispatch()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const showSidebar = useAppSelector(state => state.user.settings.showSidebar)
  const companyDetail = useAppSelector(state => state.company.companyDetail)

  useEffect(() => {
    trackPageView() // Tracks each pageview.
  }, [location.pathname])

  const gatherAppDependencies = () => {
    dispatch(getUser())
    dispatch(getCompanyDetail())
    dispatch(getCarrierManagerDetail())
    dispatch(getLatestCFR())
    dispatch(getCarrierCompanyList())
    dispatch(getPopularCities())
  }

  useEffect(() => {
    gatherAppDependencies()
    const hasLoadOffer =
      searchParams.has('loadOfferReference') || searchParams.has('loadOfferSMSId')
    const hasUTMSource = searchParams.has('utm_source')
    const hasCustomerInvite = searchParams.has('acceptingCustomerInvite')

    if (hasLoadOffer) {
      const loadOfferEmailId = searchParams.get('loadOfferReference') || ''
      const loadOfferSMSId = searchParams.get('loadOfferSMSId') || ''
      if (loadOfferEmailId)
        trackEvent('Referred from load offer email', { reference_id: loadOfferEmailId })
      if (loadOfferSMSId)
        trackEvent('Referred from load offer SMS', { reference_id: loadOfferSMSId })
      dispatch(trackReferralLinkOpening({ loadOfferEmailId, loadOfferSMSId }))
    }

    if (hasUTMSource) {
      const utmSource = searchParams.get('utm_source') || ''

      if (utmSource === 'new-contact') trackEvent('Referred by new contact email to login')
      if (utmSource === 'new-company-access')
        trackEvent('Referred by new company access email to login')
    }

    if (hasCustomerInvite) {
      const customerID = searchParams.get('customerID') || ''
      dispatch(acceptCustomerInvite(customerID))
    }

    // clear query params
    if (hasLoadOffer || hasUTMSource) {
      searchParams.delete('loadOfferReference')
      searchParams.delete('loadOfferSMSId')
      searchParams.delete('utm_source')
      setSearchParams(searchParams, { replace: true })
    }
  }, [])

  useEffect(() => {
    if (!companyDetail.approvedToBank) return
    if (!companyDetail.bankingExpiry) {
      dispatch(setHasActiveSession(false))
      return
    }
    dispatch(setHasActiveSession(true))

    const timeoutLength = companyDetail.bankingExpiry - 10 * 1000 // 10 seconds before backend expiry
    const activityTimeout = setTimeout(() => {
      dispatch(setCompanyDetail({ bankingExpiry: 0 }))
      dispatch(setHasActiveSession(false))
    }, timeoutLength)

    return () => {
      clearTimeout(activityTimeout)
    }
  }, [companyDetail.approvedToBank, companyDetail.bankingExpiry, companyDetail.receivedTimestamp])

  if (!companyDetail.receivedTimestamp) return <LoaderLogo />

  return (
    <>
      <Header />
      <Sidebar />
      <AppContent showHeader showSidebar={showSidebar}>
        <Suspense>
          <Outlet />
        </Suspense>
      </AppContent>
    </>
  )
}

const NavigateAwayIfManagedTrans = ({ page }: { page: JSX.Element }) => {
  const isEXOFreightNetwork = useAppSelector(
    state => !state.company.companyDetail?.activeManagedTransShipper,
  )

  if (!isEXOFreightNetwork) return <Navigate to='/my-loads' />
  else return page
}

const RoutesContainer = () => {
  const { pathname, state, search } = useLocation()
  const dispatch = useAppThunkDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user.user)
  const companyId = useAppSelector(state => state.user.companyId)
  const shipperId = useAppSelector(
    state => state.company.companyDetail?.activeManagedTransShipper?.id ?? '',
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)

  const performLogoutActions = () => {
    setIsAuthenticated(false)
    persistor.purge()
    if (pathname === '/') return

    setTimeout(() => {
      // There's an odd bug where the user is kept in the private route
      // after logout. Here, we wait for the next event loop
      // to ensure that React has finished propagating the isAuthenticated update
      navigate('/', {
        state: { from: `${pathname}${search}` },
      })
    }, 0)
  }

  useAuthBroadcast(
    () => setIsAuthenticated(true),
    () => {
      resetTracking()
      dispatch(logout()).then(performLogoutActions)
    },
  )

  const checkAuth = useCallback(() => {
    // make a request to determine if the user is authenticated
    // if the user is authenticated, and is on the login page, redirect to home
    dispatch(checkIfUserIsAuthenticated())
      .unwrap()
      .then(async ({ authenticated, carrierCompanyId }) => {
        if (!authenticated) {
          return Promise.reject()
        }

        if (carrierCompanyId) {
          dispatch(setCompanyId(carrierCompanyId))
          broadcastLogin()
        } else {
          setIsAuthenticated(false)
          dispatch(setLoginStep('SELECT_COMPANY'))
        }
      })
      .catch(performLogoutActions)
  }, [])

  useEffect(() => {
    const noAuthRoutes = ['fuel-welcome', 'driver-check-in', 'referral-redirect']
    const shouldAuth = !noAuthRoutes.some(route => pathname.includes(route))
    // Check for temporary refresh token used by email links
    if (searchParams.has('token')) {
      trackEvent('Got auto-login token from email link')
      const token = searchParams.get('token') as string

      // if request starts with number in the shape 1-, it's a contact verification token
      // otherwise it's a magic link token
      const getTokenRequest = /^\d+-.+$/.test(token)
        ? () => api.post('/carrier/api/verify-magic-link-token/', { token })
        : () => api.post(`/carrier/users/contact-verification/${token}/`)

      getTokenRequest()
        .catch(noop)
        .finally(() => {
          checkAuth()
          searchParams.delete('token')
          setSearchParams(searchParams, { replace: true })
        })
    } else shouldAuth && checkAuth()
  }, [])

  const enableFinance = useMemo(() => user?.isFinance || user?.isPrimary, [user])
  const path = useMemo(() => state?.from || defaultPath, [state])

  return (
    <Routes>
      <Route errorElement={<RouteErrorBoundary />}>
        <Route element={<DriverCheckInScreen />} path='driver-check-in' />
        <Route element={<FuelSignupScreen />} path='fuel-welcome' />
        <Route element={<ReferralRedirectScreen />} path='referral-redirect' />
        <Route
          element={
            isAuthenticated === true && companyId ? (
              // by prodiving a composite key of companyId + shipperId, we ensure the whole app re-renders when
              // they change, without having to reload the app
              // https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
              <PrivateRoute key={`${companyId}${shipperId}`} />
            ) : isAuthenticated === null ? (
              <LoaderLogo />
            ) : (
              <LoginScreen />
            )
          }
        >
          <Route element={<Navigate replace to={path} />} path='/' />
          <Route element={<ContactCarrierRepScreen />} path='contact-carrier-rep' />
          <Route element={<DocumentsScreen />} path='documents' />
          <Route element={<CapacityAndPreferredLanesScreen />} path='capacity' />
          <Route element={<ManageEquipmentScreen />} path='manage-equipment' />
          <Route element={<RecommendationsSettingsScreen />} path='recommendations-settings' />
          <Route element={<NavigateAwayIfManagedTrans page={<LoadsScreen />} />} path='loads' />
          <Route element={<LoadsScreen />} path='loads/:loadId' />
          <Route element={<ManageContactsScreen />} path='manage-contacts' />
          <Route element={<MyLoadsScreen />} path='my-loads' />
          <Route element={<LoadDetailsScreen />} path='my-loads/:loadId' />
          <Route element={<MyQuotesScreen />} path='my-quotes' />
          <Route element={<LoadDetailsScreen />} path='my-quotes/:loadId' />
          <Route element={<SignRateConScreen />} path='my-loads/:loadId/sign-rate-con' />
          <Route element={<TrackingScreen />} path='secret-tracking-test-page' />
          <Route element={<FeedbackScreen />} path='feedback' />
          <Route element={<ProjectDetailsScreen />} path='my-quotes/projects/:id' />
          <Route element={<RFPDetailsScreen />} path='my-quotes/rfps/:id' />
          {enableFinance && (
            <>
              {/* Payment Screens */}
              <Route element={<PaymentMethodsScreen />} path='payment-methods' />
              <Route element={<PaymentsScreen />} path='payments' />

              {/* Finance screens */}
              <Route
                element={<NavigateAwayIfManagedTrans page={<BankingScreen />} />}
                path='banking'
              />
              <Route element={<NavigateAwayIfManagedTrans page={<FuelScreen />} />} path='fuel' />

              {/* Factoring screens */}
              <Route
                element={<NavigateAwayIfManagedTrans page={<FactoringScreen />} />}
                path='exo-factoring'
              />
              <Route
                element={<NavigateAwayIfManagedTrans page={<FactorALoadScreen />} />}
                path='exo-factoring/new-load'
              />
              <Route
                path='exo-factoring/credit-check'
                element={
                  <NavigateAwayIfManagedTrans page={<FactorALoadScreen creditCheckOnly />} />
                }
              />
              <Route
                element={<NavigateAwayIfManagedTrans page={<LoadFactoringDetailScreen />} />}
                path='exo-factoring/:loadFactoringRequestId'
              />
              <Route
                element={<NavigateAwayIfManagedTrans page={<RecoveryDetailsScreen />} />}
                path='exo-factoring/recoveries/:recoveryId'
              />
              {/* Referrals is not linked anywhere until we turn back on factoring */}
              <Route
                element={<NavigateAwayIfManagedTrans page={<FactoringReferralsScreen />} />}
                path='exo-factoring-referrals'
              />

              {/* Transfer funds screens */}
              <Route
                path='fuel/deposit'
                element={
                  <NavigateAwayIfManagedTrans
                    page={<TransferFundsScreen tenant='fuel' transferType='DEPOSIT' />}
                  />
                }
              />
              <Route
                path='fuel/withdraw'
                element={
                  <NavigateAwayIfManagedTrans
                    page={<TransferFundsScreen tenant='fuel' transferType='WITHDRAW' />}
                  />
                }
              />
              <Route
                path='banking/deposit'
                element={
                  <NavigateAwayIfManagedTrans
                    page={<TransferFundsScreen tenant='banking' transferType='DEPOSIT' />}
                  />
                }
              />
              <Route
                path='banking/withdraw'
                element={
                  <NavigateAwayIfManagedTrans
                    page={<TransferFundsScreen tenant='banking' transferType='WITHDRAW' />}
                  />
                }
              />
            </>
          )}
          {/* Screen for misc front-end testing and debugging - currently only used for testing Plaid Link flow */}
          {isLocal && <Route element={<TestPanel />} path='test' />}

          {/* catch-all 404 */}
          <Route element={<NotFoundScreen />} path='*' />
        </Route>

        {/* Redirection routes from old code */}
        <Route element={<Navigate replace state={state} to='/' />} path='welcome' />
        <Route element={<Navigate replace state={state} to='/' />} path='onboard' />
        <Route element={<Navigate replace state={state} to='/' />} path='login' />
        <Route
          element={<Navigate replace to='exo-factoring/credit-check' />}
          path='/credit-check'
        />
        <Route element={<Navigate replace to='/exo-factoring/new-load' />} path='factor-a-load' />
        <Route element={<Navigate replace to='/my-loads?tab=1' />} path='historical-loads' />
        <Route element={<Navigate replace to='/capacity' />} path='preferred-lanes' />
        <Route element={<Navigate replace to='/capacity' />} path='load-recommendations' />
      </Route>
    </Routes>
  )
}

export const SwitchRouter = (): JSX.Element => (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{ removeDefaultsFromUrl: true, enableBatching: true }}
  >
    <RoutesContainer />
  </QueryParamProvider>
)
