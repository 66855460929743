import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

export const ReferralRedirectScreen = () => {
  const [, setCookie] = useCookies(['referral_code'])
  const [referralCode] = useQueryParam('REF', withDefault(StringParam, ''))

  useEffect(() => {
    if (referralCode) {
      setCookie('referral_code', referralCode)
      window.location.href = 'https://exofreight.com/factoring'
    }
  }, [referralCode])

  return <div>Redirecting...</div>
}
