// Mixpanel documentation:
// https://developer.mixpanel.com/docs/javascript-quickstart?projectToken=5f3855549423149ae299d5372460b39e

import Hotjar from '@hotjar/browser'
import mixpanel from 'mixpanel-browser'

import { store } from '../redux/store'
import { trackEventInternally } from '../redux/trackingSlice'
import { isPreview, isProduction, isStage } from './constants'
import { RootState } from './types'
import { getLoadIdFromPath } from './utils'

const mixpanelID = '5f3855549423149ae299d5372460b39e'
const hotjarSiteId = 3347619
const hotjarVersion = 6

let isExternalTrackingEnabled = false

if (isPreview || isStage) {
  // This is running in stage and preview pre-prod environments.
  isExternalTrackingEnabled = true
  Hotjar.init(hotjarSiteId, hotjarVersion, {
    debug: true,
    nonce: 'EXOa9gkfjsh',
  })
  mixpanel.init(mixpanelID, { debug: true, ignore_dnt: true })
} else if (isProduction) {
  // This is running in production.
  isExternalTrackingEnabled = true
  Hotjar.init(hotjarSiteId, hotjarVersion, {
    nonce: 'EXOa9gkfjsh',
  })
  mixpanel.init(mixpanelID, { ignore_dnt: true })
}

// We use email to identify users in mixpanel and connect their actions across front-end,
// back-end and multiple devices.
export const identifyUser = (userEmail: string, userName: string, force_track = false) => {
  if (!isExternalTrackingEnabled && !force_track) return
  // console.log(`Tracking with mixpanel: identifying user ${userEmail}`)
  Hotjar.identify(userEmail, { name: userName })
  mixpanel.identify(userEmail)
  mixpanel.people.set({ $email: userEmail, $name: userName })
}

// You can pass information into extra_data in an optional dict.
//
// Example call:
// trackEvent('View Load', {'load_id': 8311})
export const trackEvent = (
  event: string,
  extra_data?: any,
  force_track = false,
  track_internally = true,
) => {
  // Regardless of whether external tracking is enabled, we will always track events internally
  if (track_internally) {
    try {
      const user = (store.getState() as RootState).user.user
      store.dispatch(trackEventInternally({ event, extra_data, user }))
    } catch {
      // NOTE: This will throw an error if trackEvent is called from within a reducer.
      // For now, this isn't affecting any events that we want to track internally,
      // so we just swallow the error.
    }
  }

  event = `Carrier Portal - ${event}`
  // console.log(`Mixpanel event: ${event}`, extra_data)
  if (isExternalTrackingEnabled || force_track) {
    mixpanel.track(event, extra_data)
  }
}

export const resetTracking = () => {
  if (isExternalTrackingEnabled) {
    mixpanel.reset()
  }
}

export const trackPageView = () => {
  const path = location.pathname
  if (path.startsWith('/loads')) {
    const loadId = getLoadIdFromPath(path)
    if (loadId) {
      trackEvent('Navigated to /loads', { load_id: loadId }, false, false)
      return
    }
  }
  trackEvent(`Navigated to ${path}`, {}, false, false)
}
