import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { Filters, RootState } from '../common/types'
import { formatFilters } from '../common/utils'

type PaymentsState = {
  payments: Array<any>
  paymentsLoading: boolean
  count: number
  filters: Filters
  offset: number
  size: number
  scroll: number
  search: string
  order: string
  orderDisplay: string
}

const initialState: PaymentsState = {
  payments: [],
  paymentsLoading: false,
  count: 0,
  filters: initialFilters,
  offset: 0,
  size: 20,
  scroll: 0,
  search: '',
  order: '',
  orderDisplay: '',
}

export const getPaymentStatusDisplay = (paymentStatus: string) => {
  let status = ''

  switch (paymentStatus.toLowerCase()) {
    case 'approved':
      status = 'approved'
      break
    case 'void':
      status = 'void'
      break
    case 'failed':
      status = 'failed'
      break
    default:
      status = paymentStatus.toLowerCase()
  }

  return status
}

export const getPayments = createAsyncThunk('payments/getPayments', async (_, { getState }) => {
  const { filters, size, offset } = (getState() as RootState).payments
  const response = await api.post('/billing/api/carrier-load-payments/', formatFilters(filters), {
    params: { limit: size, offset },
  })

  return response.data
})

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPayments.pending, state => {
        state.paymentsLoading = true
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.paymentsLoading = false
        const payments = action.payload.results.map((payment: any) => ({
          ...payment,
          status_display: getPaymentStatusDisplay(payment.status_display),
        }))
        state.payments = payments
        state.count = action.payload.count
      })
      .addCase(getPayments.rejected, state => {
        state.paymentsLoading = false
        toast.error('Error getting payments')
      })
  },
})

export const { setFilters, setSize, setOffset } = paymentsSlice.actions

export default paymentsSlice.reducer
