import { CityLocation } from '@common'
import dayjs from 'dayjs'

import { PreferredLane } from '../common/types'
import { toTitleCase } from '../common/utils'

const normalizeLocation = (location: CityLocation) => ({
  name: location.title,
  city: location.city,
  state_province_region: location.state,
  country: location.country,
  contact_name: null,
  contact_phone: null,
  hours: null,
  latitude: location.latitude,
  longitude: location.longitude,
  notes: null,
  current_place: {
    title: location.title,
    address: {
      label: location.title,
      countryCode: location.country,
      stateCode: location.state,
      city: location.city,
    },
    position: {
      lat: location.latitude,
      lng: location.longitude,
    },
  },
})

export const normalizeLane = (lane: Partial<PreferredLane>) => ({
  /* Format PreferredLane for backend */
  src_location: lane.srcLocation ? normalizeLocation(lane.srcLocation) : null,
  src_radius: lane.srcRadius,
  dst_location: lane.dstLocation ? normalizeLocation(lane.dstLocation) : null,
  dst_radius: lane.dstRadius,
  match_date: dayjs().format('YYYY-MM-DD'),
  availability_start: lane.availabilityStart,
  availability_end: lane.availabilityEnd,
  equipment_type: lane.equipmentType,
  source: 1,
  match_type: 3,
  id: lane.id,
})

/* Format backend response to PreferredLane */
export const denormalizeLane = (
  lane: Partial<PreferredLane> & {
    srcLocation: { stateProvinceRegion: string }
    dstLocation: { stateProvinceRegion: string }
  },
) =>
  ({
    ...lane,
    srcLocation: lane.srcLocation
      ? {
          ...lane.srcLocation,
          title: `${toTitleCase(lane.srcLocation.city)}, ${lane.srcLocation.stateProvinceRegion}`,
          state: lane.srcLocation.stateProvinceRegion,
        }
      : null,
    dstLocation: lane.dstLocation
      ? {
          ...lane.dstLocation,
          title: `${toTitleCase(lane.dstLocation.city)}, ${lane.dstLocation.stateProvinceRegion}`,
          state: lane.dstLocation.stateProvinceRegion,
        }
      : null,
  }) as PreferredLane

export const normalizeContact = (contact: any) => {
  const { name, phone, email, roles, isPrimary } = contact

  return {
    name,
    phone,
    email,
    is_primary: isPrimary,
    send_sms_offers: roles.includes('SMS Load Offers'),
    send_email_offers: roles.includes('Email Load Offers'),
    is_dispatch: roles.includes('Dispatch'),
    is_accounting: roles.includes('Accounting'),
    is_claims: roles.includes('Claims'),
    is_driver: roles.includes('Driver'),
    is_finance: roles.includes('Finance'),
  }
}
