import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'

import { RecoveryDetail } from '../../common/types'

export const RecoveryDetailsTableMobile = ({ item }: { item: RecoveryDetail }) => (
  <NavLink to={`/loads/${item.loadId}`}>
    <div className='flex flex-row justify-between pt-3 pb-3'>
      <div className='max-w-[70%]'>
        <p className='font-bold text-lg text-navy'>{item.description}</p>
        <p className='text-lg'>{item.loadId}</p>
      </div>
      <div className='flex justify-between items-center text-lg'>
        <span>${item.amount}</span>
        <p className='ml-5'>
          <ChevronRightIcon color='#3F6AA6' />
        </p>
      </div>
    </div>
  </NavLink>
)
