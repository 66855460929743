import {
  CatchError,
  formatAxiosErrorToPayload,
  getErrorString,
  keysToCamelCase,
  keysToSnakeCase,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as sentry from '@sentry/react'
import { toast } from 'react-toastify'
import { PURGE } from 'redux-persist'

import { api } from '../api/api'
import { emptyCarrier } from '../common/constants'
import { trackEvent } from '../common/tracking'
import { FileProps, RootState, User } from '../common/types'
import { buildBackendCarrierObject } from './factory/carrier'
import { initializeTracking } from './trackingSlice'

type LoginCredentials = {
  otp: string
  docket: string
  typeTab: number
  phone: string
  email: string
  codeId: string
}

type LoginState = {
  credentials: LoginCredentials
  credentials_last: LoginCredentials
  error: string
  loading: boolean
  refresh: string
  token: string
  validateCodeLoading: boolean
  currentStep: 'LOGIN' | 'SELECT_COMPANY' | 'ONBOARD' | 'VALIDATE'
}

type OnboardingState = {
  buttonLoading: boolean
  currentStep: number | null
  onboardingDetails: any
}

type SettingsState = {
  showFactoringPageUnread: boolean
  showFuelPageUnread: boolean
  showSidebar: boolean
  showBankingSecurityWarning: boolean
  showFuelHowItWorks: boolean
}

export type UserState = {
  login: LoginState
  onboarding: OnboardingState
  user: User | null
  settings: SettingsState
  customerInviteAccepted: boolean
  companyId: number
  carrierCompanies: Array<{ id: number; name: string; mcNumber: string; dotNumber: string }>
  carrierCompaniesCount: number
  loading: {
    carrierCompanies: boolean
    getUserToken: number | false
  }
}

const initialState = (): UserState => ({
  login: {
    credentials: {
      otp: '',
      phone: '',
      email: '',
      codeId: '',
      docket: '',
      typeTab: 0,
    },
    credentials_last: {
      otp: '',
      phone: '',
      email: '',
      codeId: '',
      docket: '',
      typeTab: 0,
    },
    error: '',
    loading: false,
    refresh: '',
    token: '',
    validateCodeLoading: false,
    currentStep: 'LOGIN',
  },
  onboarding: {
    buttonLoading: false,
    currentStep: null,
    onboardingDetails: emptyCarrier,
  },
  user: null,
  settings: {
    showFactoringPageUnread: true,
    showFuelPageUnread: true,
    showSidebar: true,
    showBankingSecurityWarning: true,
    showFuelHowItWorks: true,
  },
  customerInviteAccepted: false,
  companyId: 0,
  carrierCompanies: [],
  carrierCompaniesCount: 0,
  loading: {
    carrierCompanies: false,
    getUserToken: false,
  },
})

export const createAccount = createAsyncThunk(
  'user/createAccount',
  async (
    { email, firstName, lastName, phoneNumber, dotNumber, mcNumber }: { [key: string]: string },
    { rejectWithValue },
  ) =>
    await api
      .post('/accounts/api/carrier-company-create/', {
        dot_number: dotNumber,
        email,
        first_name: firstName,
        last_name: lastName,
        mc_number: mcNumber,
        mobile_number: phoneNumber,
      })
      .then(response => {
        if (response.status === 201) {
          return response.data
        } else if (response.status === 204) {
          localStorage.setItem('latest-docket', mcNumber || dotNumber)
          localStorage.setItem('latest-login-phone-or-email', phoneNumber || email)
          return rejectWithValue('COMPANY_ALREADY_EXISTS')
        } else {
          return null
        }
      })
      .catch(err => {
        if (!err.response) {
          throw err
        }

        return rejectWithValue(formatAxiosErrorToPayload(err))
      }),
)

export const sendOTP = createAsyncThunk(
  'user/sendOTP',
  async (_, { rejectWithValue, getState }) => {
    const { email, phone } = (getState() as RootState).user.login.credentials

    try {
      return api
        .post('/carrier/users/contact-verification/', {
          ...(email && { email }),
          ...(phone && { phone }),
        })
        .then(({ data }) => keysToCamelCase(data))
        .then(({ codeId }) => {
          localStorage.setItem('latest-login-phone-or-email', email || phone)

          return codeId
        })
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkContact = createAsyncThunk(
  'user/checkContact',
  async (_, { getState, rejectWithValue }) => {
    const { email, phone } = (getState() as RootState).user.login.credentials

    return api
      .post('/carrier/api/check-contact-exists/', {
        ...(phone && { phone }),
        ...(email && { email }),
      })
      .then(({ data }) => data.exists)
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const getCarrierCompanyList = createAsyncThunk(
  'user/getCarrierCompanyList',
  async (query: string | undefined, { rejectWithValue }) => {
    try {
      return await api
        .get('/accounts/api/carrier-company-list/', { params: { ...(query && { query }) } })
        .then(({ data }) => keysToCamelCase(data))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getUserToken = createAsyncThunk(
  'user/getUserToken',
  async (companyId: number, { rejectWithValue }) => {
    try {
      const response = await api.post('carrier/api/company-token/', keysToSnakeCase({ companyId }))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkCarrierCanRegister = createAsyncThunk(
  'user/checkCarrierCanRegister',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const { docket, typeTab } = (getState() as RootState).user.login.credentials

    return api
      .post('/accounts/api/check-carrier-can-register/', {
        ...(typeTab === 0 ? { mc_number: docket } : { dot_number: docket }),
      })
      .then(result => {
        const data = keysToCamelCase(result.data)

        if (data.canRegister) {
          dispatch(lookupCarrier())
        }

        return data
      })
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const verifyOTP = createAsyncThunk(
  'user/vetifyOTP',
  async (_, { rejectWithValue, getState }) => {
    const {
      credentials: { codeId, otp },
    } = (getState() as RootState).user.login

    try {
      return api
        .post(`/carrier/users/contact-verification/${codeId}/${otp}/`)
        .then(({ data }) => keysToCamelCase(data.user))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const logout = createAsyncThunk('user/logout', async () => api.post('/api/logout/'))

export const getUser = createAsyncThunk('user/getUser', async (_, { dispatch }) => {
  const data = await api
    .get('/accounts/api/carrier/details/')
    .then(({ data }) => keysToCamelCase(data))

  sentry.setUser({ email: data.email })
  trackEvent('Received user information')
  dispatch(initializeTracking({ email: data.email, name: data.name }))
  return data
})

export const checkIfUserIsAuthenticated = createAsyncThunk(
  'user/checkIfUserIsAuthenticated',
  async () => api.get('/carrier/users/check-auth/').then(({ data }) => keysToCamelCase(data)),
)

export const acceptCustomerInvite = createAsyncThunk(
  'user/acceptCustomerInvite',
  async (customerID: string) => {
    const payload = { customerID: customerID }
    await api.post('/accounts/api/accept-customer-invite', payload)
  },
)

const lookupCarrier = createAsyncThunk(
  'user/lookupCarrier',
  async (_, { getState, rejectWithValue }) => {
    const { docket, typeTab } = (getState() as RootState).user.login.credentials

    const payload = { identifying_number: docket, type: typeTab ? 'DOT' : 'MC' }
    return api
      .post('/integrations/api/carrier-lookup-doc/', payload)
      .then(response => {
        if (response.status === 200) {
          return response.data
        } else {
          return null
        }
      })
      .catch(error => rejectWithValue(error.response.data))
  },
)

export const createCarrierCompany = createAsyncThunk(
  'user/createCarrierCompany',
  async (_, { getState, rejectWithValue }) => {
    try {
      const payload = buildBackendCarrierObject(
        (getState() as RootState).user.onboarding.onboardingDetails,
      )
      const { data } = await api.post('/accounts/api/carrier-company-create-external/', payload)
      return keysToCamelCase(data)
    } catch (err: CatchError) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState: initialState(),
  reducers: {
    resetOnboarding: state => {
      state.onboarding.currentStep = null
      state.login.currentStep = 'LOGIN'
      state.onboarding.onboardingDetails = emptyCarrier
    },
    setOnboardingStep: (state, { payload }) => {
      state.onboarding.currentStep = payload >= 0 ? payload : null
      trackEvent(
        `Carrier onboarding - ${
          state.onboarding.currentStep == null
            ? 'quit'
            : `viewing step ${state.onboarding.currentStep}`
        }`,
        { ...state.login.credentials },
      )
    },
    patchCredentials: (state, { payload }: { payload: Partial<LoginCredentials> }) => {
      state.login.credentials = { ...state.login.credentials, ...payload }
    },
    setOnboardingDetails: (state, { payload }) => {
      state.onboarding.onboardingDetails = {
        ...state.onboarding.onboardingDetails,
        ...payload,
      }
    },
    toggleFactoringInterest: state => {
      state.onboarding.onboardingDetails.isInterestedInFactoring =
        !state.onboarding.onboardingDetails.isInterestedInFactoring
    },
    setUserSetting: (state, { payload }: { payload: Partial<SettingsState> }) => {
      state.settings = { ...state.settings, ...payload }
    },
    setCompanyId: (state, { payload }) => {
      state.companyId = payload
    },
    setLoginStep: (state, { payload }: { payload: LoginState['currentStep'] }) => {
      state.login.currentStep = payload
      state.login.error = ''
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createAccount.pending, state => {
        state.login.error = ''
        state.login.loading = true
        trackEvent('Carrier company creation started', { ...state.login.credentials })
      })
      .addCase(createAccount.fulfilled, (state, { payload }) => {
        state.login.loading = false
        trackEvent('Carrier company created', { ...state.login.credentials, payload })
      })
      .addCase(createAccount.rejected, (state, { payload }) => {
        state.login.loading = false
        if (payload === 'COMPANY_ALREADY_EXISTS') {
          state.login.error = payload
          trackEvent('Carrier company already exists', { ...state.login.credentials })
        } else {
          state.login.error = getErrorString(payload)
          trackEvent('Carrier company creation failed', { ...state.login.credentials, payload })
        }
      })
      .addCase(acceptCustomerInvite.fulfilled, state => {
        trackEvent('Carrier accepted customer invite')
        toast.success('Invitation accepted')
        state.customerInviteAccepted = true
      })
      .addCase(acceptCustomerInvite.rejected, state => {
        trackEvent('Error accepting customer invite')
        toast.error('Error accepting customer invitation, please try again.')
        state.customerInviteAccepted = false
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(lookupCarrier.pending, state => {
        state.login.loading = true
      })
      .addCase(lookupCarrier.fulfilled, (state, action) => {
        if (!action.payload) {
          state.login.loading = false
          state.login.error =
            'We were unable to lookup your company. Please double check your input and try again.'
          return
        }
        const { documents } = state.onboarding.onboardingDetails
        const { carrier_details, cert_data, document } = action.payload

        let saferWatchInsuranceDocuments: FileProps[] = []

        let certificate = []

        if (cert_data['@status'] === 'OK' && document) {
          saferWatchInsuranceDocuments = Array.isArray(document)
            ? document.map(
                (doc: any) =>
                  ({
                    name: doc.file_name,
                    fileData: `data:application/pdf;base64,${doc.file}`,
                    mimeType: 'application/pdf',
                  }) as FileProps,
              )
            : [
                {
                  name: document.file_name,
                  fileData: `data:application/pdf;base64,${document.file}`,
                  mimeType: 'application/pdf',
                },
              ]

          certificate = Array.isArray(cert_data.Certificate)
            ? cert_data.Certificate
            : [cert_data.Certificate]
        }

        state.login.loading = false
        state.onboarding.currentStep = 0
        state.onboarding.onboardingDetails = {
          ...state.onboarding.onboardingDetails,
          accountReceivableInformation: {
            ...state.onboarding.onboardingDetails.accountReceivableInformation,
            entityName: carrier_details.name,
            address: carrier_details.address,
            city: carrier_details.city,
            state: carrier_details.state_province_region,
            postalCode: carrier_details.postal_code,
          },
          contacts: {
            ...state.onboarding.onboardingDetails.contacts,
            primaryContact: {
              ...state.onboarding.onboardingDetails.contacts.primaryContact,
              phone: state.login.credentials.phone, // Use the phone number that was entered
              email: carrier_details.email,
            },
          },
          documents: {
            ...documents,
            insurance: [...documents.insurance, ...saferWatchInsuranceDocuments],
          },
          qualification: {
            ...keysToCamelCase(action.payload),
            certData: {
              ...keysToCamelCase(cert_data),
              Certificate: certificate,
            },
          },
        }
      })
      .addCase(lookupCarrier.rejected, (state, { payload }) => {
        state.login.loading = false
        state.login.error =
          'We encountered an error looking up your company. Please double check your input and try again.'
        const error = getErrorString(payload)
        if (error) toast.error(error, { autoClose: false })
      })
      .addCase(createCarrierCompany.pending, state => {
        state.onboarding.buttonLoading = true
      })
      .addCase(createCarrierCompany.fulfilled, (state, action) => {
        state.onboarding.buttonLoading = false
        toast.success(action.payload.message.toString())
      })
      .addCase(createCarrierCompany.rejected, (state, { payload }) => {
        state.onboarding.buttonLoading = false
        toast.error(getErrorString(payload, 'Failed to create carrier company'))
      })
      .addCase(checkContact.pending, state => {
        state.login.loading = true
      })
      .addCase(checkContact.fulfilled, state => {
        state.login.loading = false
      })
      .addCase(checkContact.rejected, state => {
        state.login.loading = false
      })
      .addCase(verifyOTP.pending, state => {
        state.login.loading = true
      })
      .addCase(verifyOTP.fulfilled, (state, { payload }) => {
        state.login.loading = false
        if (payload.carrierCompanyId) {
          state.companyId = payload.carrierCompanyId
        } else {
          state.login.currentStep = 'SELECT_COMPANY'
        }
      })
      .addCase(verifyOTP.rejected, state => {
        state.login.loading = false
        state.login.error = 'Could not validate your code'
      })
      .addCase(sendOTP.pending, state => {
        state.login.loading = true
        state.login.error = ''
        state.login.credentials.otp = ''
      })
      .addCase(sendOTP.fulfilled, (state, { payload }) => {
        state.login.loading = false
        state.login.credentials.codeId = payload
        state.login.currentStep = 'VALIDATE'
        state.login.credentials_last = Object.assign({}, state.login.credentials)
      })
      .addCase(sendOTP.rejected, state => {
        state.login.loading = false
        state.login.credentials_last = Object.assign({}, state.login.credentials)
      })
      .addCase(getCarrierCompanyList.pending, state => {
        state.loading.carrierCompanies = true
      })
      .addCase(getCarrierCompanyList.fulfilled, (state, { payload }) => {
        state.carrierCompanies = payload.companies
        state.carrierCompaniesCount = payload.count
        state.loading.carrierCompanies = false
      })
      .addCase(getCarrierCompanyList.rejected, state => {
        state.loading.carrierCompanies = false
      })
      .addCase(getUserToken.pending, (state, { meta }) => {
        state.loading.getUserToken = meta.arg
      })
      .addCase(getUserToken.fulfilled, (state, { meta }) => {
        state.loading.getUserToken = false
        state.companyId = meta.arg
      })
      .addCase(getUserToken.rejected, state => {
        state.loading.getUserToken = false
      })
      .addCase(checkCarrierCanRegister.pending, state => {
        state.login.loading = true
        state.login.error = ''
      })
      .addCase(checkCarrierCanRegister.fulfilled, (state, { payload }) => {
        if (!payload.canRegister) {
          state.login.error = 'This company cannot be registered at this time'
          state.login.loading = false
        }

        if (payload.invitedBy) {
          state.onboarding.onboardingDetails.invitedBy = payload.invitedBy
        }
      })
      .addCase(checkCarrierCanRegister.rejected, state => {
        state.login.loading = false
        state.login.error = 'Could not check company'
      })
      .addCase(PURGE, state => ({
        ...initialState(),
        login: { ...state.login, currentStep: 'LOGIN' },
        onboarding: state.onboarding,
      }))
  },
})

export const userPersistConfig = {
  key: 'user',
  whitelist: ['onboarding'],
}

export const {
  resetOnboarding,
  patchCredentials,
  setOnboardingStep,
  setOnboardingDetails,
  toggleFactoringInterest,
  setUserSetting,
  setCompanyId,
  setLoginStep,
} = userSlice.actions

export default userSlice.reducer
