import { Button, LinkPageHeader, LoaderSpinner, Select, Tabs } from '@components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { factoringTeamPhone, formattedFactoringTeamPhone } from '../../common/constants'
import {
  CheckBanner,
  ShippersList,
  SuccessBanner,
} from '../../components/FactoringCreditCheck/components'
import { FactoringLoadRequest } from '../../components/FactoringCreditCheck/stages'
import {
  checkBroker,
  checkCompany,
  checkShipper,
  Company,
  getBrokers,
  getRecentBrokers,
  getRecentShippers,
  getShippers,
  resetCreditCheck,
  resetShippersList,
  setFactoringRequestTab,
  setSearchQuery,
  setSelectedCompany,
  setSelectedCompanyCheckResult,
  setShowSuccessMessage,
} from '../../redux/factoringSlice'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'

export const FactorALoadScreen = ({ creditCheckOnly }: { creditCheckOnly?: boolean }) => {
  const [inputText, setInputText] = useState<string>('')

  const dispatch = useAppThunkDispatch()

  const creditCheck = useAppSelector(state => state.factoring.selectedCompanyCheckResult)
  const selectedCompany = useAppSelector(state => state.factoring.selectedCompany)
  const brokers = useAppSelector(state => state.factoring.brokers)
  const shippers = useAppSelector(state => state.factoring.shippers)
  const recentBrokers = useAppSelector(state => state.factoring.recentBrokers)
  const recentShippers = useAppSelector(state => state.factoring.recentShippers)
  const activeTab = useAppSelector(state => state.factoring.factoringRequestTab)
  const listLoading = useAppSelector(state => state.factoring.loading.companySuggestions)
  const loadingValues = useAppSelector(state => state.factoring.loading.checkCompany)
  const showSuccessMessage = useAppSelector(state => state.factoring.showSuccessMessage)
  const searchQuery = useAppSelector(state => state.factoring.searchQuery)

  const loading = Object.values(loadingValues).some(Boolean)

  const companies = useMemo(() => {
    if (!activeTab) return inputText ? brokers : recentBrokers
    return inputText ? shippers : recentShippers
  }, [activeTab, brokers, shippers, recentBrokers, recentShippers, inputText])

  const recentCompanies = useMemo(
    () => (!activeTab ? recentBrokers : recentShippers),
    [activeTab, recentBrokers, recentShippers],
  )

  // disable the button if the query is less than 6 or over 8 characters for MC Numbers or less than 3 characters for shipper name
  const isSearchButtonDisabled = useMemo(() => {
    const invalidBrokerSearch = !activeTab && !/^\d{6,8}$/.test(searchQuery)
    const invalidShipperSearch = activeTab && searchQuery.length < 3
    return invalidBrokerSearch || invalidShipperSearch
  }, [activeTab, searchQuery])

  const placeholder = useMemo(() => (!activeTab ? 'Broker MC Number' : 'Shipper Name'), [activeTab])

  const HeaderRightContent = useCallback(
    () => (
      <div className='text-right font-bold whitespace-nowrap'>
        <div>Need Help?</div>
        <a className='text-link' href={`tel:${factoringTeamPhone}`}>
          {formattedFactoringTeamPhone}
        </a>
      </div>
    ),
    [],
  )

  useEffect(() => {
    dispatch(setShowSuccessMessage(false))
    dispatch(setFactoringRequestTab(0))
    dispatch(resetCreditCheck())
    dispatch(getRecentShippers())
    dispatch(getRecentBrokers())
    dispatch(getShippers())
    dispatch(getBrokers())
  }, [])

  useEffect(() => {
    if (creditCheck) {
      if (creditCheck?.approved === false) resetRequest()
    }
  }, [showSuccessMessage, creditCheck?.approved])

  useEffect(() => {
    dispatch(setSelectedCompany(null))
    dispatch(setSelectedCompanyCheckResult(null))
    dispatch(resetShippersList())
  }, [activeTab])

  const resetRequest = () => {
    dispatch(setSelectedCompany(null))
    dispatch(setSearchQuery(''))
  }

  const runManualCreditCheck = () => {
    const handler = activeTab ? checkShipper : checkBroker
    dispatch(handler(searchQuery)).then(handleNavigate)
  }

  const handleNavigate = (payload: { meta: { requestStatus: string } }) =>
    payload.meta.requestStatus === 'rejected' && resetRequest()

  const onKeyDown = ({ keyCode }: { keyCode: number }) =>
    !isSearchButtonDisabled && keyCode === 13 && runManualCreditCheck()

  const handleInputChange = (newInputText: string) => {
    setInputText(newInputText)
    if (newInputText) {
      dispatch(setSearchQuery(newInputText))
      activeTab && dispatch(resetShippersList())
    }
  }

  const handleCheckCompany = (company: Company) => {
    dispatch(setSelectedCompany(company))
    if (company?.customerId) dispatch(checkCompany(company.customerId)).then(handleNavigate)
  }

  const shouldShowRequestPaymentArea =
    !creditCheckOnly && !!creditCheck && creditCheck?.approved !== false

  return (
    <div className='flex flex-col overflow-hidden items-center'>
      <LinkPageHeader
        bottomBordered
        rightContent={HeaderRightContent}
        title={creditCheckOnly ? 'Check Credit' : 'Factor a Load'}
        to='/exo-factoring'
      />
      <Container>
        <div className='flex flex-col gap-4 items-center w-full lg:w-1/2 md:w-2/3 mt-6'>
          {showSuccessMessage && <SuccessBanner />}
          <div className='font-bold text-2xl text-center mt-6'>
            {creditCheckOnly ? 'Check Credit' : 'Who did you haul for?'}
          </div>
          <Tabs
            activeTab={activeTab}
            className='w-full md:w-2/3 mb-6'
            setActiveTab={tab => dispatch(setFactoringRequestTab(tab))}
            tabs={['Broker', 'Shipper']}
          />
          <div className='flex w-full gap-2'>
            <Select
              autoFocus
              hideDropdownIndicator
              sm
              choices={companies}
              className='w-full'
              field='label'
              loading={listLoading}
              noOptionsMessage={() => null}
              placeholder={placeholder}
              value={selectedCompany || null}
              onChange={handleCheckCompany}
              onInputChange={handleInputChange}
              onKeyDown={onKeyDown}
              {...(!inputText &&
                recentCompanies.length && {
                  listLabel: 'Recently Factored Invoices',
                })}
            />
            <Button disabled={!!isSearchButtonDisabled} type='warn' onClick={runManualCreditCheck}>
              Search
            </Button>
          </div>
          {loading && <LoaderSpinner />}
          {!loading && !!creditCheck && <CheckBanner />}
          <ShippersList />
          {shouldShowRequestPaymentArea && <FactoringLoadRequest />}
        </div>
      </Container>
    </div>
  )
}

const Container = tw.div`
  flex
  flex-col
  h-screen
  items-center
  mb-4
  overflow-y-auto
  px-4
  w-full
`
