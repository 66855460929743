import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

export const DetailsSectionTitle = ({
  title,
  className = '',
  error = false,
  required,
}: {
  title: string
  className?: string
  error?: boolean
  required?: boolean
}): JSX.Element => (
  <Container className={className}>
    <div className='min-w-fit'>
      {title}{' '}
      {required && (
        <span className='text-error' data-testid='asterisk'>
          *
        </span>
      )}
    </div>
    <Line />
    {error && <Exclamation data-testid='error' />}
  </Container>
)

const Container = tw.div`
  tracking-wide 
  flex 
  items-center 
  uppercase 
  font-semibold 
  mt-6 
  text-sm
`

const Line = tw.div`
  ml-4 
  border-b 
  border-brand-light 
  w-full
`

const Exclamation = tw(ExclamationCircleIcon)`
  text-error 
  ml-5 
  w-9 
  h-9
`
