import tw from 'tailwind-styled-components'

export type ProgressIndicatorProps<Steps extends readonly string[]> = {
  steps: Steps
  currentStep: Steps[number] | number
  className?: string
}

const SEPARATOR_SYMBOL = Symbol('SEPARATOR')

const insertSeparatorsIntoSteps = (steps: readonly string[]): (string | symbol)[] =>
  steps.flatMap((step, i) => {
    if (i < steps.length - 1) {
      return [step, SEPARATOR_SYMBOL]
    }
    return step
  })

const getCurrentIndex = (
  mappedSteps: readonly (string | symbol)[],
  currentStep: string | number,
): number => (typeof currentStep === 'number' ? 2 * currentStep : mappedSteps.indexOf(currentStep))

export const ProgressIndicator = <Steps extends readonly string[]>(
  props: ProgressIndicatorProps<Steps>,
) => {
  const mappedSteps = insertSeparatorsIntoSteps(props.steps)
  const currentIndex = getCurrentIndex(mappedSteps, props.currentStep)

  return (
    <ProgressIndicatorContainer aria-label='progress' className={props.className} role='group'>
      {mappedSteps.map((step, i) => {
        if (step === SEPARATOR_SYMBOL) {
          return <Separator key={i} $completed={i < currentIndex} />
        }
        if (i === currentIndex) {
          return (
            <CurrentStepContainer key={i} aria-current='true'>
              <>
                <StepDot $internal />
                {step}
              </>
            </CurrentStepContainer>
          )
        }
        return <StepDot key={i} $as='li' $completed={i < currentIndex} />
      })}
    </ProgressIndicatorContainer>
  )
}

const ProgressIndicatorContainer = tw.ul`
  flex
  w-full
  justify-between
  items-center
`
ProgressIndicatorContainer.displayName = 'ProgressIndicatorContainer'

type SeparatorTransients = {
  $completed?: boolean
}

const Separator = tw.div`
  h-0.5
  flex-1
  ${({ $completed }: SeparatorTransients) => ($completed ? 'bg-success' : 'bg-slate-200')}
`
Separator.displayName = 'Separator'

type StepDotTransients = {
  $completed?: boolean
  $internal?: boolean
}

const StepDot = tw.div`
  rounded-full
  h-3
  w-3
  ${({ $completed }: StepDotTransients) =>
    $completed ? 'bg-success' : 'border-2 border-slate-200'}
  ${({ $internal }: StepDotTransients) => ($internal ? 'bg-brand-dark' : '')}
`
StepDot.displayName = 'StepDot'

const CurrentStepContainer = tw.li`
  uppercase
  rounded-lg
  px-2
  py-1
  border-2
  border-slate-200
  bg-slate-100
  flex
  items-baseline
  gap-1
  font-bold
  text-brand-dark
`
CurrentStepContainer.displayName = 'CurrentStepContainer'
