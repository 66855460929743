import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { keysToCamel } from '../common/utils'
import { FactoringPaymentMethod } from './paymentSlice'

type ExternalFactoring = {
  factoringCompanySuggestions: FactoringPaymentMethod[]
  loading: {
    factoringCompanySuggestions: boolean
  }
}

const initialState: ExternalFactoring = {
  factoringCompanySuggestions: [],
  loading: {
    factoringCompanySuggestions: false,
  },
}

export const fetchFactoringCompanySuggestions = createAsyncThunk(
  'externalFactoring/fetchFactoringCompanySuggestions',
  async (q: string) => {
    const {
      data: { results },
    } = await api.get('/accounts/api/factoring/autocomplete/', { params: { q } })

    return keysToCamel(results)
  },
)

const externalFactoringSlice = createSlice({
  name: 'externalFactoring',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFactoringCompanySuggestions.pending, state => {
        state.loading.factoringCompanySuggestions = true
      })
      .addCase(fetchFactoringCompanySuggestions.fulfilled, (state, action) => {
        state.factoringCompanySuggestions = action.payload
        state.loading.factoringCompanySuggestions = false
      })
      .addCase(fetchFactoringCompanySuggestions.rejected, state => {
        state.loading.factoringCompanySuggestions = false
        toast.error('Error fetching factoring company suggestions')
      })
  },
})

export default externalFactoringSlice.reducer
