import { LatLng } from '@common'
import { Button, Map, Marker, MarkerType } from '@components'

import { api } from '../../api/api'
import { LoadDetail } from '../../common/types'
import { useAppThunkDispatch } from '../../redux/store'
import {
  sendCarrierDelayNotification,
  setTrackingStep,
  TrackingStep,
} from '../../redux/trackingSlice'

export const Reminder = ({
  destination,
  messageBody,
  messageHeader,
  needsTrackingUpdate,
  origin,
  selectedLoad,
  setModalVisible,
}: {
  destination?: LatLng
  messageBody?: JSX.Element | null
  messageHeader?: string | null
  needsTrackingUpdate?: boolean
  origin?: LatLng
  selectedLoad?: LoadDetail
  setModalVisible: (visible: boolean) => void
}) => {
  const dispatch = useAppThunkDispatch()

  const handleDelayed = () => {
    dispatch(setTrackingStep(TrackingStep.DELAYED))
    if (selectedLoad?.id) dispatch(sendCarrierDelayNotification(selectedLoad?.id))
  }

  return (
    <div className='p-8'>
      <h1 className='text-2xl font-bold'>{messageHeader}</h1>
      <p className='mt-4'>{messageBody}</p>
      {origin && destination && (
        <div className='flex my-6 border'>
          <Map className='h-full min-h-[200px]' mapClassName='h-full' routeAPI={api}>
            {origin && <Marker marker={MarkerType.ORIGIN} position={origin} />}
            {destination && <Marker marker={MarkerType.DESTINATION} position={destination} />}
          </Map>
        </div>
      )}
      <div className='flex justify-between mt-6 gap-4'>
        <Button fullWidth lg short className='w-full' type='secondary' onClick={handleDelayed}>
          Delayed / Not Departed
        </Button>
        <Button
          fullWidth
          lg
          className='w-full'
          type='success'
          onClick={() =>
            needsTrackingUpdate
              ? dispatch(setTrackingStep(TrackingStep.REQUESTING))
              : setModalVisible(false)
          }
        >
          Confirm, I&apos;m on my way
        </Button>
      </div>
    </div>
  )
}
