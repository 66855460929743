import { displayCityAndState } from '../../../common/utils'
import { fetchFactoringCompanySuggestions } from '../../../redux/externalFactoringSlice'
import { FactoringPaymentMethod } from '../../../redux/paymentSlice'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { SimpleAutocomplete } from '../../Autocomplete/SimpleAutocomplete'
import { PaymentMethodNickname } from './PaymentMethodNickname'

export const FactoringPaymentDetails = ({
  setPaymentInformation,
}: {
  setPaymentInformation: (obj: any) => void
}) => {
  const dispatch = useAppThunkDispatch()
  const paymentInformation = useAppSelector(
    state => state.user.onboarding.onboardingDetails.paymentInformation,
  )
  return (
    <>
      <div className='flex flex-col gap-2 md:flex-row justify-between md:items-center w-full'>
        <SimpleAutocomplete
          required
          className='w-full mb-4'
          label='Factoring Company'
          titleField='businessName'
          value={paymentInformation?.businessName}
          fetchData={(search: string) =>
            dispatch(fetchFactoringCompanySuggestions(search)).unwrap()
          }
          renderAdditionalItemContent={(factoringCompany: FactoringPaymentMethod) => (
            <span className='text-sm text-gray-500'>
              {!factoringCompany.isPlaceholder
                ? `${factoringCompany.addressLine1},
                              ${displayCityAndState(factoringCompany.city, factoringCompany.state)}`
                : "Select this option if you can't find your factoring company in the list"}
            </span>
          )}
          onKeyDown={() => setPaymentInformation({ factoringCompanyId: null })}
          onChange={(factoringCompany: FactoringPaymentMethod) =>
            setPaymentInformation({
              factoringCompanyId: factoringCompany.id,
              businessName: factoringCompany.businessName,
            })
          }
          onSearch={(search: string) => {
            setPaymentInformation({ businessName: search })
          }}
        />
      </div>
      <PaymentMethodNickname isACH={false} setPaymentInformation={setPaymentInformation} />
    </>
  )
}
