import { Button, Tabs, TextInput } from '@components'

import { Link } from '../../components/Login'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { checkCarrierCanRegister, patchCredentials, setLoginStep } from '../../redux/userSlice'

export const CompanyIdentifierForm = () => {
  const dispatch = useAppThunkDispatch()
  const typeTab = useAppSelector(state => state.user.login.credentials.typeTab)
  const docket = useAppSelector(state => state.user.login.credentials.docket)
  const loading = useAppSelector(state => state.user.login.loading)
  const error = useAppSelector(state => state.user.login.error)
  const email = useAppSelector(state => state.user.login.credentials.email)
  const phone = useAppSelector(state => state.user.login.credentials.phone)

  return (
    <>
      <p className='text-xs mb-8 text-gray-600'>
        Enter the information for the company you&apos;d like to register for the email:{' '}
        <strong>{email || phone}</strong>
      </p>
      <Tabs
        activeTab={typeTab}
        setActiveTab={(typeTab: number) => dispatch(patchCredentials({ typeTab }))}
        tabs={['MC', 'DOT']}
      />
      <TextInput
        autoFocus
        required
        className='mt-6'
        id='docket'
        label={!typeTab ? 'MC Number' : 'DOT Number'}
        labelStyle='text-gray-500'
        name='docket'
        value={docket}
        maskOptions={{
          mask: Number,
          min: 99999,
          max: 9999999999,
        }}
        onChange={docket => dispatch(patchCredentials({ docket }))}
      />
      <Button
        fullWidth
        lg
        className='w-full my-6'
        disabled={!!loading || !!(docket?.length < 6)}
        loading={loading}
        type='warn'
        onClick={() => dispatch(checkCarrierCanRegister())}
      >
        Continue to onboard
      </Button>
      {!loading && error && (
        <div className='text-red-500 font-semibold text-center pb-6'>{error}</div>
      )}
      <Link onClick={() => dispatch(setLoginStep('LOGIN'))}>Back to login</Link>
    </>
  )
}
