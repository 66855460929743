import { AlphaModal } from '@components'
import { noop } from 'lodash-es'
import { useEffect } from 'react'

import { formatCurrency } from '../../common/utils'
import { getCarrierLetterOfRelease, requestRelease } from '../../redux/factoringSlice'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { LegalAgreement } from '../LegalAgreement'

type FactoringReleaseModalProps = {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

export const FactoringReleaseModal = ({ setIsVisible, isVisible }: FactoringReleaseModalProps) => {
  const carrierLetterOfReleaseHTML = useAppSelector(
    state => state.factoring.carrierLetterOfReleaseHTML,
  )
  const loadingCarrierFactoringReleaseRequest = useAppSelector(
    state => state.factoring.loadingCarrierFactoringReleaseRequest,
  )
  const latestCarrierRequest = useAppSelector(state => state.factoring.latestCarrierRequest)
  const dispatch = useAppThunkDispatch()
  const onConfirm = () => {
    dispatch(requestRelease())
      .unwrap()
      .then(() => setIsVisible(false))
      .catch(noop)
  }

  useEffect(() => {
    dispatch(getCarrierLetterOfRelease())
  }, [])

  const isSingular = latestCarrierRequest?.outstandingInvoices === 1

  return (
    <AlphaModal
      className='!overflow-visible'
      confirmButtonLabel='confirm'
      isConfirmButtonLoading={loadingCarrierFactoringReleaseRequest}
      isVisible={isVisible}
      setVisible={setIsVisible}
      title='Request Factoring Release'
      onConfirm={onConfirm}
    >
      <div className='p-5 flex flex-col gap-5 text-center max-h-[60vh]'>
        <p className='text-sm px-10'>
          <strong>
            Factoring release is pending account review and collection of outstanding receivables.
            You can expect your release to be issued within 30 days.
          </strong>
        </p>
        {latestCarrierRequest?.outstandingInvoices ? (
          <p>
            <strong>Outstanding Invoices</strong>
            <br />? `There ${isSingular ? 'is' : 'are'} ${latestCarrierRequest?.outstandingInvoices}{' '}
            ${isSingular ? 'invoice' : 'invoices'} totaling $
            {formatCurrency(latestCarrierRequest?.outstandingBalance)} to reconcile`
          </p>
        ) : null}
        <p>
          <strong>Letter of Release</strong>
        </p>
        <LegalAgreement legalHTML={carrierLetterOfReleaseHTML} />
      </div>
    </AlphaModal>
  )
}
