import { NavLink } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { Checkbox } from '../Checkbox'
import { CheckboxSelectElement } from '../CheckboxSelect'

export const CheckboxSelectItem = ({
  isSelected,
  index,
  setData,
  item,
  noDescription = false,
}: {
  isSelected: boolean
  index: number
  setData: Function
  item: CheckboxSelectElement
  noDescription: boolean
}) => (
  <CheckboxSelectItemCustom
    key={item.title}
    $disabled={item.disabled ?? false}
    onClick={() => !item.disabled && setData(item.value ?? index)}
  >
    <div className='flex flex-col'>
      <div className='flex mb-3'>
        <Checkbox selected={isSelected} />
        <Title $disabled={item.disabled ?? false}>{item.title}</Title>
      </div>
      {!noDescription && (
        <Description $disabled={item.disabled ?? false}>{item.description}</Description>
      )}
      {item.cta && (
        <NavLink to={item.cta.url}>
          <CTA>{item.cta.text}</CTA>
        </NavLink>
      )}
    </div>
  </CheckboxSelectItemCustom>
)

const CheckboxSelectItemCustom = tw.div<{
  $disabled?: boolean
}>`
  flex
  p-4
  rounded-md
  border
  border-brand-light 
  w-full 
  mb-4 
  cursor-pointer 
  transition-all 
  ${({ $disabled }) => $disabled && 'hover:opacity-70'}
`

const Title = tw.div`
  font-bold
  ${({ $disabled }: { $disabled: boolean }) => $disabled && 'text-gray-400'}
`

const Description = tw.div`
  ml-10
  text-dark-gray
  ${({ $disabled }: { $disabled: boolean }) => $disabled && 'text-gray-300'}
`

const CTA = tw.div`
  text-blue-700
  ml-10
  mt-3
`
