import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { setShowSuccessMessage } from '../../../redux/factoringSlice'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'

export const SuccessBanner = () => {
  const dispatch = useAppThunkDispatch()

  const successMessageCompanyName = useAppSelector(
    state => state.factoring.successMessageCompanyName,
  )

  return (
    <Container>
      <div className='flex items-center'>
        <CheckCircleIcon className='text-success w-6 mr-2' />
        <div className='font-bold text-2xl'>Request Received!</div>
      </div>
      <div className='mt-3'>
        Load factoring request for <span className='font-bold'>{successMessageCompanyName}</span>{' '}
        was received.
      </div>
      <CloseIcon onClick={() => dispatch(setShowSuccessMessage(false))} />
    </Container>
  )
}

const Container = tw.div`
  flex
  flex-col
  items-center
  justify-center
  bg-lighter-blue
  border
  border-brand-light
  rounded-lg
  mt-10
  p-6
  relative
`

const CloseIcon = tw(XMarkIcon)`
  absolute
  right-2
  top-2
  w-5
  cursor-pointer
  transition-all
  hover:opacity-80
`
