import './Sidebar.scss'

import { GiftIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { ApprovedToHaulStatus } from '../../common/types/company'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { setUserSetting } from '../../redux/userSlice'
import { ManagedTransShipperPicker } from './ManagedTransShipperPicker'

export const SidebarPromo = () => (
  <div className='bg-brand-dark flex text-brand-accent px-6 py-2 rounded-lg items-center promo'>
    <GiftIcon className='pr-2 w-7' />
    <p>Get $500</p>
  </div>
)

export const FactoringLabel = () => {
  const showFactoringPageUnread = useAppSelector(
    state => state.user.settings.showFactoringPageUnread,
  )
  const factoringStatus = useAppSelector(state => state.factoring.factoringStatus)
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    if (
      ['PENDING', 'APPROVED', 'REJECTED', 'RELEASED', 'RELEASE_REQUESTED'].includes(factoringStatus)
    ) {
      dispatch(setUserSetting({ showFactoringPageUnread: false }))
    }
  }, [factoringStatus])

  return (
    <div className='flex justify-between w-full pr-1'>
      Factoring
      {showFactoringPageUnread && <NewLabel>NEW</NewLabel>}
    </div>
  )
}

export const FuelDiscountsLabel = () => {
  const showFuelPageUnread = useAppSelector(state => state.user.settings.showFuelPageUnread)
  const approvedToReserveFuel = useAppSelector(
    state => state.company.companyDetail.approvedToReserveFuel,
  )
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    if (approvedToReserveFuel) dispatch(setUserSetting({ showFuelPageUnread: false }))
  }, [approvedToReserveFuel])

  return (
    <div className='flex justify-between w-full pr-1'>
      Fuel Discounts
      {showFuelPageUnread && <NewLabel>NEW</NewLabel>}
    </div>
  )
}

type Link = {
  customLabel?: React.ReactNode
  isVisible?: () => boolean
  label?: string
  url: string
}

export type LinkMetadata = {
  heading: string
  isVisible?: () => boolean
  links: Link[]
}

export const useSections = (): LinkMetadata[] => {
  const user = useAppSelector(state => state.user.user)
  const companyDetail = useAppSelector(state => state.company.companyDetail)
  const companyDetailLoading = useAppSelector(state => state.company.loading.fetchCompanyDetail)

  return useMemo(() => {
    if (!user || !companyDetail || companyDetailLoading) return []

    const { approvedToFactor, approvedToReserveFuel, approvedToHaulStatus } = companyDetail
    const userHasFinance = user.isFinance || user.isPrimary
    const haulingOnboardingStarted = approvedToHaulStatus
      ? approvedToHaulStatus != ApprovedToHaulStatus.NOT_STARTED
      : false
    const isEXOFreightNetwork = !companyDetail.activeManagedTransShipper

    const sections: LinkMetadata[] = [
      {
        heading: 'Freight',
        links: [
          {
            url: '/loads',
            label: 'Load Board',
            isVisible: () => isEXOFreightNetwork,
          },
          {
            url: '/my-loads',
            label: 'My Loads',
          },
          {
            url: '/my-quotes',
            label: 'My Quotes',
            isVisible: () => !isEXOFreightNetwork || haulingOnboardingStarted,
          },
        ],
      },
      {
        heading: 'Finance',
        links: [
          {
            url: '/exo-factoring',
            customLabel: <FactoringLabel />,
            isVisible: () => !!approvedToFactor && isEXOFreightNetwork,
          },
          {
            url: '/banking',
            label: 'Banking',
            isVisible: () => (!!approvedToReserveFuel || !!approvedToFactor) && isEXOFreightNetwork,
          },
          {
            url: '/payments',
            label: 'Payments',
            isVisible: () => haulingOnboardingStarted,
          },
        ],
        isVisible: () => !!userHasFinance,
      },
      {
        heading: 'Settings',
        links: [
          {
            url: '/manage-contacts',
            label: 'Users',
          },
          {
            url: '/capacity',
            label: 'Capacity & Lanes',
            isVisible: () => haulingOnboardingStarted,
          },
          {
            url: '/manage-equipment',
            label: 'Equipment',
            isVisible: () => haulingOnboardingStarted,
          },
          {
            url: '/recommendations-settings',
            label: 'Recommendations',
            isVisible: () => haulingOnboardingStarted,
          },
          {
            url: '/payment-methods',
            label: 'Payment Methods',
            isVisible: () => !!userHasFinance && haulingOnboardingStarted,
          },
        ],
      },
      {
        heading: 'Support',
        links: [
          {
            url: '/documents',
            label: 'Statement Documents',
            isVisible: () => isEXOFreightNetwork,
          },
          {
            url: '/contact-carrier-rep',
            label: 'Contact Carrier Rep',
          },
          {
            url: '/feedback',
            label: 'Share Your Feedback',
          },
        ],
      },
    ]

    return sections
      .filter(section => !section.isVisible || section.isVisible())
      .map(section => ({
        ...section,
        links: section.links.filter(link => !link.isVisible || link.isVisible()),
      }))
  }, [companyDetail, companyDetailLoading, user])
}

export const Sidebar = () => {
  const location = useLocation()
  const showSidebar = useAppSelector(state => state.user.settings.showSidebar)
  const user = useAppSelector(state => state.user.user)
  const sections = useSections()

  if (!user || showSidebar === false) return null

  return (
    <nav className='sidebar border-r border-light-gray h-full pt-6' role='menubar'>
      <ManagedTransShipperPicker classNames='mx-6' />
      {sections.map((section, i) => (
        <div key={i} className='pl-6 pr-6 pb-3'>
          <HeaderText>{section.heading}</HeaderText>
          {section.links.map((link, i) => (
            <NavLink
              key={i}
              role={link.url.split('/')[1]} // it gives each link a role(URL-based, e.g. my-quotes, my-loads) in order for the link to be found in the test
              state={{ prevPath: location.pathname, fromNav: true }}
              className={({ isActive }) =>
                isActive ? 'text-brand-accent font-bold' : 'text-link font-semibold'
              }
              to={{
                pathname: link.url,
              }}
            >
              <div
                className={`text-base font-sans flex items-center py-1 ${
                  location.pathname.startsWith(link.url) && 'active-link'
                }`}
              >
                {link?.customLabel ?? link?.label}
              </div>
            </NavLink>
          ))}
        </div>
      ))}
    </nav>
  )
}

const HeaderText = tw.div`
  pb-2
  text-sm
  font-semibold
  font-poppins
`

const NewLabel = tw.div`
  inline-flex
  items-center
  justify-center
  w-12
  h-5
  rounded-2xl
  bg-error
  text-xs
  text-white
`
