import { Button, Modal } from '@components'
import { ArrowDownOnSquareStackIcon, XMarkIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

export default function BOLModal({
  isVisible,
  setVisible,
  loadId,
  bolUrls,
}: {
  isVisible: boolean
  setVisible: (isVisible: boolean) => void
  loadId: number
  bolUrls: string[]
}) {
  const closeModal = () => setVisible(false)
  const downloadAll = () =>
    bolUrls
      .map((bol: string) => getBOLBtnClickHandler(bol))
      .forEach((handler: () => void) => handler())

  return (
    <Modal isVisible={isVisible} setVisible={setVisible} showCloseIcon={false}>
      <Container data-testid='bol-modal'>
        <Header>
          <div className='flex items-center'>
            <HeaderAccent />
            <Title>Documents Attached: Load {loadId}</Title>
          </div>
          <IconCloseBtn onClick={closeModal}>
            <XMarkIcon />
          </IconCloseBtn>
        </Header>
        <Body>
          <Table>
            <Row>
              <FCell className='font-normal'>Name</FCell>
              <MCell className='text-brand-dark'>Type</MCell>
              <LCell>Action</LCell>
            </Row>
            {bolUrls.map((bol: string, idx: number) => (
              <Row key={bol}>
                <FCell>BOL #{idx + 1}</FCell>
                <MCell>PDF</MCell>
                <LCell>
                  <Button
                    sm
                    className='!h-7 !w-7 md:!h-5 md:!w-5 !rounded'
                    preIcon='download'
                    type='link'
                    onClick={getBOLBtnClickHandler(bol)}
                  />
                </LCell>
              </Row>
            ))}
          </Table>
        </Body>
        <Footer>
          <CloseBtn onClick={closeModal}>Close</CloseBtn>
          <DownloadAllBtn onClick={downloadAll}>
            <ArrowDownOnSquareStackIcon />
            Download All
          </DownloadAllBtn>
        </Footer>
      </Container>
    </Modal>
  )
}

function getBOLBtnClickHandler(bol: string) {
  return () => window.open(bol, '_blank')
}

const Container = tw.div`
  flex
  flex-col
  w-[90vw]
  md:w-[480px]
`

const Header = tw.div`
  flex
  h-10
  bg-brand-dark
  gap-4
  items-center
  justify-between
  pr-2
`

const HeaderAccent = tw.div`
  w-2
  bg-link
  h-8
  mr-4
`

const IconCloseBtn = tw.button`
  h-6
  w-6
  text-white
`

const Title = tw.span`
  font-sans
  font-semibold
  text-sm
  uppercase
  text-white
`

const Body = tw.div`
  flex
  flex-col
  md:flex-row
  h-44
  p-6
`

const Table = tw.div`
  flex
  flex-col
  w-full
  gap-2
`

const Row = tw.div`
  flex
  h-5
  w-full
  justify-between
  mb-2
  items-center
`

const Cell = tw.span`
  flex
  w-1/3
  text-brand-dark
  text-xs
`

const FCell = tw(Cell)`
  font-bold
`

const MCell = tw(Cell)`
  justify-center
  text-dark-gray
`

const LCell = tw(Cell)`
  justify-end
`

const Footer = tw.div`
  flex
  h-20
  bg-brand-light
  items-center
  justify-between
  px-6
`

const CloseBtn = tw.button`
  bg-white
  rounded-lg
  h-11
  w-20
  uppercase
  text-brand-dark
  text-sm
  font-semibold
`

const DownloadAllBtn = tw.button`
  flex
  bg-brand-accent
  rounded-lg
  h-11
  w-44
  justify-between
  items-center
  py-3
  px-4
  text-sm
  text-brand-dark
  uppercase
  font-semibold
`
