import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { equipmentTypeChoices } from '../common/constants'
import { RootState, UnitType } from '../common/types'
import { keysToCamel, keysToSnake } from '../common/utils'

export type EquipmentType = (typeof equipmentTypeChoices)[number]['id']

export type Equipment = {
  id?: number
  equipmentType: EquipmentType | null
  unitType: UnitType | null
  quantity: number
}

type EquipmentsState = {
  equipments: Equipment[]
  newEquipmentModalVisible: boolean
  newEquipment: Equipment
  loading: {
    fetchingEquipments: boolean
    addingEquipment: boolean
  }
}

const getNewEquipment = (): Equipment => ({
  equipmentType: null,
  unitType: null,
  quantity: 1,
})

const initialState: EquipmentsState = {
  equipments: [],
  newEquipmentModalVisible: false,
  newEquipment: getNewEquipment(),
  loading: {
    fetchingEquipments: true,
    addingEquipment: false,
  },
}

export const getCarrierEquipments = createAsyncThunk(
  'equipments/getCarrierEquipment',
  async () =>
    await api
      .get('/accounts/api/carrier/carrier-company-equipment/', {
        params: { limit: 100 },
      })
      .then(({ data }) => data)
      .then(({ results }) => keysToCamel(results)),
)

export const optimisticEquipmentUpdate = createAsyncThunk(
  'equipments/optimisticEquipmentUpdate',
  async (
    {
      updatedEquipment,
      existingEquipment,
    }: { updatedEquipment: Equipment; existingEquipment: Equipment },
    { dispatch },
  ) =>
    // perform the update, and on failure revert the state
    api
      .put(
        `/accounts/api/carrier/carrier-company-equipment/${existingEquipment.id}/`,
        keysToSnake(updatedEquipment),
      )
      .catch(() => {
        dispatch(setEquipmentById(existingEquipment))
      }),
)

export const deleteEquipment = createAsyncThunk(
  'equipments/deleteEquipment',
  async (id: number) =>
    await api.delete(`/accounts/api/carrier/carrier-company-equipment/${id}/`).then(() => id),
)

export const addEquipment = createAsyncThunk('equipments/addEquipment', async (_, { getState }) => {
  const equipment = (getState() as RootState).equipments.newEquipment
  return await api
    .post('/accounts/api/carrier/carrier-company-equipment/', keysToSnake(equipment))
    .then(({ data }) => keysToCamel(data) as Equipment)
})

export const equipmentsSlice = createSlice({
  name: 'equipments',
  initialState,
  reducers: {
    setNewEquipmentModalVisible: (state, action) => {
      state.newEquipmentModalVisible = action.payload
    },
    setNewEquipment: (state, action: PayloadAction<Partial<Equipment>>) => {
      state.newEquipment = {
        ...state.newEquipment,
        ...action.payload,
      }
    },
    setEquipmentById: (state, { payload }: PayloadAction<Equipment>) => {
      state.equipments = state.equipments.map(equipment =>
        equipment.id === payload.id ? payload : equipment,
      )
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCarrierEquipments.pending, state => {
        state.loading.fetchingEquipments = true
      })
      .addCase(getCarrierEquipments.fulfilled, (state, action) => {
        state.loading.fetchingEquipments = false
        state.equipments = action.payload
      })
      .addCase(getCarrierEquipments.rejected, state => {
        state.loading.fetchingEquipments = false
        toast.error('Could not fetch equipments')
      })
      .addCase(addEquipment.pending, state => {
        state.loading.addingEquipment = true
      })
      .addCase(addEquipment.fulfilled, (state, action) => {
        state.loading.addingEquipment = false
        state.newEquipmentModalVisible = false
        state.equipments.push(action.payload)
        state.newEquipment = getNewEquipment()
        toast.success('Equipment added successfully')
      })
      .addCase(addEquipment.rejected, state => {
        state.loading.addingEquipment = false
        toast.error('Could not add equipment')
      })
      .addCase(deleteEquipment.fulfilled, (state, { payload }) => {
        state.equipments = state.equipments.filter(equipment => equipment.id !== payload)
      })
      .addCase(deleteEquipment.rejected, () => {
        toast.error('Could not delete equipment')
      })
  },
})

export default equipmentsSlice.reducer

export const { setNewEquipmentModalVisible, setNewEquipment, setEquipmentById } =
  equipmentsSlice.actions
