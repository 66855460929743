import { Button } from '@components'
import { useEffect } from 'react'

import { contactHint } from '../../../common/constants'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { setOnboardingDetails } from '../../../redux/userSlice'
import { Hint } from '../../Hint'
import { SectionTitle } from '../../SectionTitle'
import { Box, ContactForm } from '../components'

export const ContactInformation = () => {
  const dispatch = useAppThunkDispatch()

  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)

  const { contacts } = onboardingDetails

  const setPrimaryContact = (field: string, value: any) => {
    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        contacts: {
          ...contacts,
          primaryContact: {
            ...contacts?.primaryContact,
            [field]: value,
          },
        },
      }),
    )
  }

  const setIndividualContacts = (idx: number, field: string, value: any) => {
    const individuals = [...(contacts.individualContacts || [])]
    individuals[idx] = { ...individuals[idx], [field]: value }

    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        contacts: {
          ...contacts,
          individualContacts: individuals,
        },
      }),
    )
  }

  const addNewIndividual = () => {
    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        contacts: {
          ...contacts,
          individualContacts: [
            ...contacts.individualContacts,
            {
              types: [],
              name: '',
              phone: '',
              email: '',
              emailRemit: '',
            },
          ],
        },
      }),
    )
  }

  const removeIndividual = (id: number) => {
    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        contacts: {
          ...contacts,
          individualContacts: contacts.individualContacts.filter(
            (item: any, i: number) => i !== id,
          ),
        },
      }),
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className='relative border border-brand-light rounded-lg form pb-4 text-left'>
        <Hint className='m-4 md:m-6' text={contactHint} />
        <SectionTitle required title='Primary Contact' />
        <div className='px-4 md:px-6'>
          <ContactForm
            contact={contacts?.primaryContact}
            isPrimary={true}
            setItem={setPrimaryContact}
          />
        </div>
        {contacts?.individualContacts.length ? (
          <SectionTitle className='mt-8' title='Individual Contacts' />
        ) : null}
        <div className='px-4 md:px-6'>
          {contacts?.individualContacts?.map((_: void, i: number) => (
            <ContactForm
              key={i}
              contact={contacts?.individualContacts[i]}
              setItem={(field: string, value: any) => setIndividualContacts(i, field, value)}
              title={`Contact ${i + 1}`}
              onDelete={() => removeIndividual(i)}
            />
          ))}
          <Box>
            <div className='font-semibold mb-4 md:mb-0'>Need to add more contacts?</div>

            <Button lg short preIcon='plus-circle' type='secondary' onClick={addNewIndividual}>
              add another
            </Button>
          </Box>
        </div>
      </div>
    </div>
  )
}
