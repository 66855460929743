import {
  CarrierLocationTrackingEvent,
  CatchError,
  CityLocation,
  formatAxiosErrorToPayload,
  getErrorString,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { isEmpty, isNil, isObject, omitBy } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { identifyUser } from '../common/tracking'
import { User } from '../common/types'

export enum TrackingStep {
  SHOW_REMINDER = 'SHOW_REMINDER',
  DELAYED = 'DELAYED',
  REQUESTING = 'REQUESTING',
  ALLOW_CLICKED = 'ALLOW_CLICKED',
  TRACKING = 'TRACKING',
  TRACKING_USER_ENTRY = 'TRACKING_USER_ENTRY',
}

export enum TrackingType {
  NATIVELY_BACKGROUND = 'NATIVELY_BACKGROUND',
  BROWSER_FOREGROUND = 'BROWSER_FOREGROUND',
  NOT_INITIALIZED = 'NOT_INITIALIZED',
}

const initialState: {
  citySearchQuery: CityLocation | null
  geoLocationError: string | null
  hasInitializedUser: boolean
  locationEvents: CarrierLocationTrackingEvent[]
  locationEventsLoading: boolean
  trackingStep: TrackingStep
  trackingType: TrackingType
} = {
  citySearchQuery: null,
  geoLocationError: null,
  hasInitializedUser: false,
  locationEvents: [],
  locationEventsLoading: true,
  trackingStep: TrackingStep.SHOW_REMINDER,
  trackingType: TrackingType.NOT_INITIALIZED,
}

export const trackReferralLinkOpening = createAsyncThunk(
  'trackingInfo/trackReferralLinkOpening',
  async (data: { loadOfferEmailId?: string; loadOfferSMSId?: string }) => {
    const { loadOfferEmailId, loadOfferSMSId } = data
    api.post('/carrier-matching/api/carrier/track-referral-link-opening/', {
      load_offer_email_id: loadOfferEmailId || undefined,
      load_offer_sms_id: loadOfferSMSId || undefined,
    })
  },
)

export const trackDriverCheckIn = createAsyncThunk(
  'trackingInfo/trackDriverCheckIn',
  async (data: { loadId: string; checkInType: string; checkInResponse: string }) => {
    const { loadId, checkInType, checkInResponse } = data
    let slug = ''
    if (checkInType === 'pickupEta')
      slug =
        checkInResponse == 'yes'
          ? '/carrier/carrier-notifications/pickup-eta-check-yes'
          : '/carrier/carrier-notifications/pickup-eta-check-no'
    else if (checkInType === 'deliveryEta')
      slug =
        checkInResponse == 'yes'
          ? '/carrier/carrier-notifications/delivery-eta-check-yes'
          : '/carrier/carrier-notifications/delivery-eta-check-no'
    else if (checkInType === 'pickupConfirmation')
      slug =
        checkInResponse == 'yes'
          ? '/tracking/pickup-confirmation-yes'
          : '/tracking/pickup-confirmation-no'
    else if (checkInType === 'deliveryConfirmation')
      slug =
        checkInResponse == 'yes'
          ? '/tracking/delivery-confirmation-yes'
          : '/tracking/delivery-confirmation-no'
    await api.post(`${slug}/${loadId}/`)
  },
)

export const trackEventInternally = createAsyncThunk(
  'trackingInfo/trackEventInternally',
  async ({ event, extra_data, user }: { event: string; extra_data?: any; user: User | null }) => {
    /*
    Used to track app events internally.
    */
    if (user && Cookies.get('csrftoken3')) {
      // Only attempt to track events if the user is authenticated and CSRF token is present
      const payload = omitBy(
        { event, extra_data },
        value => value === '' || isNil(value) || (isObject(value) && isEmpty(value)),
      )
      await api.post('accounts/api/track-carrier-event/', payload).catch(() => {})
    }
  },
)

export const trackCarrierLocation = createAsyncThunk(
  'trackingInfo/trackCarrierLocation',
  async (
    payload: {
      accuracy: number
      heading: number | null
      latitude: number
      longitude: number
      speed: number | null
      loadId?: number
    },
    { rejectWithValue },
  ) => {
    const { accuracy, heading, latitude, longitude, speed, loadId } = payload
    if (!latitude || !longitude) return
    try {
      const geoPoint = {
        type: 'Point',
        coordinates: [longitude, latitude], // GeoJSON uses [longitude, latitude] order
      }
      await api.post('/tracking/location-tracking-event/', {
        ...(loadId ? { load: loadId } : {}),
        accuracy,
        geo_point: geoPoint,
        heading,
        speed,
      })
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCarrierLocationHistory = createAsyncThunk(
  'trackingInfo/getCarrierLocationHistory',
  async (loadId?: number) => {
    const url = loadId
      ? `/tracking/location-tracking-event/${loadId}/`
      : '/tracking/location-tracking-event/'
    const response = await api.get(url)
    const transformedData = response.data.map((event: any) => {
      const [longitude, latitude] = event.geo_point.split('(')[1].split(')')[0].split(' ')
      return {
        timestamp: new Date(event.timestamp).toISOString(),
        accuracy: event.accuracy,
        coordinates: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
      }
    })
    return transformedData
  },
)

export const sendCarrierDelayNotification = createAsyncThunk(
  'trackingInfo/sendCarrierDelayNotification',
  async (loadId: number, { rejectWithValue }) => {
    try {
      await api.post(`/tracking/carrier-delayed-event/${loadId}/`)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const trackingSlice = createSlice({
  name: 'trackingInfo',
  initialState,
  reducers: {
    initializeTracking: (state, { payload }: { payload: { email: string; name: string } }) => {
      // This action is set up to be idempotent, so we only identify the user once, and
      // only after the user's information is known.

      if (payload !== undefined && !state.hasInitializedUser) {
        identifyUser(payload.email, payload.name)
        state.hasInitializedUser = true
      }
    },
    setCitySearchQuery: (state, { payload }: { payload: CityLocation | null }) => {
      state.citySearchQuery = payload
    },
    setGeoLocationError: (state, { payload }: { payload: string | null }) => {
      state.geoLocationError = payload
    },
    setTrackingStep: (state, { payload }: { payload: TrackingStep }) => {
      state.trackingStep = payload
    },
    setTrackingType: (state, { payload }: { payload: TrackingType }) => {
      state.trackingType = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCarrierLocationHistory.pending, state => {
        state.locationEventsLoading = true
      })
      .addCase(getCarrierLocationHistory.fulfilled, (state, { payload }) => {
        state.locationEvents = payload
        state.locationEventsLoading = false
      })
      .addCase(getCarrierLocationHistory.rejected, state => {
        state.locationEventsLoading = false
      })
      .addCase(trackCarrierLocation.fulfilled, (state, { meta }) => {
        if (meta.arg.latitude && meta.arg.longitude) {
          state.geoLocationError = null
          state.locationEvents.push({
            timestamp: new Date().toISOString(),
            accuracy: meta.arg.accuracy,
            coordinates: {
              lat: meta.arg.latitude,
              lng: meta.arg.longitude,
            },
          })
        }
      })
      .addCase(trackCarrierLocation.rejected, (state, { payload }) => {
        state.geoLocationError = getErrorString(
          payload,
          'An error occurred while communicating your location, please refresh the page.',
        )
      })
      .addCase(sendCarrierDelayNotification.rejected, (state, { payload }) => {
        toast.error(
          getErrorString(payload, 'An error occurred while sending the delay notification.'),
        )
      })
  },
})

export const {
  initializeTracking,
  setCitySearchQuery,
  setGeoLocationError,
  setTrackingStep,
  setTrackingType,
} = trackingSlice.actions

export default trackingSlice.reducer
