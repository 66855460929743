import './Table.scss'

import { useEffect } from 'react'
import tw from 'tailwind-styled-components'

import { Pagination } from './Pagination'

export const PaginationContainer = ({
  pageSize,
  setPageSize,
  setSize,
  setActive,
  setOffset,
  count,
  active,
  offset,
}: {
  pageSize: number
  setPageSize: Function
  setSize: Function
  setOffset: Function
  setActive: Function
  setScrollPosition?: Function
  count: number
  active: any
  offset: number
}) => {
  useEffect(() => {
    if (!offset) setActive(1)
  }, [offset])

  return (
    <PaginationContainerStyle className='pagination-container'>
      <div className='selector-container'>
        Showing{' '}
        <span className='font-medium'>
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
              setSize(Number(e.target.value))
              setOffset(0)
              setActive(1)
            }}
          >
            {[5, 10, 20, 50, 100].map(pageSizeVal => (
              <option key={pageSizeVal} value={pageSizeVal}>
                {pageSizeVal}
              </option>
            ))}
          </select>
        </span>{' '}
        of <span className='font-medium'>{count}</span>
      </div>
      <div className='pagination-wrap'>
        <Pagination
          active={active}
          count={count}
          offset={offset}
          pageSize={pageSize}
          setOffset={setOffset}
          size={Math.ceil(count / pageSize)}
          step={2}
          onClickHandler={(clickedActive: any) => {
            setActive(parseInt(clickedActive))
            setOffset(pageSize * (parseInt(clickedActive) - 1))
          }}
        />
      </div>
    </PaginationContainerStyle>
  )
}

const PaginationContainerStyle = tw.div`
  fixed 
  w-[calc(100%-240px)]
  py-1
  border-t
  bottom-0
  bg-white
  pb-3
`
