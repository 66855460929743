import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

export const DetailSectionRow = ({
  title,
  children,
  className,
}: {
  title: string
  children: ReactNode
  className?: string
}) => (
  <Row className={className}>
    <Title>
      {title}
      <span className='hidden md:inline'>:</span>
    </Title>
    <div>{children || '—'}</div>
  </Row>
)

const Row = tw.div`
  flex
  flex-col
  md:flex-row
  md:items-center
  my-1.5
  md:my-4
`

const Title = tw.div`
  md:mr-4
  text-dark-gray
  mb-1
  md:mb-0
`
