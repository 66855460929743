import tw from 'tailwind-styled-components'

export const SectionTitle = ({
  title,
  className = '',
  required,
}: {
  title: string
  className?: string
  required?: boolean
}) => (
  <Container className={className}>
    <div className='pl-5'>
      {title}{' '}
      {required && (
        <span className='text-error' data-testid='asterisk'>
          *
        </span>
      )}
    </div>
  </Container>
)

const Container = tw.div`
  text-xl 
  font-bold 
  border-l-4 
  border-link
`
