import { FileProps, FuelStation, PaymentMethodType, UnitType } from './types'

export const accountTypes = ['CHECKING', 'SAVINGS']

export const contactHint =
  'You must complete the fields below. If you have any problems completing this form please contact your carrier representative at 702-840-3285 ext. 1 or carriersetup@exofreight.com'

export const countries = [
  { name: 'United States', value: 'US' },
  { name: 'Canada', value: 'CA' },
  { name: 'Mexico', value: 'MX' },
]

export const devEmail = 'agent@exofreight.com'
export const supportEmail = 'support@exofreight.com'

export const defaultPath = '/loads'

export const emptyFile: FileProps = {
  name: '',
  fileData: '',
  mimeType: '',
}

export const emptyCarrier = {
  qualificationConfirmed: false,
  qualification: null,
  paymentInformation: {
    paymentType: PaymentMethodType.STANDARD,
    paymentMethod: 'ACH',
    accountType: 'CHECKING',
    routingNumber: '',
    accountNumber: '',
    accountNumberConfirm: '',
    name: '',
    businessName: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    factoringCompanyId: null,
  },
  accountReceivableInformation: {
    entityType: 0,
    entityName: '',
    scac: '',
    secNumber: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
  },
  signing: {
    firstName: '',
    lastName: '',
    signed: false,
  },
  documents: {
    w9: {
      name: '',
      image: '',
    },
    insurance: <Array<any>>[],
    certificate: {
      name: '',
      value: '',
    },
  },
  contacts: {
    primaryContact: {
      types: ['SMS Load Offers', 'Email Load Offers'],
      name: '',
      phone: '',
      email: '',
      emailRemit: '',
    },
    individualContacts: [],
  },
  referralCode: null,
  isInterestedInFactoring: false,
}

export const entityTypes = [
  {
    title: 'I represent a business',
    description: 'e.g. LLC, Corporation',
  },
  {
    title: 'I am an individual',
    description: 'e.g. Sole Prop',
  },
]

export const notionUrls = {
  bankingFAQs: 'https://exo-freight.notion.site/exo-freight/2e994faef51f4179be6f77861655f7f8',
  carrierPortalFAQs: 'https://exo-freight.notion.site/97278ba80dba49f9811f6a381b376a05',
  loginFAQs: 'https://exo-freight.notion.site/H1533cbcac2d141e0acfd250a00a12866',
  privacyPolicy: 'https://exo-freight.notion.site/8ba5cf7a975c47d6b569c6a332270d3b',
}

// See CreateReservationSerializer in backend/apps/fuel/serializers.py
export const MIN_GALLONS = 5
export const MAX_GALLONS = 400

export const fuelPurchaseType = {
  gallons: {
    title: 'How many gallons are you purchasing?',
    buttonLabel: 'Switch to Dollars',
  },
  totalCost: {
    title: 'How much fuel are you purchasing?',
    buttonLabel: 'Switch to Gallons',
  },
}

export const initialContact = {
  name: '',
  roles: [],
  email: '',
  phone: '',
  id: 1,
}

export const initialFilters = {
  equipmentType: '',
  origin: {
    title: '',
    city: '',
    state: '',
  },
  destination: {
    title: '',
    city: '',
    state: '',
  },
  dateRange: [],
  miles: '',
  loadId: '',
  bookable: null,
  invoice: '',
  check: '',
  deadHeadOriginMiles: '',
  deadHeadDestinationMiles: '',
}

export const initialLane = {
  origin: {
    city: '',
    state: '',
  },
  destination: {
    city: '',
    state: '',
  },
  equipment: '',
  miles: '',
  rate: '',
  id: 1,
}

const environment = import.meta.env.VITE_ENVIRONMENT
export const isLocal = environment === 'local'
export const isPreview = environment === 'preview'
export const isStage = environment === 'stage'
export const isProduction = environment === 'production'

// SENTRY_ENV when defined, otherwise use VITE_ENVIRONMENT when Stage or Prod
// otherwise null
export const sentryEnv = import.meta.env.VITE_SENTRY_ENV
  ? import.meta.env.VITE_SENTRY_ENV.toLowerCase()
  : ((isProduction || isStage) && environment) || null

export const officePhone = '17028403285'
export const factoringTeamPhone = '12483924581'
export const formattedFactoringTeamPhone = '+1 (248) 392-4581'
export const bankingTeamPhone = '12482668916'

export const paymentMethods = [
  {
    title: 'ACH',
    value: 'ACH',
    description: "You'll get a direct deposit into your bank account.",
  },
  {
    title: 'Check',
    value: 'CHECK',
    description: "We'll mail you a check, which can take up to 7 days to arrive.",
  },
  {
    title: 'Factoring Company',
    value: 'FACTORING',
    description: "You'll be paid by your factoring company.",
  },
]

export const paymentTypes = [
  {
    title: 'EXO Finance Factoring',
    value: PaymentMethodType.EXO_FACTORING,
    description: 'Our premier factoring program offers the fastest payment speeds.',
    disabled: true,
    cta: {
      text: 'Take me there',
      url: '/exo-factoring',
    },
  },
  {
    title: '3rd-Party Factoring',
    value: PaymentMethodType.FACTORING,
    description:
      'Select a primary payment method of type "factoring" below to use your factoring company.',
    disabled: true,
  },
  {
    title: 'Standard Net 30',
    value: PaymentMethodType.STANDARD,
    description: "You'll be paid in full within 30 days following receipt of proof of delivery.",
  },
  {
    title: 'Quick Pay',
    value: PaymentMethodType.QUICK_PAY,
    description:
      "You'll be paid within 48 hours upon receipt of proof of delivery. \
      We charge a 3.5% fee for this service.",
  },
]

export const unitEmptyMessages = [
  'Add your trailers types and count to help us offer you the right load opportunities',
  'Let us know how many tractors you have in your fleet',
]

export const unitTypes: UnitType[] = [
  { id: 1, name: 'Trailer' },
  { id: 2, name: 'Tractor' },
]

export const FACTORING_RATE = 0.015

export const bankingStructureTypes = [
  { id: 1, name: 'Sole Proprietorship', value: 'SOLE_PROPRIETORSHIP' },
  { id: 2, name: 'Partnership', value: 'PARTNERSHIP' },
  { id: 3, name: 'LLC', value: 'LLC' },
  { id: 4, name: 'Corporation', value: 'CORPORATION' },
  { id: 5, name: 'S Corporation', value: 'S_CORPORATION' },
  { id: 6, name: 'Non-Profit', value: 'NON_PROFIT' },
  { id: 7, name: 'Other', value: 'OTHER' },
]

export const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const bankingTransactionTypes = {
  ach: 'ACH Transfer',
  cashback: 'Internal Transfer',
  internal_transfer: 'Internal Transfer',
  pos_purchase: 'Point of Sale Purchase',
}

export const equipmentTypeChoices = [
  {
    id: 'Flatbed',
    name: 'Flatbed',
  },
  {
    id: 'Van',
    name: 'Van',
  },
  {
    id: 'Power Only',
    name: 'Power Only',
  },
  {
    id: 'Hot Shot',
    name: 'Hot Shot',
  },
  {
    id: 'Reefer',
    name: 'Reefer',
  },
  {
    id: 'Step Deck',
    name: 'Step Deck',
  },
  {
    id: 'Removable Gooseneck',
    name: 'Removable Gooseneck',
  },
  {
    id: 'Conestoga',
    name: 'Conestoga',
  },
  {
    id: 'Flatbed or Step Deck',
    name: 'Flatbed or Step Deck',
  },
  {
    id: 'Van or Reefer',
    name: 'Van or Reefer',
  },
  {
    id: 'Flatbed or Step Deck or Van',
    name: 'Flatbed or Step Deck or Van',
  },
  {
    id: 'Load Out',
    name: 'Load Out',
  },
  {
    id: 'Double Drop',
    name: 'Double Drop',
  },
  {
    id: 'Van Intermodal',
    name: 'Van Intermodal',
  },
  {
    id: 'Tanker Intermodal',
    name: 'Tanker Intermodal',
  },
  {
    id: 'Reefer Intermodal',
    name: 'Reefer Intermodal',
  },
  {
    id: 'Straight Box Truck',
    name: 'Straight Box Truck',
  },
] as const

export const MatchType = {
  HISTORICAL_LOAD: 1,
  LANE: 3,
  CARRIER_LOCATION: 5,
} as const

export const carrierSalesPhone = '7026233352'

export const getDirections = (station: FuelStation) =>
  `https://www.google.com/maps/dir/Your+Location/${encodeURI(
    `${station?.address}, ${station?.city}, ${station?.state} ${station?.postalCode}`,
  )}`

export enum RFPStatusOptions {
  ACTION_REQUIRED = 'Action Required',
  INVITATION_ACCEPTED = 'Invitation Accepted',
  IN_PROGRESS = 'In Progress',
  INVITATION_REJECTED = 'Invitation Rejected',
  RATES_SUBMITTED = 'Rates Submitted',
  AWARDED = 'Awarded',
  BID_EVALUATION = 'Bid Evaluation',
}
