export const agreement = {
  description:
    'This Broker/Carrier Agreement is being entered into by and between Power Only Transit, Inc. DBA EXO Freight a Delaware corporation (hereinafter referred to as “BROKER”), and #USER_NAME#, (hereinafter referred to as “CARRIER”) as defined below, on this 23 day of Dec, 2021.',
  sections: [
    {
      title: 'Parties',
      text:
        'A. Power Only Transit, Inc. is the “Broker” as that term is defined under 49 U.S.C. § 13102(2) or any regulation, amendment or renumbered law by which the United States or any agency thereof defines a freight broker and any applicable federal or state regulations, statutes, decisional law or administrative law. BROKER will arrange for the freight tendered by a shipper to be transported by CARRIER under the means, manner, method, and terms selected by the shipper or CARRIER, but BROKER is not engaged in the business of and will not act as a “Carrier,” “Motor Carrier,” or “Freight Forwarder,” as those terms are defined under 49 U.S.C. § 13102, and BROKER is not engaged in the business of and will not act as a “Rail Carrier” as that term is defined under 49 U.S.C. § 11706.\n' +
        '\n' +
        'B. #USER_NAME# is the “CARRIER,” and hereby agrees to transport freight identified by BROKER as requiring transportation services.\n' +
        '\n' +
        '  C. BROKER and CARRIER will sometimes be referred to collectively as “The Parties.”',
    },
    {
      title: 'Recitals',
      text:
        'This Broker/Carrier Agreement is being entered into by and between Power Only Transit, Inc. DBA EXO Freight a Delaware corporation (hereinafter referred to as “BROKER”), and #USER_NAME#, (hereinafter referred to as “CARRIER”) as defined below, on this 23 day of Dec, 2021.\n' +
        '1. Parties\n' +
        'A. Power Only Transit, Inc. is the “Broker” as that term is defined under 49 U.S.C. § 13102(2) or any regulation, amendment or renumbered law by which the United States or any agency thereof defines a freight broker and any applicable federal or state regulations, statutes, decisional law or administrative law. BROKER will arrange for the freight tendered by a shipper to be transported by CARRIER under the means, manner, method, and terms selected by the shipper or CARRIER, but BROKER is not engaged in the business of and will not act as a “Carrier,” “Motor Carrier,” or “Freight Forwarder,” as those terms are defined under 49 U.S.C. § 13102, and BROKER is not engaged in the business of and will not act as a “Rail Carrier” as that term is defined under 49 U.S.C. § 11706.\n' +
        '\n' +
        'B. #USER_NAME# is the “CARRIER,” and hereby agrees to transport freight identified by BROKER as requiring transportation services.\n' +
        '\n' +
        '  C. BROKER and CARRIER will sometimes be referred to collectively as “The Parties.”\n' +
        '2. Recitals\n' +
        '1.\tTerm - The term of this Agreement shall be one (1) year, commencing on the date listed above. If not cancelled by one of The Parties, the Agreement shall automatically renew itself for consecutive one year terms. The Agreement can be terminated at any time by either of The Parties with thirty (30) days written or electronic notice to the other party, provided all balances are settled, and the termination can be with or without cause.\n' +
        '2.\tBroker Requirements - BROKER warrants that it is licensed to arrange for the transportation of freight pursuant to license number MC- , but that it does not transport freight, and that it will maintain such authority as required by all applicable federal and state laws and regulations throughout the course of this Agreement. BROKER also warrants that it will maintain a surety bond or trust fund agreement as required by the Federal Motor Carrier Safety Administration in the amount of $75,000.00 or in such amount as may be amended from time to time and furnish CARRIER with proof of same upon request.\n' +
        '3.\tBroker Obligations - Broker shall pay CARRIER for services rendered in an amount equal to the rates and charges agreed to as set forth on any Load Confirmation(s) that is issued and that supplements and amends this Agreement to the extent its terms conflict with those in this Agreement. This Agreement or the Load Confirmation also governs all assessorial services which may be required or performed. CARRIER shall not bill for any accessorial or other charge not approved in this Agreement or in any Load Confirmation(s). Rates may be amended orally but must be confirmed in writing within five working days of the modification in order to remain binding between the PARTIES. As a condition precedent to payment, CARRIER must submit proof of delivery with its invoices, and the invoices must reflect that CARRIER delivered the freight to its final destination.\n' +
        '  a.\tBROKER agrees to arrange for the transportation of a shipper’s freight with CARRIER pursuant to the terms of this Agreement, and to comply with all federal, state, and local laws and regulations pertaining to the brokerage services covered by this Agreement.\n' +
        '  b.\tThe Parties agree that BROKER’S responsibilities under this Agreement are limited to arranging for the transportation of a shipper’s freight with CARRIER, and not actually performing the transportation services, possessing the freight, or controlling he means or methods of the transportation.\n' +
        '4.\tCarrier Obligations - CARRIER warrants that at all times during this Agreement it will act as a “motor carrier,” as that term is defined under 49 U.S.C. § 13102 and any applicable federal or state regulations, statutes, decisional law or administrative law. CARRIER further warrants that at all times during this Agreement it will remain licensed and authorized by the Federal Motor Carrier Safety Administration to provide interstate transportation services or that it provides only intrastate service and complies with all applicable state registration requirements, and warrants that it will maintain insurance or otherwise demonstrate financial responsibility in accordance with all applicable federal and state regulations.\n' +
        '  CARRIER is solely responsible for the operation of the equipment, actions of the driver, any other persons associated with the operation of the equipment, transportation of freight, securement or any other aspect of actions of a motor carrier as that term is defined by law. CARRIER is solely responsible for the safety and operation of the equipment, and the actions of all drivers and other persons or entities responsible for the transportation of freight. Nothing in this Agreement abrogates the responsibility of the CARRIER to operate safely and in accordance with all law and good accepted best practices of a motor carrier.\n' +
        '  CARRIER represents that it is in compliance with and shall maintain, during the terms of this Agreement, compliance with all applicable federal, state and local laws relating to the provision of its services.\n' +
        '  CARRIER will notify BROKER immediately if its federal Operating Authority is revoked, suspended or rendered inactive for any reason; and/or if it is sold, or if there is a change in control of ownership, and/or any insurance required hereunder is threatened to be or is terminated, cancelled (whether by an insurer or surety provider by CARRIER, or by any person or entity), suspended, or revoked for any reason.\n' +
        '  a.\tCARRIER agrees it will not have a U.S. DOT safety rating or evaluation of unsatisfactory or conditional. Any change in CARRIER’S safety rating requires immediate written notification to BROKER. CARRIER may not have an unsatisfactory or conditional rating under any rating system. If CARRIER’S rating becomes conditional or unsatisfactory, CARRIER is no longer authorized as a CARRIER under this Agreement.\n' +
        '  b.\tUpon reasonable demand, CARRIER shall provide to BROKER copies of its DOT Operating Authority, Policy of Insurance, including all endorsements, Certificate of Insurance, surety, and financial responsibility.\n' +
        '5.\tFood Protocols\n' +
        "All equipment provided for the transportation of food or food grade products will comply with the requirements of The Sanitary Food Transportation Act, or, to the extent that CARRIER performs services hereunder within, or to or from Canada, the Food and Drug Acts and any/all other applicable statutes and regulations, including, but not limited to the Ontario Food Safety and Quality Act, 2001, or any other jurisdiction's equivalent, and none of the equipment so provided has been or will be used for the transportation of any waste of any kind, garbage, hazardous materials, poisons, pesticides, herbicides, or any other commodity that might adulterate or contaminate food, food products or cosmetics.\n" +
        'Where a seal is placed on a trailer by consignor, shipper, CARRIER or other party, CARRIER is responsible to maintain the seal intact until removed by an authorized employee of consignee upon delivery. CARRIER is liable for any and all claims, losses, or liabilities arising from or as a result of any unauthorized removal of seal, broken seal, missing seal, tampered seal, or mismatched seal number. CARRIER is solely responsible for ensuring that cargo is maintained according to any requirements stated on the bill of lading or load confirmation\n' +
        'CARRIER must ensure that all personnel transporting or handling freight subject to the Food Safety Modernization Act of 2011 and its implementing regulations (collectively the “Act”), receive training required by the Act. BROKER will transmit to CARRIER, on the Load Confirmation or separately by email, the shipper’s or consignee’s protocols and requirements for transporting food shipments subject to the Act. CARRIER must strictly comply with all such protocols and requirements. CARRIER’S failure to comply with such protocols and requirements will permit the consignor, consignee, or broker to declare any freight transported on a shipment on which noncompliance occurred to be rejected and a total loss.\n' +
        '6.\tShipper-Broker Relationship - The Parties agree that BROKER at all times will be acting as an independent contractor, and not an employee, agent, or principal of a shipper.\n' +
        '7.\tBroker-Carrier Relationship - CARRIER agrees and acknowledges that as the motor carrier transporting a shipper’s freight pursuant to this Agreement, CARRIER is an independent contractor, and not an employee, agent or principal of BROKER. CARRIER further agrees and acknowledges that its employees and agents, including the driver or drivers transporting freight, are not the employees or agents of BROKER, and that BROKER does not control or have the right to control the CARRIER, its employees, agents, drivers, or any person or entity associated with the CARRIER.\n' +
        '  CARRIER must give priority to compliance with all laws and regulations and must not interpret any provision of this Agreement or request or communication from any employee or agent of BROKER, shipper, consignor, or BROKER’s customer(s) to authorize or encourage, directly or by implication, CARRIER to deviate from any law or regulation applicable to CARRIER’s operations as a motor carrier. BROKER will not coerce CARRIER, and any directions or instructions given by BROKER to CARRIER for the transportation of the freight shall be for information and convenience only, and CARRIER retains full control of the details of transportation of freight assigned to it under this Agreement. BROKER will not impose fines on CARRIER unless BROKER is instructed to do so by the shipper.\n' +
        '8.\tNo Broker Liability - CARRIER agrees and acknowledges that BROKER will not be liable to a shipper for any act or omission of the CARRIER or any of its “employees” which transport a shipper’s freight, as the term “employee” is defined under 49 C.F.R. §390.5 or for any of Carrier’s Agents, Principals, Assigns or Subcontractors. CARRIER thus agrees and acknowledges to indemnify and hold harmless BROKER for any cargo loss or damage, or for delay in the delivery of a shipper’s freight, or for any actual or consequential damages resulting therefrom.\n' +
        '  CARRIER shall defend, indemnify and hold BROKER and its shipper customer harmless from any claims, actions or damages, arising out of its performance under this Agreement, including cargo loss and damage, theft, delay, damage to property, and personal injury or death, and BROKER shall defend, indemnify, and hold CARRIER harmless from any claims, actions, or damages, including cargo loss and damage, theft, delay, property damage, bodily injury or death, arising out of its performance hereunder. Neither Party shall be liable to the other for any claims, actions or damages due to the negligence, culpable conduct or intentional act of the other Party, or the shipper. The obligation to defend shall include all costs of defense as they accrue.\n' +
        '  Except for CARRIERS’S liability under Paragraph 10, unless otherwise agreed in writing, and regardless of whether the Parties’ insurance as referred to in this Agreement above is valid or provides coverage, the Parties’ indemnity obligations shall not exceed the monetary insurance limits referred to in the paragraph above.\n' +
        '9.\tNo Broker Control - The Parties agree that BROKER will not assert any control nor have any right to exercise control over a shipper’s freight, including, but not limited to, taking possession of a shipper’s freight, and BROKER shall not direct or control the routes taken by CARRIER in the transportation of a shipper’s freight.\n' +
        '10.\tCarrier Liability - CARRIER hereby assumes the liability of a motor carrier as provided in §14706 of Title 49 of the United States Code (the Carmack Amendment), and all claims for loss, damage and/or salvage will be handled and processed in accordance with 49 C.F.R. Part 370.\n' +
        '11.\tBills of Lading\n' +
        'a.\tFor each shipment tendered to CARRIER, CARRIER will provide to the shipper a standard bill of lading that is in accordance with 49 C.F.R. §373, listing the consignor and consignee, the origins and destinations, the number of packages, the description of the freight, and the weight, volume or measurement of the freight. The Parties agree that BROKER will not be a party to the bill of lading.\n' +
        '  b.\tCARRIER acknowledges that BROKER should not be listed on the bill of lading and that if BROKER is listed on the Bill of Lading as the carrier this will occur for the convenience of the shipper only and CARRIER at all times is the actual carrier of goods and BROKER’S role is limited to arranging for transportation. In the event BROKER’S name is listed on the bill of lading, shipping manifest or other similar document, as the carrier, CARRIER shall cross-out or otherwise remove BROKER’S name and enter CARRIER’S name as applicable.\n' +
        '  c.\tCARRIER understands that re-brokering and double brokering may be prohibited by law and will not re-broker, assign or interline the shipments hereunder without the express written consent of BROKER prior to the shipment being tendered to any other CARRIER. If CARRIER breaches this provision, BROKER shall have the right of paying the monies it owes CARRIER directly to the delivering carrier, in lieu of payment to CARRIER, and BROKER shall thereby be released from any further obligation to pay CARRIER. Upon Broker’s payment to delivering carrier, CARRIER shall not be released from any liability to BROKER under this Agreement. IN ADDITION TO THE INDEMNITY OBLIGATION IN PARAGRAPH 7, CARRIER WILL BE LIABLE FOR CONSEQUENTIAL DAMAGES FOR VIOLATION OF THIS PARAGRAPH.\n' +
        '1.\tThe Parties agree that the shipment of freight will move under the terms and conditions listed in the bill of lading, except where inconsistent with the terms of this Agreement.\n' +
        '2.\tCARRIER agrees to list itself on the bill of lading as the party in possession and control of the freight.\n' +
        '3.\tThe terms and conditions of the bill of lading shall not operate to alter or modify the terms of this Agreement between CARRIER and BROKER.\n' +
        '4.\tCARRIER shall issue a bill of lading in compliance with 49 U.S.C. §80101 et seq., 49 C.F.R. §373.101 (and any amendments thereto), for the property it receives for transportation under this Agreement. Unless otherwise agreed in writing, CARRIER shall become responsible/liable for the freight when it takes/receives possession thereof, and the trailer(s) is loaded, regardless of whether a bill of lading has been issued, and/or delivered to CARRIER, and which responsibility/liability shall continue until delivery of the shipment to the consignee and the consignee signs the bill of lading or delivery receipt. Any terms of the bill of lading (including but not limited to payment terms, released rates or released value) inconsistent with the terms of this Agreement shall be ineffective. Failure to issue a bill of lading or sign a bill of lading acknowledging receipt of the cargo by CARRIER shall not affect the liability of CARRIER. Said Bills of Lading are intended by the Parties to be Bills of Lading, as that term is interpreted under the Carmack Amendment and applicable law and not merely as “delivery receipts”, “freight receipts” or any similar term.\n' +
        '12.\tNon-Solicitation of Shippers - CARRIER agrees that it will not directly or indirectly conduct business with any shipper whose freight was transported pursuant to this Agreement for a period of two (2) years beginning with the last day such service was performed for that shipper. The Parties agree that a breach of this provision shall entitle BROKER, as reasonable liquidated damages and not as a penalty, to the full amount of commissions and/or compensation under the terms set forth in this Agreement that would have been due to BROKER had it arranged for the movement of said freight.\n' +
        '13.\tAssignment/Modifications of Agreement - Neither CARRIER or BROKER may assign or transfer any rights under this Agreement, in whole or in part, without the prior written consent of the other party. Further, neither CARRIER nor BROKER may amend or modify the terms of this Agreement without the prior written consent of an expressly authorized official of the other party. For BROKER, only a company official with the title of Vice President or higher is authorized to agree to amendments to this Agreement. Any amendments or modifications to this Agreement not agreed to by both CARRIER and BROKER shall be null and void.\n' +
        '14.\tInsurance - CARRIER shall furnish BROKER with Certificate(s) of Insurance; financial responsibility or insurance policies providing thirty (30) days advance written notice of cancellation or termination; and unless otherwise agreed, subject to the following minimum limits:\n' +
        '  A.\tgeneral liability $1,000,000;\n' +
        'B.\tcommercial auto or commercial motor vehicle insurance $1,000,000, ($5,000,000 if transporting hazardous materials including environmental damages due to release or discharge of hazardous substances; hazmat carriers must have endorsement CA9948, sudden and accidental pollution coverage, and this endorsement must be shown on the Certificate of Insurance provided to BROKER);\n' +
        'C.\tcargo damage/loss, $250,000 This coverage must be All Risk Broad Form Motor Truck Cargo Legal Liability Coverage. The coverage provided under the policy shall have no exclusions or restrictions of any type that would foreseeably preclude coverage relating to cargo claims including, but not limited to, exclusions of unattended or unattached trailers, unattended or unlocked vehicles, theft, or for any commodities transported under this Agreement, refrigeration breakdown or lack of refrigerator fuel. Furthermore, if the commodity being hauled is refrigerated, refrigeration breakdown coverage will be provided and the CARRIER will honor and abide by the servicing requirements set forth in the insurance policy or endorsement. Furthermore, if the commodity being hauled is on a flatbed or similar open conveyance, that there be no exclusion for wetness, rust, corrosion or moisture.\n' +
        '  D.\tworkers’ compensation with limits required by law.\n' +
        '  Except for the higher coverage limits which may be specified above, the insurance policies and financial responsibility shall comply with minimum requirements of the Federal Motor Carrier Safety Administration and any other applicable regulatory state agency. Nothing in this Agreement shall be construed to avoid CARRIER’S liability due to any exclusion or deductible of any insurance policy or to limit CARRIER’S liability for contribution and/or indemnification and defense of the BROKER.\n' +
        '  Coverage must be written with a CARRIER rated A- or better as rated by AM Best Company. When an intrastate policy is issued, BROKER must be named as an additional insured.\n' +
        '15.\tMiscellaneous\n' +
        'a.\tNon-Exclusive Agreement: CARRIER and BROKER acknowledge and agree that this contract does not bind the respective Parties to exclusive services to each other. Either party may enter into similar agreements with other carriers, brokers, or freight forwarders.\n' +
        '  b.\tWaiver of Provisions:\n' +
        '  1.\tFailure of either Party to enforce a breach of waiver of any provision or term of this Agreement shall not be deemed to constitute a waiver of any subsequent failure or breach, and shall not affect or limit the right of either Party to thereafter enforce such a term or provision.\n' +
        '2.\tThis Agreement is for specified services pursuant to 49 U.S.C.§14101(b). To the extent that terms and conditions herein are inconsistent with Part (b), Subtitle IV, of Title 49 U.S.C. (ICC Termination Act of 1995), the Parties expressly waive any or all rights and remedies they may have under the Act.\n' +
        '16.\tSeverability - If any portion or provision of this Agreement is determined by a court of competent jurisdiction to be invalid or unenforceable, The Parties agree that said portion or provision of the Agreement shall be severable, and that the remaining provisions of the Agreement shall continue in full force and effect.\n' +
        '17.\tNotices - Any and all written or electronic notices required or permitted to be given under this Agreement shall be addressed as follows:\n' +
        '(BROKER)\n' +
        '\n' +
        'Power Only Transit, Inc\n' +
        '\n' +
        '360 N. Main St Suite 400\n' +
        '\n' +
        'Royal Oak, MI 48067\n' +
        '\n' +
        '(CARRIER)\n' +
        '\n' +
        '#USER_NAME#\n' +
        '\n' +
        '#USER_ADDRESS#\n' +
        '\n' +
        '#USER_CITY#, #USER_STATE# #USER_POSTAL_CODE#\n' +
        '\n' +
        '18.\tForce Majeure - In the event that fire, flood, other natural disaster, war, embargo, riot, or civil disobedience prevents the performance of either BROKER or CARRIER’S obligations under this agreement, that party shall not be liable to the other party for such failure to perform.\n' +
        '19.\tChoice of Law and Venue - All issues concerning the construction, interpretation, validity, and enforceability of this Agreement, and any other dispute arising out of this Agreement, whether in a court of law or in alternative dispute resolution, shall be governed by and construed and enforced in accordance with the laws of the State of Nevada, including the applicable statutes of limitations under Nevada law, without giving effect to any choice of law provision applying the laws of another jurisdiction.\n' +
        '20.\tIndemnification - CARRIER will indemnify and hold harmless BROKER, its employees, officers, directors, agents, principals and assigns from any liability, settlements, judgments, verdicts, attorney fees or expense or any nature whatsoever arising out of any claims, demands or suits against BROKER which in any way relate to a claim of BROKER’s liability or culpability for the actions of CARRIER, including negligent or improper hiring or retention of the CARRIER, its employees (statutory or otherwise) agents, principals, officers, directors, assigns or anyone acting by or for CARRIER, for any aspect of the transportation of freight, public liability, personal injury, bodily injury, emotional or mental distress, wrongful death, loss of consortium, cargo liability or any claim or cause of action recognized by any state, municipality, county or any jurisdiction, Administrative Agency, or the Government of the United States. CARRIER agrees to have insurance to cover its indemnification obligations under this section, but CARRIER’s indemnification obligations are not capped by the amount of any available insurance.\n' +
        '21.\tEntire Agreement - This Agreement, including all appendices and addenda, constitutes the entire agreement intended by and between The Parties and supersedes all prior agreements, representations, warranties, and understandings, whether oral or in writing.\n' +
        '22.\tModification of Agreement - This Agreement may not be amended, except by mutual written agreement, or the procedures set forth above.',
    },
  ],
  footer:
    'IN WITNESS WHEREOF, The Parties have caused this Agreement to be executed on the effective date listed above in their respective names by their fully authorized representatives below:\n',
}
