import * as Sentry from '@sentry/react'
import { ErrorEvent } from '@sentry/types'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { sentryEnv } from './common/constants'

/*
  If Sentry is reporting an issue from file ex:
  node_modules/@here/maps-api-for-javascript/bin/mapjs.bundle.js
  
  This is retrieved from our source maps, so you can't ignore that actual file.
  You need to find its counterpart in the `build/assets` folder using `yarn build:local`
  since that will be the actual file that is seen at runtime
  
  - @here/maps-api-for-javascript/bin/mapsjs.bundle.js
  - @here/maps-api-for-javascript.*.js
  - @here_maps-api-for-javascript.js. <- ex built file
    The first is the original file from the HERE Maps library responsible for a large number of noisy errors.
    The other two are compiled variations of the same file.
    mapsjs-*.js <- Imported in index.html

*/
const filesToIgnore = [
  '@here/maps-api-for-javascript/bin/mapsjs.bundle.js',
  '@here/maps-api-for-javascript.',
  '@here_maps-api-for-javascript.js',
  'mapsjs-core.js',
  'mapsjs-service.js',
  'mapsjs-ui.js',
  'mapsjs-mapevents.js',
]

if (sentryEnv !== null) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    // Use Filters sparingly. DON'T FILTER STUFF FROM OUR OWN CODE. Fix the error!
    // See: https://docs.sentry.io/platforms/javascript/configuration/filtering/
    ignoreErrors: [],
    beforeSend(event: ErrorEvent) {
      // Use this kind of filtering sparingly. If you filter entirely on that a file was involved,
      // you will eliminate any error that includes that code in its path. The maps bundle only
      // seems to produce errors within its own loop / subroutine.
      //
      // Also: If you cause an error in `beforeSend` you might cause an infinite loop in errors,
      //       so don't do that
      if (event?.exception?.values) {
        for (const exception of event.exception.values) {
          // If any one frame includes any one of the filesToIgnore, don't send the event
          const framesIncludeFileToIgnore = exception?.stacktrace?.frames?.some(frame =>
            filesToIgnore.some(file => frame?.filename?.includes(file)),
          )
          if (framesIncludeFileToIgnore) {
            return null
          }
        }
      }
      return event
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    tracePropagationTargets: [
      'localhost',
      /^\//,
      /^https:\/\/staging\.powertranspro\.com/,
      /^https:\/\/powertranspro\.com/,
      /^https:\/\/api\.v1\.stage\.exofreight\.com/,
      /^https:\/\/api\.v1\.exofreight\.com/,
    ],
    tracesSampleRate: 0.01,
    normalizeDepth: 15,
    maxBreadcrumbs: 10,
    environment: sentryEnv,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    attachStacktrace: true,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}
