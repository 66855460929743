import { useEffect, useState } from 'react'
import Tooltip, { TooltipProps } from 'react-tooltip-lite'
import tw from 'tailwind-styled-components'

type DropdownProps = Omit<TooltipProps, 'isOpen' | 'onToggle' | 'eventToggle' | 'content'> & {
  content: React.ReactNode | ((hide: () => void) => React.ReactNode)
  contentClassName?: string
}

export const Dropdown = ({ children, content, contentClassName, ...rest }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const closeDropdown = (event: MouseEvent) => {
      const els = document.querySelectorAll('.react-tooltip-trigger, .react-tooltip-content')

      if (els.length && Array.from(els).every(el => !el.contains(event.target as Node))) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', closeDropdown)

    return () => {
      document.removeEventListener('mousedown', closeDropdown)
    }
  }, [])

  return (
    <Tooltip
      arrow={false}
      background='transparent'
      direction='bottom-end'
      isOpen={isOpen}
      padding={0}
      content={
        <ContentContainer className={`react-tooltip-content ${contentClassName ?? ''}`}>
          {typeof content === 'function' ? content(() => setIsOpen(false)) : content}
        </ContentContainer>
      }
      onToggle={setIsOpen}
      {...rest}
    >
      <div
        className='react-tooltip-trigger'
        style={{ display: 'contents' }}
        onClick={e => {
          setIsOpen(val => !val)
          e.stopPropagation()
        }}
      >
        {children}
      </div>
    </Tooltip>
  )
}

const ContentContainer = tw.div`
  bg-white
  shadow-md
  w-full
  h-full
  p-2
  rounded-md
`
