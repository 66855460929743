import { StarIcon } from '@heroicons/react/24/outline'
import tw from 'tailwind-styled-components'

export const RecommendedLoadIndicator = ({ className = '' }: { className?: string }) => (
  <StyledRecommendedLoadIndicator className={className}>
    <StarIcon className='w-4 h-4' />
  </StyledRecommendedLoadIndicator>
)

const StyledRecommendedLoadIndicator = tw.div`
    w-6
    h-6
    bg-brand-accent
    rounded-sm
    flex
    items-center
    justify-center
`
