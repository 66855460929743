import { useEffect } from 'react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { OnboardingHeader } from '../../components/OnboardingHeader'
import { SendOTPForm, ValidateOTPForm } from '../../components/OTPForms'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { patchCredentials } from '../../redux/userSlice'
import { OnboardingScreen } from '../OnboardingScreen'
import { CompanyIdentifierForm } from './CompanyIdentifierForm'
import { LoginForm } from './LoginForm'
import { SelectCompanyStep } from './SelectCompanyStep'

export const LoginScreen = (): JSX.Element => {
  const dispatch = useAppThunkDispatch()
  const onboardingStep = useAppSelector(state => state.user.onboarding.currentStep)
  const loginStep = useAppSelector(state => state.user.login.currentStep)
  const isOnboarding = onboardingStep != null

  const [query, setQuery] = useQueryParams({
    email: withDefault(StringParam, ''),
    mc_number: withDefault(StringParam, ''),
    dot_number: withDefault(StringParam, ''),
  })

  useEffect(() => {
    if (query.mc_number)
      dispatch(patchCredentials({ docket: query.mc_number, email: query.email, typeTab: 0 }))
    else if (query.dot_number)
      dispatch(patchCredentials({ docket: query.dot_number, email: query.email, typeTab: 1 }))

    setQuery(query => ({ ...query, mc_number: '', dot_number: '', email: '' }))
  }, [])

  if (isOnboarding) {
    return (
      <>
        <OnboardingHeader />
        <OnboardingScreen />
      </>
    )
  } else {
    return (
      <LoginForm>
        {loginStep === 'SELECT_COMPANY' ? (
          <SelectCompanyStep />
        ) : loginStep === 'VALIDATE' ? (
          <ValidateOTPForm />
        ) : loginStep === 'ONBOARD' ? (
          <CompanyIdentifierForm />
        ) : (
          <SendOTPForm />
        )}
      </LoginForm>
    )
  }
}
