import { Select, TextInput } from '@components'

import { accountTypes } from '../../../common/constants'
import { validateAccountNumber, validateRoutingNumber } from '../../../common/utils'
import { useAppSelector } from '../../../redux/store'
import { PaymentMethodNickname } from './PaymentMethodNickname'

export const ACHPaymentDetails = ({
  setPaymentInformation,
}: {
  setPaymentInformation: (obj: any) => void
}) => {
  const paymentInformation = useAppSelector(
    state => state.user.onboarding.onboardingDetails.paymentInformation,
  )

  return (
    <div>
      <Select
        required
        choices={accountTypes}
        className='mb-4'
        label='Account Type'
        value={paymentInformation?.accountType}
        onChange={(accountType: string) => setPaymentInformation({ accountType })}
      />
      <PaymentMethodNickname isACH={true} setPaymentInformation={setPaymentInformation} />
      <TextInput
        required
        className='mb-4'
        label='Routing (ABA) Number'
        mask='000000000'
        placeholder='_________'
        value={paymentInformation?.routingNumber}
        error={
          paymentInformation?.routingNumber?.length &&
          !validateRoutingNumber(paymentInformation.routingNumber) &&
          'Provide a valid numeric routing number'
        }
        onChange={(routingNumber: string) => setPaymentInformation({ routingNumber })}
      />
      <TextInput
        required
        className='mb-4'
        label='Account Number'
        placeholder='______________'
        value={paymentInformation?.accountNumber}
        error={
          paymentInformation?.accountNumber?.length &&
          !validateAccountNumber(paymentInformation.accountNumber) &&
          'Provide a valid numeric account number'
        }
        onChange={(accountNumber: string) => setPaymentInformation({ accountNumber })}
      />
      <TextInput
        required
        className='mb-2'
        label='Confirm Account Number'
        placeholder='______________'
        value={paymentInformation?.accountNumberConfirm}
        error={
          paymentInformation?.accountNumberConfirm?.length &&
          paymentInformation?.accountNumber !== paymentInformation?.accountNumberConfirm &&
          'Fields do not match'
        }
        onChange={(accountNumberConfirm: string) => setPaymentInformation({ accountNumberConfirm })}
      />
    </div>
  )
}
