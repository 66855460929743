import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import tw from 'tailwind-styled-components'

interface FAQCardProps {
  title: string
  description: string | React.ReactNode
  className?: string
  descriptionClassName?: string
  titleClassName?: string
  isOpenOverride?: boolean
  onClickOverride?: () => void
}

export const FAQCard = ({
  title,
  description,
  className,
  descriptionClassName,
  titleClassName,
  isOpenOverride,
  onClickOverride,
}: FAQCardProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <div className={`flex flex-col w-full ${className}`}>
      <Questions
        $isOpen={isOpen}
        $isOpenOverride={isOpenOverride}
        onClick={onClickOverride || toggleOpen}
      >
        <span className={`font-semibold ${titleClassName}`}>{title}</span>
        <div className='flex items-center justify-center'>
          {isOpenOverride || isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
      </Questions>
      {(isOpenOverride || isOpen) && (
        <div className='flex items-center bg-lighter-gray pt-2 p-4 pb-6 rounded-b-lg'>
          <span className={descriptionClassName}>{description}</span>
        </div>
      )}
    </div>
  )
}

const Questions = tw.div<{ $isOpen: boolean; $isOpenOverride: boolean | undefined }>`
flex 
items-center 
border 
${({ $isOpen, $isOpenOverride }) => (!$isOpen && !$isOpenOverride ? 'rounded-lg' : 'rounded-t-lg ')}
bg-lighter-gray 
h-[56px]
p-4 
w-full 
justify-between 
cursor-pointer
`
