import { FAQCard } from '../../../components/FAQCard'

const HowItWorksAnswer = () => (
  <>
    <p>
      The application opens a business banking account which you can use to purchase discounted
      fuel.
    </p>
    <br />
    <ol className='pl-2'>
      <li className='pb-1'>1. Create an account.</li>
      <li className='pb-1'>
        2. Pre-fund your account with broker load payments or securely connect an external account
        and transfer funds.
      </li>
      <li className='pb-1'>
        3. Use our app to find and reserve discounted fuel. You will receive a code to redeem your
        reserved fuel.
      </li>
      <li className='pb-1'>
        4. When you reach the station, show your code at the counter to redeem your discounted fuel
        and start pumping!
      </li>
    </ol>
  </>
)

export const fuelFaqs = [
  {
    title: 'How does this work?',
    description: <HowItWorksAnswer />,
  },
  {
    title: 'Is this a line of credit?',
    description:
      'No, this is not a line of credit. Your EXO Fuel account is a business bank account, and the card associated with your \
      account is a debit card. The debit card can be used to make any non-fuel purchase should you \
      need instant access to the funds in your Fuel account.',
  },
  {
    title: 'Is this a credit card?',
    description:
      'No, this is not a credit card. Your EXO Fuel account is a business bank account, and the card associated with your \
        account is a debit card. The debit card can be used to make any non-fuel purchase should you \
        need instant access to the funds in your Fuel account.',
  },
  {
    title: 'Does applying affect my credit?',
    description:
      'No. Applying does not involve a FICO credit check and will not affect your credit. Fuel \
      purchases are not made on credit.',
  },
  {
    title: 'Is EXO Freight a bank?',
    description:
      'No. We partner with Regent Bank, who provides the underlying bank account and services. All \
      accounts are FDIC-insured.',
  },
  {
    title: 'Can I get a fuel advance?',
    description:
      "No. We do not currently offer an advance or credit system. This could change in the future. Contact us if you're interested and believe you could demonstrate your creditworthiness.",
  },
  {
    title: 'Does this program have fees?',
    description:
      'No. There are no fees associated with Fuel purchases or with transfers into or out of your \
      debit account.',
  },
  {
    title: 'What happens to my money when I transfer it into this account?',
    description:
      ' Your money is held in a secure business banking account. When you reserve fuel, we put a \
          hold on the necessary funds until your fuel transaction is complete. After you finish \
          pumping your fuel, we complete the transaction based on the amount of fuel that you actually \
          pumped. If you need your fuel funds for any reason, you can transfer your money out via ACH \
          or spend it using your physical card or card numbers.',
  },
]

export const FuelFAQ = () => (
  <>
    {fuelFaqs.map((faq: any, index: any) => (
      <FAQCard
        key={index}
        className='my-3 text-sm'
        description={faq.description}
        descriptionClassName='text-sm'
        title={faq.title}
        titleClassName='text-sm'
      />
    ))}
  </>
)
