import { useAppSelector } from '../../../redux/store'
import { BreakdownItem } from './BreakdownItem'
import { ExpandButton } from './ExpandButton'

export const PriceBreakdown = ({
  isViewingBreakdown,
  setViewingBreakdown,
}: {
  isViewingBreakdown: boolean
  setViewingBreakdown: (value: boolean) => void
}) => {
  const newFactoringRequest = useAppSelector(state => state.factoring.newFactoringRequest)
  const deductions = useAppSelector(state => state.factoring.deductions)

  return (
    <>
      {isViewingBreakdown && (
        <div className='mt-3 w-full'>
          <BreakdownItem
            amount={deductions.totalAmountAfterDeductions + deductions.totalDeductions}
            title='Invoice'
          />
          <BreakdownItem amount={-deductions.flatFee} title='Invoice Processing Fee' />
          <BreakdownItem
            amount={-deductions.margin.amount}
            title={`Factoring Fee (${deductions.margin.percentage}%)`}
          />
          <BreakdownItem
            amount={-deductions.reserve.amount}
            title={`Reserve (${deductions.reserve.percentage}%)`}
          />
          {!!deductions.achFee && <BreakdownItem amount={-deductions.achFee} title='ACH Fee' />}
          {(deductions.recoveries || []).map(recovery => (
            <BreakdownItem
              key={recovery.description}
              amount={-recovery.amount}
              description={recovery.description}
              title='Recovery'
            />
          ))}
          <BreakdownItem amount={-deductions.totalDeductions} title='Total Deductions' />
        </div>
      )}
      <ExpandButton
        disabled={!newFactoringRequest.totalAmount}
        onClick={() => setViewingBreakdown(!isViewingBreakdown)}
      >
        {isViewingBreakdown ? 'Hide' : 'See'} Breakdown
      </ExpandButton>
    </>
  )
}
