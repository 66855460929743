import tw from 'tailwind-styled-components'

import { formatCurrency } from '../../../common/utils'

export const BreakdownItem = ({
  title,
  amount = 0,
  description,
}: {
  title: string
  amount?: number
  description?: string
}) => (
  <div className='text-sm'>
    <Container>
      <span>{title}</span>
      <Dots />
      <span>
        {amount < 0 && '-'}
        {formatCurrency(amount)}
      </span>
    </Container>
    {description && <div className='text-sm mb-1'>{description}</div>}
  </div>
)

const Container = tw.div`
  flex
  items-baseline
  mb-1
  font-bold
`

const Dots = tw.span`
  flex-1
  border-b-2
  border-dotted
  border-brand-dark
`
