import { CarrierLocationTrackingEvent } from '@common'
import { Button, Map, Marker, MarkerType } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { setTrackingStep, TrackingStep } from '../../redux/trackingSlice'

export const RequestLocation = ({
  mostRecentLocationEvent,
}: {
  mostRecentLocationEvent?: CarrierLocationTrackingEvent | null
}) => {
  const dispatch = useAppThunkDispatch()

  const geoLocationError = useAppSelector(state => state.tracking.geoLocationError)
  const locationTrackingLoads = useAppSelector(state => state.user.user?.locationTrackingLoads)
  const trackingStep = useAppSelector(state => state.tracking.trackingStep)

  const usingMobileApp = Boolean(window.natively.isAndroidApp || window.natively.isIOSApp)

  const openAppSettings = () => window.natively.openAppSettings()

  const handleAllowClick = () => {
    if (trackingStep === TrackingStep.ALLOW_CLICKED)
      alert('Please enable location tracking in your settings.')
    dispatch(setTrackingStep(TrackingStep.ALLOW_CLICKED))
  }

  return (
    <div className='p-8'>
      <h1 className='text-2xl font-bold'>📍 Please Share Your Location</h1>
      <p className='mt-4'>
        Thank you for working on load {(locationTrackingLoads ?? []).join(', ')}. To minimize phone
        calls and ensure efficient communication, please <b>share your location</b> by allowing
        location tracking in{' '}
        {usingMobileApp ? (
          <button className='text-link underline' onClick={openAppSettings}>
            your app settings.
          </button>
        ) : (
          <>your browser settings.</>
        )}
      </p>
      <div className='flex my-6 border'>
        <Map className='h-full min-h-[200px]' mapClassName='h-full'>
          {mostRecentLocationEvent?.coordinates && (
            <Marker marker={MarkerType.ORIGIN} position={mostRecentLocationEvent?.coordinates} />
          )}
        </Map>
      </div>
      <div className='flex items-center justify-center'>
        <p className='text-lg text-center'>
          {trackingStep === TrackingStep.REQUESTING
            ? 'Please click "Allow" to share your location.'
            : geoLocationError || 'Waiting for permission...'}
        </p>
      </div>
      <div className='flex justify-between mt-6 gap-4'>
        <Button
          fullWidth
          lg
          short
          className='w-full'
          type='secondary'
          onClick={() => dispatch(setTrackingStep(TrackingStep.TRACKING_USER_ENTRY))}
        >
          Cancel
        </Button>
        <Button fullWidth lg className='w-full' type='success' onClick={handleAllowClick}>
          Allow
        </Button>
      </div>
    </div>
  )
}
