import { Button } from '@components'
import { useEffect } from 'react'

import { emptyFile } from '../../../common/constants'
import { FileProps } from '../../../common/types'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { setOnboardingDetails } from '../../../redux/userSlice'
import { FileUpload } from '../../FileUpload'
import { SectionTitle } from '../../SectionTitle'
import { DocumentItem } from '../components/DocumentItem'

const types = ['Insurance Card', 'MC Certificate']

export const DocumentsUpload = () => {
  const dispatch = useAppThunkDispatch()

  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)

  const {
    documents = {
      w9: null,
      insurance: [],
      certificate: null,
    },
  } = onboardingDetails

  const setDocuments = (field: string, value: FileProps) =>
    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        documents: {
          ...documents,
          [field]: {
            ...value,
          },
        },
      }),
    )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className='relative border border-brand-light rounded-lg form py-6 text-left'>
        <SectionTitle className='mb-4' title='Instructions' />
        <div className='px-6'>
          <div className='text-dark-gray'>
            To complete setup we require the following documents from you:
          </div>
          <div className='mt-2'>
            {types.map(type => (
              <div key={type} className='my-2 flex items-center md:mr-6'>
                <div className='bg-link rounded-full w-1 h-1 mr-2' />
                <div className='text-dark-gray'>{type}</div>
              </div>
            ))}
          </div>
          <div className='text-dark-gray font-semibold my-6'>
            If your documents are in a single PDF please upload the same file in all fields
          </div>
        </div>
        <SectionTitle className='mb-4' title='Upload files' />
        <div className='px-6'>
          {!documents.insurance?.length && (
            <DocumentItem
              required
              className='mt-6'
              status='Insurance'
              title='Insurance PDF'
              value={emptyFile}
              setItem={(file: FileProps) => {
                dispatch(
                  setOnboardingDetails({
                    ...onboardingDetails,
                    documents: {
                      ...documents,
                      insurance: [{ ...file }],
                    },
                  }),
                )
              }}
            />
          )}
          {documents.insurance?.map((ins: any) => (
            <DocumentItem
              key={ins.name}
              required
              className='mt-6'
              status='Insurance'
              title='Insurance PDF'
              value={ins}
              setItem={(file: FileProps) => {
                dispatch(
                  setOnboardingDetails({
                    ...onboardingDetails,
                    documents: {
                      ...documents,
                      insurance: [{ ...file }],
                    },
                  }),
                )
              }}
              onDelete={() => {
                dispatch(
                  setOnboardingDetails({
                    ...onboardingDetails,
                    documents: {
                      ...documents,
                      insurance: documents.insurance.filter(
                        (doc: { name: string }) => doc.name !== ins.name,
                      ),
                    },
                  }),
                )
              }}
            />
          ))}
          {documents.insurance?.length ? (
            <div className='bg-brand-light border-l-4 border-link rounded-md py-5 px-6 flex flex-col md:flex-row md:items-center justify-between mt-6'>
              <div className='font-semibold mb-4 md:mb-0 md:w-1/2'>
                Do you need to upload another insurance file?
              </div>
              <FileUpload
                onUpload={(file: FileProps) => {
                  dispatch(
                    setOnboardingDetails({
                      ...onboardingDetails,
                      documents: {
                        ...documents,
                        insurance: [...(documents.insurance || []), { ...file }],
                      },
                    }),
                  )
                }}
              >
                <Button lg short preIcon='plus-circle' type='secondary'>
                  add another file
                </Button>
              </FileUpload>
            </div>
          ) : null}
          <DocumentItem
            required
            className='mt-6'
            setItem={(file: FileProps) => setDocuments('certificate', file)}
            status='MC Certificate'
            title='MC Certificate PDF'
            value={documents.certificate}
            onDelete={() => setDocuments('certificate', emptyFile)}
          />
        </div>
      </div>
    </div>
  )
}
