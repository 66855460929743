import { FileProps } from '../../common/types'

const mapInsuranceToBackendTypes = (type: string) => {
  if (type === 'Auto') {
    return { insurance_type: 3, notes: '' }
  } else if (type.startsWith('Cargo')) {
    return { insurance_type: 1, notes: type }
  } else if (type === 'WorkersCompensation') {
    return { insurance_type: 6, notes: '' }
  } else {
    return { insurance_type: 2, notes: type === 'General' ? '' : type }
  }
}

export const buildBackendCarrierObject = (carrier: any) => {
  const {
    qualification: { carrierDetails, certData },
    accountReceivableInformation,
    paymentInformation,
    contacts,
    documents,
    signing,
    referralCode,
  } = carrier

  // (1 - General, 2 - Insurance, 3 - Carrier Agreement, 4 - MC Certificate and 5 - W9)

  const coverageList = certData.Certificate.map((cert: any) =>
    Array.isArray(cert.Coverage) ? cert.Coverage : [cert.Coverage],
  ).flat()

  return {
    name: carrierDetails.name,
    mc_number: carrierDetails.mcNumber,
    dot_number: carrierDetails.dotNumber,
    scac_number: accountReceivableInformation.scac || null,
    ein: !accountReceivableInformation.entityType
      ? accountReceivableInformation.secNumber.replaceAll('-', '')
      : null,
    phone: contacts.primaryContact.phone,
    phone_two: '',
    fax: '',
    email: contacts.primaryContact.email,
    address: accountReceivableInformation.address,
    city: accountReceivableInformation.city,
    state_province_region: accountReceivableInformation.state,
    postal_code: accountReceivableInformation.postalCode,
    country: 'USA',
    insurance_active: certData['@status'] === 'OK',
    payment_method_type: paymentInformation.paymentType,
    is_business: accountReceivableInformation.entityType === 0,
    ssn: !accountReceivableInformation.entityType
      ? null
      : accountReceivableInformation.secNumber.replaceAll('-', ''),
    ar_name: accountReceivableInformation.entityName,
    ar_address: accountReceivableInformation.address,
    ar_city: accountReceivableInformation.city,
    ar_state: accountReceivableInformation.state,
    ar_zipcode: accountReceivableInformation.postalCode,
    ar_country: 'USA',
    ar_phone: contacts.primaryContact.phone,
    ar_email: contacts.primaryContact.email,
    ar_remit_email: contacts.primaryContact.emailRemit,
    ar_contact_name: contacts.primaryContact.name,

    carrier_insurances: coverageList.map((cert: any) => ({
      coverage_amount: cert.coverageLimit.replaceAll(',', ''),
      expiration_date: cert.expirationDate,
      provider: cert.insurerName,
      producer: certData.Certificate.producerName,
      policy_number: cert.policyNumber,
      ...mapInsuranceToBackendTypes(cert.type),
    })),

    carrier_documents: [
      ...documents.insurance.map((document: FileProps) => ({
        name: 'Insurance Cert',
        file: document.fileData,
        file_name: document.name,
        document_type: 'INSURANCE',
      })),
      {
        name: 'MC Cert',
        file: documents.certificate.fileData,
        file_name: documents.certificate.name,
        document_type: 'MC_CERTIFICATE',
      },
      {
        name: 'w9',
        file: documents.w9.fileData,
        file_name: documents.w9.name,
        document_type: 'W9',
      },
    ],

    carrier_contacts: [
      {
        name: contacts.primaryContact.name,
        phone: contacts.primaryContact.phone,
        email: contacts.primaryContact.email,
        is_primary: true,
        send_sms_offers: contacts.primaryContact.types.includes('SMS Load Offers'),
        send_email_offers: contacts.primaryContact.types.includes('Email Load Offers'),
        is_dispatch: true,
        is_accounting: contacts.primaryContact.types.includes('Accounting'),
        is_claims: contacts.primaryContact.types.includes('Claims'),
        is_driver: contacts.primaryContact.types.includes('POD Requests'),
      },
      ...contacts.individualContacts.map((contact: any) => ({
        name: contact.name,
        phone: contact.phone,
        email: contact.email,
        is_primary: false,
        send_sms_offers: contact.types.includes('SMS Load Offers'),
        send_email_offers: contact.types.includes('Email Load Offers'),
        is_dispatch: contact.types.includes('Dispatch'),
        is_accounting: contact.types.includes('Accounting'),
        is_claims: contact.types.includes('Claims'),
        is_driver: contact.types.includes('POD Requests'),
      })),
    ],

    payment_details: {
      name: paymentInformation.name,
      payment_method: paymentInformation.paymentMethod,
      is_primary: true,
      ...(paymentInformation.paymentMethod === 'ACH' && {
        account_type: paymentInformation.accountType,
        routing_number: paymentInformation.routingNumber,
        account_number: paymentInformation.accountNumber,
      }),
      ...(paymentInformation.paymentMethod === 'CHECK' && {
        business_name: paymentInformation.businessName,
        address_line_1: paymentInformation.address,
        city: paymentInformation.city,
        country: 'USA',
        state: paymentInformation.state,
        postal_code: paymentInformation.postalCode,
      }),
      ...(paymentInformation.paymentMethod === 'FACTORING' && {
        factoring_company_id: paymentInformation.factoringCompanyId,
      }),
    },

    carrier_agreement: {
      first_name: signing.firstName,
      last_name: signing.lastName,
      is_agree: true,
    },
    referral_code: referralCode,
  }
}
