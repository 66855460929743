import { Button } from '@components'

import { useAppThunkDispatch } from '../../redux/store'
import { setTrackingStep, TrackingStep } from '../../redux/trackingSlice'
export const CarrierDelayed = ({
  needsTrackingUpdate,
  setModalVisible,
}: {
  needsTrackingUpdate?: boolean
  setModalVisible: (visible: boolean) => void
}) => {
  const dispatch = useAppThunkDispatch()
  return (
    <div className='p-8'>
      <h1 className='text-2xl font-bold'>Thank you for letting us know you are delayed.</h1>
      <p className='mt-4'>
        One of our representatives will reach out to you soon to inquire more about the progress of
        your trip. Alternatively, you can reach us by phone by clicking the button below.
      </p>
      <div className='flex justify-between mt-6 gap-4'>
        <Button
          fullWidth
          lg
          short
          className='w-full'
          type='secondary'
          onClick={() =>
            needsTrackingUpdate
              ? dispatch(setTrackingStep(TrackingStep.REQUESTING))
              : setModalVisible(false)
          }
        >
          Close
        </Button>
        <Button
          fullWidth
          lg
          className='w-full'
          type='success'
          onClick={() => {
            window.location.href = 'tel:1-800-123-4567'
          }}
        >
          Call Us
        </Button>
      </div>
    </div>
  )
}
