import { AppLogoImage } from '@components'
import { useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { Button } from '../../../../../components/src/components/button'
import { TextInput } from '../../../../../components/src/components/textinput'
import { factoringTeamPhone, formattedFactoringTeamPhone } from '../../../common/constants'
import { trackEvent } from '../../../common/tracking'
import { getDocumentTitle, getPhoneMaskOptions, validateEmail } from '../../../common/utils'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { createAccount, resetOnboarding } from '../../../redux/userSlice'

export const CreateAccountForm = () => {
  const [queryEmail] = useQueryParam('email', withDefault(StringParam, ''))

  const [email, setEmail] = useState(queryEmail)
  const [firstName, setFirstName] = useState('')
  const [formDOT, setFormDOT] = useState('')
  const [formMC, setFormMC] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const dispatch = useAppThunkDispatch()
  const navigate = useNavigate()

  const error = useAppSelector(state => state.user.login.error)
  const loading = useAppSelector(state => state.user.login.loading)

  const formIsValid = useMemo(
    () =>
      validateEmail(email) &&
      firstName &&
      (formMC || formDOT) &&
      lastName &&
      phoneNumber.length >= 10,
    [email, firstName, formMC, formDOT, lastName, phoneNumber],
  )

  const onSubmit = () =>
    dispatch(
      createAccount({
        dotNumber: formDOT,
        email,
        firstName,
        lastName,
        mcNumber: formMC,
        phoneNumber,
      }),
    )
      .unwrap()
      .then(() => navigate('/fuel?startFlow=true'))
      .catch(() => {})

  useEffect(() => {
    document.title = getDocumentTitle('Create Fuel Account')
    dispatch(resetOnboarding())
    if (queryEmail) {
      trackEvent('Carrier entered create account funnel from fuel site', {
        email: queryEmail,
      })
    }
  }, [])

  return (
    <div className='bg-white rounded-xl h-full z-50 '>
      <div className='flex justify-center pt-6 pb-2'>
        <AppLogoImage className='h-16' />
      </div>
      <div className='text-center font-bold text-xl'>Spend less. Drive more.</div>
      {error === 'COMPANY_ALREADY_EXISTS' ? (
        <div className='text-center px-8 py-8'>
          Your company is already registered with Pulse! Log in and click on &apos;Fuel
          Discounts&apos; to get started.
          <NavLink className='flex justify-center cursor-pointer pt-2' to='/'>
            <Button lg={true}>Log In</Button>
          </NavLink>
        </div>
      ) : (
        <div className='text-sm font-bold pt-2 text-error'>{error}</div>
      )}

      <div className='px-8'>
        <div className='flex gap-4 pb-4 pt-2 '>
          <TextInput sm className='w-full' label='MC' value={formMC} onChange={setFormMC} />
          <TextInput sm className='w-full' label='DOT' value={formDOT} onChange={setFormDOT} />
        </div>
        <TextInput
          required
          sm
          className='w-full mb-4'
          label='First Name'
          value={firstName}
          onChange={setFirstName}
        />
        <TextInput
          required
          sm
          className='w-full mb-4'
          label='Last Name'
          value={lastName}
          onChange={setLastName}
        />
        <div className='flex flex-col'>
          <TextInput
            required
            sm
            className='mb-4'
            label='Email'
            type='email'
            value={email}
            onChange={setEmail}
          />
          <TextInput
            required
            sm
            className='mb-6'
            label='Phone Number'
            maskOptions={getPhoneMaskOptions()}
            type='phone'
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
        </div>
        <Button
          className='flex justify-center pb-4'
          disabled={loading || !formIsValid}
          loading={loading}
          type='warn'
          onClick={onSubmit}
        >
          Create Account
        </Button>
        <div className='text-center font-bold pb-6'>
          Have Questions?
          <br />
          <a className='text-link' href={`tel:${factoringTeamPhone}`}>
            {formattedFactoringTeamPhone}
          </a>
        </div>
      </div>
    </div>
  )
}
