import './TableButton.scss'

import { ReactNode } from 'react'

import { classNames } from '../../common/utils'

export const PageButton = ({
  children,
  className = '',
  disabled = false,
  onClick,
  style = {},
  ...rest
}: {
  children: ReactNode
  className?: string
  disabled?: boolean
  onClick: Function
  style?: object
}): JSX.Element => (
  <div className='table-button'>
    <button
      className={classNames(disabled ? 'disabled' : 'active', className)}
      type='button'
      onClick={() => !disabled && onClick()}
      {...rest}
      style={style}
    >
      {children}
    </button>
  </div>
)
