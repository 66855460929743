import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import { useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../../redux/store'

export const CheckBanner = () => {
  const creditCheck = useAppSelector(state => state.factoring.selectedCompanyCheckResult)
  const tab = useAppSelector(state => state.factoring.factoringRequestTab)

  const message = useMemo(() => {
    if (creditCheck?.approved === null)
      return (
        creditCheck?.notApprovedMessage ||
        'Factoring an invoice from this company requires an additional review prior to approval.'
      )
    return (
      creditCheck?.notApprovedMessage ||
      (creditCheck?.approved
        ? `This ${tab ? 'shipper' : 'broker'} is verified.`
        : 'We cannot factor loads with this company due to credit issues.')
    )
  }, [creditCheck])

  return (
    <Container $approved={creditCheck?.approved}>
      <div>
        <div className='font-bold'>{creditCheck?.name}</div>
        {creditCheck?.mcNumber && `#${creditCheck.mcNumber}`}
        {creditCheck?.cityState}
      </div>
      <BannerText>
        {creditCheck?.approved && <CheckBadgeIcon className='text-success mr-1 w-5' />}
        {message}
      </BannerText>
    </Container>
  )
}

const Container = tw.div<{ $approved?: boolean | null }>`
  w-full
  text-center
  mb-6
  p-3
  rounded-lg
  ${({ $approved }) =>
    ($approved !== null && ($approved ? 'bg-success-bg' : 'bg-error-bg')) ||
    ($approved === null && 'bg-warn-bg')}
`

const BannerText = tw.div`
  mt-1
  flex
  items-center
  justify-center
`
