import { CheckIcon } from '@heroicons/react/24/outline'
import tw from 'tailwind-styled-components'

export const Checkbox = ({ selected, className }: { selected: boolean; className?: string }) => (
  <CustomCheckbox $checked={selected} className={className}>
    {selected && <CheckIcon className='text-white w-4 h-4' data-testid='checkbox-icon' />}
  </CustomCheckbox>
)

const CustomCheckbox = tw.div`
  bg-white 
  rounded-md 
  flex 
  items-center 
  justify-center 
  mr-4 
  border 
  w-6
  h-6
  min-w-6
  min-h-6
  max-h-6
  max-w-6
  ${({ $checked }: { $checked: boolean }) => $checked && 'bg-success border-success'}
`
