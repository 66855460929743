import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { defaultPath } from '../common/constants'
import { getDocumentTitle } from '../common/utils'

export const NotFoundScreen = () => {
  useEffect(() => {
    document.title = getDocumentTitle('Not Found')
  }, [])

  // render content in center of screen
  return (
    <div className='flex items-center justify-center h-full'>
      <div className='text-center'>
        <div className='font-semibold mb-2 text-base'>Page not found</div>
        <div>
          The page you are looking for doesn&apos;t exist. Go to{' '}
          <NavLink className='hover:text-link transition-all underline' to={defaultPath}>
            Loads
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default NotFoundScreen
