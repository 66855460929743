import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { findIndex, orderBy, sortBy } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { PaymentMethodType } from '../common/types'
import { keysToCamel, keysToSnake } from '../common/utils'

export type NewPaymentMethod = {
  nickname: string
  accountType: string
  routingNumber: string
  accountNumber: string
  accountNumberConfirm: string
}

const initialNewPaymentMethod = {
  nickname: '',
  accountType: '',
  routingNumber: '',
  accountNumber: '',
  accountNumberConfirm: '',
}

export type BasePaymentMethod = {
  id?: number
  isPrimary: boolean
  name: string
  isDeleted?: boolean
}

export type AddressPaymentMethod = BasePaymentMethod & {
  businessName: string
  addressLine1: string
  addressLine2: string
  country: string
  city: string
  state: string
  postalCode: string
}

export type CheckPaymentMethod = AddressPaymentMethod & {
  paymentMethod: 'CHECK'
  isSynctera?: boolean
}

export type FactoringPaymentMethod = AddressPaymentMethod & {
  paymentMethod: 'FACTORING'
  isPlaceholder?: boolean
  contactName: string
  email: string
  phone: string
  isSynctera?: boolean
}

export type ACHPaymentMethodAccountType = 'CHECKING' | 'SAVINGS'

export type ACHPaymentMethod = BasePaymentMethod & {
  paymentMethod: 'ACH'
  accountType: ACHPaymentMethodAccountType
  accountNumber: string
  routingNumber: string
  isFinance?: boolean
  isSynctera?: boolean
}

export type PaymentMethod = CheckPaymentMethod | FactoringPaymentMethod | ACHPaymentMethod

export type PaymentMethodChoice = PaymentMethod['paymentMethod']

export const getNewACHPaymentMethod = (): ACHPaymentMethod & { accountNumberConfirm: string } => ({
  paymentMethod: 'ACH',
  name: '',
  isPrimary: false,
  accountType: 'CHECKING',
  routingNumber: '',
  accountNumber: '',
  accountNumberConfirm: '',
})

const getNewCheckPaymentMethod = (): AddressPaymentMethod &
  CheckPaymentMethod & {
    apt: string
  } => ({
  paymentMethod: 'CHECK',
  name: '',
  isPrimary: false,
  businessName: '',
  addressLine1: '',
  addressLine2: '',
  apt: '',
  country: 'US',
  city: '',
  state: '',
  postalCode: '',
})

export const getNewFactoringPaymentMethod = (): BasePaymentMethod & {
  paymentMethod: 'FACTORING'
  businessName: string
  factoringCompanyId: number | null
} => ({
  paymentMethod: 'FACTORING',
  name: '',
  businessName: '',
  isPrimary: false,
  factoringCompanyId: null,
})

type PaymentState = {
  paymentMethods: PaymentMethod[]
  addingNewMethod: boolean
  newACH: ReturnType<typeof getNewACHPaymentMethod>
  newCheck: ReturnType<typeof getNewCheckPaymentMethod>
  newFactoring: ReturnType<typeof getNewFactoringPaymentMethod>
  paymentMethodsLoading: boolean
  updatePaymentMethodsLoading: Record<number, boolean>
  addingNewMethodLoading: boolean
  newPaymentMethod: NewPaymentMethod
}

const initialState: PaymentState = {
  paymentMethods: [],
  addingNewMethod: false,
  newACH: getNewACHPaymentMethod(),
  newCheck: getNewCheckPaymentMethod(),
  newFactoring: getNewFactoringPaymentMethod(),
  paymentMethodsLoading: false,
  updatePaymentMethodsLoading: {},
  addingNewMethodLoading: false,
  newPaymentMethod: initialNewPaymentMethod,
}

export const selectFactoringPaymentMethods = createSelector(
  (state: { payment: PaymentState }) => state.payment.paymentMethods,
  paymentMethods =>
    sortBy(
      paymentMethods.filter(method => method.paymentMethod === 'ACH' && method.isFinance),
      pm => !pm.isSynctera,
    ),
)

export const updatePaymentType = createAsyncThunk(
  'payment/updatePaymentType',
  async (paymentMethodType: PaymentMethodType) => {
    await api.patch('/accounts/api/carrier/company-detail/', {
      payment_method_type: paymentMethodType,
    })
  },
)

export const getPaymentMethods = createAsyncThunk('payment/getPaymentMethods', async () =>
  api.get('/billing/api/carrier-company-payment-methods/').then(({ data }) => keysToCamel(data)),
)

export const updatePrimaryMethod = createAsyncThunk(
  'payment/updatePrimaryMethod',
  async ({ id, isPrimary }: { id: number; isPrimary: true }, { dispatch }) => {
    dispatch(setUpdatePaymentMethodLoading({ id, loading: true }))
    return api
      .patch(`/billing/api/carrier-company-payment-method-ud/${id}/`, keysToSnake({ isPrimary }))
      .finally(() => {
        dispatch(setUpdatePaymentMethodLoading({ id, loading: false }))
      })
  },
)

export const deleteMethod = createAsyncThunk('payment/deleteMethod', async (idsToDelete: any) =>
  api.delete('/billing/api/carrier-company-payment-methods-delete/', {
    data: {
      payment_method_ids: idsToDelete,
    },
  }),
)

export const addNewMethod = createAsyncThunk(
  'payment/addNewMethod',
  async (newPaymentMethod: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        '/billing/api/carrier-company-payment-methods/',
        keysToSnake(newPaymentMethod),
      )
      dispatch(getPaymentMethods())
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    toggleNewPaymentMethod(state) {
      state.addingNewMethod = !state.addingNewMethod
    },
    updateACH(state, action: PayloadAction<any>) {
      state.newACH = action.payload
    },
    updateCheck(state, action: PayloadAction<any>) {
      state.newCheck = action.payload
    },
    updateFactoring(state, action: PayloadAction<any>) {
      state.newFactoring = action.payload
    },
    setUpdatePaymentMethodLoading(state, action: PayloadAction<{ id: number; loading: boolean }>) {
      state.updatePaymentMethodsLoading = {
        ...state.updatePaymentMethodsLoading,
        [action.payload.id]: action.payload.loading,
      }
    },
    resetNewPaymentMethods(state) {
      state.newACH = getNewACHPaymentMethod()
      state.newCheck = getNewCheckPaymentMethod()
      state.newFactoring = getNewFactoringPaymentMethod()
    },
    setNewPaymentMethod(state, { payload }) {
      state.newPaymentMethod = payload
    },
    resetNewPaymentMethod(state) {
      state.newPaymentMethod = initialNewPaymentMethod
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPaymentMethods.pending, state => {
        state.paymentMethodsLoading = true
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethodsLoading = false
        state.paymentMethods = orderBy(action.payload, ['isDeleted'], ['asc'])
      })
      .addCase(getPaymentMethods.rejected, state => {
        state.paymentMethodsLoading = false
        toast.error('Error getting payment methods')
      })
      .addCase(updatePrimaryMethod.fulfilled, (state, action) => {
        const { id } = action.meta.arg
        const currentPrimaryIdx = findIndex(state.paymentMethods, { isPrimary: true })
        const newPrimaryIdx = findIndex(state.paymentMethods, { id })

        currentPrimaryIdx > -1 && (state.paymentMethods[currentPrimaryIdx].isPrimary = false)
        state.paymentMethods[newPrimaryIdx].isPrimary = true
      })
      .addCase(updatePrimaryMethod.rejected, () => {
        toast.error('Error changing primary payment method')
      })
      .addCase(deleteMethod.rejected, () => {
        toast.error('Error deleting payment method')
      })
      .addCase(addNewMethod.pending, state => {
        state.addingNewMethodLoading = true
      })
      .addCase(addNewMethod.fulfilled, state => {
        state.addingNewMethodLoading = false
      })
      .addCase(addNewMethod.rejected, (state, payload) => {
        state.addingNewMethodLoading = false
        toast.error(getErrorString(payload, 'Error adding new payment method'))
      })
  },
})

export default paymentSlice.reducer

export const {
  toggleNewPaymentMethod,
  updateACH,
  updateCheck,
  updateFactoring,
  setUpdatePaymentMethodLoading,
  resetNewPaymentMethods,
  setNewPaymentMethod,
  resetNewPaymentMethod,
} = paymentSlice.actions
