import { TopLevelConfirmDialog } from '@components'
import { Worker } from '@react-pdf-viewer/core'
import * as Sentry from '@sentry/react'
import { ReactNode, useEffect } from 'react'

import { FallBackScreen } from './components/FallbackScreen'
import { SwitchRouter } from './components/SwitchRouter'
import { TrackingOverlay } from './components/TrackingOverlay'
import { useAppThunkDispatch } from './redux/store'
import { setUserSetting } from './redux/userSlice'

const App = () => {
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    // Make sure sidebar is showing on initial load, in case of bad persisted state
    dispatch(setUserSetting({ showSidebar: true }))
  }, [])

  // TODO: add sandbox/prod differentiation to Marqeta script endpoint
  /* We use a library called Marqeta to render debit card information in the banking dashboard.
   * This library is inserted dynamically into the dom to eventually allow sandbox/prod substitution */
  useEffect(() => {
    if (!document.getElementById('marqeta-widget-script')) {
      const script = document.createElement('script')

      script.src = 'https://widgets-sandbox.marqeta.com/marqetajs/2.0.0/marqeta.min.js'
      script.type = 'text/javascript'
      script.id = 'marqeta-widget-script'

      document.body.appendChild(script)
    }
  }, [])

  return (
    <CustomErrorBoundary fallback={<FallBackScreen />}>
      <SwitchRouter />
      <TopLevelConfirmDialog />
      {/*pdfjs uses a web worker to parse and render PDF documents*/}
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js' />
      <TrackingOverlay />
    </CustomErrorBoundary>
  )
}

const CustomErrorBoundary = ({
  children,
  fallback,
}: {
  children: ReactNode
  fallback: JSX.Element
}) => {
  const beforeCapture = (scope: Sentry.Scope, error: Error | null) => {
    if (error && error.message.includes('Failed to fetch dynamically imported module'))
      location.reload()
  }

  return (
    <Sentry.ErrorBoundary beforeCapture={beforeCapture} fallback={fallback}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default App
