import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { AvailableLoad } from '../common/types'
import { keysToCamel } from '../common/utils'

type BackhaulLoadsState = {
  loads: AvailableLoad[]
  loadsLoading: boolean
  count: number
  offset: number
  size: number
  scroll: number
  search: string
  order: string
  orderDisplay: string
  selectedBackhaulLoad: AvailableLoad | null
  selectedBackhaulLoadLoading: boolean
}

const initialState: BackhaulLoadsState = {
  loads: [],
  loadsLoading: false,
  count: 0,
  offset: 0,
  size: 10,
  scroll: 0,
  search: '',
  order: '',
  orderDisplay: '',
  selectedBackhaulLoad: null,
  selectedBackhaulLoadLoading: false,
}

export const getBackhaulLoads = createAsyncThunk(
  'backhaulLoads/getBackhaulLoads',
  async (
    { limit = 10, offset = 0, id }: { limit: number; offset: number; id: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await api
        .get('/carrier-matching/reloads/', {
          params: { limit, offset, load_id: id },
        })
        .then(({ data }) => keysToCamel(data))
      return response
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getBackhaulLoadDetails = createAsyncThunk(
  'loads/getLoadDetail',
  async (id: number, { rejectWithValue }) =>
    api
      .get(`/loads/api/basic-load-detail/${id}/`)
      .then(({ data }) => keysToCamel(data) as AvailableLoad)
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

const backhaulLoadsSlice = createSlice({
  name: 'backhaulLoads',
  initialState,
  reducers: {
    resetSelectedLoad: state => {
      state.selectedBackhaulLoad = null
    },
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setScroll(state, { payload }) {
      state.scroll = payload
    },
    setSearch(state, { payload }) {
      state.search = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setOrderDisplay(state, { payload }) {
      state.orderDisplay = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBackhaulLoads.pending, state => {
        state.loadsLoading = true
      })
      .addCase(getBackhaulLoads.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.loadsLoading = false
        state.loads = results.map((load: any) => ({
          ...load,
          status: 'Available',
          price: load.carrierStartBuy || 0,
          children: [],
        }))
        state.count = count
      })
      .addCase(getBackhaulLoads.rejected, state => {
        state.loadsLoading = false
        toast.error('Error getting backhaul loads')
      })
      .addCase(getBackhaulLoadDetails.pending, state => {
        state.selectedBackhaulLoadLoading = true
      })
      .addCase(getBackhaulLoadDetails.fulfilled, (state, { payload }) => {
        state.selectedBackhaulLoadLoading = false
        state.selectedBackhaulLoad = payload
      })
      .addCase(getBackhaulLoadDetails.rejected, (state, { payload }) => {
        state.selectedBackhaulLoadLoading = false
        toast.warn(getErrorString(payload, 'The load you requested is unavailable'))
      })
  },
})

export const {
  resetSelectedLoad,
  setSize,
  setOffset,
  setScroll,
  setSearch,
  setOrder,
  setOrderDisplay,
} = backhaulLoadsSlice.actions

export default backhaulLoadsSlice.reducer
