import { Widget } from '@typeform/embed-react'

import { useAppSelector } from '../../redux/store'

export const FeedbackScreen = () => {
  const mcNumber = useAppSelector(state => state.company.companyDetail.mcNumber) ?? ''
  const dotNumber = useAppSelector(state => state.company.companyDetail.dotNumber) ?? ''
  const companyId = String(useAppSelector(state => state.company.companyDetail.companyId) ?? '')
  return (
    <div className='flex flex-col grow overflow-hidden'>
      <Widget
        className='grow'
        id='EMLTfDpb'
        hidden={{
          mc_number: mcNumber,
          dot_number: dotNumber,
          company_id: companyId,
          environment: import.meta.env.VITE_ENVIRONMENT,
        }}
      />
    </div>
  )
}
export default FeedbackScreen
