import { useEffect } from 'react'

import { paymentMethods, paymentTypes } from '../../../common/constants'
import { PaymentMethodType } from '../../../common/types'
import { PaymentMethodChoice } from '../../../redux/paymentSlice'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { setOnboardingDetails } from '../../../redux/userSlice'
import { CheckboxSelect } from '../../CheckboxSelect'
import { DetailsSectionTitle } from '../../DetailsSectionTitle'
import { Hint } from '../../Hint'
import { SectionTitle } from '../../SectionTitle'
import { ACHPaymentDetails } from './ACHPaymentDetails'
import { CheckPaymentDetails } from './CheckPaymentDetails'
import { FactoringPaymentDetails } from './FactoringPaymentDetails'

export const PaymentInformation = () => {
  const dispatch = useAppThunkDispatch()

  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)
  const paymentInformation = useAppSelector(
    state => state.user.onboarding.onboardingDetails.paymentInformation,
  )
  const onboardingPaymentTypes = paymentTypes.filter(x => !x.disabled)

  const setPaymentInformation = (obj: any) => {
    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        paymentInformation: { ...paymentInformation, ...obj },
      }),
    )
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='form'>
      <div className='relative border border-brand-light rounded-lg pb-4 text-left'>
        <SectionTitle className='mt-4' title='Payment Information' />
        <div className='px-4 md:px-6'>
          <DetailsSectionTitle required title='Select Type of Payment' />
          <Hint
            text='If you factor invoices, Standard Net 30 and Quick Pay do not apply.
            Instead, select your factoring company from the dropdown under the "Factoring Company" payment method.'
          />
          <CheckboxSelect
            className='my-4'
            data={onboardingPaymentTypes}
            selected={paymentInformation?.paymentType ?? PaymentMethodType.STANDARD}
            setData={(paymentType: PaymentMethodType) => setPaymentInformation({ paymentType })}
          />
          <div>
            <DetailsSectionTitle required title='Select Form of Payment' />
            <CheckboxSelect
              className='my-4'
              data={paymentMethods}
              selected={paymentInformation?.paymentMethod ?? 'ACH'}
              setData={(paymentMethod: PaymentMethodChoice) =>
                setPaymentInformation({ paymentMethod })
              }
            />
            <DetailsSectionTitle required className='mb-4' title='Provide payment method details' />
            {paymentInformation?.paymentMethod === 'ACH' && (
              <ACHPaymentDetails setPaymentInformation={setPaymentInformation} />
            )}
            {paymentInformation?.paymentMethod === 'CHECK' && (
              <CheckPaymentDetails setPaymentInformation={setPaymentInformation} />
            )}
            {paymentInformation?.paymentMethod === 'FACTORING' && (
              <FactoringPaymentDetails setPaymentInformation={setPaymentInformation} />
            )}
          </div>
        </div>
      </div>

      {/* <FactoringInterest /> */}
    </div>
  )
}
