import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { AvailableLoad, Filters, RootState } from '../common/types'
import { formatFilters, keysToCamel } from '../common/utils'

type LoadsState = {
  loads: AvailableLoad[]
  loadsLoading: boolean
  count: number
  offset: number
  size: number
  scroll: number
  search: string
  filters: Filters
  order: string
  orderDisplay: string
  bookLoading: boolean
  quoteLoading: boolean
  fetchRecommendedLoadsOnly: boolean
  isRecommendedLoadsCountLoading: boolean
  recommendedLoadsCount: number
  selectedLoad: AvailableLoad | null
  selectedLoadLoading: boolean
  bookedLoads: number[]
  quoteLoadError: string
  bookLoadError: string
}

const initialState: LoadsState = {
  loads: [],
  loadsLoading: false,
  count: 0,
  offset: 0,
  size: 20,
  scroll: 0,
  search: '',
  filters: initialFilters,
  order: '',
  orderDisplay: '',
  bookLoading: false,
  quoteLoading: false,
  fetchRecommendedLoadsOnly: false,
  isRecommendedLoadsCountLoading: false,
  recommendedLoadsCount: 0,
  selectedLoad: null,
  selectedLoadLoading: false,
  bookedLoads: [],
  quoteLoadError: '',
  bookLoadError: '',
}

export const getLoadsRequest = async ({
  limit = 20,
  offset = 0,
  fetchRecommendedLoadsOnly,
  filters,
}: {
  limit: number
  offset: number
  fetchRecommendedLoadsOnly: boolean
  filters?: any
}) => {
  const filtersBody = filters ? formatFilters(filters) : null

  const allLoadsPath = '/loads/api/external-all-load/'
  const recommendedLoadsPath = '/carrier-matching/external/outbound/'
  const loadsPath = fetchRecommendedLoadsOnly ? recommendedLoadsPath : allLoadsPath

  return await api
    .post(loadsPath, filtersBody, { params: { limit, offset } })
    .then(({ data }) => data)
    .then(({ count, results }) => ({
      count: count as number,
      results: results
        .map((load: any) => keysToCamel(Array.isArray(load) ? load : [load]))
        .map((loadList: any) => ({
          ...loadList[0],
          children: loadList.slice(1),
        })) as AvailableLoad[],
    }))
}

export const getLoads = createAsyncThunk('loads/getLoads', async (_, { getState }) => {
  const {
    fetchRecommendedLoadsOnly,
    filters,
    size = 20,
    offset = 0,
  } = (getState() as RootState).loads

  return getLoadsRequest({ limit: size, offset, fetchRecommendedLoadsOnly, filters })
})

export const getRecommendedLoadsCount = createAsyncThunk(
  'loads/getRecommendedLoadsCount',
  async () => getLoadsRequest({ limit: 1, offset: 1, fetchRecommendedLoadsOnly: true }),
)

export const getLoadDetail = createAsyncThunk(
  'loads/getLoadDetail',
  async (id: number, { rejectWithValue }) =>
    api
      .get(`/loads/api/basic-load-detail/${id}/`)
      .then(({ data }) => keysToCamel(data) as AvailableLoad)
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const bookLoad = createAsyncThunk(
  'loads/book',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.post(`/loads/api/carrier-load-book/${id}/`).then(({ data }) => data)
      dispatch(getLoads())
      return { id, res }
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const quoteLoad = createAsyncThunk(
  'loads/quote',
  async ({ id, bid }: { id: number; bid: number }, { rejectWithValue }) =>
    api
      .post(`/quotes/api/carrier-bid-load/${id}/`, { carrier_bid: bid })
      .then(({ data }) => data)
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

const loadsSlice = createSlice({
  name: 'loads',
  initialState,
  reducers: {
    resetSelectedLoad: state => {
      state.selectedLoad = null
      state.quoteLoadError = ''
      state.bookLoadError = ''
    },
    setSize: (state, { payload }) => {
      state.size = payload
    },
    setOffset: (state, { payload }) => {
      state.offset = payload
    },
    setScroll: (state, { payload }) => {
      state.scroll = payload
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    },
    setOrder: (state, { payload }) => {
      state.order = payload
    },
    setOrderDisplay: (state, { payload }) => {
      state.orderDisplay = payload
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
    setFetchRecommendedLoadsOnly: (state, { payload }: { payload: boolean }) => {
      state.fetchRecommendedLoadsOnly = payload
      state.offset = 0
      state.size = 20
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLoads.pending, state => {
        state.loadsLoading = true
      })
      .addCase(getLoads.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.loadsLoading = false
        state.loads = results
        state.count = count
      })
      .addCase(getLoads.rejected, state => {
        state.loadsLoading = false
        toast.error('Error getting recommended loads')
      })
      .addCase(getLoadDetail.pending, state => {
        state.selectedLoadLoading = true
      })
      .addCase(getLoadDetail.fulfilled, (state, { payload }) => {
        state.selectedLoadLoading = false
        state.selectedLoad = payload
      })
      .addCase(getLoadDetail.rejected, (state, { payload }) => {
        state.selectedLoadLoading = false
        state.selectedLoad = null
        toast.warn(getErrorString(payload, 'The load you requested is unavailable'))
      })
      .addCase(quoteLoad.pending, state => {
        state.quoteLoading = true
      })
      .addCase(quoteLoad.fulfilled, state => {
        state.quoteLoading = false
        state.quoteLoadError = ''
      })
      .addCase(quoteLoad.rejected, (state, { payload }) => {
        state.quoteLoading = false
        state.quoteLoadError = getErrorString(payload, 'Could not quote load at this time')
        toast.error(getErrorString(payload, 'Could not send quote at this time'))
      })
      .addCase(bookLoad.pending, state => {
        state.bookLoading = true
      })
      .addCase(bookLoad.fulfilled, (state, action) => {
        state.bookLoading = false
        state.bookLoadError = ''
        state.bookedLoads.push(action.payload.id)
      })
      .addCase(bookLoad.rejected, (state, { payload }) => {
        state.bookLoading = false
        state.bookLoadError = getErrorString(payload, 'Could not book load at this time')
      })
      .addCase(getRecommendedLoadsCount.pending, state => {
        state.isRecommendedLoadsCountLoading = true
      })
      .addCase(getRecommendedLoadsCount.fulfilled, (state, { payload }) => {
        state.isRecommendedLoadsCountLoading = false
        state.recommendedLoadsCount = payload.count
      })
      .addCase(getRecommendedLoadsCount.rejected, state => {
        state.isRecommendedLoadsCountLoading = false
        toast.error('Could not acquire recommended loads count')
      })
  },
})

export const {
  resetSelectedLoad,
  setSize,
  setOffset,
  setScroll,
  setSearch,
  setOrder,
  setOrderDisplay,
  setFilters,
  setFetchRecommendedLoadsOnly,
} = loadsSlice.actions

export default loadsSlice.reducer
