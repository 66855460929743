import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { Contact, EditContact, RootState } from '../common/types'
import { keysToCamel } from '../common/utils'
import { normalizeContact } from './normalize'

const getNewContact = () => ({
  id: -1,
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  roles: [],
})

type ContactsState = {
  contacts: Array<Contact>
  count: number
  contactsFetched: boolean
  newContact: EditContact
  newContactModalVisible: boolean
  loading: {
    addingNewContact: boolean
    fetchingContacts: boolean
  }
}

const initialState: ContactsState = {
  contacts: [],
  count: 0,
  contactsFetched: false,
  newContact: getNewContact(),
  newContactModalVisible: false,
  loading: {
    addingNewContact: false,
    fetchingContacts: false,
  },
}

export const getContacts = createAsyncThunk(
  'contacts/getContacts',
  async ({ limit }: { limit: number; backgroundMode?: boolean }, { rejectWithValue }) => {
    try {
      return api
        .get('/accounts/api/carrier-contact-list-create/', {
          params: { limit },
        })
        .then(({ data }) => keysToCamel(data) as { results: Contact[]; count: number })
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addNewContactPlain = createAsyncThunk(
  'contacts/addNewContactPlain',
  async (extra: Partial<EditContact> | undefined, { getState, rejectWithValue }) => {
    const { firstName = '', lastName = '', ...rest } = (getState() as RootState).contacts.newContact

    return await api
      .post(
        '/accounts/api/carrier-contact-list-create/',
        normalizeContact({
          ...rest,
          ...((extra as EditContact) ?? {}),
          name: `${firstName} ${lastName}`.trim(),
        }),
      )
      .then(({ data }) => keysToCamel(data))
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const addNewContact = createAsyncThunk(
  'contacts/addNewContact',
  async (
    {
      contact,
      limit = 20,
    }: {
      contact: EditContact
      limit: number
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        '/accounts/api/carrier-contact-list-create/',
        normalizeContact(contact),
      )
      dispatch(getContacts({ limit }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateContact = createAsyncThunk(
  'contacts/updateContact',
  async (
    { contact, limit }: { contact: EditContact; limit: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.put(
        `/accounts/api/carrier-company-contact-rud/${contact.id}/`,
        normalizeContact(contact),
      )
      dispatch(getContacts({ limit, backgroundMode: true }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteContact = createAsyncThunk(
  'contacts/deleteContact',
  async ({ id, limit }: { id: number; limit: number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.delete(`/accounts/api/carrier-company-contact-rud/${id}/`)
      dispatch(getContacts({ limit, backgroundMode: true }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContacts(state, action) {
      state.contacts = action.payload
    },
    setNewContact(state, { payload }: PayloadAction<Partial<EditContact>>) {
      state.newContact = {
        ...state.newContact,
        ...(payload as EditContact),
      }
    },
    setNewContactModalVisible(state, { payload }: PayloadAction<boolean>) {
      state.newContactModalVisible = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getContacts.pending, (state, action) => {
        if (!action.meta.arg.backgroundMode) state.loading.fetchingContacts = true
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.loading.fetchingContacts = false
        state.contacts = results
        state.count = count
        state.contactsFetched = true
      })
      .addCase(getContacts.rejected, state => {
        state.loading.fetchingContacts = false
        toast.error('Error getting contacts')
      })
      .addCase(addNewContact.fulfilled, () => {
        toast.success('Contact created successfully. We sent them an email')
      })
      .addCase(addNewContact.rejected, (state, action) => {
        toast.error(getErrorString(action.payload, 'Error creating contact'))
      })
      .addCase(addNewContactPlain.pending, state => {
        state.loading.addingNewContact = true
      })
      .addCase(addNewContactPlain.fulfilled, (state, { payload }) => {
        state.loading.addingNewContact = false
        state.newContactModalVisible = false
        state.newContact = getNewContact()
        state.contacts.push(payload)
        toast.success('Contact created successfully')
      })
      .addCase(addNewContactPlain.rejected, (state, { payload }) => {
        state.loading.addingNewContact = false
        toast.error(getErrorString(payload, 'Error creating contact'))
      })
      .addCase(updateContact.fulfilled, () => {
        toast.success('Contact updated successfully')
      })
      .addCase(updateContact.rejected, (state, action) => {
        toast.error(getErrorString(action.payload, 'Error updating contact'))
      })
      .addCase(deleteContact.fulfilled, () => {
        toast.success('Contact deleted successfully')
      })
      .addCase(deleteContact.rejected, () => {
        toast.error('Error deleting contact')
      })
  },
})

export default contactsSlice.reducer

export const { setContacts, setNewContact, setNewContactModalVisible } = contactsSlice.actions
