import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import {
  Accessorial,
  LoadFactoringRequest,
  PaginatedArray,
  ReserveSettlement,
  RootState,
} from '../common/types'
import { keysToCamel } from '../common/utils'

export const getLoadFactoringRequestDetails = createAsyncThunk(
  'loadFactoringRequests/getLoadFactoringRequestDetails',
  async ({ id }: { id: number }) =>
    api.get(`/accounts/api/load-factoring-request/${id}/`).then(({ data }) => keysToCamel(data)),
)

export const getLoadFactorinRequestSettlement = createAsyncThunk(
  'loadFactoringRequests/getLoadFactorinRequestSettlement',
  async ({ id }: { id: number }) =>
    api.get(`/billing/api/carrier-lfr-settlement/${id}/`).then(({ data }) => keysToCamel(data)),
)

export const getNewLoadFactoringRequests = createAsyncThunk(
  'loadFactoringRequests/getLoadFactoringRequests',
  async (_, { getState }) => {
    const { loadFactoringRequests } = getState() as RootState
    return api
      .get('/accounts/api/load-factoring-requests/', {
        params: {
          limit: loadFactoringRequests.limit,
          offset: loadFactoringRequests.offset,
        },
      })
      .then(({ data }) => keysToCamel(data) as PaginatedArray<LoadFactoringRequest>)
  },
)

export const getDashboardInfo = createAsyncThunk<APIDashboard>(
  'loadFactoringRequests/getDashboardInfo',
  async () =>
    api.get('/accounts/api/load-factoring-dashboard/').then(({ data }) => keysToCamel(data)),
)

type APIDashboardStats = {
  last30Total: number
  prior30Total: number
}

type APIDashboard = {
  requiredReserveBalance: number
  totalReserved: number
  totalRecovery: number
  recoveries: any
  dashboardState: APIDashboardStats
  reservePercentage: number
}

export type LoadFactoringRequestState = {
  count: {
    loadFactoringRequests: number
  }
  currentLoadFactoringRequest: LoadFactoringRequest | null
  carrierLfrSettlement: ReserveSettlement | null
  loadFactoringRequests: LoadFactoringRequest[]
  loading: {
    list: boolean
    updatingLoadFactoringRequest: boolean
    currentLoadFactoringRequest: boolean
    carrierLfrSettlement: boolean
    dashboard: boolean
  }
  errors: {
    dashboard: boolean
  }
  dashboard: APIDashboard | null
  requestListStatus: 'APPROVED' | 'PENDING' | 'REJECTED'
  offset: number
  limit: number
}

const initialState: LoadFactoringRequestState = {
  loadFactoringRequests: [],
  count: {
    loadFactoringRequests: 0,
  },
  currentLoadFactoringRequest: null,
  carrierLfrSettlement: null,
  loading: {
    list: false,
    updatingLoadFactoringRequest: false,
    currentLoadFactoringRequest: false,
    carrierLfrSettlement: false,
    dashboard: false,
  },
  errors: {
    dashboard: false,
  },
  dashboard: null,
  requestListStatus: 'PENDING',
  offset: 0,
  limit: 10,
}

export const loadFactoringRequestSlice = createSlice({
  name: 'loadFactoringRequests',
  initialState,
  reducers: {
    switchListStatus(
      state,
      { payload }: PayloadAction<LoadFactoringRequestState['requestListStatus']>,
    ) {
      state.requestListStatus = payload
      state.offset = 0
      state.loadFactoringRequests = []
      state.count.loadFactoringRequests = 0
      state.loading.list = false
    },
    setOffset(state, { payload }: PayloadAction<number>) {
      state.offset = payload
    },
    setLimit(state, { payload }: PayloadAction<number>) {
      state.limit = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getNewLoadFactoringRequests.pending, state => {
        state.loading.list = true
      })
      .addCase(getNewLoadFactoringRequests.fulfilled, (state, action) => {
        const data = action.payload
        state.loadFactoringRequests = data.results as LoadFactoringRequest[]
        state.count.loadFactoringRequests = data.count ?? 0
        state.loading.list = false
      })
      .addCase(getNewLoadFactoringRequests.rejected, state => {
        state.loading.list = false
        toast.error('Error getting load factoring requests')
      })
      .addCase(getLoadFactoringRequestDetails.pending, state => {
        state.loading.currentLoadFactoringRequest = true
      })
      .addCase(getLoadFactorinRequestSettlement.pending, state => {
        state.loading.carrierLfrSettlement = true
      })
      .addCase(getLoadFactoringRequestDetails.fulfilled, (state, action) => {
        const rra = action.payload.reserveRecoveryAccessorial
        const invoiceAmount = action.payload.totalAmount
        const factoringFee = action.payload.factoringFee
        const accessorials: Accessorial[] = action.payload.accessorials

        action.payload.netAdvance = invoiceAmount - (rra.reserveAmount + factoringFee)

        if (rra.recoveryAmount > 0) {
          action.payload.grossAdvance = action.payload.netAdvance
          action.payload.netAdvance -= rra.recoveryAmount
        }

        const filteredAcessorials = accessorials.filter(item => !item.isReserve && !item.isRecovery)
        if (filteredAcessorials.length > 0) {
          if (rra.recoveryAmount === 0) {
            action.payload.grossAdvance = action.payload.netAdvance
          }
          filteredAcessorials.map(acessorial => {
            action.payload.netAdvance -= acessorial.amount
          })
        }

        action.payload.calculatedEscrow = rra.reserveAmount + factoringFee

        state.currentLoadFactoringRequest = action.payload
        state.loading.currentLoadFactoringRequest = false
      })
      .addCase(getLoadFactorinRequestSettlement.fulfilled, (state, action) => {
        const invoiceAmount = action.payload.invoiceAmount
        const factoringFee = action.payload.factoringFee
        const debtorPaid = action.payload.debtorPaid
        const netAdvance = action.payload.netAdvance
        const accessorialTotal = action.payload.accessorialTotal
        const rra = state.currentLoadFactoringRequest?.reserveRecoveryAccessorial

        if (debtorPaid == 0) {
          action.payload.paymentState = 'None'
        } else if (debtorPaid > 0 && debtorPaid < invoiceAmount) {
          action.payload.paymentState = 'Partial'
        } else if (invoiceAmount == debtorPaid) {
          action.payload.paymentState = 'Full'
        } else {
          action.payload.paymentState = ''
        }

        action.payload.grossReserveSettlement =
          debtorPaid - (netAdvance + rra?.recoveryAmount + accessorialTotal)
        action.payload.netReserveSettlement = action.payload.grossReserveSettlement - factoringFee

        action.payload.netSettlement = netAdvance + action.payload.netReserveSettlement

        state.carrierLfrSettlement = action.payload
        state.loading.carrierLfrSettlement = false
      })
      .addCase(getLoadFactoringRequestDetails.rejected, state => {
        state.loading.currentLoadFactoringRequest = false
        toast.error('Error getting factoring request data')
      })
      .addCase(getLoadFactorinRequestSettlement.rejected, state => {
        state.loading.carrierLfrSettlement = false
        toast.error('Error getting factoring request settlement data')
      })
      .addCase(getDashboardInfo.pending, state => {
        state.loading.dashboard = true
      })
      .addCase(getDashboardInfo.fulfilled, (state, action) => {
        state.errors.dashboard = false
        state.loading.dashboard = false
        state.dashboard = action.payload
      })
      .addCase(getDashboardInfo.rejected, state => {
        state.loading.dashboard = false
        state.errors.dashboard = true
        toast.error('Error getting factoring dashboard data')
      })
  },
})

export const { setOffset, setLimit, switchListStatus } = loadFactoringRequestSlice.actions

export default loadFactoringRequestSlice.reducer
