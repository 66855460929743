import { LoaderLogo } from '@components'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { displayDate, formatCurrency } from '../../common/utils'
import { FactoringActions } from '../../components/FactoringDashboard/FactoringActions'
import { FactoringReleaseModal } from '../../components/FactoringDashboard/FactoringReleaseModal'
import { getRecoveryDetails } from '../../redux/carrierCompanyRecoveriesSlice'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { RecoveryDetailsTableDesktop } from './RecoveryDetailsTableDesktop'

export const RecoveryDetailsScreen = () => {
  const dispatch = useAppThunkDispatch()
  const [isReleaseModalVisible, setIsReleaseModalVisible] = useState(false)

  const recoveryDetails = useAppSelector(state => state.carrierCompanyRecoveries.recoveryDetails)

  const loading = useAppSelector(
    state => state.loadFactoringRequests.loading.currentLoadFactoringRequest,
  )

  const recoveryPlanData: [string, string | ReactNode | null | undefined][] = [
    ['Recovery per Factored Load', formatCurrency(recoveryDetails?.repaymentAmountPerLoad)],
    ['Estimated Factored Loads', recoveryDetails?.estimatedFactoredLoads],
  ]

  const recoveryInformationData: [string, string | ReactNode | null | undefined][] = [
    ['Created Date', displayDate(recoveryDetails?.createdAt)],
    ['Load Id', recoveryDetails?.loadId],
    ['Recovery Amount', formatCurrency(recoveryDetails?.amountToRecover)],
  ]

  const { recoveryId } = useParams<string>()

  useEffect(() => {
    if (recoveryId) {
      dispatch(getRecoveryDetails({ id: Number(recoveryId) }))
    }
  }, [])

  const navigate = useNavigate()

  const renderTitle = (title: string) => (
    <header>
      <h3 className='text-black font-bold mb-5'>{title}</h3>
    </header>
  )

  const Header = () => (
    <HeaderRow>
      <NavSection>
        <button type='button' onClick={() => navigate(-1)}>
          <ArrowLeftIcon className='w-7 text-black' />
        </button>
        <h2 className='text-black'>Recovery #{recoveryDetails?.id} </h2>
      </NavSection>
      <div className='hidden md:flex'>
        <FactoringActions setIsReleaseModalVisible={setIsReleaseModalVisible} />
      </div>
    </HeaderRow>
  )

  const RecoveryDetails = () => (
    <div className='flex flex-col'>
      <ArticleSection>
        <Article>
          {renderTitle('Outstanding')}
          <div className='flex justify-center items-center'>
            <span className='text-error text-4xl font-semibold'>
              {formatCurrency(recoveryDetails?.balance)}
            </span>
          </div>
        </Article>

        <Article>
          {renderTitle('Recovery Information')}
          {recoveryInformationData.map(([key, val], index) => (
            <DataContainerRow key={index}>
              <dt className='font-normal'>{key}</dt>
              <dd className='font-normal text-right'>{val}</dd>
            </DataContainerRow>
          ))}
        </Article>

        <Article>
          {renderTitle('Recovery Plan')}
          {recoveryPlanData.map(([key, val], index) => (
            <DataContainerRow key={index}>
              <dt className='font-normal'>{key}</dt>
              <dd className='font-normal text-right'>{val}</dd>
            </DataContainerRow>
          ))}
        </Article>
      </ArticleSection>

      <Article>
        {renderTitle('Installments')}
        <div>
          <RecoveryDetailsTableDesktop recoveryId={Number(recoveryId)} />
        </div>
      </Article>
    </div>
  )

  if (loading) return <LoaderLogo />
  return (
    <Main>
      <Header />
      <RecoveryDetails />
      <FactoringReleaseModal
        isVisible={isReleaseModalVisible}
        setIsVisible={setIsReleaseModalVisible}
      />
    </Main>
  )
}

const Main = tw.main`
  grow
  p-6
  relative
  overflow-auto
`

const HeaderRow = tw.div`
  flex
  justify-between
  pb-6
`

const NavSection = tw.div`
  flex
  gap-4
  text-2xl
  font-semibold
  items-center
`
const ArticleSection = tw.div`
  flex
  gap-4
  flex-wrap
  lg:nowrap
  mb-5
`

const Article = tw.div`
  text-xl
  rounded-lg 
  lg:border 
  lg:border-slate-200s
  lg:p-6
  flex-1
  mobile-and-tablet:mb-5
`

const DataContainerRow = tw.div`
  flex 
  justify-between 
  items-center
`
