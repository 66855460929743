import { CatchError, formatAxiosErrorToPayload } from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { api } from '../api/api'
import { CarrierCompanyReserve, PaginatedArray, RootState } from '../common/types'
import { keysToCamel } from '../common/utils'

export const getCarrierCompanyReserve = createAsyncThunk(
  'carrierCompanyReserve/getCarrierCompanyReserve',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { carrierCompanyReserve } = getState() as RootState
      return api
        .get('/billing/api/carrier-company-reserve/', {
          params: {
            limit: carrierCompanyReserve.limit,
            offset: carrierCompanyReserve.offset,
          },
        })
        .then(({ data }) => keysToCamel(data) as PaginatedArray<CarrierCompanyReserve>)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export type CarrierCompanyReserveState = {
  count: {
    carrierCompanyReserve: number
  }
  carrierCompanyReserve: CarrierCompanyReserve[]
  loading: {
    list: boolean
  }
  offset: number
  limit: number
}

const initialState: CarrierCompanyReserveState = {
  carrierCompanyReserve: [],
  count: {
    carrierCompanyReserve: 0,
  },
  loading: {
    list: false,
  },
  offset: 0,
  limit: 10,
}

export const carrierCompanyReserveSlice = createSlice({
  name: 'carrierCompanyReserve',
  initialState,
  reducers: {
    setOffset(state, { payload }: PayloadAction<number>) {
      state.offset = payload
    },
    setLimit(state, { payload }: PayloadAction<number>) {
      state.limit = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCarrierCompanyReserve.pending, state => {
        state.loading.list = true
      })
      .addCase(getCarrierCompanyReserve.fulfilled, (state, action) => {
        const data = action.payload
        // Map through the results and modify the entryType if it's 'Reserve Payment'
        state.carrierCompanyReserve = data.results?.map(reserve => {
          if (reserve.entryType === 'Reserve Payment') {
            return { ...reserve, entryType: 'Reserve Deposit' }
          }
          return reserve
        }) as CarrierCompanyReserve[]
        state.count.carrierCompanyReserve = data.count ?? 0
        state.loading.list = false
      })
  },
})

export const { setOffset, setLimit } = carrierCompanyReserveSlice.actions

export default carrierCompanyReserveSlice.reducer
