import { setBankingBusiness, updateCardState } from '../../redux/bankingSlice'
import { setCompanyDetail } from '../../redux/companySlice'
import { store } from '../../redux/store'
import { Card, VerificationStatus } from '../types/banking'

export const handlers = {
  cardUpdated({
    form,
    cardStatus,
    statusReason,
  }: Pick<Card, 'form' | 'cardStatus' | 'statusReason'>) {
    store.dispatch(
      updateCardState({
        form,
        cardStatus,
        statusReason,
      }),
    )
  },
  heartbeatResponse({ bankingExpiry }: { bankingExpiry: number }) {
    store.dispatch(
      setCompanyDetail({
        bankingExpiry,
        receivedTimestamp: Date.now(),
      }),
    )
  },
  businessVerificationStatusUpdated({
    verificationStatus,
  }: {
    verificationStatus: VerificationStatus
  }) {
    store.dispatch(
      setBankingBusiness({
        verificationStatus,
      }),
    )
  },
}

export default handlers
