import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

// renders inline label and text
// TODO: think of a new name
export const DetailSectionItem = ({
  title,
  className = '',
  children,
}: {
  title: string
  className?: string
  children: ReactNode
}) => (
  <Row className={className}>
    <div className='mr-4 text-dark-gray poppins'>{title}</div>
    <div>{children || '—'}</div>
  </Row>
)

export const VerticalDetailItem = ({
  label,
  children,
  className = '',
}: {
  label?: string
  children: ReactNode
  className?: string
}) => (
  <HalfWidthItemContainer className={className}>
    {label && <Label className='mb-1'>{label}</Label>}
    <Value>{children || '—'}</Value>
  </HalfWidthItemContainer>
)

const Row = tw.div`
  flex 
  flex-col 
  md:flex-row 
  md:items-center 
  mt-2
`

const HalfWidthItemContainer = tw.div`
  max-md:flex
  max-md:justify-between
`

const Label = tw.div`
  text-dark-gray
  mr-3
`

const Value = tw.div<{ $bold?: boolean }>`
  mr-3
  text-dark-blue
  ${({ $bold }) => $bold && 'font-semibold'}
`
