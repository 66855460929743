import { Button } from '@components'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useAppSelector } from '../../redux/store'
import { Dropdown } from '../Dropdown'

export const FactoringActions = ({
  setIsReleaseModalVisible,
}: {
  setIsReleaseModalVisible: (value: boolean) => void
}) => {
  const navigate = useNavigate()

  const startLoadRequest = () => navigate('/exo-factoring/new-load')
  const startCreditCheck = () => navigate('/exo-factoring/credit-check')

  const factoringStatus = useAppSelector(state => state.factoring.factoringStatus)
  const isApproved = useMemo(() => factoringStatus === 'APPROVED', [factoringStatus])

  // temporarily removed the ability to request a factoring release
  const showDropdown = useMemo(() => factoringStatus === 'RELEASED', [factoringStatus])

  return (
    <FactoringActionsContainer>
      {isApproved && (
        <>
          <Button
            mobileFullWidth
            disabled={true}
            preIcon='plus-circle'
            type='warn'
            onClick={startLoadRequest}
          >
            Factor a Load
          </Button>
          <Button mobileFullWidth disabled={true} onClick={startCreditCheck}>
            Credit Check
          </Button>
        </>
      )}
      {showDropdown && (
        <Dropdown
          contentClassName='bg-brand-light'
          content={hide => (
            <div className='text-sm'>
              {isApproved ? (
                <div
                  className='flex w-fit cursor-pointer text-red-500'
                  onClick={() => {
                    hide()
                    setIsReleaseModalVisible(true)
                  }}
                >
                  Request Factoring Release
                </div>
              ) : (
                <div
                  className='text-blue-500 cursor-pointer'
                  onClick={() => {
                    hide()
                    navigate(`?${new URLSearchParams({ signUpStatus: 'VERIFY_MC' })}`)
                  }}
                >
                  Re-enroll in factoring
                </div>
              )}
            </div>
          )}
        >
          <Button
            innerClassName='!px-2 !bg-brand-light !border-0'
            preIcon='chevron-down'
            type='secondary'
          />
        </Dropdown>
      )}
    </FactoringActionsContainer>
  )
}

const FactoringActionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, max-content)) auto;
  gap: 8px;
`
