import { Button } from '@components'
import tw from 'tailwind-styled-components'

import { trackEvent } from '../../../common/tracking'
import { formatCurrency } from '../../../common/utils'
import { setSelectedPaymentMethod, setViewingAccounts } from '../../../redux/factoringSlice'
import { selectFactoringPaymentMethods } from '../../../redux/paymentSlice'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'

const Badge = ({ type, isSynctera }: { type: 'eta' | 'fee'; isSynctera: boolean }) => {
  const achFee = useAppSelector(state => state.company.companyDetail.factoringAchFee)

  if (type === 'fee' && !isSynctera && !achFee) return null

  const getBadgeDetails = () => {
    if (type === 'eta')
      return {
        label: 'Transfer Time',
        text: isSynctera ? 'Instant on Approval' : '1-2 Business Days',
      }
    else
      return {
        label: 'Fee',
        text: isSynctera ? 'Free' : formatCurrency(achFee),
      }
  }

  const { label, text } = getBadgeDetails()

  return (
    <div className='text-xs mt-2 mr-2'>
      <div>{label}</div>
      <BadgeContainer $isSynctera={isSynctera}>{text}</BadgeContainer>
    </div>
  )
}

export const PaymentMethods = () => {
  const dispatch = useAppThunkDispatch()

  const isViewingAccounts = useAppSelector(state => state.factoring.isViewingAccounts)
  const paymentMethods = useAppSelector(selectFactoringPaymentMethods)
  const selectedPaymentMethod = useAppSelector(state => state.factoring.selectedPaymentMethod)

  return (
    <>
      {isViewingAccounts && (
        <PaymentMethodsContainer>
          {paymentMethods.map(method => (
            <div key={method.id} className='flex justify-between items-center py-2'>
              <div>
                <div>
                  <span>{method.name}</span>
                  {method.paymentMethod === 'ACH' && (
                    <span className='text-dark-gray ml-2'>...{method.accountNumber}</span>
                  )}
                </div>
                <div className='flex'>
                  <Badge isSynctera={!!method.isSynctera} type='eta' />
                  <Badge isSynctera={!!method.isSynctera} type='fee' />
                </div>
              </div>
              <Button
                disabled={method.id === selectedPaymentMethod?.id}
                type='warn'
                onClick={() => {
                  if (!method.isSynctera) trackEvent('Switched to an ACH payment method')
                  dispatch(setSelectedPaymentMethod(method))
                  dispatch(setViewingAccounts(false))
                }}
              >
                Select
              </Button>
            </div>
          ))}
          <Button
            fullWidth
            className='mt-2 border'
            type='light'
            onClick={() => dispatch(setViewingAccounts(false))}
          >
            Close
          </Button>
        </PaymentMethodsContainer>
      )}
    </>
  )
}

const PaymentMethodsContainer = tw.div`
  border
  border-border-gray
  rounded-lg
  mt-6
  py-3
  px-4
  w-full
`

const BadgeContainer = tw.div<{ $isSynctera: boolean }>`
  text-white
  font-semibold
  px-3
  py-1
  rounded-full
  mt-1
  whitespace-nowrap
  ${({ $isSynctera }) => ($isSynctera ? 'bg-success' : 'bg-gray-bg')}
`
