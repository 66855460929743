import { Button } from '@components'

import { useAppSelector } from '../../redux/store'
import { TrackingType } from '../../redux/trackingSlice'

export const TrackingThankYou = ({
  setModalVisible,
}: {
  setModalVisible: (visible: boolean) => void
}) => {
  const trackingType = useAppSelector(state => state.tracking.trackingType)

  return (
    <div className='p-8'>
      <h1 className='text-2xl font-bold'>Thank you for sharing your location!</h1>
      <p className='mt-4'>
        {trackingType === TrackingType.NATIVELY_BACKGROUND && (
          <>
            We are receiving your location updates in the background. You may minimize the app but
            DO NOT close it.
          </>
        )}
        {trackingType === TrackingType.BROWSER_FOREGROUND && (
          <>
            We are now receiving your location updates. Come back and load the app occasionally to
            keep the tracking active.
          </>
        )}
      </p>
      <div className='flex mt-6'>
        <Button
          fullWidth
          lg
          short
          className='w-full'
          type='secondary'
          onClick={() => setModalVisible(false)}
        >
          Close
        </Button>
      </div>
    </div>
  )
}
