import { AppLogoImage } from '@components'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid'

import { officePhone } from '../../common/constants'
import { formatPhone } from '../../common/utils'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { setOnboardingStep } from '../../redux/userSlice'

export const OnboardingHeader = () => {
  const dispatch = useAppThunkDispatch()
  const buttonLoading = useAppSelector(state => state.user.onboarding.buttonLoading)

  return (
    <div className='w-full bg-brand-primary text-brand-light'>
      <div className='flex flex-col sm:flex-row items-center justify-between px-4 py-2 sm:px-6'>
        <div className='flex items-center flex-col sm:flex-row w-full'>
          <AppLogoImage
            className='cursor-pointer w-28 flex-shrink-0'
            color='white'
            onClick={() => !buttonLoading && dispatch(setOnboardingStep(null))}
          />
          {/* Contact Information */}
          <div className='flex flex-row sm:flex-col p-2 sm:p-0 w-full sm:ml-8 text-xs sm:text-sm'>
            <div className='flex items-center justify-start w-full'>
              <PhoneIcon className='w-5 h-5 mr-2 flex-shrink-0' />
              <a href={`tel:+${officePhone}`}>{formatPhone(officePhone)} ext. 1</a>
            </div>
            <div className='flex items-center justify-start w-full'>
              <EnvelopeIcon className='w-5 h-5 mr-2 flex-shrink-0' />
              <a href='mailto:carriersetup@exofreight.com'>carriersetup@exofreight.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
