import { TextInput } from '@components'

import { contactHint, emptyFile, entityTypes } from '../../../common/constants'
import { FileProps } from '../../../common/types'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { setOnboardingDetails } from '../../../redux/userSlice'
import { CheckboxSelect } from '../../CheckboxSelect'
import { Hint } from '../../Hint'
import { SectionTitle } from '../../SectionTitle'
import { DocumentItem } from '../components/DocumentItem'

export const ARInformation = () => {
  const dispatch = useAppThunkDispatch()

  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)

  const {
    accountReceivableInformation,
    documents = {
      w9: null,
      insurance: [],
      certificate: null,
    },
  } = onboardingDetails

  const setDocuments = (field: string, value: FileProps) =>
    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        documents: {
          ...documents,
          [field]: {
            ...value,
          },
        },
      }),
    )

  const setARInformation = (field: string, value: any) =>
    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        accountReceivableInformation: { ...accountReceivableInformation, [field]: value },
      }),
    )

  return (
    <div>
      <div className='relative border border-brand-light rounded-lg form pb-4 text-left'>
        <Hint className='m-4 md:m-6' text={contactHint} />
        <SectionTitle className='mt-4' title='W9 Information' />
        <div className='px-4 md:px-6'>
          <div className='block text-gray-500 text-sm mb-2 self-start mt-4'>
            Business entity type <span className='text-error'>*</span>
          </div>
          <CheckboxSelect
            className='mb-4'
            data={entityTypes}
            selected={accountReceivableInformation?.entityType ?? 0}
            setData={(entityType: any) => setARInformation('entityType', entityType)}
          />
          <TextInput
            required
            className='mb-4'
            label='Who should we make payments out to?'
            value={accountReceivableInformation?.entityName}
            placeholder={
              !accountReceivableInformation?.entityType ? 'Name of the company' : 'Your name'
            }
            onChange={(entityName: string) => setARInformation('entityName', entityName)}
          />
          <TextInput
            className='mb-4'
            label='SCAC'
            mask='****'
            placeholder='____'
            value={accountReceivableInformation?.scac}
            onChange={(scac: string) => setARInformation('scac', scac)}
          />
          <TextInput
            required
            className='mb-4'
            label={!accountReceivableInformation?.entityType ? 'EIN' : 'Social Security Number'}
            mask={!accountReceivableInformation?.entityType ? '00-0000000' : '000-00-0000'}
            placeholder={!accountReceivableInformation?.entityType ? '__-_______' : '___-__-____'}
            value={accountReceivableInformation?.secNumber}
            onChange={(secNumber: string) => setARInformation('secNumber', secNumber)}
          />
          <TextInput
            required
            className='mb-4'
            label='Address'
            placeholder='eg. (1 Small Rd)'
            value={accountReceivableInformation?.address}
            onChange={(address: string) => setARInformation('address', address)}
          />
          <TextInput
            required
            className='mb-4'
            label='City'
            placeholder='eg. (New York)'
            value={accountReceivableInformation?.city}
            onChange={(city: string) => setARInformation('city', city)}
          />
          <div className='flex flex-col md:flex-row justify-between md:items-center w-full'>
            <TextInput
              required
              className='w-full md:w-1/2 md:mr-2 mb-4'
              label='State/Province (2 letter abbreviation)'
              mask='aa'
              placeholder='eg. (NY)'
              value={accountReceivableInformation?.state}
              onChange={(state: string) => setARInformation('state', state.toUpperCase())}
            />
            <TextInput
              required
              className='w-full md:w-1/2 mb-2 md:mb-4'
              label='Zip/Postal Code'
              placeholder='eg. (00000)'
              value={accountReceivableInformation?.postalCode}
              onChange={(postalCode: string) => setARInformation('postalCode', postalCode)}
            />
          </div>
        </div>
        <SectionTitle className='mt-4' title='Upload W9 PDF' />
        <div className='px-4 md:px-6'>
          <DocumentItem
            required
            className='mt-4'
            setItem={(file: FileProps) => setDocuments('w9', file)}
            status='W9 Form'
            title='W9 PDF'
            value={documents.w9}
            onDelete={() => setDocuments('w9', emptyFile)}
          />
        </div>
      </div>
    </div>
  )
}
