export enum PaymentMethodType {
  STANDARD = 'STANDARD',
  QUICK_PAY = 'QUICK_PAY',
  FACTORING = 'FACTORING',
  EXO_FACTORING = 'EXO_FACTORING',
}

export type APICompanyDetail = {
  paymentMethodType: PaymentMethodType
  mcNumber: string
  dotNumber: string
  name: string
  ein: string | null
  ssn: string | null
  isBusiness: boolean | null
  address: string | null
  city: string | null
  stateProvinceRegion: string | null
  postalCode: string | null
  country: string | null
  arName: string
  arAddress: string
  arCity: string
  arState: string | null
  arZipcode: string | null
  arCountry: string | null
  approvedToBank: boolean
  approvedToFactor: boolean
  approvedToReserveFuel: boolean
  approvedToHaul: boolean
  approvedToHaulStatus: ApprovedToHaulStatus
  isFactoringInternally: boolean
  companyId: number
  uiState: UIState
  factoringBuyStatus: boolean
  factoringAchFee: number
  managedByCustomers: ManagedTransShipper[]
  isManagedTrans: boolean | null
  activeManagedTransShipper: ManagedTransShipper | null
}

export type UIState = {
  showLoadRecommendationsPromo: boolean
}

type ExactlyOneKey<T> = {
  [K in keyof T]: Record<K, T[K]> & Partial<Record<Exclude<keyof T, K>, undefined>>
}[keyof T]

export type UIStateSingleProp = ExactlyOneKey<UIState>

export enum ApprovedToHaulStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  NOT_STARTED = 'NOT_STARTED',
}

export type CompanyDocument = {
  createdAt: string
  documentType: string
  documentTypeDisplay: string
  file: string // url
  fileName: string
  id: number
  name: string
  updatedAt: string | null
  uploadedByDisplay: string
}

export type ManagedTransShipper = {
  id: number
  name: string
}
