import { broadcastLogout } from '@common'
import { Button } from '@components'

import { CarrierCompanySearch } from './CarrierCompanySearch'

export const SelectCompanyStep = () => (
  <>
    <CarrierCompanySearch authenticate />
    <Button fullWidth lg className='w-full mt-6' type='secondary' onClick={broadcastLogout}>
      Back
    </Button>
  </>
)
