import { useIsMobile } from '@common'
import { Menu } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useCallback, useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { ManagedTransShipper } from '../../common/types'
import { setManagedTransShipper } from '../../redux/companySlice'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { Transition } from '../Transition'

export const ManagedTransShipperPicker = ({ classNames = '' }: { classNames?: string }) => {
  const dispatch = useAppThunkDispatch()
  const isMobile = useIsMobile()
  const companyDetails = useAppSelector(state => state.company.companyDetail)
  const shouldShowShipperPicker = useMemo(
    () => companyDetails.isManagedTrans && companyDetails.managedByCustomers?.length,
    [companyDetails],
  )

  const setShipper = useCallback(
    async (customer: ManagedTransShipper | null) => {
      const shoudlSet =
        (companyDetails?.activeManagedTransShipper?.id ?? null) !== (customer?.id ?? null)
      if (!shoudlSet) return

      dispatch(setManagedTransShipper(customer))
    },
    [companyDetails?.activeManagedTransShipper?.id],
  )

  if (!shouldShowShipperPicker) return null

  return (
    <div
      className={`flex flex-col gap-2 p-3 mb-5 border border-gray-300 rounded-md relative ${classNames}`}
    >
      <div className='text-xs border-b-2 pb-2'>You&apos;re viewing network</div>
      <Menu>
        <Menu.Button className='flex justify-between items-center cursor-pointer'>
          {({ open }) => (
            <>
              <span className='text-[#3F6AA6] font-bold'>
                {companyDetails.activeManagedTransShipper?.name || 'EXO Freight'}
              </span>
              <ChevronRightIcon
                style={{ stroke: '#3F6AA6' }}
                className={`w-5 h-5 transition-transform ${
                  open ? (isMobile ? 'rotate-90' : 'rotate-180') : ''
                }`}
              />
            </>
          )}
        </Menu.Button>
        <Transition>
          <Items isMobile={isMobile}>
            <div className='flex flex-col bg-white p-2 gap-1 border border-gray-300 rounded-md shadow-sm text-sm'>
              <Menu.Item key='exo'>
                <div
                  className={`hover:bg-gray-100 p-2 rounded-md cursor-pointer ${
                    companyDetails?.activeManagedTransShipper === null ? 'bg-gray-100' : ''
                  }`}
                  onClick={() => setShipper(null)}
                >
                  EXO Freight
                </div>
              </Menu.Item>
              {companyDetails.managedByCustomers?.map(customer => (
                <Menu.Item key={customer.id}>
                  <div
                    className={`hover:bg-gray-100 p-2 rounded-md cursor-pointer ${
                      companyDetails?.activeManagedTransShipper?.id === customer.id
                        ? 'bg-gray-100'
                        : ''
                    }`}
                    onClick={() => setShipper(customer)}
                  >
                    {customer.name}
                  </div>
                </Menu.Item>
              ))}
            </div>
          </Items>
        </Transition>
      </Menu>
    </div>
  )
}

const Items = tw(Menu.Items)<{ isMobile: boolean }>`
  absolute
  z-10
  ${({ isMobile }) => (isMobile ? 'top-full left-0 mt-3 w-full' : 'left-full top-0 ml-3 w-[200px]')}
`
