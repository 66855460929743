import { TextInput, TextInputProps } from '@components'
import { debounce, get } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import tw from 'tailwind-styled-components'

export type SimpleAutocompleteProps<T> = Omit<TextInputProps, 'onChange'> & {
  value?: string
  onChange?: (item: T) => void
  onSearch?: (search: string) => void
  data?: Array<T>
  titleField?: string
  filterField?: string
  fetchData?: (search: string) => Promise<Array<T>>
  fetchDebounce?: number
  renderAdditionalItemContent?: (item: T) => JSX.Element | null
  showItems?: number
  className?: string
}

export const SimpleAutocomplete = <T,>({
  value = '',
  onChange = () => {},
  onSearch = () => {},
  data,
  titleField = 'title',
  filterField,
  fetchData,
  fetchDebounce = 500,
  renderAdditionalItemContent,
  className = '',
  ...textInputProps
}: SimpleAutocompleteProps<T>) => {
  const [search, setSearch] = useState(value)
  const [hasFocus, setHasFocus] = useState(false)
  const [items, setItems] = useState<Array<T>>([])
  const [loadingItems, setLoadingItems] = useState(false)
  const container = useRef<HTMLDivElement>(null)
  /*
   * makes sure that the data is not fetched when the component is mounted, or when a selection is
   * clicked and the value prop is updated as a result
   **/
  const bypassNextSearch = useRef(true)

  const debouncedSearch = useCallback(
    debounce((search: string) => {
      if (!fetchData) {
        return
      }

      fetchData(search)
        .then((items: Array<T>) => setItems(items))
        .catch(() => setItems([]))
        .finally(() => setLoadingItems(false))
    }, fetchDebounce),
    [],
  )

  useEffect(() => {
    onSearch(search)

    if (!search) {
      setLoadingItems(false)
    }
  }, [search])

  useEffect(() => {
    if (value !== search) {
      setSearch(value)
    }
  }, [value])

  useEffect(() => {
    if (data) {
      setItems(data)
    }
  }, [data])

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (container.current?.contains(event.target as Node)) {
        setHasFocus(true)
      } else {
        setHasFocus(false)
      }
    }

    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleItemClick = (item: T) => {
    bypassNextSearch.current = true
    onChange(item)
    setItems([])
  }

  const handleChange = (search: string) => {
    if (search === value) return // Avoids re-fetching for the same search term
    setSearch(search)

    if (bypassNextSearch.current) {
      bypassNextSearch.current = false
      return
    }

    if (fetchData) {
      setLoadingItems(true)
      debouncedSearch(search)
    } else if (filterField && data) {
      setItems(
        data.filter(item => get(item, filterField).toLowerCase().includes(search.toLowerCase())),
      )
    }
  }

  return (
    <AutocompleteWrapper ref={container} className={className}>
      <TextInput
        {...{ ...textInputProps, loading: loadingItems }}
        useLoadingSpinner
        value={search}
        onChange={handleChange}
        onKeyDown={event => {
          bypassNextSearch.current = false
          textInputProps.onKeyDown?.(event)
        }}
      />
      {!!items.length && hasFocus && (
        <AutocompletePopover>
          {items.map((item, i) => (
            <AutocompleteItem key={i} onClick={() => handleItemClick(item)}>
              <div>{get(item, titleField, 'Title field not set') as string}</div>
              {renderAdditionalItemContent && <div>{renderAdditionalItemContent(item)}</div>}
            </AutocompleteItem>
          ))}
        </AutocompletePopover>
      )}
    </AutocompleteWrapper>
  )
}

const AutocompleteWrapper = tw.div`
  relative
`

const AutocompletePopover = tw.div`
  absolute
  m-[1px]
  top-100
  left-0
  right-0
  z-10
  bg-white
  border
  border-gray-300
  rounded-lg
  shadow
  overflow-hidden
  max-h-[200px]
  overflow-y-auto
`

const AutocompleteItem = tw.div`
  p-1
  cursor-pointer
  hover:bg-gray-100
`
