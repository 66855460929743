import { CatchError, formatAxiosErrorToPayload } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { APICompanyDetail, ManagedTransShipper, UIStateSingleProp } from '../common/types'
import { keysToCamel, keysToSnake } from '../common/utils'
import { updatePaymentType } from './paymentSlice'

type CompanyDetail = Partial<APICompanyDetail> & {
  bankingExpiry: number
  receivedTimestamp: number
  factoringFeePercentage: number
}

type CompanyState = {
  companyDetail: CompanyDetail
  loading: {
    fetchCompanyDetail: boolean
    updateUIState: boolean
  }
}

const initialState: CompanyState = {
  companyDetail: {
    bankingExpiry: 0,
    receivedTimestamp: 0,
    factoringFeePercentage: 1.5,
  },
  loading: {
    fetchCompanyDetail: false,
    updateUIState: false,
  },
}

export const getCompanyDetail = createAsyncThunk<APICompanyDetail>(
  'company/getCompanyDetail',
  async () => {
    const { data } = await api.get('/accounts/api/carrier/company-detail/')

    return keysToCamel(data)
  },
)

export const updateUIState = createAsyncThunk(
  'company/updateUIState',
  async (payload: UIStateSingleProp, { rejectWithValue }) => {
    try {
      const snake = keysToSnake(payload)
      const key = Object.keys(snake)[0]
      const value = snake[key]

      return api
        .put('/accounts/api/carrier/ui-state/', { key, value })
        .then(({ data }) => keysToCamel(data))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const setManagedTransShipper = createAsyncThunk(
  'company/setManagedTransShipper',
  async (company: ManagedTransShipper | null, { rejectWithValue }) =>
    api
      .post(
        'accounts/api/carrier/set-managed-trans-shipper/',
        keysToSnake({ shipperId: company?.id ?? null }),
      )
      .then(() => {})
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyDetail(state, { payload }: { payload: Partial<CompanyDetail> }) {
      state.companyDetail = {
        ...state.companyDetail,
        ...payload,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCompanyDetail.pending, state => {
        state.loading.fetchCompanyDetail = true
      })
      .addCase(getCompanyDetail.fulfilled, (state, action) => {
        state.loading.fetchCompanyDetail = false
        state.companyDetail = {
          ...state.companyDetail,
          ...action.payload,
          receivedTimestamp: Date.now(),
        }
      })
      .addCase(getCompanyDetail.rejected, state => {
        state.loading.fetchCompanyDetail = false
        toast.error('Error getting company detail')
      })
      .addCase(updateUIState.pending, state => {
        state.loading.updateUIState = true
      })
      .addCase(updateUIState.fulfilled, (state, action) => {
        state.loading.updateUIState = false
        state.companyDetail.uiState = action.payload
      })
      .addCase(updateUIState.rejected, state => {
        state.loading.updateUIState = false
      })
      .addCase(updatePaymentType.fulfilled, (state, action) => {
        state.companyDetail.paymentMethodType = action.meta.arg
      })
      .addCase(setManagedTransShipper.fulfilled, (state, action) => {
        state.companyDetail.activeManagedTransShipper = action.meta.arg
      })
      .addCase(setManagedTransShipper.rejected, () => {
        toast.error('Failed to configure shipper, please try again later')
      })
  },
})

export default companySlice.reducer
export const { setCompanyDetail } = companySlice.actions
