import { useCallback, useEffect, useRef, useState } from 'react'
import tw from 'tailwind-styled-components'

export type LegalAgreementProps = {
  templateContext?: Record<string, string>
  legalHTML: string
  onScrollBottom?: () => void
}

export const LegalAgreement = (props: LegalAgreementProps) => {
  const scrollableRef: React.LegacyRef<HTMLDivElement> | undefined = useRef(null)
  const [hasBeenAtBottom, setHasBeenAtBottom] = useState(false)
  const handleScroll = useCallback(
    (ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
      // within 1 "real" pixel of scrolling to the bottom
      const isAtBottom =
        ev.currentTarget.scrollHeight - ev.currentTarget.scrollTop - ev.currentTarget.clientHeight <
        1 / window.devicePixelRatio
      if (isAtBottom) {
        setHasBeenAtBottom(true)
      }
    },
    [setHasBeenAtBottom],
  )

  useEffect(() => {
    if (
      scrollableRef.current &&
      scrollableRef.current.scrollHeight <= scrollableRef.current.offsetHeight
    ) {
      setHasBeenAtBottom(true)
    }
  }, [])

  useEffect(() => {
    if (hasBeenAtBottom) {
      props?.onScrollBottom?.()
    }
  }, [hasBeenAtBottom, props.onScrollBottom])

  return (
    <SectionContainer>
      <Article>
        <div
          ref={scrollableRef}
          className='overflow-auto grow shrink'
          dangerouslySetInnerHTML={{ __html: props.legalHTML }}
          onScroll={handleScroll}
        />
      </Article>
    </SectionContainer>
  )
}

const SectionContainer = tw.section`
  overflow-auto
  grow
  self-center
  flex
  flex-col
  max-h-96
`

const Article = tw.article`
  border
  border-brand-light
  rounded-lg
  text-left
  overflow-hidden
  p-3
  flex
  flex-col
  max-h-full
`
