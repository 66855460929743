import tw from 'tailwind-styled-components'

import { getInitialsFromName } from '../../common/utils'
import { AvatarDropdownIcon } from '../Icons'

interface AvatarProps {
  name?: string
  className?: string
  dropdown?: boolean
}

// NOTE: This simply shows a circle with the initials of the user. Later we may
//       update this component to show a real photo.
export default function Avatar({ name, className, dropdown }: AvatarProps): JSX.Element {
  const initials = getInitialsFromName(name)

  const renderDropdown = () => (
    <DropdownContainer>
      <AvatarDropdownIcon />
    </DropdownContainer>
  )

  return (
    <Container className={className} data-testid='avatar'>
      {initials}
      {dropdown && renderDropdown()}
    </Container>
  )
}

const Container = tw.div`
  flex
  relative
  items-center
  justify-center
  font-bold
  w-full
  h-full
  rounded-full
  font-poppins
  uppercase
`

const DropdownContainer = tw.div`
  absolute
  -right-1
  bottom-0
  fill-brand-accent
  stroke-brand-dark
`
