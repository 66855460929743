import { LoaderSpinner } from '@components'
import { ReactNode } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

export const InfiniteScroller = ({
  children,
  length,
  count = 0,
  fetchData,
  targetId,
}: {
  children: ReactNode
  length: number
  count: number
  fetchData: any
  targetId: string
}) => (
  <InfiniteScroll
    className='md:!min-h-[79vh]'
    dataLength={length}
    hasMore={length !== count}
    loader={<LoaderSpinner />}
    next={fetchData}
    scrollableTarget={targetId}
  >
    {children}
  </InfiniteScroll>
)
