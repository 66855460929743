import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import tw from 'tailwind-styled-components'

import { Filters } from './Filters'

export const SearchArea = ({
  filters,
  setFilters,
  showFilters,
  setShowFilters,
  filtersList = [],
}: {
  filters: any
  setFilters: Function
  showFilters: boolean
  setShowFilters: Function
  filtersList?: Array<string>
}) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false)

  const outer = useRef() as React.MutableRefObject<HTMLInputElement>

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleClick = (e: { target: any }) => {
    if (outer.current && outer.current.contains(e.target)) return
    setShowMobileFilters(false)
  }

  const renderFiltersButtonText = () => (showMobileFilters ? 'hide filters' : 'show filters')

  const filtersProps = { filters, setFilters, filtersList }

  const renderDesktopView = () => (
    <SearchContainer>
      <div className='w-10/12'>
        <Row>
          <Filters type='primary' {...filtersProps} />
        </Row>
        {showFilters && (
          <Row className='mt-3'>
            <Filters type='secondary' {...filtersProps} />
          </Row>
        )}
      </div>
      <ShowMoreContainer>
        <ShowMoreText>more filters</ShowMoreText>
        <SwitchButton onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? 'hide' : 'show more'}
          {showFilters ? <ChevronUp /> : <ChevronDown />}
        </SwitchButton>
      </ShowMoreContainer>
    </SearchContainer>
  )

  const renderMobileView = () => (
    <MobileFilters ref={outer} $isOpen={showMobileFilters}>
      <div
        className='flex items-center justify-between p-4'
        onClick={() => setShowMobileFilters(!showMobileFilters)}
      >
        <MobileFiltersButton $isOpen={showMobileFilters}>
          {renderFiltersButtonText()}
        </MobileFiltersButton>
        {showMobileFilters ? (
          <ChevronUp className='text-error' />
        ) : (
          <ChevronDown className='text-link' />
        )}
      </div>
      {showMobileFilters && (
        <div className='mt-3 p-4 pt-0'>
          <Filters type='primary' {...filtersProps} />
          <Filters type='secondary' {...filtersProps} />
        </div>
      )}
    </MobileFilters>
  )

  return (
    <Container>
      <div className='hidden md:block'>{renderDesktopView()}</div>
      <div className='block md:hidden'>{renderMobileView()}</div>
    </Container>
  )
}

const Container = tw.div`
  w-full
`

const SearchContainer = tw.div`
  p-4
  pt-6
  justify-between
  hidden
  md:flex
`

const SwitchButton = tw.div`
  bg-brand-dark 
  rounded-full 
  py-1 
  text-white 
  w-32 
  font-semibold 
  text-xs 
  flex 
  items-center 
  justify-center 
  cursor-pointer 
  hover:opacity-80 
  transition-all
`

const ChevronUp = tw(ChevronUpIcon)`
  w-4 
  h-4 
  ml-2 
  text-brand-accent
`

const ChevronDown = tw(ChevronDownIcon)`
  w-4 
  h-4 
  ml-2 
  text-brand-accent
`

const Row = tw.div`
  grid 
  grid-cols-4
  gap-x-4
`

const ShowMoreText = tw.div`
  font-semibold 
  text-dark-gray 
  text-xs 
  mb-1
`

const ShowMoreContainer = tw.div`
  w-2/12 
  flex 
  flex-col 
  items-center 
  uppercase 
  whitespace-nowrap
`

const MobileFilters = tw.div<{ $isOpen: boolean }>`
  border
  border-border-gray
  rounded-lg
  m-4
  overflow-hidden
  transition-all
  ease-in-out
  duration-200
  bg-white
  z-10
  ${({ $isOpen }) => ($isOpen ? 'max-h-[65vh] overflow-auto' : 'max-h-[60px]')}
`

const MobileFiltersButton = tw.div<{ $isOpen: boolean }>`
  text-link
  font-semibold
  uppercase
  tracking-wider
  font-poppins
  text-sm
  cursor-pointer
  hover:opacity-70
  transition-all
  w-fit
  ${({ $isOpen }) => $isOpen && 'text-error'}
`
