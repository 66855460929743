import { Button } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import {
  setCitySearchQuery,
  setTrackingStep,
  trackCarrierLocation,
  TrackingStep,
} from '../../redux/trackingSlice'
import { CityAutocomplete } from '../Autocomplete'

export const ManualEntry = ({
  setModalVisible,
}: {
  setModalVisible: (visible: boolean) => void
}) => {
  const dispatch = useAppThunkDispatch()

  const citySearchQuery = useAppSelector(state => state.tracking.citySearchQuery)
  const locationTrackingLoads = useAppSelector(state => state.user.user?.locationTrackingLoads)

  const handleSaveLocation = () => {
    if (citySearchQuery) {
      if (locationTrackingLoads) {
        locationTrackingLoads.forEach(loadId => {
          dispatch(
            trackCarrierLocation({
              accuracy: 0,
              heading: null,
              latitude: citySearchQuery.latitude,
              longitude: citySearchQuery.longitude,
              speed: null,
              loadId,
            }),
          )
        })
      }
      dispatch(setCitySearchQuery(null))
      setModalVisible(false)
    }
  }

  return (
    <div className='p-8'>
      <h1 className='text-2xl font-bold'>📍 Enter Your Approximate Location</h1>
      <p className='mt-4'>
        Please let us know where you are currently located. An approximate location will help
        everyone involved in the shipment stay informed.
      </p>
      <div className='flex mt-6 border'>
        <CityAutocomplete
          required
          className='flex-grow'
          countries={['US']}
          placeholder='Search by city or zip code'
          value={citySearchQuery}
          onChange={citySearchQuery => dispatch(setCitySearchQuery(citySearchQuery))}
        />
      </div>
      <div className='flex items-center justify-end mt-2'>
        <button
          className='text-link'
          onClick={() => dispatch(setTrackingStep(TrackingStep.REQUESTING))}
        >
          Allow Automatic Tracking Instead
        </button>
      </div>
      <div className='flex justify-between mt-6 gap-4'>
        <Button
          fullWidth
          lg
          short
          className='w-full'
          type='secondary'
          onClick={() => setModalVisible(false)}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          lg
          className='w-full'
          disabled={!citySearchQuery}
          type='success'
          onClick={handleSaveLocation}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
