import { formatAxiosErrorToPayload } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link'
import { toast } from 'react-toastify'

import { keysToCamel } from '../common/utils'

type PlaidExternalAccount = {
  id: number
  name: string
  nickname: string
  accountNumberMask: string
}

type PlaidSliceState = {
  externalAccounts: PlaidExternalAccount[]
  plaidLinkToken: string | null
  loading: {
    externalAccounts: boolean
    plaidLinkToken: boolean
  }
}

const initialState: PlaidSliceState = {
  externalAccounts: [],
  plaidLinkToken: '',
  loading: {
    externalAccounts: false,
    plaidLinkToken: false,
  },
}

export const getPlaidLinkToken = createAsyncThunk('plaid/getPlaidLinkToken', async () =>
  axios.get('/finance/api/plaid-link-token/').then(({ data }) => data.token),
)

export const submitPlaidLinkSuccessCallback = createAsyncThunk(
  'plaid/submitPlaidLinkSuccessCallback',
  async (
    payload: { public_token: string; metadata: PlaidLinkOnSuccessMetadata },
    { rejectWithValue },
  ) =>
    await axios
      .post('/finance/api/plaid-link-success-callback/', payload)
      .then(({ data }) => keysToCamel(data) as PlaidExternalAccount[])
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const getPlaidExternalAccounts = createAsyncThunk(
  'plaid/getPlaidExternalAccounts',
  async (_, { rejectWithValue }) =>
    await axios
      .get('/finance/api/plaid-external-accounts/')
      .then(({ data }) => keysToCamel(data) as PlaidExternalAccount[])
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

const plaidSlice = createSlice({
  name: 'plaid',
  initialState,
  reducers: {
    resetPlaidLinkToken: state => {
      state.plaidLinkToken = null
    },
  },
  extraReducers(builder) {
    builder.addCase(getPlaidLinkToken.pending, state => {
      state.loading.plaidLinkToken = true
    })
    builder.addCase(getPlaidLinkToken.fulfilled, (state, { payload }) => {
      state.plaidLinkToken = payload
      state.loading.plaidLinkToken = false
    })
    builder.addCase(getPlaidLinkToken.rejected, state => {
      toast.error('Failed to initialize Plaid Link')
      state.loading.plaidLinkToken = false
    })
    builder.addCase(submitPlaidLinkSuccessCallback.pending, state => {
      state.loading.externalAccounts = true
    })
    builder.addCase(submitPlaidLinkSuccessCallback.fulfilled, (state, { payload }) => {
      state.externalAccounts = payload
      state.loading.externalAccounts = false
    })
    builder.addCase(submitPlaidLinkSuccessCallback.rejected, state => {
      toast.error('Failed to configure Plaid accounts')
      state.loading.externalAccounts = false
    })
    builder.addCase(getPlaidExternalAccounts.pending, state => {
      state.loading.externalAccounts = true
    })
    builder.addCase(getPlaidExternalAccounts.fulfilled, (state, { payload }) => {
      state.externalAccounts = payload
      state.loading.externalAccounts = false
    })
    builder.addCase(getPlaidExternalAccounts.rejected, state => {
      toast.error('Failed to retrieve Plaid accounts')
      state.loading.externalAccounts = false
    })
  },
})

export default plaidSlice.reducer
export const { resetPlaidLinkToken } = plaidSlice.actions
