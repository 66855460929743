import { Status } from '@components'

import { DetailSectionRow } from '../../DetailSectionRow'

export const DetailSectionRowPill = ({
  title,
  children,
  className,
}: {
  title: string
  children: string
  className?: string
}) => (
  <DetailSectionRow className={className} title={title}>
    <Status status={children} />
  </DetailSectionRow>
)
