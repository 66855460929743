import {
  CarrierLocationTrackingEvent,
  dateDiff,
  durationToReadableTime,
  LatLng,
  useDeepDependencies,
} from '@common'
import { AlphaModal } from '@components'
import { useEffect, useMemo, useState } from 'react'

import { LoadDetail } from '../../common/types'
import { getLoadDetail } from '../../redux/myLoadsSlice'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import {
  getCarrierLocationHistory,
  setTrackingStep,
  TrackingStep,
  TrackingType,
} from '../../redux/trackingSlice'
import { CarrierDelayed } from './CarrierDelayed'
import { ManualEntry } from './ManualEntry'
import { Reminder } from './Reminder'
import { RequestLocation } from './RequestLocation'
import { TrackingThankYou } from './TrackingThankYou'
import { useGeoTracking } from './utils/geolocation'

const maxUpdateInterval = 20 * 60 * 1000 // Maximum interval between location updates in milliseconds (20 minutes)

const TrackingModalBody = ({
  destination,
  messageBody,
  messageHeader,
  mostRecentLocationEvent,
  needsTrackingUpdate,
  origin,
  selectedLoad,
  setModalVisible,
}: {
  destination?: LatLng
  messageBody?: JSX.Element | null
  messageHeader?: string | null
  mostRecentLocationEvent?: CarrierLocationTrackingEvent | null
  needsTrackingUpdate?: boolean
  origin?: LatLng
  selectedLoad?: LoadDetail
  setModalVisible: (visible: boolean) => void
}) => {
  const trackingStep = useAppSelector(state => state.tracking.trackingStep)

  switch (trackingStep) {
    case TrackingStep.SHOW_REMINDER:
      return (
        <Reminder
          {...{ destination, selectedLoad, messageBody, messageHeader, origin, setModalVisible }}
        />
      )
    case TrackingStep.DELAYED:
      return <CarrierDelayed {...{ needsTrackingUpdate, setModalVisible }} />
    case TrackingStep.REQUESTING:
    case TrackingStep.ALLOW_CLICKED:
      return <RequestLocation mostRecentLocationEvent={mostRecentLocationEvent} />
    case TrackingStep.TRACKING:
      return <TrackingThankYou {...{ needsTrackingUpdate, setModalVisible }} />
    case TrackingStep.TRACKING_USER_ENTRY:
      return <ManualEntry {...{ selectedLoad, setModalVisible }} />
    default:
      return null
  }
}

export const TrackingOverlay = () => {
  const dispatch = useAppThunkDispatch()

  const [isModalVisible, setModalVisible] = useState(false)
  const locationEvents = useAppSelector(state => state.tracking.locationEvents)
  const locationEventsLoading = useAppSelector(state => state.tracking.locationEventsLoading)
  const locationTrackingLoads = useAppSelector(state => state.user.user?.locationTrackingLoads)
  const selectedLoad = useAppSelector(state => state.myLoads.selectedLoad)
  const trackingType = useAppSelector(state => state.tracking.trackingType)
  const user = useAppSelector(state => state.user.user)

  const alertState = useMemo(
    () => {
      const hasLoadToTrack =
        locationTrackingLoads && selectedLoad && locationTrackingLoads.includes(selectedLoad.id)
      if (locationEventsLoading || !hasLoadToTrack) return {}

      // Check if the user needs a tracking update and if they have given location recently
      const mostRecentLocationEvent =
        locationEvents.length === 0
          ? null
          : locationEvents.reduce((prev, current) =>
              new Date(prev.timestamp) > new Date(current.timestamp) ? prev : current,
            )
      const now = new Date()
      const { messageHeader, messageBody } = getReminder(selectedLoad, now)
      const needsTrackingUpdate =
        !mostRecentLocationEvent ||
        dateDiff(now, new Date(mostRecentLocationEvent.timestamp)) > maxUpdateInterval
      const userSharedLocationRecently = mostRecentLocationEvent && mostRecentLocationEvent.accuracy

      // Determine the tracking step based on the tracking type and the need for a tracking update
      let trackingStep = TrackingStep.TRACKING
      if (trackingType === TrackingType.NATIVELY_BACKGROUND) {
        trackingStep = TrackingStep.TRACKING
      } else if (needsTrackingUpdate) {
        if (messageHeader && messageBody) {
          trackingStep = TrackingStep.SHOW_REMINDER
        } else {
          trackingStep = TrackingStep.REQUESTING
        }
      } else {
        if (userSharedLocationRecently) {
          trackingStep = TrackingStep.TRACKING
        } else {
          trackingStep = TrackingStep.REQUESTING
        }
      }

      dispatch(setTrackingStep(trackingStep))
      if (trackingStep !== TrackingStep.TRACKING) setModalVisible(true)

      const origin: LatLng = {
        lat: selectedLoad?.shipper?.latitude ?? 0,
        lng: selectedLoad?.shipper?.longitude ?? 0,
      }
      const destination: LatLng = {
        lat: selectedLoad?.consignee?.latitude ?? 0,
        lng: selectedLoad?.consignee?.longitude ?? 0,
      }

      return {
        destination,
        messageBody,
        messageHeader,
        mostRecentLocationEvent,
        needsTrackingUpdate,
        now,
        origin,
        selectedLoad,
        trackingStep,
        trackingType,
      }
    },
    useDeepDependencies([
      locationEvents,
      locationEventsLoading,
      locationTrackingLoads,
      selectedLoad,
      trackingType,
    ]),
  )

  useEffect(() => {
    if (!selectedLoad && locationTrackingLoads && locationTrackingLoads.length > 0) {
      dispatch(getLoadDetail(locationTrackingLoads[0]))
    }
  }, [locationTrackingLoads, selectedLoad])

  useEffect(() => {
    if (user?.id) dispatch(getCarrierLocationHistory())
  }, [user?.id])

  useGeoTracking()

  return (
    <AlphaModal
      closeOnOverlayClick={false}
      isVisible={isModalVisible}
      setVisible={setModalVisible}
      showFooter={false}
      showHeader={false}
    >
      <TrackingModalBody setModalVisible={setModalVisible} {...alertState} />
    </AlphaModal>
  )
}

// Helper function to get the reminder message based on the load details and the current time
const getReminder = (load: LoadDetail, now: Date = new Date()) => {
  const deliveryEarly = new Date(`${load.deliveryDate}T${load.deliveryEarlyTime}`)
  const pickupEarly = new Date(`${load.pickupDate}T${load.pickupEarlyTime}`)

  const pickupDiff = dateDiff(pickupEarly, now)
  const deliveryDiff = dateDiff(deliveryEarly, now)

  if (pickupDiff > 0 && pickupDiff <= 2 * 60 * 60 * 1000) {
    return {
      messageHeader: '🚚 Pickup Reminder',
      messageBody: (
        <>
          Your pickup at <b>{load.shipper.address}</b> is scheduled in{' '}
          <b>{durationToReadableTime(pickupDiff)}</b>.
        </>
      ),
    }
  } else if (deliveryDiff > 0 && deliveryDiff <= 2 * 60 * 60 * 1000) {
    return {
      messageHeader: '🚚 Delivery Reminder',
      messageBody: (
        <>
          Your delivery at <b>{load.consignee.address}</b> is scheduled in{' '}
          <b>{durationToReadableTime(deliveryDiff)}</b>.
        </>
      ),
    }
  } else {
    return {
      messageHeader: null,
      messageBody: null,
    }
  }
}
