import { Button, TextInput } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { setOnboardingDetails } from '../../../redux/userSlice'
import { PaymentMethodNickname } from './PaymentMethodNickname'

export const CheckPaymentDetails = ({
  setPaymentInformation,
}: {
  setPaymentInformation: (obj: any) => void
}) => {
  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)
  const paymentInformation = useAppSelector(
    state => state.user.onboarding.onboardingDetails.paymentInformation,
  )
  const accountReceivableInformation = useAppSelector(
    state => state.user.onboarding.onboardingDetails.accountReceivableInformation,
  )
  const dispatch = useAppThunkDispatch()
  return (
    <>
      <PaymentMethodNickname isACH={false} setPaymentInformation={setPaymentInformation} />
      <Button
        className='mb-4'
        label='copy from business address'
        type='warn'
        onClick={() =>
          dispatch(
            setOnboardingDetails({
              ...onboardingDetails,
              paymentInformation: {
                ...paymentInformation,
                businessName: accountReceivableInformation?.entityName,
                address: accountReceivableInformation?.address,
                city: accountReceivableInformation?.city,
                state: accountReceivableInformation?.state,
                postalCode: accountReceivableInformation?.postalCode,
              },
            }),
          )
        }
      />
      <TextInput
        required
        className='mb-4'
        label={!accountReceivableInformation?.entityType ? 'Entity name' : 'Legal name'}
        value={paymentInformation?.businessName}
        placeholder={
          !accountReceivableInformation?.entityType ? 'Name of the company' : 'Your name'
        }
        onChange={(businessName: string) => setPaymentInformation({ businessName })}
      />
      <TextInput
        required
        className='mb-4'
        label='Address'
        placeholder='e.g. 1 Small Rd'
        value={paymentInformation?.address}
        onChange={(address: string) => setPaymentInformation({ address })}
      />
      <TextInput
        required
        className='mb-4'
        label='City'
        placeholder='e.g. New York'
        value={paymentInformation?.city}
        onChange={(city: string) => setPaymentInformation({ city })}
      />
      <div className='flex flex-col gap-2 md:flex-row justify-between md:items-center w-full'>
        <TextInput
          required
          className='w-full md:w-1/2 mb-4 md:mb-2'
          label='State/Province (2 letter abbreviation)'
          mask='aa'
          placeholder='e.g. NY'
          value={paymentInformation?.state}
          onChange={(state: string) => setPaymentInformation({ state: state.toUpperCase() })}
        />
        <TextInput
          required
          className='w-full md:w-1/2 mb-4 md:mb-2'
          label='Zip/Postal Code'
          placeholder='e.g. 00000'
          value={paymentInformation?.postalCode}
          onChange={(postalCode: string) => setPaymentInformation({ postalCode })}
        />
      </div>
    </>
  )
}
