import { DateInput, Select, TextInput, Toggle } from '@components'
import { debounce } from 'lodash-es'
import { useCallback } from 'react'

import { equipmentTypeChoices } from '../../../common/constants'
import { trackEvent } from '../../../common/tracking'
import { CityAutocomplete } from '../../Autocomplete'

export const Filters = ({
  filters,
  setFilters,
  filtersList = [],
  type,
}: {
  filters: any
  setFilters: Function
  filtersList: Array<any>
  type: string
}) => {
  const onChangeLoadId = (loadId: string) => {
    trackEvent('Changing id search field', {
      searchedLoadId: loadId,
    })
    setFilters({ ...filters, loadId })
  }

  const onChangeTypeInvoice = (invoice: string) => setFilters({ ...filters, invoice })

  const onChangeTypeCheck = (check: string) => setFilters({ ...filters, check })

  const changeTextDebouncer = (functionToDebounce: (input: any) => any) =>
    useCallback(debounce(functionToDebounce, 1750), [])

  const milesChoices = [50, 100, 150, 200, 250, 300, 350, 400, 450, 500].map(
    item => `${item} miles`,
  )

  const props = { className: 'mb-2 md:mb-0 w-full', sm: true }

  const setLocation = (location: any, field: string) => {
    setFilters({
      ...filters,
      [field]: {
        city: location?.city ?? '',
        state: location?.state ?? '',
        title: location?.title ?? '',
      },
    })
  }

  const renderFilters = (type: string) => {
    const list = type === 'primary' ? filtersList.slice(0, 4) : filtersList.slice(4)

    return list.map((type: string) => filterItems.find((x: any) => x.type === type)?.component)
  }

  const filterItems = [
    {
      type: 'equipment',
      component: (
        <Select
          key='equipment'
          extractValue
          choices={equipmentTypeChoices}
          field='name'
          placeholder='Select Equipment'
          value={filters.equipmentType}
          valueField='id'
          onChange={(equipmentType: any) => {
            trackEvent('Changing equipment search field', {
              equipmentType: equipmentType,
            })
            setFilters({ ...filters, equipmentType })
          }}
          {...props}
        />
      ),
    },
    {
      type: 'pickupDate',
      component: (
        <DateInput
          key='pickupDate'
          range
          showMonthDropdown
          showYearDropdown
          placeholder='Select Pickup Date(s)'
          size='sm'
          value={filters.dateRange}
          onChange={(dateRange: any) => {
            const dateRangeReadable = { from: dateRange[0]?.toJSON(), to: dateRange[1]?.toJSON() }
            trackEvent('Changing date search field', {
              dateRange: dateRangeReadable,
            })
            setFilters({ ...filters, dateRange })
          }}
          {...props}
        />
      ),
    },
    {
      type: 'paidDate',
      component: (
        <DateInput
          key='paidDate'
          range
          showMonthDropdown
          showYearDropdown
          placeholder='Select Paid Date(s)'
          size='sm'
          value={filters.dateRange}
          onChange={(dateRange: any) => {
            const dateRangeReadable = { from: dateRange[0]?.toJSON(), to: dateRange[1]?.toJSON() }
            trackEvent('Changing paid date search field', {
              dateRange: dateRangeReadable,
            })
            setFilters({ ...filters, dateRange })
          }}
          {...props}
        />
      ),
    },
    {
      type: 'origin',
      component: (
        <CityAutocomplete
          key='origin'
          placeholder='Type Origin'
          value={filters?.origin}
          onChange={location => {
            if (location) {
              trackEvent('Changing origin search field', {
                origin: location,
              })
            }
            setLocation(location, 'origin')
          }}
          {...props}
        />
      ),
    },
    {
      type: 'destination',
      component: (
        <CityAutocomplete
          key='destination'
          placeholder='Type Destination'
          value={filters?.destination}
          onChange={location => {
            if (location) {
              trackEvent('Changing destination search field', {
                destination: location,
              })
            }
            setLocation(location, 'destination')
          }}
          {...props}
        />
      ),
    },
    {
      type: 'deadHeadOriginMiles',
      component: (
        <Select
          key='deadHeadOriginMiles'
          choices={milesChoices}
          placeholder='DH-O'
          value={filters.deadHeadOriginMiles}
          onChange={(deadHeadOriginMiles: string) => {
            trackEvent('Changing deadHeadOriginMiles search field', {
              deadHeadOriginMiles: deadHeadOriginMiles,
            })
            setFilters({ ...filters, deadHeadOriginMiles })
          }}
          {...props}
        />
      ),
    },
    {
      type: 'deadHeadDestinationMiles',
      component: (
        <Select
          key='deadHeadDestinationMiles'
          choices={milesChoices}
          placeholder='DH-D'
          value={filters.deadHeadDestinationMiles}
          onChange={(deadHeadDestinationMiles: string) => {
            trackEvent('Changing deadHeadDestinationMiles search field', {
              deadHeadDestinationMiles: deadHeadDestinationMiles,
            })
            setFilters({ ...filters, deadHeadDestinationMiles })
          }}
          {...props}
        />
      ),
    },
    {
      type: 'id',
      component: (
        <TextInput
          key='id'
          placeholder='Type Load ID'
          type='number'
          value={filters.loadId}
          onChange={changeTextDebouncer(onChangeLoadId)}
          {...props}
        />
      ),
    },
    {
      type: 'bookable',
      component: (
        <div key='bookable' className='flex items-center mt-4 md:mt-0'>
          <div className='mr-2'>Bookable</div>
          <Toggle
            value={filters.bookable}
            onChange={() => {
              trackEvent('Toggling bookable field in search')
              setFilters({ ...filters, bookable: !filters.bookable || null })
            }}
          />
        </div>
      ),
    },
    {
      type: 'invoice',
      component: (
        <TextInput
          key='invoice'
          placeholder='Type Invoice #'
          value={filters.invoice}
          onChange={changeTextDebouncer(onChangeTypeInvoice)}
          {...props}
        />
      ),
    },
    {
      type: 'check',
      component: (
        <TextInput
          key='check'
          placeholder='Type Check #'
          type='number'
          value={filters.check}
          onChange={changeTextDebouncer(onChangeTypeCheck)}
          {...props}
        />
      ),
    },
  ]

  return <>{renderFilters(type)}</>
}
