import { dateDiff } from '@common'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import {
  setGeoLocationError,
  setTrackingType,
  trackCarrierLocation,
  TrackingStep,
  TrackingType,
} from '../../../redux/trackingSlice'

const geoLocationErrorMessages = {
  1: 'You denied our request for your location.', // PERMISSION_DENIED
  2: 'Your location position information is unavailable.', // POSITION_UNAVAILABLE
  3: 'The request to get your location timed out. Please try again.', // TIMEOUT
}

// NATIVELY GEOLOCATION CONFIG
const minAccuracy = 50 // in meters
const accuracyType = 'NearestTenMeters' // Affects location accuracy and battery life. BestForNavigation,Best,NearestTenMeters,HundredMeters,Kilometer,ThreeKilometers. More details here https://developer.apple.com/documentation/corelocation/cllocationaccuracy
const priority_android = 'BALANCED' // "BALANCED" or "HIGH" [Android only]
const interval = 30_000 // in milliseconds

// BROWSER GEOLOCATION CONFIG
const watchOptions: PositionOptions = {
  enableHighAccuracy: false, // Request lower accuracy to save battery
  maximumAge: 60_000, // Accept a cached position within 1 minute old
  timeout: 15_000, // Try to get a new location within 15 seconds
}

const maxUpdateInterval = 60_000 // Maximum interval between location updates in milliseconds

type NativelyCallback = {
  status: 'SUCCESS' | 'FAILURE'
}

/**
 * Custom hook for tracking the user's geolocation.
 *
 * @returns null
 */
export const useGeoTracking = () => {
  const locationService = useRef<NativelyLocation | null>(null)
  const [watchId, setWatchId] = useState<number | null>(null)

  const dispatch = useAppThunkDispatch()

  const locationEvents = useAppSelector(state => state.tracking.locationEvents)
  const locationTrackingLoads = useAppSelector(state => state.user.user?.locationTrackingLoads)
  const trackingStep = useAppSelector(state => state.tracking.trackingStep)
  const trackingType = useAppSelector(state => state.tracking.trackingType)
  const userId = useAppSelector(state => state.user.user?.id)

  const lastUpdate = useMemo(() => {
    if (locationEvents.length === 0) return 0
    const lastEvent = locationEvents[locationEvents.length - 1]
    return new Date(lastEvent.timestamp).getTime()
  }, [locationEvents])

  const userRequestedTracking = useMemo(
    () => [TrackingStep.ALLOW_CLICKED, TrackingStep.TRACKING].includes(trackingStep),
    [trackingStep],
  )

  const stopBrowserTracking = () => {
    if (watchId !== null) {
      navigator.geolocation.clearWatch(watchId)
      setWatchId(null)
    }
  }

  const watchCallback = useCallback(
    (position: GeolocationPosition) => {
      const { accuracy, heading, latitude, longitude, speed } = position.coords
      dispatch(setGeoLocationError(null))
      if (locationTrackingLoads && dateDiff(new Date(), new Date(lastUpdate)) > maxUpdateInterval) {
        locationTrackingLoads.forEach(loadId => {
          dispatch(
            trackCarrierLocation({
              accuracy,
              heading,
              latitude,
              longitude,
              speed,
              loadId,
            }),
          )
        })
      }
    },
    [dispatch, lastUpdate, locationTrackingLoads],
  )

  const startBrowserTracking = () => {
    if (navigator.geolocation) {
      const id = navigator.geolocation.watchPosition(
        watchCallback,
        error => {
          dispatch(
            setGeoLocationError(
              geoLocationErrorMessages[error.code as keyof typeof geoLocationErrorMessages],
            ),
          )
        },
        watchOptions,
      )
      setWatchId(id)
    } else {
      dispatch(setGeoLocationError('Geolocation is not supported by this browser.'))
    }
  }

  const restartNativelyTracking = (
    locationService: NativelyLocation,
    eligibleLoads: number[],
    userId: number,
  ) => {
    locationService.stopBackground((resp: NativelyCallback) => {
      if (resp.status === 'SUCCESS') {
        startNativelyTracking(locationService, eligibleLoads, userId)
      }
    })
  }

  const startNativelyTracking = (
    locationService: NativelyLocation,
    eligibleLoads: number[],
    userId: number,
  ) => {
    if (!userId || eligibleLoads.length === 0) return
    const identifier = `${userId}-${eligibleLoads.join('-')}`
    locationService.startBackground(
      interval,
      minAccuracy,
      accuracyType,
      priority_android,
      identifier,
      (resp: NativelyCallback) => {
        if (resp.status === 'SUCCESS') {
          dispatch(setTrackingType(TrackingType.NATIVELY_BACKGROUND))
        } else {
          // Failed to start Natively background tracking, fallback to browser foreground tracking
          dispatch(setTrackingType(TrackingType.BROWSER_FOREGROUND))
        }
      },
    )
  }

  useEffect(() => {
    if (!userRequestedTracking) return
    if (trackingType === TrackingType.NOT_INITIALIZED) {
      const usingMobileApp = Boolean(window.natively.isAndroidApp || window.natively.isIOSApp)
      if (usingMobileApp) {
        locationService.current = new NativelyLocation()
      } else {
        dispatch(setTrackingType(TrackingType.BROWSER_FOREGROUND))
      }
    } else if (trackingType === TrackingType.BROWSER_FOREGROUND && !watchId) {
      startBrowserTracking()
      return () => stopBrowserTracking()
    }
  }, [userRequestedTracking, trackingType])

  useEffect(() => {
    if (!userId || !locationService.current || !userRequestedTracking) return
    const eligibleLoads = locationTrackingLoads ?? []

    locationService.current.statusBackground((resp: { status: boolean }) => {
      if (resp.status) {
        // If tracking is already running, restart as needed
        restartNativelyTracking(locationService.current as NativelyLocation, eligibleLoads, userId)
      } else if (eligibleLoads.length > 0) {
        // Otherwise, start tracking if a load is selected
        startNativelyTracking(locationService.current as NativelyLocation, eligibleLoads, userId)
      }
    })
  }, [locationService.current, userRequestedTracking, locationTrackingLoads, userId])

  return null
}
