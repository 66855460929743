import { Button } from '@components'
import { useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { formatCurrency } from '../../../common/utils'
import { setSelectedPaymentMethod, submitLoadFactoringRequest } from '../../../redux/factoringSlice'
import { getPaymentMethods, selectFactoringPaymentMethods } from '../../../redux/paymentSlice'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { LoadFactoringForm, PaymentMethods, PriceBreakdown } from '../components'

export const FactoringLoadRequest = () => {
  const [isFormValid, setFormValid] = useState(false)
  const [isViewingBreakdown, setViewingBreakdown] = useState(false)

  const dispatch = useAppThunkDispatch()
  const paymentMethods = useAppSelector(selectFactoringPaymentMethods)
  const selectedPaymentMethod = useAppSelector(state => state.factoring.selectedPaymentMethod)
  const newFactoringRequest = useAppSelector(state => state.factoring.newFactoringRequest)
  const deductions = useAppSelector(state => state.factoring.deductions)
  const loading = useAppSelector(state => state.factoring.isCreatingLoadFactoringRequest)

  const isButtonDisabled = useMemo(
    () => !isFormValid || !newFactoringRequest.files.length || !selectedPaymentMethod?.id,
    [isFormValid, newFactoringRequest.files.length],
  )

  const totalAmount = useMemo(
    () => (newFactoringRequest.totalAmount ? deductions.totalAmountAfterDeductions : 0),
    [newFactoringRequest.totalAmount, deductions],
  )

  useEffect(() => {
    dispatch(getPaymentMethods())
  }, [])

  useEffect(() => {
    if (!newFactoringRequest.totalAmount) setViewingBreakdown(false)
  }, [newFactoringRequest.totalAmount])

  useEffect(() => {
    const primaryPaymentMethod = paymentMethods[0] || null
    dispatch(setSelectedPaymentMethod(primaryPaymentMethod))
  }, [paymentMethods])

  const submitRequest = () => dispatch(submitLoadFactoringRequest())

  return (
    <>
      <LoadFactoringForm setFormValid={setFormValid} />
      <PaymentMethods />
      <PaymentLabel $disabled={!newFactoringRequest.totalAmount}>Your Payment</PaymentLabel>
      <PaymentBanner $disabled={!newFactoringRequest.totalAmount}>
        <BannerText>
          <span className='text-4xl -mt-6'>{totalAmount < 0 && '-'}$</span>
          {formatCurrency(totalAmount, false)}
        </BannerText>
        <span className='text-xs mt-4'>
          Final payment may differ based on outstanding recoveries
        </span>
      </PaymentBanner>
      <PriceBreakdown
        isViewingBreakdown={isViewingBreakdown}
        setViewingBreakdown={setViewingBreakdown}
      />
      <Button
        fullWidth
        lg
        className='mt-6 mb-6 w-full'
        disabled={isButtonDisabled}
        loading={loading}
        type='success'
        onClick={submitRequest}
      >
        Request Payment
      </Button>
    </>
  )
}

const PaymentLabel = tw.div<{ $disabled: boolean }>`
  text-xs
  mt-10
  font-semibold
  ${({ $disabled }) => $disabled && 'text-border-gray'}
  mr-auto
`

const PaymentBanner = tw.div<{ $disabled: boolean }>`
  w-full
  bg-lighter-blue
  py-5
  mt-1
  rounded-lg
  text-center
  ${({ $disabled }) => $disabled && 'bg-lighter-gray text-border-gray'}
`

const BannerText = tw.div`
  font-bold
  text-6xl
  flex
  items-center
  justify-center
`
