import { Button, FileInput, Form, TextInput } from '@components'
import { BuildingLibraryIcon } from '@heroicons/react/24/outline'
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/solid'
import { debounce } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { randomString } from '../../../common/utils'
import {
  getDeductions,
  NewFactoringRequest,
  setNewFactoringRequest,
  setViewingAccounts,
} from '../../../redux/factoringSlice'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { ExpandButton } from './ExpandButton'

export const LoadFactoringForm = ({
  setFormValid,
}: {
  setFormValid: (isValid: boolean) => void
}) => {
  const navigate = useNavigate()

  const dispatch = useAppThunkDispatch()

  const newFactoringRequest = useAppSelector(state => state.factoring.newFactoringRequest)
  const isViewingAccounts = useAppSelector(state => state.factoring.isViewingAccounts)
  const selectedPaymentMethod = useAppSelector(state => state.factoring.selectedPaymentMethod)
  const activeTab = useAppSelector(state => state.factoring.factoringRequestTab)
  const paymentMethodsLoading = useAppSelector(state => state.payment.paymentMethodsLoading)

  const loadNumberLabel = useMemo(
    () => (activeTab ? 'Shipper Load Number' : 'Broker Load Number'),
    [activeTab],
  )

  useEffect(() => {
    const calculateDeductions = debounce(
      () => dispatch(getDeductions(newFactoringRequest.totalAmount || '0')),
      1500,
    )

    calculateDeductions()

    return () => {
      calculateDeductions.cancel()
    }
  }, [newFactoringRequest.totalAmount, selectedPaymentMethod])

  const handleSetData = (extraData: Partial<NewFactoringRequest>) =>
    dispatch(setNewFactoringRequest({ ...newFactoringRequest, ...extraData }))

  return (
    <Form className='w-full' setFormValid={setFormValid}>
      <FileLabel>
        BOL & Rate Con <span className='text-error'>*</span>
      </FileLabel>
      <>
        {newFactoringRequest.files.map(file => (
          <div key={file.id} className='mb-6 flex'>
            <DocumentIcon className='text-link stroke-2 w-5' />
            <span className='text-link font-semibold ml-2'>{file.file.name}</span>
            <DeleteIcon
              onClick={() =>
                handleSetData({
                  files: newFactoringRequest.files.filter(
                    requestFile => requestFile.id !== file.id,
                  ),
                })
              }
            />
          </div>
        ))}
      </>
      <FileInput
        mimeType='application/pdf,image/png,image/jpg,image/jpeg'
        onChange={file =>
          handleSetData({ files: [...newFactoringRequest.files, { file, id: randomString() }] })
        }
      >
        <Button preIcon='upload' type='link'>
          Upload File
        </Button>
      </FileInput>
      <FormWrap>
        <TextInput
          required
          sm
          label={loadNumberLabel}
          value={newFactoringRequest.loadNumber}
          onChange={loadNumber => handleSetData({ loadNumber })}
        />
        <TextInput
          sm
          label='Carrier Reference Number'
          value={newFactoringRequest.refNumber}
          onChange={refNumber => handleSetData({ refNumber })}
        />
        <TextInput
          required
          sm
          label='Total Amount'
          type='currency'
          value={newFactoringRequest.totalAmount}
          onBlur={() => dispatch(getDeductions(newFactoringRequest.totalAmount || '0'))}
          onChange={totalAmount => handleSetData({ totalAmount })}
        />
        {selectedPaymentMethod && (
          <div className='mt-6'>
            <PrimaryPaymentMethod>
              <div className='flex items-center'>
                <BuildingLibraryIcon className='mr-2 w-5 stroke-2' />
                <span className='font-semibold'>{selectedPaymentMethod.name}</span>
              </div>
              {selectedPaymentMethod.paymentMethod === 'ACH' && (
                <span className='text-dark-gray'>...{selectedPaymentMethod.accountNumber}</span>
              )}
            </PrimaryPaymentMethod>
            {!isViewingAccounts && (
              <ExpandButton onClick={() => dispatch(setViewingAccounts(true))}>
                View Accounts
              </ExpandButton>
            )}
          </div>
        )}
        {!selectedPaymentMethod && !isViewingAccounts && !paymentMethodsLoading && (
          <div className='mt-5 flex justify-end'>
            <Button onClick={() => navigate('/payment-methods')}>Add ACH Account</Button>
          </div>
        )}
      </FormWrap>
    </Form>
  )
}

const FormWrap = tw.div`
  grid
  grid-cols-2
  gap-4
  mt-4
`

const FileLabel = tw.div`
  text-dark-gray
  text-xs
  font-poppins
  mb-2
`

const PrimaryPaymentMethod = tw.div`
  flex
  justify-between
  items-center
  text-sm
`

const DeleteIcon = tw(TrashIcon)`
  text-error
  cursor-pointer
  transition-all
  hover:opacity-80
  w-5
  ml-6
`
