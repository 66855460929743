import {
  CatchError,
  CityLocation,
  formatAxiosErrorToPayload,
  getErrorString,
  LatitudeLongitude,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { trackEvent } from '../common/tracking'
import { Filters, FuelReservation, FuelStation, NewReservation, RootState } from '../common/types'
import { keysToCamel } from '../common/utils'

type CurrentLocation = {
  latitude: number | null
  longitude: number | null
}

export type FuelState = {
  count: number
  currentLocation: CurrentLocation
  filters: Filters
  lastFetchedCoords: LatitudeLongitude
  loading: boolean
  newReservation: NewReservation
  offset: number
  reservations: FuelReservation[]
  selectedReservation: FuelReservation | null
  showReservations: boolean
  size: number
  stations: FuelStation[]
  searchQuery: CityLocation | null
  mobileView: 'map' | 'cards' // tablet and mobile view can render a map or a list of card
}

const initialState: FuelState = {
  count: 0,
  currentLocation: {
    latitude: null,
    longitude: null,
  },
  filters: initialFilters,
  lastFetchedCoords: {
    latitude: 0,
    longitude: 0,
  },
  loading: false,
  newReservation: {
    gallons: 0,
    purchaseType: 'gallons',
    station: null,
    step: 'search',
    totalCost: 0,
  },
  offset: 0,
  reservations: [],
  selectedReservation: null,
  showReservations: false,
  size: 20,
  stations: [],
  searchQuery: null,
  mobileView: 'map',
}

export const getStationsByLatLon = createAsyncThunk(
  'fuel/getStationsByLatLon',
  async ({ latitude, longitude }: { latitude: number; longitude: number }) =>
    api
      .get('/fuel/location/search/', {
        params: {
          radius: 200,
          lat: latitude,
          lon: longitude,
        },
      })
      .then(({ data }) => keysToCamel(data) as FuelStation[]),
)

export const getStationsByPostalCode = createAsyncThunk(
  'fuel/getStationsByPostalCode',
  async ({ postalCode }: { postalCode: string }) =>
    api
      .get('/fuel/location/search/', {
        params: {
          radius: 200,
          postal_code: postalCode,
        },
      })
      .then(({ data }) => keysToCamel(data) as FuelStation[]),
)

export const getStationsByCityState = createAsyncThunk(
  'fuel/getStationsByCityState',
  async ({ city, state }: { city: string; state: string }) =>
    api
      .get('/fuel/location/search/', {
        params: {
          radius: 200,
          city,
          state,
        },
      })
      .then(({ data }) => keysToCamel(data) as FuelStation[]),
)

export const getStationByID = createAsyncThunk('fuel/getStationByID', async (id: number) =>
  api.get(`/fuel/location/${id}/`).then(({ data }) => keysToCamel(data) as FuelStation),
)

export const getReservations = createAsyncThunk('fuel/getReservations', async () =>
  api.get('/fuel/reservations-list/').then(
    ({ data }) =>
      keysToCamel(data).map((r: any) => ({
        ...r,
        carrierPrice: parseFloat(r.carrierPrice) || 0,
        fundsToRecover: parseFloat(r.fundsToRecover) || 0,
        gallonsRedeemed: parseFloat(r.gallonsRedeemed) || 0,
        retailPrice: parseFloat(r.retailPrice) || 0,
        totalCarrierCost: parseFloat(r.totalCarrierCost) || 0,
        totalCarrierSavings: parseFloat(r.totalCarrierSavings) || 0,
        totalFundsHeld: parseFloat(r.totalFundsHeld) || 0,
        totalFundsReleased: parseFloat(r.totalFundsReleased) || 0,
        totalFundsSettled: parseFloat(r.totalFundsSettled) || 0,
      })) as FuelReservation[],
  ),
)

export const createNewReservation = createAsyncThunk(
  'fuel/createNewReservation',
  async (_, { getState, rejectWithValue }) => {
    const newReservation = (getState() as RootState).fuel.newReservation
    if (!newReservation.station || !newReservation.totalCost)
      return rejectWithValue('No new reservation found')
    const {
      gallons,
      station: { basePriceId, carrierPrice, exoLocationId },
    } = newReservation
    return api
      .post('/fuel/reservations-list/', {
        base_price_id: basePriceId,
        carrier_price: carrierPrice,
        exo_location_id: exoLocationId,
        gallon_limit: gallons,
      })
      .then(({ data }) => keysToCamel(data) as FuelReservation)
  },
)

export const cancelReservation = createAsyncThunk('fuel/cancelReservation', async (id: number) =>
  api.patch(`/fuel/reservation/${id}/`, { status: 'Canceled' }),
)

export const carrierCompleteFuelOnboarding = createAsyncThunk(
  'fuel/carrierCompleteFuelOnboarding',
  async ({ companyId }: { companyId: number }, { rejectWithValue }) => {
    try {
      const response = await api.patch(
        `/accounts/api/carrier/complete-carrier-fuel-onboarding/${companyId}/`,
      )
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const fuelSlice = createSlice({
  name: 'fuel',
  initialState,
  reducers: {
    patchNewReservation: (state, { payload }: { payload: Partial<NewReservation> }) => {
      const incoming = { ...state.newReservation, ...payload }
      if (incoming.step !== state.newReservation.step)
        trackEvent(`User entered this step of fuel reservation flow: ${incoming.step}`)
      if (
        incoming.station &&
        incoming.station.exoLocationId !== state.newReservation.station?.exoLocationId
      )
        trackEvent('Viewing location details', {
          exo_location_id: incoming.station.exoLocationId,
        })

      state.newReservation = incoming
    },
    setCurrentLocation: (state, { payload }: { payload: CurrentLocation }) => {
      state.currentLocation = payload
    },
    setSelectedReservation: (state, { payload }: { payload: FuelReservation | null }) => {
      state.selectedReservation = payload
    },
    setShowReservations: (state, { payload }: { payload: boolean }) => {
      state.showReservations = payload
      state.newReservation.step = 'search'
    },
    setSize: (state, { payload }: { payload: number }) => {
      state.size = payload
    },
    setSearchQuery: (state, { payload }: { payload: CityLocation | null }) => {
      state.searchQuery = payload
    },
    setMobileView: (state, { payload }: { payload: 'map' | 'cards' }) => {
      state.mobileView = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStationsByLatLon.pending, state => {
        state.loading = true
      })
      .addCase(getStationsByLatLon.fulfilled, (state, action) => {
        const results = action.payload
        state.count = results.length
        state.lastFetchedCoords = {
          latitude: action.meta.arg.latitude,
          longitude: action.meta.arg.longitude,
        }
        state.stations = results
        state.loading = false
      })
      .addCase(getStationsByLatLon.rejected, state => {
        state.loading = false
        toast.error('Error loading fuel stations')
      })
      .addCase(getStationsByPostalCode.pending, state => {
        state.loading = true
      })
      .addCase(getStationsByPostalCode.fulfilled, (state, action) => {
        const results = action.payload
        state.count = results.length
        state.stations = results
        state.loading = false
      })
      .addCase(getStationsByPostalCode.rejected, state => {
        state.loading = false
        toast.error('Error loading fuel stations')
      })
      .addCase(getStationsByCityState.pending, state => {
        state.loading = true
      })
      .addCase(getStationsByCityState.fulfilled, (state, action) => {
        const results = action.payload
        state.count = results.length
        state.stations = results
        state.loading = false
      })
      .addCase(getStationsByCityState.rejected, state => {
        state.loading = false
        toast.error('Error loading fuel stations')
      })
      .addCase(getReservations.pending, state => {
        state.loading = true
      })
      .addCase(getReservations.fulfilled, (state, action) => {
        const results = action.payload
        state.count = results.length
        state.reservations = results
        if (state.selectedReservation) {
          const selectedReservation = results.find(
            reservation => reservation.id === state.selectedReservation?.id,
          )
          if (selectedReservation) state.selectedReservation = selectedReservation
        }
        state.loading = false
      })
      .addCase(getReservations.rejected, state => {
        state.loading = false
        toast.error('Error loading fuel reservations')
      })
      .addCase(createNewReservation.pending, state => {
        state.loading = true
      })
      .addCase(createNewReservation.fulfilled, (state, action) => {
        state.loading = false
        state.newReservation = {
          ...state.newReservation,
          gallons: 0,
          station: null,
          step: 'complete',
          totalCost: 0,
        }
        state.reservations = [action.payload, ...state.reservations]
        state.selectedReservation = action.payload
        trackEvent('Fuel reservation created', {
          reservationId: action.payload.id,
          gallons: action.payload.gallonsRedeemed,
          stationId: action.payload.exoLocationId,
          stationName: action.payload.locationName,
          totalCarrierCost: action.payload.totalCarrierCost,
          totalCarrierSavings: action.payload.totalCarrierSavings,
        })
      })
      .addCase(createNewReservation.rejected, (state, action) => {
        state.loading = false
        toast.error(`Error creating reservation: ${action.payload}`)
        trackEvent('Error creating reservation', { payload: JSON.stringify(action.payload) })
      })
      .addCase(cancelReservation.pending, state => {
        state.loading = true
      })
      .addCase(cancelReservation.rejected, () => {
        toast.error('Error canceling reservation, please try again')
      })
      .addCase(carrierCompleteFuelOnboarding.pending, state => {
        state.loading = true
      })
      .addCase(carrierCompleteFuelOnboarding.fulfilled, state => {
        state.loading = false
        trackEvent('Carrier completed fuel onboarding')
      })
      .addCase(carrierCompleteFuelOnboarding.rejected, (state, { payload }) => {
        state.loading = false
        toast.error(getErrorString(payload, 'Error while trying to complete fuel onboarding'))
      })
  },
})

export const {
  patchNewReservation,
  setCurrentLocation,
  setMobileView,
  setSearchQuery,
  setSelectedReservation,
  setShowReservations,
  setSize,
} = fuelSlice.actions

export default fuelSlice.reducer
