import {
  downloadCSV,
  formatAxiosErrorToPayload,
  formatDateForBackend,
  getErrorString,
} from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { MatchType } from '../common/constants'
import { PreferredLane, RootState } from '../common/types'
import { keysToCamel } from '../common/utils'
import { denormalizeLane, normalizeLane } from './normalize'

export enum LANE_TYPE_CHOICES {
  CAPACITY = 'Capacity',
  PREFERRED_LANE = 'Lane',
}

const getNewLane = (): PreferredLane => ({
  srcLocation: null,
  srcRadius: 50,
  dstLocation: null,
  dstRadius: 50,
  matchType: MatchType.LANE,
})

type LanesState = {
  lanes: Array<PreferredLane>
  capacity: Array<PreferredLane>
  count: number
  loading: {
    fetchingLanes: boolean
    addingNewlane: boolean
    exportCapacity: boolean
  }
  newLane: PreferredLane
  newLaneModalVisible: boolean
  newLaneType: LANE_TYPE_CHOICES
}

const initialState: LanesState = {
  lanes: [],
  capacity: [],
  count: 0,
  loading: {
    fetchingLanes: true,
    addingNewlane: false,
    exportCapacity: false,
  },
  newLane: getNewLane(),
  newLaneModalVisible: false,
  newLaneType: LANE_TYPE_CHOICES.PREFERRED_LANE,
}

export const getPreferredLanes = createAsyncThunk(
  'lanes/getPreferredLanes',
  async ({ limit }: { limit: number; backgroundMode?: boolean }) => {
    const response = await api.get('/carrier-matching/api/lane/', {
      params: { limit },
    })

    const { results, count } = keysToCamel(response.data)

    // Convert stateProvinceRegion -> state and format title
    const lanes: Array<PreferredLane> = results.map(
      (
        lane: Partial<PreferredLane> & {
          srcLocation: { stateProvinceRegion: string }
          dstLocation: { stateProvinceRegion: string }
        },
      ) => denormalizeLane(lane),
    )

    // Split results into preferred lanes / active capacity based on whether or not lane.availabilityStart is present
    const capacity = lanes.filter((lane: PreferredLane) => Boolean(lane.availabilityStart))
    let preferredLanes = lanes.filter((lane: PreferredLane) => !lane.availabilityStart)

    // Sort capacity by date
    capacity.sort((a, b) => {
      let returnPositive = false
      if (a.availabilityStart == b.availabilityStart) {
        returnPositive = Boolean((a.availabilityEnd as string) >= (b.availabilityEnd as string))
      } else {
        returnPositive = Boolean((a.availabilityStart as string) >= (b.availabilityStart as string))
      }
      if (returnPositive) return 1
      return -1
    })

    // remove duplicate origin/destination from preferred lanes
    const seen = new Set()
    preferredLanes = preferredLanes.filter((lane: PreferredLane) => {
      const key = `${lane.srcLocation?.city}-${lane.srcLocation?.state}-${lane.dstLocation?.city}-${lane.dstLocation?.state}`
      if (seen.has(key)) return false

      seen.add(key)
      return true
    })
    return { capacity, preferredLanes, count }
  },
)

export const addCapacity = createAsyncThunk(
  'lanes/addCapacity',
  async (_, { getState, rejectWithValue }) =>
    await api
      .post('/carrier-matching/api/lane/', normalizeLane((getState() as RootState).lanes.newLane))
      .then(({ data }) => denormalizeLane(keysToCamel(data)))
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const optimisticLaneUpdate = createAsyncThunk(
  'lanes/optimisticLaneUpdate',
  async (
    { updatedLane, existingLane }: { updatedLane: PreferredLane; existingLane: PreferredLane },
    { dispatch, rejectWithValue },
  ) =>
    // perform the update, and on failure revert the state
    api
      .put(`/carrier-matching/api/lane-rud/${existingLane.id}/`, normalizeLane(updatedLane))
      .catch(err => {
        dispatch(setLaneById(existingLane))
        return rejectWithValue(formatAxiosErrorToPayload(err))
      }),
)

export const deleteLane = createAsyncThunk('lanes/deleteLane', async ({ id }: { id: number }) =>
  api.delete(`/carrier-matching/api/lane-rud/${id}/`).then(() => id),
)

export const exportCapacity = createAsyncThunk('lanes/exportCapacity', async () => {
  const response = await api.get(`/carrier-matching/export-capacity/`)
  downloadCSV(response.data, 'capacity')
})

const lanesSlice = createSlice({
  name: 'lanes',
  initialState,
  reducers: {
    setLaneById(state, { payload }: PayloadAction<PreferredLane>) {
      state.lanes = state.lanes.map(lane => (lane.id === payload.id ? payload : lane))
      state.capacity = state.capacity.map(lane => (lane.id === payload.id ? payload : lane))
    },
    setNewLaneModalVisible(state, action) {
      state.newLaneModalVisible = action.payload
    },
    setNewLane(
      state,
      { payload }: PayloadAction<Partial<PreferredLane> & { availability?: Date[] }>,
    ) {
      if (payload.availability) {
        payload.availabilityStart = formatDateForBackend(payload.availability[0])
        payload.availabilityEnd = formatDateForBackend(payload.availability[1])
        delete payload.availability
      }
      state.newLane = {
        ...state.newLane,
        ...payload,
      }
    },
    setNewLaneType(state, { payload }: PayloadAction<LANE_TYPE_CHOICES>) {
      state.newLaneType = payload
    },
    resetNewLane(state) {
      state.newLane = getNewLane()
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPreferredLanes.pending, (state, action) => {
        if (!action.meta.arg.backgroundMode) state.loading.fetchingLanes = true
      })
      .addCase(getPreferredLanes.fulfilled, (state, action) => {
        const { capacity, preferredLanes, count } = action.payload
        state.loading.fetchingLanes = false
        state.lanes = preferredLanes
        state.capacity = capacity
        state.count = count
      })
      .addCase(getPreferredLanes.rejected, state => {
        state.loading.fetchingLanes = false
        toast.error('Could not get preferred lanes')
      })
      .addCase(addCapacity.pending, state => {
        state.loading.addingNewlane = true
      })
      .addCase(addCapacity.fulfilled, (state, { payload }) => {
        state.loading.addingNewlane = false
        state.newLaneModalVisible = false
        state.newLane = getNewLane()
        if (payload.availabilityStart) {
          state.capacity.push(payload)
          state.capacity.sort((a, b) => {
            let returnPositive = false
            if (a.availabilityStart == b.availabilityStart) {
              returnPositive = Boolean(
                (a.availabilityEnd as string) >= (b.availabilityEnd as string),
              )
            } else {
              returnPositive = Boolean(
                (a.availabilityStart as string) >= (b.availabilityStart as string),
              )
            }
            if (returnPositive) return 1
            return -1
          })
        } else {
          const insertIndex = state.lanes[0]?.matchType === MatchType.CARRIER_LOCATION ? 1 : 0
          state.lanes.splice(insertIndex, 0, payload)
        }
        toast.success('Lane added successfully')
      })
      .addCase(addCapacity.rejected, (state, { payload }) => {
        state.loading.addingNewlane = false
        toast.error(getErrorString(payload, 'Error adding lane'))
      })
      .addCase(optimisticLaneUpdate.pending, state => {
        state.loading.addingNewlane = true
      })
      .addCase(optimisticLaneUpdate.fulfilled, state => {
        state.loading.addingNewlane = false
        state.newLaneModalVisible = false
        state.newLane = getNewLane()
      })
      .addCase(optimisticLaneUpdate.rejected, (state, { payload }) => {
        state.loading.addingNewlane = false
        toast.error(getErrorString(payload, 'Failed to update lane'))
      })
      .addCase(deleteLane.fulfilled, (state, { payload }) => {
        state.lanes = state.lanes.filter(lane => lane.id !== payload)
        state.capacity = state.capacity.filter(lane => lane.id !== payload)
        toast.success('Lane deleted successfully')
      })
      .addCase(deleteLane.rejected, () => {
        toast.error('Could not delete lane')
      })
      .addCase(exportCapacity.pending, state => {
        state.loading.exportCapacity = true
      })
      .addCase(exportCapacity.fulfilled, state => {
        state.loading.exportCapacity = false
      })
      .addCase(exportCapacity.rejected, state => {
        state.loading.exportCapacity = false
        toast.error('Failed to download capacity')
      })
  },
})

export default lanesSlice.reducer

export const { setNewLaneModalVisible, setNewLane, setLaneById, resetNewLane, setNewLaneType } =
  lanesSlice.actions
