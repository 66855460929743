import dayjs from 'dayjs'
import { camelCase, capitalize, isEmpty, isEqual, snakeCase, startCase, transform } from 'lodash-es'
import { v4 as uuid } from 'uuid'

// 'exo freight' -> 'Exo Freight'
export const capitalizeEachLetter = (string: string): string => startCase(string)

// 'EXO_FREIGHT' -> 'Exo Freight'
export const toTitleCase = (str: string) =>
  str && capitalizeEachLetter(str.replace('_', ' ').toLowerCase())

export const randomId = (): string => `_${Math.random().toString(36).substring(2, 9)}`

const camelToSnakeCase = (str: string) => snakeCase(str)

export const isArray = (a: unknown): a is unknown[] => Array.isArray(a)

export const isObject = (o: unknown): o is Record<PropertyKey, unknown> =>
  o === Object(o) && !isArray(o) && typeof o !== 'function'

export const displayDate = (date?: string) => (date ? dayjs(date).format('MM/DD/YYYY') : '—')

// Converts the keys of the given object to camelCase
export const keysToCamel = (o: any): any => {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach(k => {
      n[camelCase(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map(i => keysToCamel(i)) as any
  }

  return o as any
}

// Converts the keys of the given object to snake_case
export const keysToSnake = (o: any): any => {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach((k: any) => {
      n[camelToSnakeCase(k)] = keysToSnake(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: any) => keysToSnake(i))
  }

  return o
}

export const validateAccountNumber = (accountNumber: string): boolean =>
  /^\d{5,17}$/.test(accountNumber)

export const validateRoutingNumber = (routingNumber: string): boolean =>
  /^\d{9}$/.test(routingNumber)

// Returns whether the string is at least two words long
export const validateFullName = (name: string): boolean =>
  name?.split(' ').filter(name => name != '').length >= 2

// Returns whether the string is of the form of an email address.
export const validateEmail = (email: string): boolean => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

// Ex: 'new york' -> 'New York'
export const setCityCase = (city?: string): string =>
  (city ?? '').toLowerCase().split(' ').map(capitalize).join(' ')

// Concatenates city and state together to form a location string
// ex: 'New York', 'NY' => 'New York, NY'
export const displayCityAndState = (city: any, state: any): string => {
  const builder = []
  if (city) builder.push(setCityCase(city))
  if (state) builder.push(state.toUpperCase())
  if (!isEmpty(builder)) return builder.join(', ')
  return '—'
}

// ex: getInitialsFromName('EXO Freight Stage') -> 'ES'
// only keeps the first char of each of the first and last words in given string
export const getInitialsFromName = (name?: string): string => {
  let words = (name ?? '').split(' ')
  const firstWord = words[0]
  const lastWord = words.slice(-1)[0]
  words = [firstWord, lastWord]

  return words.map((name: string) => name.charAt(0)).join('')
}

export const dateFormatter = new Intl.DateTimeFormat('en-US')

/**
 * Deep diff between two object, using lodash.
 *
 * Example:
 * base: {a: 1, b: 2, c: 3, d: {e: 4, f: 5, g: 6}}
 * object: {a: 1, b: 2, c: 4, d: {e: 4, f: 5, g: 7}}
 *
 * returns: {c: 4, d: {g: 7}}
 *
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object representing the diff
 */
export const differenceBetweenObjects = (object: any, base: any) => {
  const changes = (object: any, base: any) =>
    transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  return changes(object, base)
}

// Returns the fully formatted document title for a given page.
export const getDocumentTitle = (pageName: string) => `${pageName} | Pulse`

export const getClientId = () => {
  const hasId = sessionStorage.getItem('client-id')
  if (!hasId) {
    sessionStorage.setItem('client-id', uuid())
  }
  return sessionStorage.getItem('client-id') as string
}

export const randomString = () => Math.random().toString(36).substring(2, 6)
