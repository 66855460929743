import { DetailSectionRow } from '../../DetailSectionRow'

export const DetailSectionRowBold = ({
  title,
  children,
  className,
}: {
  title: string
  children: string
  className?: string
}) => (
  <DetailSectionRow className={className} title={title}>
    <div className='font-bold'>{children || '—'}</div>
  </DetailSectionRow>
)
