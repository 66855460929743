import { CityLocation } from '@common'
import { CityAutosuggest } from '@components'
import { debounce } from 'lodash-es'
import { useCallback, useState } from 'react'

import { getCities } from '../../redux/locationsSlice'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'

export const CityAutocomplete = ({
  required,
  countries = ['US', 'CA', 'MX'],
  value,
  placeholder,
  onChange,
  label,
  disabled,
  className,
  id,
  style = {},
  error,
  errorClassname,
}: {
  required?: boolean
  countries?: string[]
  value: CityLocation | null
  placeholder?: string
  onChange: (value: CityLocation | null) => void
  label?: string
  disabled?: boolean
  className?: string
  id?: string
  style?: React.CSSProperties
  validator?: (value: CityLocation | null) => string | undefined
  error?: string
  errorClassname?: string
}) => {
  const [choices, setChoices] = useState<CityLocation[]>([])

  const dispatch = useAppThunkDispatch()

  const popularCities = useAppSelector(state => state.locations.popularCities)
  const cities = useAppSelector(state => state.locations.cities)
  const citiesLoading = useAppSelector(state => state.locations.loading.cities)
  const debouncedGetCities = useCallback(
    debounce((query: string) => dispatch(getCities({ query, countries })), 300),
    [],
  )

  return (
    <CityAutosuggest
      choices={choices}
      cities={cities}
      className={className}
      countries={countries}
      disabled={disabled}
      error={error}
      errorClassname={errorClassname}
      id={id}
      label={label}
      loading={citiesLoading}
      placeholder={placeholder}
      popularCities={popularCities}
      required={required}
      setChoices={setChoices}
      style={style}
      value={value}
      onChange={onChange}
      onGeocode={query => debouncedGetCities(query)}
    />
  )
}
