import tw from 'tailwind-styled-components'

export const Hint = ({ text, className = '' }: { text?: string; className?: string }) => (
  <Container className={className}>{text}</Container>
)

const Container = tw.div`
  rounded-lg
  bg-lighter-gray
  border
  border-light-gray
  py-1
  px-3
  text-base
  w-fit
  my-4
  font-semibold
`
