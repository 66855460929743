import '../Onboarding.scss'

import { Checkbox, TextInput } from '@components'
import { useEffect } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'
import { setOnboardingDetails } from '../../../redux/userSlice'
import { agreement } from '../data'

export const Agreement = () => {
  const dispatch = useAppThunkDispatch()
  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)

  const setSigning = (field: string, value: any) => {
    dispatch(
      setOnboardingDetails({
        ...onboardingDetails,
        signing: {
          ...onboardingDetails.signing,
          [field]: value,
        },
      }),
    )
  }

  if (onboardingDetails.invitedBy) return <TOSAgreement setSigning={setSigning} />
  else return <CarrierAgreement setSigning={setSigning} />
}
const CarrierAgreement = ({ setSigning }: { setSigning: (field: string, value: any) => void }) => {
  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    signing,
    qualification: { carrierDetails },
  } = onboardingDetails

  const replaceWithUserInfo = (text: string) =>
    text
      .replaceAll('#USER_NAME#', carrierDetails.name)
      .replaceAll('#USER_ADDRESS#', carrierDetails.address)
      .replaceAll('#USER_CITY#', carrierDetails.city)
      .replaceAll('#USER_STATE#', carrierDetails.stateProvinceRegion)
      .replaceAll('#USER_POSTAL_CODE#', carrierDetails.postalCode)

  return (
    <div className='flex-1'>
      <div className='text-xl md:text-2xl mb-6'>Broker/Carrier Agreement</div>
      <div className='border border-brand-light rounded-lg form text-left px-6 mb-24 md:mb-0'>
        <div className='pt-6'>{replaceWithUserInfo(agreement.description)}</div>
        {agreement.sections.map((section, i) => (
          <div key={section.title}>
            <div className='mb-2 mt-6 font-bold'>
              {i + 1}. {section.title}
            </div>
            <div style={{ whiteSpace: 'pre-line' }}>{replaceWithUserInfo(section.text)}</div>
          </div>
        ))}
        <div className='font-bold my-6'>{agreement.footer}</div>
      </div>
      <div className='form bottom-[72px] md:bottom-[88px] fixed w-[40rem]'>
        <div className='flex flex-col md:flex-row md:items-center justify-between p-4 md:px-6 bg-brand-light gap-4'>
          <div
            className='flex items-center hover:opacity-70 transition-all cursor-pointer whitespace-nowrap'
            onClick={() => setSigning('signed', !signing.signed)}
          >
            <Checkbox isChecked={signing.signed} title='I agree' />
          </div>
          <TextInput
            md
            required
            className='w-full md:mt-0'
            dataTestId='firstName'
            labelStyle='font-semibold'
            placeholder='First Name'
            value={signing.firstName}
            onChange={(firstName: string) => setSigning('firstName', firstName)}
          />
          <TextInput
            md
            required
            className='w-full'
            dataTestId='lastName'
            labelStyle='font-semibold'
            placeholder='Last Name'
            value={signing.lastName}
            onChange={(lastName: string) => setSigning('lastName', lastName)}
          />
        </div>
      </div>
    </div>
  )
}

const TOSAgreement = ({ setSigning }: { setSigning: (field: string, value: any) => void }) => {
  const onboardingDetails = useAppSelector(state => state.user.onboarding.onboardingDetails)

  return (
    <div className='flex gap-1'>
      <Checkbox
        isChecked={onboardingDetails.signing.signed}
        title='I agree'
        onChange={value => setSigning('signed', value)}
      />
      <div>
        to the{' '}
        {
          <a
            className='text-link font-bold underline'
            href='https://www.exofreight.com/docs/pulse-carrier-tos.pdf'
            rel='noreferrer'
            target='_blank'
          >
            Terms of Service
          </a>
        }{' '}
        and{' '}
        {
          <a
            className='text-link font-bold underline'
            href='https://www.exofreight.com/docs/pulse-privacy-policy.pdf'
            rel='noreferrer'
            target='_blank'
          >
            Privacy Policy
          </a>
        }
      </div>
    </div>
  )
}
