import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

export const Box = ({ className = '', children }: { className?: string; children: ReactNode }) => (
  <Container className={className}>{children}</Container>
)

const Container = tw.div`
  bg-brand-light
  border-link
  border-l-4
  rounded-md
  py-3
  px-4
  md:py-5
  md:px-6
  mb-4
  md:mb-6
  flex
  flex-col
  md:flex-row
  md:items-center
  justify-between
  mt-4
  transition-all
`
