import { combineReducers, configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { isLocal, isProduction } from '../common/constants'
import { resetTracking, trackEvent } from '../common/tracking'
import { RootState, ThunkAppDispatch } from '../common/types'
import backhaulLoadsSlice from './backhaulLoadsSlice'
import bankingOnboardingSlice, { bankingOnboardingPersistConfig } from './bankingOnboardingSlice'
import bankingSlice from './bankingSlice'
import carrierCompanyRecoveriesSlice from './carrierCompanyRecoveriesSlice'
import carrierCompanyReserveSlice from './carrierCompanyReserveSlice'
import companySlice from './companySlice'
import contactCarrierRepSlice from './contactCarrierRepSlice'
import contactsSlice from './contactsSlice'
import documentsSlice from './documentsSlice'
import equipmentSlice from './equipmentSlice'
import equipmentsSlice from './equipmentsSlice'
import externalFactoringSlice from './externalFactoringSlice'
import factoringReferralsSlice from './factoringReferralsSlice'
import factoringSlice from './factoringSlice'
import fuelSlice from './fuelSlice'
import lanesSlice from './lanesSlice'
import loadFactoringRequestSlice from './loadFactoringRequestSlice'
import loadRecommendationsSettingsSlice from './loadRecommendationsSettingsSlice'
import loadsSlice from './loadsSlice'
import locationsSlice from './locationsSlice'
import { rejectedThunkListenerMiddleware } from './middleware'
import myLoadsSlice from './myLoadsSlice'
import myQuotesSlice from './myQuotesSlice'
import paymentSlice from './paymentSlice'
import paymentsSlice from './paymentsSlice'
import plaidSlice from './plaidSlice'
import trackingSlice from './trackingSlice'
import userSlice, { userPersistConfig } from './userSlice'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

// base configuration for redux-persist to use for all persistors
const getBasePersistConfig: any = () => ({
  storage,
  version: isLocal ? 1 : Number(__BUILD_VERSION__),
  migrate: (state: RootState, currentVersion: number) => {
    if (state?._persist && state._persist.version !== currentVersion) {
      return Promise.resolve({})
    }
    return Promise.resolve(state)
  },
})

const appReducer = combineReducers({
  backhaulLoads: backhaulLoadsSlice,
  bankingOnboarding: persistReducer(
    { ...getBasePersistConfig(), ...bankingOnboardingPersistConfig },
    bankingOnboardingSlice,
  ),
  banking: bankingSlice,
  carrierCompanyRecoveries: carrierCompanyRecoveriesSlice, // TODO: refactor to 'recoveriesSlice'
  carrierCompanyReserve: carrierCompanyReserveSlice, // TODO: refactor to 'reservesSlice'
  company: companySlice,
  contactCarrierRep: contactCarrierRepSlice,
  contacts: contactsSlice,
  documents: documentsSlice,
  equipment: equipmentSlice,
  equipments: equipmentsSlice,
  externalFactoring: externalFactoringSlice,
  factoring: factoringSlice,
  factoringReferrals: factoringReferralsSlice,
  fuel: fuelSlice,
  lanes: lanesSlice,
  loadFactoringRequests: loadFactoringRequestSlice,
  loads: loadsSlice,
  locations: locationsSlice,
  loadRecommendationsSettings: loadRecommendationsSettingsSlice,
  myLoads: myLoadsSlice,
  myQuotes: myQuotesSlice,
  payment: paymentSlice,
  payments: paymentsSlice,
  plaid: plaidSlice,
  tracking: trackingSlice,
  user: persistReducer({ ...getBasePersistConfig(), ...userPersistConfig }, userSlice),
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    trackEvent('Logout')
    resetTracking()
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

// it's hard to type this, so we'll just use any
const rootPersistConfig: any = {
  ...getBasePersistConfig(),
  key: 'root',
  whitelist: [],
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const configuredStore = {
  reducer: persistedReducer,
  devTools: !isProduction,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(rejectedThunkListenerMiddleware),
  enhancers: (getDefaultEnhancers: any) => getDefaultEnhancers().concat(sentryReduxEnhancer),
}

export const store = configureStore(configuredStore)
export const persistor = persistStore(store)

export const setupStore = (preloadedState?: RootState) =>
  configureStore({
    ...configuredStore,
    preloadedState,
  })

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
