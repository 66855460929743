import { CatchError, formatAxiosErrorToPayload } from '@common'
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { createTransform, PURGE } from 'redux-persist'

import { api } from '../api/api'
import { trackEvent } from '../common/tracking'
import {
  AgreementsHTML as AgreementsHTML,
  BankingBusiness,
  BankingPerson,
  emptyOwner,
  NewBusiness,
  NewPerson,
  RootState,
} from '../common/types'
import { keysToCamel, keysToSnake } from '../common/utils'
import { BankingOnboardingStep } from '../pages/BankingSignup/components/SignupSteps'

type BankingOnboardingState = {
  agreementsHTML: AgreementsHTML
  agreementsProgress: {
    achFirstName: string | null
    achLastName: string | null
    hasAgreedToACH: boolean
    hasAgreedToESign: boolean
    bocFirstName: string | null
    bocLastName: string | null
    hasCertifiedBOC: boolean
  }
  businessInfo: NewBusiness
  loading: {
    acknowledgePatriotAct: boolean
    agreements: boolean
    signDisclosures: boolean
    submittingBusinessInfo: boolean
    submittingCardInfo: boolean
    submittingPersonInfo: boolean
    deletingPerson: boolean
    kycDataCollection: boolean
  }
  owners: NewPerson[]
  signUpStep: BankingOnboardingStep
  latestSignUpStep: BankingOnboardingStep
}

const initialState = (signUpStep: BankingOnboardingStep): BankingOnboardingState => ({
  agreementsHTML: {
    eSign: null,
    scAccountAgreement: null,
    achAuthorization: null,
    cardholderAgreement: null,
    kycDataCollection: null,
    privacyNotice: null,
    regCc: null,
    termsAndConditions: null,
    regDd: null,
  },
  agreementsProgress: {
    achFirstName: null,
    achLastName: null,
    hasAgreedToACH: false,
    hasAgreedToESign: false,
    bocFirstName: null,
    bocLastName: null,
    hasCertifiedBOC: false,
  },
  businessInfo: {
    entityName: null,
    tradeNames: [],
    formationDate: null,
    formationState: null,
    structure: null,
    phoneNumber: null,
    ein: null,
    legalAddress: {
      addressLine1: '',
      city: '',
      state: '',
      postalCode: '',
    },
  },
  loading: {
    acknowledgePatriotAct: false,
    agreements: false,
    signDisclosures: false,
    submittingBusinessInfo: false,
    submittingCardInfo: false,
    submittingPersonInfo: false,
    deletingPerson: false,
    kycDataCollection: false,
  },
  owners: [emptyOwner()],
  signUpStep,
  latestSignUpStep: signUpStep,
})

export const selectCumulativeOwnershipPercentage = createSelector(
  (state: RootState) => state.bankingOnboarding.owners,
  owners => owners.reduce((sum, { ownershipPercentage }) => sum + ownershipPercentage, 0),
)

export const selectHasManagingPerson = createSelector(
  (state: RootState) => state.bankingOnboarding.owners,
  owners => owners.length === 1 || owners.some(({ isManagingPerson }) => isManagingPerson),
)

export const getAgreementsHTML = createAsyncThunk<{ data: string }>(
  'bankingOnboarding/getAgreementsHTML',
  async () => {
    const { data } = await api.get('/finance/api/all-disclosure-previews/', {
      headers: { 'Content-Type': 'text/html' },
    })
    return keysToCamel(data['disclosures'])
  },
)

export const getKycHTML = createAsyncThunk(
  'bankingOnboarding/getKycHTML',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { bankingOnboarding: { businessInfo: business } = {} } = getState() as RootState
      if (!business) return null

      return await api
        .get('/finance/api/disclosure-preview/KYC_DATA_COLLECTION', {
          headers: { 'Content-Type': 'text/html' },
          params: { structure: business.structure },
        })
        .then(response => response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const acknowledgePatriotAct = createAsyncThunk(
  'bankingOnboarding/acknowledgePatriotAct',
  async (_, { rejectWithValue }) => {
    try {
      return api.patch(`/finance/api/businesses/patriot-act/`, {
        acknowledged_patriot_act: true,
      })
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const upsertBankingBusiness = createAsyncThunk(
  'bankingOnboarding/upsertBankingBusiness',
  async (_, { getState, rejectWithValue }) => {
    const business: NewBusiness = {
      ...(getState() as RootState).bankingOnboarding.businessInfo,
    }

    if (business.structure !== 'SOLE_PROPRIETORSHIP') {
      // We only want to send the date itself, no time or timezone. This sends for example '2023-02-21'.
      business.formationDate = (business.formationDate as string).substring(0, 10)
    } else {
      business.ein = null
      business.tradeNames = []
      business.formationDate = null
      business.formationState = null
    }

    const method = business.id ? api.put : api.post
    const conditionalParam = business.id ? `${business.id}/` : ''
    const response = await method(
      `/finance/api/businesses/${conditionalParam}`,
      keysToSnake(business),
    )
      .then(({ data }) => keysToCamel(data))
      .then(business => {
        const persons = business.persons as (BankingPerson & { ssnMasked: string })[]
        return {
          ...business,
          persons: persons.map(({ ssnMasked, ...person }) => ({ ...person, ssn: ssnMasked })),
        } as BankingBusiness
      })
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err)))

    return response
  },
)

export const upsertBankingPerson = createAsyncThunk(
  'bankingOnboarding/upsertBankingPerson',
  async (idx: number, { getState }) => {
    const person = keysToSnake((getState() as RootState).bankingOnboarding.owners[idx])
    const existingPersons = (getState() as RootState).banking.business?.persons || []

    const method = person.id ? api.put : api.post
    const conditionalParam = person.id ? `${person.id}/` : ''

    if (person.ssn.includes('*')) delete person.ssn

    const response = await method(`/finance/api/persons/${conditionalParam}`, person)
      .then(({ data }) => keysToCamel(data))
      .then(({ ssnMasked: ssn, ...person }) => ({ ssn, ...person }) as BankingPerson)

    // update existingPersons if the person already exists, otherwise add the new person to the list
    const persons = existingPersons.some(({ id }) => id === response.id)
      ? existingPersons.map(p => (p.id === response.id ? response : p))
      : [...existingPersons, response]

    return persons
  },
)

export const deleteBankingPerson = createAsyncThunk(
  'bankingOnboarding/deleteBankingPerson',
  async (ownerId: number, { getState }) => {
    await api.delete(`/finance/api/persons/${ownerId}/`)

    const existingPersons = (getState() as RootState).banking.business?.persons || []
    const persons = existingPersons.filter(({ id }) => id !== ownerId)

    return persons
  },
)

export const submitCardInfo = createAsyncThunk(
  'bankingOnboarding/submitCardInfo',
  async (data: any, { rejectWithValue }) => {
    try {
      const payload = keysToSnake(data)
      return await api.post('/finance/api/accounts/', payload).then(({ data }) => data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const signDisclosures = createAsyncThunk(
  'bankingOnboarding/signDisclosures',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { achFirstName, achLastName, bocFirstName, bocLastName } = (getState() as RootState)
        .bankingOnboarding.agreementsProgress
      const data = await api
        .post('/finance/api/disclosures/', {
          ...(achFirstName &&
            achLastName && { ach_signing_name: `${achFirstName} ${achLastName}` }),
          ...(bocFirstName &&
            bocLastName && { boc_signing_name: `${bocFirstName} ${bocLastName}` }),
        })
        .then(({ data }) => keysToCamel(data))
        .catch(err => rejectWithValue(formatAxiosErrorToPayload(err)))

      return data.verificationStatus
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const bankingOnboardingSlice = createSlice({
  name: 'bankingOnboarding',
  initialState: initialState('NOT_STARTED'),
  reducers: {
    setHasAgreedToESign(state, { payload }) {
      state.agreementsProgress.hasAgreedToESign = payload
    },
    setHasAgreedToACH(state, { payload }) {
      state.agreementsProgress.hasAgreedToACH = payload
    },
    setHasCertifiedBOC(state, { payload }) {
      state.agreementsProgress.hasCertifiedBOC = payload
    },
    setACHFirstName: (state, { payload }) => {
      state.agreementsProgress.achFirstName = payload
    },
    setACHLastName: (state, { payload }) => {
      state.agreementsProgress.achLastName = payload
    },
    setBOCFirstName: (state, { payload }) => {
      state.agreementsProgress.bocFirstName = payload
    },
    setBOCLastName: (state, { payload }) => {
      state.agreementsProgress.bocLastName = payload
    },
    setOnboardingStep: (state, { payload }: PayloadAction<BankingOnboardingStep>) => {
      trackEvent(`User entered this step of fuel/banking signup: ${payload}`)
      state.signUpStep = payload
    },
    setLatestOnboardingStep: (state, { payload }: PayloadAction<BankingOnboardingStep>) => {
      state.latestSignUpStep = payload
    },
    setBusinessInfo: (state, { payload }) => {
      state.businessInfo = {
        ...state.businessInfo,
        ...payload,
      }
    },
    setBusinessInfoAddress(state, { payload }) {
      state.businessInfo.legalAddress = {
        ...state.businessInfo.legalAddress,
        ...payload,
      }
    },
    setEntityName: (state, { payload }) => {
      state.businessInfo.entityName = payload
    },
    setTradeNames: (state, { payload }) => {
      state.businessInfo.tradeNames = payload
    },
    setFormationDate: (state, { payload }) => {
      state.businessInfo.formationDate = payload
    },
    setFormationState: (state, { payload }) => {
      state.businessInfo.formationState = payload
    },
    setStructure: (state, { payload }) => {
      state.businessInfo.structure = payload
    },
    setPhoneNumber: (state, { payload }) => {
      state.businessInfo.phoneNumber = payload
    },
    setEIN: (state, { payload }) => {
      state.businessInfo.ein = payload
    },
    setAddress: (state, { payload }) => {
      state.businessInfo.legalAddress.addressLine1 = payload
    },
    setCity: (state, { payload }) => {
      state.businessInfo.legalAddress.city = payload
    },
    setState: (state, { payload }) => {
      state.businessInfo.legalAddress.state = payload
    },
    setZipcode: (state, { payload }) => {
      state.businessInfo.legalAddress.postalCode = payload
    },
    saveOwner: (
      state,
      { payload: { idx, owner } }: PayloadAction<{ idx: number; owner: Partial<NewPerson> }>,
    ) => {
      state.owners.splice(idx, 1, { ...state.owners[idx], ...owner })
    },
    addOwner: state => {
      state.owners.push(emptyOwner())
    },
    deleteOwner: (state, { payload: idx }: PayloadAction<number>) => {
      state.owners.splice(idx, 1)
    },
    resetBankingOnboardingState: state => initialState(state.signUpStep),
  },
  extraReducers(builder) {
    builder
      .addCase(upsertBankingBusiness.pending, state => {
        state.loading.submittingBusinessInfo = true
      })
      .addCase(upsertBankingBusiness.fulfilled, (state, { payload }) => {
        state.loading.submittingBusinessInfo = false
        state.businessInfo = payload
      })
      .addCase(upsertBankingBusiness.rejected, state => {
        // TODO: Display a more useful error when this is rejected.
        toast.error('Error submitting business information')
        state.loading.submittingBusinessInfo = false
      })
      .addCase(upsertBankingPerson.pending, state => {
        state.loading.submittingPersonInfo = true
      })
      .addCase(upsertBankingPerson.fulfilled, (state, { payload }) => {
        state.loading.submittingPersonInfo = false
        state.owners = payload
      })
      .addCase(upsertBankingPerson.rejected, state => {
        // TODO: Display a more useful error when this is rejected.
        toast.error('Error submitting person information')
        state.loading.submittingPersonInfo = false
      })
      .addCase(deleteBankingPerson.pending, state => {
        state.loading.deletingPerson = true
      })
      .addCase(deleteBankingPerson.fulfilled, (state, { payload }) => {
        state.loading.deletingPerson = false
        state.owners = payload
      })
      .addCase(deleteBankingPerson.rejected, state => {
        state.loading.deletingPerson = false
        toast.error('Error deleting person information')
      })
      .addCase(getAgreementsHTML.pending, state => {
        state.loading.agreements = true
      })
      .addCase(getAgreementsHTML.fulfilled, (state, action) => {
        state.loading.agreements = false
        state.agreementsHTML = keysToCamel(action.payload)
      })
      .addCase(getAgreementsHTML.rejected, state => {
        state.loading.agreements = false
        toast.error('Error loading agreement documents, please reload the page and try again')
      })
      .addCase(getKycHTML.pending, state => {
        state.loading.kycDataCollection = true
      })
      .addCase(getKycHTML.fulfilled, (state, { payload }) => {
        state.agreementsHTML.kycDataCollection = payload
        state.loading.kycDataCollection = false
      })
      .addCase(getKycHTML.rejected, state => {
        state.loading.kycDataCollection = false
        toast.error('Error loading KYC document, please reload the page and try again')
      })
      .addCase(signDisclosures.pending, state => {
        state.loading.signDisclosures = true
      })
      .addCase(signDisclosures.fulfilled, state => {
        state.loading.signDisclosures = false
      })
      .addCase(signDisclosures.rejected, state => {
        state.loading.signDisclosures = false
        toast.error('Error loading agreement documents, please reload the page and try again')
      })
      .addCase(submitCardInfo.pending, state => {
        state.loading.submittingCardInfo = true
      })
      .addCase(submitCardInfo.fulfilled, state => {
        state.loading.submittingCardInfo = false
      })
      .addCase(submitCardInfo.rejected, state => {
        state.loading.submittingCardInfo = false
        toast.error('Error submitting information, please try again')
      })
      .addCase(acknowledgePatriotAct.pending, state => {
        state.loading.acknowledgePatriotAct = true
      })
      .addCase(acknowledgePatriotAct.fulfilled, state => {
        state.loading.acknowledgePatriotAct = false
      })
      .addCase(acknowledgePatriotAct.rejected, state => {
        state.loading.acknowledgePatriotAct = false
        toast.error('Error acknowledging patriot act, please try again')
      })
      .addCase(
        // bankingSlice is importing from this file, so to avoid circular dependencies, we need to access the action type as a string
        'banking/getBankingBusinesses/fulfilled' as any,
        (state, { payload }: PayloadAction<BankingBusiness[]>) => {
          if (payload.length) {
            const business = payload[0]
            state.businessInfo = business

            if (business.persons.length) {
              state.owners = business.persons
            }
          }
        },
      )
      .addCase(PURGE, () => initialState('NOT_STARTED'))
  },
})

// Configuration for redux-persist to know what to save and what not to save to local-storage
// We blacklist owners because it contains sensitive info like SSN that we don't want to persist
export const bankingOnboardingPersistConfig = {
  key: 'bankingOnboarding',
  whitelist: ['businessInfo', 'owners', 'signUpStep', 'latestSignUpStep'],
  // make sure we don't persist the ssn of the owners
  transforms: [
    createTransform((inboundState: any, key) => {
      if (key === 'owners') {
        return inboundState.map((owner: NewPerson) => ({
          ...owner,
          ssn: owner.ssn.includes('*') ? owner.ssn : '',
        }))
      }
      return inboundState
    }),
  ],
}

export const {
  setHasAgreedToESign,
  setHasAgreedToACH,
  setHasCertifiedBOC,
  setACHFirstName,
  setACHLastName,
  setBOCFirstName,
  setBOCLastName,
  setOnboardingStep,
  setLatestOnboardingStep,
  setEntityName,
  setTradeNames,
  setFormationDate,
  setFormationState,
  setStructure,
  setPhoneNumber,
  setEIN,
  setAddress,
  setCity,
  setState,
  setZipcode,
  saveOwner,
  addOwner,
  deleteOwner,
  resetBankingOnboardingState,
  setBusinessInfo,
  setBusinessInfoAddress,
} = bankingOnboardingSlice.actions

export default bankingOnboardingSlice.reducer
