import { pluralizeNoun } from '@common'
import { Button } from '@components'
import { useCallback } from 'react'
import tw from 'tailwind-styled-components'

import { setSelectedCompanyCheckResult } from '../../../redux/factoringSlice'
import { useAppSelector, useAppThunkDispatch } from '../../../redux/store'

export const ShippersList = () => {
  const dispatch = useAppThunkDispatch()

  const shippersList = useAppSelector(state => state.factoring.shippersList)
  const creditCheck = useAppSelector(state => state.factoring.selectedCompanyCheckResult)
  const showProceedAnywayButton = useAppSelector(state => state.factoring.showProceedAnywayButton)
  const query = useAppSelector(state => state.factoring.searchQuery)

  const ProceedAnywayButton = useCallback(
    () => (
      <Button
        className='mt-4'
        type='warn'
        onClick={() => dispatch(setSelectedCompanyCheckResult({ name: query, approved: null }))}
      >
        Continue Anyway
      </Button>
    ),
    [query],
  )

  return (
    <>
      {!!shippersList.length && !creditCheck && (
        <>
          <div className='mt-10 mb-4'>
            ({shippersList.length}) {pluralizeNoun(shippersList.length, 'Result')} for{' '}
            <span className='font-bold'>{query}</span>
          </div>
          <List>
            {shippersList.map(shipper => (
              <Item key={shipper.customerId}>
                <div>
                  <div className='font-bold'>{shipper.name}</div>
                  <div>{shipper.cityState}</div>
                </div>
                <Button
                  type='link'
                  onClick={() => dispatch(setSelectedCompanyCheckResult(shipper))}
                >
                  Continue
                </Button>
              </Item>
            ))}
          </List>
          <ButtonWrap>
            <div>
              Don&apos;t see the right <span className='font-bold'>{query}</span>?
            </div>
            <ProceedAnywayButton />
          </ButtonWrap>
        </>
      )}
      {showProceedAnywayButton && (
        <ButtonWrap>
          <div>
            There were (0) results for <span className='font-bold'>{query}</span>
          </div>
          <div>Double-check for typos and search again or continue anyway.</div>
          <ProceedAnywayButton />
        </ButtonWrap>
      )}
    </>
  )
}

const List = tw.div`
  border
  border-border-gray
  rounded-lg
  text-sm
  w-full
`

const Item = tw.div`
  flex
  items-center
  justify-between
  border-b-2
  border-brand-light
  last:border-none
  last:pb-0
  m-4
  pb-4
`

const ButtonWrap = tw.div`
  mt-10
  flex
  flex-col
  items-center
`
