import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

import { FileProps } from '../../common/types'

const extractMimeType = /data:(.*);/

type FileUploadProps = {
  onUpload: (file: FileProps) => void
  children?: ReactNode
}

export const FileUpload = ({ onUpload, children }: FileUploadProps) => {
  const handleFileChange = (e: any) => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      const data = reader.result?.toString() ?? ''
      const type = (extractMimeType.exec(data) ?? ['', ''])[1] as string
      if (type == 'application/pdf' || type.startsWith('image/')) {
        onUpload({ fileData: data, name: file.name, mimeType: type })
      }
    }

    reader.readAsDataURL(file)

    e.target.value = ''
  }

  return (
    <UploadBtnWrapper>
      <UploadBtnInput
        accept='image/*,application/pdf'
        id='fileUploadElement'
        name='myfile'
        type='file'
        onChange={handleFileChange}
      />
      {children}
    </UploadBtnWrapper>
  )
}

const UploadBtnWrapper = tw.div`
  relative
  overflow-hidden
  inline-block
  w-full
  md:w-fit
`

const UploadBtnInput = tw.input`
  absolute
  h-full
  w-full
  opacity-0
  z-10
`
