/* Keep ./sentry at the top to ensure it's imported before
   anything that it would instrument */
import './sentry'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import './index.scss'
import './api/middleware'

import { BackStackProvider } from '@common'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

import { isProduction } from './common/constants'
import { persistor, store } from './redux/store'
import reportWebVitals from './reportWebVitals'
import { router } from './router'

// disable logs on prod
if (isProduction) {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const container = document.getElementById('root')

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          autoClose={2000}
          limit={3}
          pauseOnFocusLoss={false}
          position='bottom-right'
        />
        <BackStackProvider router={router}>
          <RouterProvider router={router} />
        </BackStackProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
