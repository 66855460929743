import tw from 'tailwind-styled-components'

export const AppContent = ({
  showSidebar,
  children,
}: {
  showHeader: boolean
  showSidebar: boolean
  children: React.ReactNode
}) => (
  <AppContentContainer $showHeader={true} $showSidebar={showSidebar}>
    {children}
  </AppContentContainer>
)

const AppContentContainer = tw.div<{
  $showHeader: boolean
  $showSidebar: boolean
}>`
  flex
  flex-col
  grow
  ${({ $showHeader }) => ($showHeader ? 'mt-[72px] max-h-[calc(100%-72px)]' : 'max-h-full')}
  ${({ $showSidebar }) => ($showSidebar ? 'md:pl-60' : '')}
`
