import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { keysToCamel } from '../common/utils'

export const getCarrierReferrals = createAsyncThunk(
  'factoringReferrals/getCarrierReferrals',
  async () => (await api.get('/accounts/api/carrier-factoring-referrals/')).data,
)

export type FactoringReferralsState = {
  referrals: any[]
  totalRewardsReceived: number
  activeReferrals: number
  loading: {
    referrals: boolean
  }
}

const initialState: FactoringReferralsState = {
  referrals: [],
  totalRewardsReceived: 0,
  activeReferrals: 0,
  loading: {
    referrals: false,
  },
}

const factoringReferralsSlice = createSlice({
  name: 'factoringReferrals',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCarrierReferrals.pending, state => {
        state.loading.referrals = true
      })
      .addCase(getCarrierReferrals.fulfilled, (state, action) => {
        const payload = keysToCamel(action.payload)
        state.referrals = payload.referrals
        state.totalRewardsReceived = payload.totalRewardsReceived
        state.activeReferrals = payload.activeReferrals
      })
      .addCase(getCarrierReferrals.rejected, () => {
        toast.error('Error getting carrier referrals')
      })
  },
})

export default factoringReferralsSlice.reducer
