export const TableHeader = ({ headerGroup }: { headerGroup: any }) => (
  <tr {...headerGroup.getHeaderGroupProps()}>
    {headerGroup.headers.map((column: any, i: number) => (
      <th
        key={i}
        {...column.getHeaderProps()}
        style={{ textAlign: 'left', width: column.totalWidth }}
      >
        <span className='font-semibold truncate text-ellipsis overflow-hidden'>
          {column.render('Header')}
        </span>
      </th>
    ))}
  </tr>
)
