import tw from 'tailwind-styled-components'

import { FuelFAQ } from '../FuelScreen/components/FuelFAQ'
import { CreateAccountForm } from './components/CreateAccountForm'

export const FuelSignupScreen = () => (
  <div className='overflow-y-auto overflow-x-hidden font-poppins md:bg-[#EFEFEF]'>
    <div className='flex flex-col items-center relative'>
      <FuelBanner />
      <CreateAccountFormContainer>
        <CreateAccountForm />
      </CreateAccountFormContainer>
    </div>
    <div className='flex flex-col items-center m-auto py-16'>
      <FAQHeader>Frequently Asked Questions</FAQHeader>
      <div className='w-1/2 mobile-only:w-[90%]'>
        <FuelFAQ />
      </div>
    </div>
  </div>
)

const FAQHeader = tw.div`
  flex
  justify-center
  text-xl
  font-bold
  pb-4
  inset-x-0
`

const CreateAccountFormContainer = tw.div`
  flex 
  justify-center
  items-center
  md:max-w-[384px]
  md:max-h-[78%]
  md:pt-20
`

const FuelBanner = tw.div`
  md:absolute
  w-full 
  mobile-only:h-32
  h-[29.75rem]
  bg-cover
  bg-[url("https://storage.googleapis.com/exo-public-assets/Fuel%20Signup.jpg")]
  bg-fuel-banner
  flex
  justify-center
  items-center
`
