import { CatchError, formatAxiosErrorToPayload } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { Equipment, EquipmentAccessory, EquipmentType, UnitType } from '../common/types'

type EquipmentState = {
  accessoryOptions: EquipmentAccessory[]
  accessoryOptionsLoading: boolean
  carrierAccessories: EquipmentAccessory[]
  carrierAccessoriesLoading: boolean
  carrierEquipment: Equipment[]
  carrierEquipmentLoading: boolean
  selectedAccessories: EquipmentAccessory[]
  selectedEquipment: Equipment[]
}

const initialState: EquipmentState = {
  accessoryOptions: [],
  accessoryOptionsLoading: false,
  carrierAccessories: [],
  carrierAccessoriesLoading: false,
  carrierEquipment: [],
  carrierEquipmentLoading: false,
  selectedAccessories: [],
  selectedEquipment: [],
}

const payloadToEquipment = (data: any): Equipment => ({
  id: data.id,
  equipmentType: {
    id: data.equipment_type,
    name: data.equipment_type,
  } as EquipmentType,
  unitType: {
    id: data.unit_type,
    name: data.unit_type_display,
  } as UnitType,
  quantity: data.quantity,
})

export const getCarrierEquipment = createAsyncThunk(
  'equipment/getCarrierEquipment',
  async (_, { rejectWithValue }) => {
    try {
      return await api
        .get('/accounts/api/carrier/carrier-company-equipment/', {
          params: { limit: 100 },
        })
        .then(({ data }) => data)
        .then(({ results }) => results)
        .then(data => data.map((data: any) => payloadToEquipment(data)))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const setCarrierEquipment = createAsyncThunk(
  'equipment/setCarrierEquipment',
  async ({ equipment }: { equipment: Equipment[] }, { rejectWithValue }) => {
    try {
      const normalizedEquipments = equipment.map((equipment: Equipment) => ({
        ...(!equipment.new && { id: equipment.id }),
        equipment_type: equipment.equipmentType?.id,
        unit_type: equipment.unitType?.id,
        quantity: equipment.quantity,
      }))
      const body = {
        equipments: normalizedEquipments,
      }
      return await api
        .post('/accounts/api/carrier/modify-carrier-company-equipment/', body)
        .then(({ data }) => data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    setSelectedAccessories(state, { payload }: { payload: EquipmentAccessory[] }) {
      state.selectedAccessories = payload
    },
    setSelectedEquipment(state, { payload }: { payload: Equipment[] }) {
      state.selectedEquipment = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCarrierEquipment.pending, state => {
        state.carrierEquipmentLoading = true
      })
      .addCase(getCarrierEquipment.fulfilled, (state, action) => {
        state.carrierEquipmentLoading = false
        state.carrierEquipment = action.payload
      })
      .addCase(getCarrierEquipment.rejected, state => {
        state.carrierEquipmentLoading = false
        toast.error('Error getting carrier equipment')
      })
      .addCase(setCarrierEquipment.fulfilled, state => {
        state.carrierEquipment = state.selectedEquipment
        toast.success('Carrier equipment saved successfully')
      })
      .addCase(setCarrierEquipment.rejected, () => {
        toast.error('Error saving carrier equipment')
      })
  },
})

export const { setSelectedAccessories, setSelectedEquipment } = equipmentSlice.actions

export default equipmentSlice.reducer
