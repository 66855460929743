import './Pagination.scss'

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { times } from 'lodash-es'
import { useEffect } from 'react'

import { classNames } from '../../common/utils'
import { PageButton } from '../TableButton'

export const Pagination = ({
  active: activePage,
  size,
  step,
  onClickHandler,
  offset,
  setOffset,
  pageSize,
  count,
}: {
  active: number
  size: number
  step: number
  onClickHandler: Function
  offset: number
  setOffset: Function
  pageSize: number
  count: number
}): JSX.Element => {
  useEffect(() => {
    if (offset < 0) setOffset(pageSize)
  }, [])

  const active = Math.floor(activePage)

  const showingNumbers = step * 2 + 1
  let startNumber = 2
  let startArrayNumber = step

  let needStartDots = false
  let needEndDots = false

  if (active > step) {
    startArrayNumber = active - step

    needStartDots = active > step + startNumber
  }

  if (size > showingNumbers) {
    {
      needEndDots = size > active + step + 1

      if (size < active + step + 1) {
        startArrayNumber = size - showingNumbers
      }
    }
  }

  let contentNumber

  const renderLeftButtons = () => (
    <>
      <PageButton
        className='start-page-button'
        disabled={!offset}
        onClick={() => {
          setOffset(0)
          onClickHandler(1)
        }}
      >
        <span className='sr-only'>First</span>
        <ChevronDoubleLeftIcon aria-hidden='true' className='arrow-icon' />
      </PageButton>
      <PageButton
        className='mr-4'
        disabled={!offset}
        onClick={() => {
          setOffset(offset - pageSize)
          onClickHandler(active - 1)
        }}
      >
        <span className='sr-only'>Previous</span>
        <ChevronLeftIcon aria-hidden='true' className='arrow-icon' />
      </PageButton>
    </>
  )

  const renderRightButtons = () => (
    <>
      <PageButton
        className='next-page-button'
        disabled={offset >= count - pageSize}
        onClick={() => {
          setOffset(offset + pageSize)
          onClickHandler(active + 1)
        }}
      >
        <span className='sr-only'>Next</span>
        <ChevronRightIcon aria-hidden='true' className='arrow-icon' />
      </PageButton>
      <PageButton
        className='hidden md:block'
        disabled={offset >= count - pageSize}
        onClick={() => {
          setOffset(count - pageSize)
          onClickHandler(size)
        }}
      >
        <span className='sr-only'>Last</span>
        <ChevronDoubleRightIcon aria-hidden='true' className='arrow-icon' />
      </PageButton>
    </>
  )

  return (
    <ul className='pagination'>
      {renderLeftButtons()}
      {size > showingNumbers + startNumber ? (
        <>
          <li
            className={classNames('page-item', active === 1 ? 'active' : '')}
            onClick={e => onClickHandler(e.currentTarget.textContent)}
          >
            1
          </li>

          {needStartDots && <span>...</span>}
          {times(showingNumbers, (i: number) => (
            <li
              key={i++}
              {...((contentNumber = needStartDots ? startArrayNumber : startNumber) as {})}
              {...(startNumber++ as {})}
              {...(startArrayNumber++ as {})}
              className={classNames('page-item', active === contentNumber ? 'active' : '')}
              onClick={e => onClickHandler(e.currentTarget.textContent)}
            >
              {contentNumber}
            </li>
          ))}
          {needEndDots && <span>...</span>}
          <li
            className={classNames('page-item', active === size ? 'active' : '')}
            onClick={e => onClickHandler(e.currentTarget.textContent)}
          >
            {size}
          </li>
        </>
      ) : (
        ((startArrayNumber = 1),
        times(size, (i: number) => (
          <li
            key={i++}
            className={classNames('page-item', active === startArrayNumber ? 'active' : '')}
            onClick={e => onClickHandler(e.currentTarget.textContent)}
          >
            {startArrayNumber++}
          </li>
        )))
      )}
      {renderRightButtons()}
    </ul>
  )
}
