import { TextInput } from '@components'

import { useAppSelector } from '../../../redux/store'

export const PaymentMethodNickname = ({
  setPaymentInformation,
  isACH,
}: {
  setPaymentInformation: (obj: any) => void
  isACH: boolean
}) => {
  const paymentInformationName = useAppSelector(
    state => state.user.onboarding.onboardingDetails.paymentInformation.name,
  )
  const label = `Account Name - ${isACH ? 'e.g. "Main ACH account"' : 'e.g. "Primary Method"'}`
  return (
    <TextInput
      required
      className='mb-4'
      label={label}
      value={paymentInformationName}
      onChange={(name: string) => setPaymentInformation({ name })}
    />
  )
}
