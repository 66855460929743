import tw from 'tailwind-styled-components'

import { supportEmail } from '../../common/constants'

export const Link = ({
  children,
  href,
  onClick,
}: {
  children: string
  href?: string
  onClick?: () => void
}) => (
  <LinkContainer {...(href && { href })} {...(onClick && { onClick })}>
    {children}
  </LinkContainer>
)

const LinkContainer = tw.a`
  text-link
  font-semibold
  hover:underline
  transition-all
  cursor-pointer
`

export const Help = ({ className }: { className?: string }) => (
  <HelpContainer className={className}>
    Please contact <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> if you need help
  </HelpContainer>
)

const HelpContainer = tw.div`
  text-center
  mt-3
`
