import { devEmail, isLocal, isPreview } from '../constants'
import { validateEmail } from './text-helpers'

export const getCredentials = () => {
  const lastDocket = localStorage.getItem('latest-docket') || ''
  const lastUserId = localStorage.getItem('latest-login-phone-or-email') || ''
  const sanitizePhone = (userId: string) => userId.replace(/\D/g, '').slice(-10)

  const isDev = isLocal || isPreview
  const isEmail = validateEmail(lastUserId)

  let email = ''
  let phone = ''

  if (!lastUserId && isDev) {
    email = devEmail
  } else if (isEmail) {
    email = lastUserId
  } else if (lastUserId) {
    phone = sanitizePhone(lastUserId)
  }

  return { docket: lastDocket, email, phone, isEmail }
}
