import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { trackEvent } from '../common/tracking'
import { FactoringDocument, PaginatedQuery, RootState } from '../common/types'
import { keysToCamel, keysToSnake } from '../common/utils'
import { PaymentMethod } from './paymentSlice'

const initialNewFactoringRequest = {
  files: [],
  loadNumber: '',
  refNumber: '',
  totalAmount: '',
}

export type NewFactoringRequest = {
  files: { file: File; id: string }[]
  loadNumber: string
  refNumber: string
  totalAmount: string
}

export type CarrierFactoringApprovalRequestStatus =
  | 'PENDING'
  | 'APPROVED'
  | 'REJECTED'
  | 'RELEASED'
  | 'RELEASE_REQUESTED'

export type CarrierFactoringApprovalRequestRetrieveStatus =
  | CarrierFactoringApprovalRequestStatus
  | 'LOADING'
  | 'ERROR'
  | 'IDLE'
  | 'NOT_STARTED'

export type HasId = {
  id: string
}

export type CarrierFactoringRequestQuery = PaginatedQuery & {
  carrierCompany?: number
  status?: CarrierFactoringApprovalRequestStatus
}

export type APICreditCheckBasicReport = {
  companyName: string
  address: string
  city: string
  state: string
  postalCode: string
  countryCode: string
}

export type APICreditCheckCompleteReport = {
  daysToPay: number
  creditScore: number
}

export type FactoringCreditBucket = {
  creditLimit: number
  bucketName: CreditBucketName
}

export type CreditBucketName = 'RED' | 'BLUE' | 'YELLOW' | 'GREEN'

export type APICreditCheckResult = {
  bucketName: CreditBucketName
  creditLimit: number
  basicReport: APICreditCheckBasicReport | null
  completeReport?: APICreditCheckCompleteReport
  creditBucket?: FactoringCreditBucket
  forbidden?: boolean
  notFound?: boolean
  hasConflict?: boolean
  badCredit?: boolean
  noCredit?: boolean
  customerCompanyId: number
}

export type CreditCheckState = {
  mcNumber: string | null
  isLoading: boolean
  isSuccess: boolean
  companyInfo: APICreditCheckResult | null
  error?: unknown
}

export type LoadFactoringRequestSubmission = {
  customerCompanyId: number
  loadNumber: string | null
  referenceNumber: string
  totalAmount: number
  documents: File[]
  selectedPaymentMethodId: number | null
}

export type CarrierFactoringRequest = {
  status: CarrierFactoringApprovalRequestStatus
  createdAt: string
  documents: {
    file: string
    documentType: 'AGREEMENT' | 'NOA' | 'INTERNAL_LETTER_OF_RELEASE'
  }[]
  outstandingBalance: number
  outstandingInvoices: number
  releaseRequestedAt: string | null
  releasedAt: string | null
}

export type Company = {
  customerId: number | null
  name: string
  mcNumber?: string
  cityState?: string
  label: string
  approved?: boolean
  notApprovedMessage?: string
}

export type FactoringState = {
  factoringDocuments: {
    id: number
    file: string
    fileName: string
    timeStamp: string
  }[]
  addFactoringDocumentLoading: boolean
  factoringStatus: CarrierFactoringApprovalRequestRetrieveStatus
  isCreatingCarrierFactoringRequest: boolean
  isCreatingLoadFactoringRequest: boolean
  creditCheck: CreditCheckState
  loadFactoringRequestSubmission: LoadFactoringRequestSubmission
  latestCarrierRequest: CarrierFactoringRequest | null
  factoringAgreementHTML: string
  noaHTML: string
  carrierLetterOfReleaseHTML: string
  loadingCarrierFactoringReleaseRequest: boolean
  legal: {
    noaSigningName: string
    noaDidAgree: boolean
    agreementSigningName: string
    agreementDidAgree: boolean
  }
  recentBrokers: Company[]
  recentShippers: Company[]
  brokers: Company[]
  shippers: Company[]
  loading: {
    recentFactoringCompanies: boolean
    deductions: boolean
    companySuggestions: boolean
    checkCompany: {
      checkSelectedCompany: boolean
      checkBroker: boolean
      checkShipper: boolean
    }
  }
  newFactoringRequest: NewFactoringRequest
  isViewingAccounts: boolean
  selectedPaymentMethod: PaymentMethod | null
  deductions: {
    flatFee: number
    margin: {
      amount: number
      percentage: number
    }
    reserve: {
      amount: number
      percentage: number
    }
    recoveries: {
      amount: number
      description: string
    }[]
    totalDeductions: number
    totalAmountAfterDeductions: number
    achFee?: number
  }
  factoringRequestTab: number
  selectedCompanyCheckResult: Company | null
  selectedCompany: Company | null
  shippersList: Company[]
  showProceedAnywayButton: boolean
  showSuccessMessage: boolean
  successMessageCompanyName: string
  searchQuery: string
}

const getInitialLoadFactoringRequestSubmission = (): LoadFactoringRequestSubmission => ({
  customerCompanyId: -1,
  loadNumber: '',
  referenceNumber: '',
  totalAmount: '' as unknown as number, // trick useForm into thinking it's a number all the time
  documents: [],
  selectedPaymentMethodId: null,
})

const initialState: FactoringState = {
  factoringDocuments: [],
  addFactoringDocumentLoading: false,
  factoringStatus: 'IDLE',
  isCreatingCarrierFactoringRequest: false,
  isCreatingLoadFactoringRequest: false,
  creditCheck: {
    companyInfo: null,
    isLoading: false,
    isSuccess: false,
    mcNumber: null,
  },
  loadingCarrierFactoringReleaseRequest: false,
  loadFactoringRequestSubmission: getInitialLoadFactoringRequestSubmission(),
  latestCarrierRequest: null,
  factoringAgreementHTML: '',
  noaHTML: '',
  carrierLetterOfReleaseHTML: '',
  legal: {
    noaSigningName: '',
    noaDidAgree: false,
    agreementSigningName: '',
    agreementDidAgree: false,
  },
  recentBrokers: [],
  recentShippers: [],
  brokers: [],
  shippers: [],
  loading: {
    recentFactoringCompanies: false,
    deductions: false,
    companySuggestions: false,
    checkCompany: {
      checkSelectedCompany: false,
      checkBroker: false,
      checkShipper: false,
    },
  },
  newFactoringRequest: initialNewFactoringRequest,
  isViewingAccounts: false,
  selectedPaymentMethod: null,
  deductions: {
    flatFee: 0,
    margin: {
      amount: 0,
      percentage: 0,
    },
    reserve: {
      amount: 0,
      percentage: 0,
    },
    recoveries: [],
    totalDeductions: 0,
    totalAmountAfterDeductions: 0,
    achFee: 0,
  },
  factoringRequestTab: 0,
  selectedCompanyCheckResult: null,
  selectedCompany: null,
  shippersList: [],
  showProceedAnywayButton: false,
  showSuccessMessage: false,
  successMessageCompanyName: '',
  searchQuery: '',
}
// example: '/path/to/file/document.txt' => ['document.txt']
const extractFileNameFromPath = (file = '') => file.split('/').slice(-1)

export const getRecentBrokers = createAsyncThunk('factoring/getRecentBrokers', async () =>
  api.get('/factoring/api/lfr-submission/company-suggestions/broker/').then(({ data }) =>
    keysToCamel(
      data.map((result: { mc_number: string; name: string }) => ({
        ...result,
        label: `${result.mc_number} | ${result.name}`,
      })),
    ),
  ),
)

export const getRecentShippers = createAsyncThunk('factoring/getRecentShippers', async () =>
  api.get('/factoring/api/lfr-submission/company-suggestions/shipper/').then(({ data }) =>
    keysToCamel(
      data.map((result: { name: string; city_state?: string }) => ({
        ...result,
        label: `${result.name} ${result.city_state && `| ${result.city_state}`}`,
      })),
    ),
  ),
)

export const getBrokers = createAsyncThunk('factoring/getBrokers', async () =>
  api.get('/factoring/api/lfr-submission/company-autocomplete/broker/').then(({ data }) =>
    keysToCamel(
      data.map((result: { mc_number: string; name: string }) => ({
        ...result,
        label: `${result.mc_number} | ${result.name}`,
      })),
    ),
  ),
)

export const getShippers = createAsyncThunk('factoring/getShippers', async () =>
  api.get('/factoring/api/lfr-submission/company-autocomplete/shipper/').then(({ data }) =>
    keysToCamel(
      data.map((result: { name: string; city_state?: string }) => ({
        ...result,
        label: `${result.name} ${result.city_state && `| ${result.city_state}`}`,
      })),
    ),
  ),
)

export const checkCompany = createAsyncThunk(
  'factoring/checkCompany',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/factoring/api/lfr-submission/check-company-approval/${id}/`)
      return keysToCamel(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkBroker = createAsyncThunk(
  'factoring/checkBroker',
  async (mcNumber: string, { rejectWithValue }) => {
    try {
      const response = await api.get('/factoring/api/lfr-submission/company-search/broker/', {
        params: {
          mc_number: mcNumber,
        },
      })
      return keysToCamel({
        mcNumber,
        broker: {
          ...response.data.broker,
          label: `${response.data.broker.mc_number} | ${response.data.broker.name}`,
        },
      })
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkShipper = createAsyncThunk(
  'factoring/checkShipper',
  async (name: string, { rejectWithValue }) => {
    try {
      const response = await api.get('/factoring/api/lfr-submission/company-search/shipper/', {
        params: {
          name,
        },
      })
      return keysToCamel({ shippers: response.data, name })
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getDeductions = createAsyncThunk(
  'factoring/getDeductions',
  async (totalAmount: string, { getState, rejectWithValue }) => {
    const { selectedPaymentMethod } = (getState() as RootState).factoring

    try {
      const response = await api.get('/factoring/api/lfr-submission/get-deductions/', {
        params: {
          total_amount: totalAmount,
          payment_method_id: selectedPaymentMethod?.id,
        },
      })
      return keysToCamel(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLatestCFR = createAsyncThunk(
  'factoring/getLatestCFR',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/accounts/api/carrier-factoring-requests/', {
        params: {
          limit: 1,
          offset: 0,
        },
      })
      const results = keysToCamel(response.data.results)
      if (results.length) {
        return results[0] as CarrierFactoringRequest
      }
      return { status: 'NOT_STARTED' } as unknown as CarrierFactoringRequest
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkCreditByMC = createAsyncThunk<APICreditCheckResult, string>(
  'factoring/checkCreditByMC',
  async (mcNumber: string) =>
    await api
      .get(`/accounts/api/check-credit-by-mc/${encodeURI(mcNumber.trim())}/`)
      .then(({ data }) => keysToCamel(data))
      .catch(error =>
        error.response.status === 404 ? { basicReport: null } : Promise.reject(error),
      ),
)

export const checkCreditByName = createAsyncThunk<
  APICreditCheckResult,
  { name: string; postalCode: string }
>(
  'factoring/checkCreditByName',
  async ({ name, postalCode }) =>
    await api
      .get('/accounts/api/check-credit-by-name/', { params: keysToSnake({ name, postalCode }) })
      .then(({ data }) => keysToCamel(data))
      .catch(error =>
        error.response.status === 404 ? { basicReport: null } : Promise.reject(error),
      ),
)

export const getFactoringAgreement = createAsyncThunk<{ data: string }>(
  'factoring/getFactoringAgreement',
  async () =>
    api.get('/accounts/api/carrier-factoring-agreement/', {
      headers: { 'Content-Type': 'text/html' },
    }),
)

export const getNOA = createAsyncThunk<{ data: string }>('factoring/getNOA', async () =>
  api.get('/accounts/api/carrier-noa/', { headers: { 'Content-Type': 'text/html' } }),
)

export const getCarrierLetterOfRelease = createAsyncThunk<{ data: string }>(
  'factoring/getCarrierLetterOfRelease',
  async () =>
    api.get('/accounts/api/carrier-letter-of-release/', {
      headers: { 'Content-Type': 'text/html' },
    }),
)

export const requestRelease = createAsyncThunk('factoring/requestRelease', async () =>
  api.post('/accounts/api/carrier-request-factoring-release/'),
)

export const createFactoringRequest = createAsyncThunk(
  'factoring/createFactoringRequest',
  async (_, { getState }) =>
    api.post(
      '/accounts/api/carrier-factoring-requests/',
      keysToSnake((getState() as RootState).factoring.legal),
    ),
)

export const submitLoadFactoringRequestOld = createAsyncThunk(
  'factoring/submitLoadFactoringRequestOld',
  async (_: void, { getState, rejectWithValue }) => {
    const {
      factoring: { loadFactoringRequestSubmission: submission },
    } = getState() as RootState
    if (submission) {
      const formData = new FormData()
      formData.append('customer_company_id', submission.customerCompanyId.toString())
      formData.append('reference_number', submission.referenceNumber)
      formData.append(
        'invoice_number',
        submission.loadNumber ? submission.loadNumber : submission.referenceNumber,
      )
      if (submission.selectedPaymentMethodId !== null) {
        formData.append('payment_method_id', String(submission.selectedPaymentMethodId))
      }
      formData.append('line_haul', submission.totalAmount.toFixed(2))
      submission.documents.forEach(doc => formData.append('documents', doc))

      return await api
        .post('/accounts/api/load-factoring-requests/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch(error => rejectWithValue(error.response?.data.message))
    } else {
      return rejectWithValue('No submission data')
    }
  },
)

export const submitLoadFactoringRequest = createAsyncThunk(
  'factoring/submitLoadFactoringRequest',
  async (_: void, { getState, rejectWithValue, dispatch }) => {
    const { newFactoringRequest, selectedPaymentMethod, selectedCompanyCheckResult, searchQuery } =
      (getState() as RootState).factoring
    const formData = new FormData()
    if (selectedCompanyCheckResult?.customerId)
      formData.append('customer_company_id', selectedCompanyCheckResult?.customerId?.toString())
    else formData.append('new_shipper_company_name', searchQuery)
    formData.append('reference_number', newFactoringRequest.loadNumber)
    if (newFactoringRequest.refNumber)
      formData.append('invoice_number', newFactoringRequest.refNumber)
    selectedPaymentMethod && formData.append('payment_method_id', String(selectedPaymentMethod.id))
    formData.append('line_haul', parseFloat(newFactoringRequest.totalAmount).toFixed(2))
    formData.append('approved', selectedCompanyCheckResult?.approved == true ? 'false' : 'true')
    const documents = newFactoringRequest.files.map(doc => doc.file)
    documents.forEach(doc => formData.append('documents', doc))

    return await api
      .post('/accounts/api/load-factoring-requests/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        dispatch(getBrokers())
        dispatch(getShippers())
        dispatch(getRecentBrokers())
        dispatch(getRecentShippers())
        dispatch(setNewFactoringRequest(initialNewFactoringRequest))
        return keysToCamel(data)
      })
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const getFactoringDocuments = createAsyncThunk(
  'factoring/getFactoringDocuments',
  async ({ loadId }: { loadId: number }) => {
    const factoringDocuments = await api
      .get(`/loads/api/load-finance-documents/${loadId}/`)
      .then(({ data }) =>
        keysToCamel(data).map((document: FactoringDocument) => ({
          id: document.id,
          fileName: extractFileNameFromPath(document.fileName),
          timeStamp: document.createdAt,
          file: document.file,
        })),
      )
    return factoringDocuments
  },
)

export const deleteFactoringDocument = createAsyncThunk(
  'factoring/deleteFactoringDocument',
  async (
    { document, loadId }: { document: FactoringDocument; loadId: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.delete(`/accounts/api/carrier/finance-document-ud/${document.id}/`)

      dispatch(getFactoringDocuments({ loadId: loadId }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addFactoringDocument = createAsyncThunk(
  'factoring/addFactoringDocument',
  async (
    document: {
      loadId: number
      fileData: string
    },
    { dispatch, rejectWithValue },
  ) => {
    const formData = new FormData()
    formData.append('file', document?.fileData)
    formData.append('document_type', 'BOL_AND_OR_RATE_CONFIRMATION')

    try {
      const response = await api.post(
        `/loads/api/load-finance-documents/${document.loadId}/`,
        formData,
      )

      dispatch(getFactoringDocuments({ loadId: document.loadId }))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const factoringSlice = createSlice({
  name: 'factoring',
  initialState,
  reducers: {
    setLoadFactoringRequestSubmission(
      state,
      { payload }: PayloadAction<LoadFactoringRequestSubmission>,
    ) {
      state.loadFactoringRequestSubmission = payload
    },
    setLegal(state, { payload }: PayloadAction<Partial<FactoringState['legal']>>) {
      state.legal = {
        ...state.legal,
        ...payload,
      }
    },
    resetCreditCheck(state) {
      state.creditCheck = {
        companyInfo: null,
        isLoading: false,
        isSuccess: false,
        mcNumber: null,
      }
    },
    setNewFactoringRequest(state, { payload }) {
      state.newFactoringRequest = payload
    },
    setViewingAccounts(state, { payload }) {
      state.isViewingAccounts = payload
    },
    setSelectedPaymentMethod(state, { payload }) {
      state.selectedPaymentMethod = payload
    },
    setFactoringRequestTab(state, { payload }) {
      state.factoringRequestTab = payload
    },
    setSelectedCompany(state, { payload }) {
      state.selectedCompany = payload
    },
    setSelectedCompanyCheckResult(state, { payload }) {
      state.selectedCompanyCheckResult = payload
      state.showProceedAnywayButton = false
    },
    resetShippersList(state) {
      state.shippersList = []
    },
    setShowSuccessMessage(state, { payload }) {
      state.showSuccessMessage = payload
    },
    setSearchQuery(state, { payload }) {
      state.searchQuery = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(checkBroker.pending, state => {
        state.selectedCompanyCheckResult = null
        state.loading.checkCompany.checkBroker = true
      })
      .addCase(checkBroker.fulfilled, (state, { payload }) => {
        state.loading.checkCompany.checkBroker = false
        state.selectedCompany = payload.broker
        state.selectedCompanyCheckResult = {
          ...payload.broker,
          mcNumber: payload.broker?.mcNumber || payload.mcNumber,
        }
      })
      .addCase(checkBroker.rejected, (state, { payload }) => {
        state.loading.checkCompany.checkBroker = false
        state.selectedCompany = null
        toast.error(getErrorString(payload, 'Failed to check broker'))
      })
      .addCase(checkShipper.pending, state => {
        state.selectedCompanyCheckResult = null
        state.loading.checkCompany.checkShipper = true
      })
      .addCase(checkShipper.fulfilled, (state, { payload }) => {
        state.loading.checkCompany.checkShipper = false
        state.selectedCompany = {
          ...state.selectedCompany,
          customerId: null,
          label: payload.name,
          name: payload.name,
        }
        state.shippersList = payload.shippers
        state.showProceedAnywayButton = !payload.shippers?.length
      })
      .addCase(checkShipper.rejected, (state, { payload }) => {
        state.loading.checkCompany.checkShipper = false
        state.selectedCompany = null
        toast.error(getErrorString(payload, 'Failed to check shipper'))
      })
      .addCase(checkCompany.pending, state => {
        state.selectedCompanyCheckResult = null
        state.loading.checkCompany.checkSelectedCompany = true
      })
      .addCase(checkCompany.fulfilled, (state, { payload }) => {
        state.loading.checkCompany.checkSelectedCompany = false
        state.selectedCompanyCheckResult = payload
      })
      .addCase(checkCompany.rejected, (state, { payload }) => {
        state.loading.checkCompany.checkSelectedCompany = false
        toast.error(getErrorString(payload, 'Failed to check company'))
      })
      .addCase(getDeductions.pending, state => {
        state.loading.deductions = true
      })
      .addCase(getDeductions.fulfilled, (state, { payload }) => {
        state.loading.deductions = false
        state.deductions = payload
      })
      .addCase(getDeductions.rejected, (state, { payload }) => {
        state.loading.deductions = false
        toast.error(getErrorString(payload, 'Failed to retrieve deductions'))
      })
      .addCase(getBrokers.pending, state => {
        state.loading.companySuggestions = true
      })
      .addCase(getBrokers.fulfilled, (state, { payload }) => {
        state.loading.companySuggestions = false
        state.brokers = payload
      })
      .addCase(getBrokers.rejected, (state, { payload }) => {
        state.loading.companySuggestions = false
        toast.error(getErrorString(payload, 'Failed to retrieve brokers'))
      })
      .addCase(getShippers.pending, state => {
        state.loading.companySuggestions = true
      })
      .addCase(getShippers.fulfilled, (state, { payload }) => {
        state.loading.companySuggestions = false
        state.shippers = payload
      })
      .addCase(getShippers.rejected, (state, { payload }) => {
        state.loading.companySuggestions = false
        toast.error(getErrorString(payload, 'Failed to retrieve shippers'))
      })
      .addCase(getRecentBrokers.pending, state => {
        state.loading.recentFactoringCompanies = true
      })
      .addCase(getRecentBrokers.fulfilled, (state, { payload }) => {
        state.recentBrokers = payload
        state.loading.recentFactoringCompanies = false
      })
      .addCase(getRecentBrokers.rejected, state => {
        state.loading.recentFactoringCompanies = false
        toast.error('Failed to get recent brokers')
      })
      .addCase(getRecentShippers.pending, state => {
        state.loading.recentFactoringCompanies = true
      })
      .addCase(getRecentShippers.fulfilled, (state, { payload }) => {
        state.recentShippers = payload
        state.loading.recentFactoringCompanies = false
      })
      .addCase(getRecentShippers.rejected, state => {
        state.loading.recentFactoringCompanies = false
        toast.error('Failed to get recent shippers')
      })
      .addCase(getLatestCFR.pending, state => {
        state.factoringStatus = 'LOADING'
      })
      .addCase(getLatestCFR.fulfilled, (state, { payload }) => {
        state.factoringStatus = payload.status
        state.latestCarrierRequest = payload
      })
      .addCase(getLatestCFR.rejected, state => {
        state.factoringStatus = 'ERROR'
      })
      .addCase(createFactoringRequest.pending, state => {
        state.isCreatingCarrierFactoringRequest = true
      })
      .addCase(createFactoringRequest.fulfilled, state => {
        state.isCreatingCarrierFactoringRequest = false
        trackEvent('Carrier factoring request submitted')
      })
      .addCase(createFactoringRequest.rejected, state => {
        state.isCreatingCarrierFactoringRequest = false
        toast.error('Error creating factoring request')
        trackEvent('Carrier factoring request submission failed')
      })
      .addCase(checkCreditByMC.pending, (state, action) => {
        state.creditCheck = {
          companyInfo: null,
          isLoading: true,
          isSuccess: false,
          mcNumber: action.meta.arg,
        }
        trackEvent('Carrier factoring credit check started', { mc: action.meta.arg })
      })
      .addCase(checkCreditByMC.fulfilled, (state, action) => {
        state.creditCheck = {
          mcNumber: action.meta.arg,
          isLoading: false,
          isSuccess: true,
          companyInfo: action.payload,
        }
        state.loadFactoringRequestSubmission.customerCompanyId = action.payload.customerCompanyId
      })
      .addCase(checkCreditByMC.rejected, (state, action) => {
        state.creditCheck = {
          mcNumber: action.meta.arg,
          isLoading: false,
          isSuccess: false,
          error: action.payload || true,
          companyInfo: null,
        }
        trackEvent('Carrier factoring credit check rejected', {
          mc: action.meta.arg,
          error: action.payload,
        })
      })
      .addCase(checkCreditByName.pending, (state, action) => {
        state.creditCheck = {
          companyInfo: null,
          isLoading: true,
          isSuccess: false,
          mcNumber: null,
        }
        trackEvent('Carrier factoring credit check started', action.meta.arg)
      })
      .addCase(checkCreditByName.fulfilled, (state, action) => {
        state.creditCheck = {
          mcNumber: null,
          isLoading: false,
          isSuccess: true,
          companyInfo: action.payload,
        }
        state.loadFactoringRequestSubmission.customerCompanyId = action.payload.customerCompanyId
      })
      .addCase(checkCreditByName.rejected, (state, action) => {
        state.creditCheck = {
          mcNumber: null,
          isLoading: false,
          isSuccess: false,
          error: action.payload || true,
          companyInfo: null,
        }
        trackEvent('Carrier factoring credit check rejected', {
          mc: action.meta.arg,
          error: action.payload,
        })
      })
      .addCase(submitLoadFactoringRequest.pending, state => {
        state.isCreatingLoadFactoringRequest = true
      })
      .addCase(submitLoadFactoringRequest.fulfilled, state => {
        state.isCreatingLoadFactoringRequest = false
        state.successMessageCompanyName = state.selectedCompanyCheckResult?.name || ''
        state.newFactoringRequest = initialNewFactoringRequest
        state.selectedCompanyCheckResult = null
        state.selectedCompany = null
        state.showSuccessMessage = true
        state.isViewingAccounts = false
        state.shippersList = []
        trackEvent('Load factoring request submitted')
      })
      .addCase(submitLoadFactoringRequest.rejected, (state, { payload }) => {
        state.showSuccessMessage = false
        state.isCreatingLoadFactoringRequest = false
        trackEvent('Load factoring request submission failed')
        toast.error(getErrorString(payload, 'Error creating factoring request'))
      })
      .addCase(submitLoadFactoringRequestOld.pending, state => {
        state.isCreatingLoadFactoringRequest = true
      })
      .addCase(submitLoadFactoringRequestOld.rejected, (state, { payload }) => {
        toast.error((payload as string) ?? 'Error creating factoring request.')
        state.isCreatingLoadFactoringRequest = false
        trackEvent('Load factoring request submission failed')
      })
      .addCase(submitLoadFactoringRequestOld.fulfilled, state => {
        state.loadFactoringRequestSubmission = getInitialLoadFactoringRequestSubmission()
        state.isCreatingLoadFactoringRequest = false
        toast.success('Load Factoring Request created successfully')
        trackEvent('Load factoring request submitted')
      })
      .addCase(getFactoringAgreement.fulfilled, (state, action) => {
        state.factoringAgreementHTML = action.payload.data
      })
      .addCase(getFactoringAgreement.rejected, () => {
        toast.error('Could not get factoring agreement')
        trackEvent('Factoring agreement request failed')
      })
      .addCase(getNOA.fulfilled, (state, action) => {
        state.noaHTML = action.payload.data
      })
      .addCase(getNOA.rejected, () => {
        toast.error('Could not get NOA')
        trackEvent('NOA request failed')
      })
      .addCase(getCarrierLetterOfRelease.fulfilled, (state, action) => {
        state.carrierLetterOfReleaseHTML = action.payload.data
      })
      .addCase(getCarrierLetterOfRelease.rejected, () => {
        toast.error('Could not get letter of release')
        trackEvent('Letter of release request failed')
      })
      .addCase(requestRelease.pending, state => {
        state.loadingCarrierFactoringReleaseRequest = true
      })
      .addCase(requestRelease.fulfilled, state => {
        toast.success('Release request submitted')
        state.loadingCarrierFactoringReleaseRequest = false
        state.factoringStatus = 'RELEASE_REQUESTED'
        if (state.latestCarrierRequest) {
          state.latestCarrierRequest.status = 'RELEASE_REQUESTED'
          state.latestCarrierRequest.releaseRequestedAt = new Date().toISOString()
        }
        trackEvent('Factoring release request submitted')
      })
      .addCase(requestRelease.rejected, state => {
        toast.error('Error requesting release')
        state.loadingCarrierFactoringReleaseRequest = false
        trackEvent('Factoring release request failed')
      })
      .addCase(getFactoringDocuments.fulfilled, (state, action) => {
        state.factoringDocuments = action.payload
      })
      .addCase(getFactoringDocuments.rejected, () => {
        toast.error('Error getting documents')
      })
      .addCase(addFactoringDocument.pending, state => {
        state.addFactoringDocumentLoading = true
      })
      .addCase(addFactoringDocument.fulfilled, state => {
        state.addFactoringDocumentLoading = false
      })
      .addCase(addFactoringDocument.rejected, state => {
        state.addFactoringDocumentLoading = false
        toast.error('Error adding document')
      })
  },
})

export const {
  setLoadFactoringRequestSubmission,
  setLegal,
  resetCreditCheck,
  setNewFactoringRequest,
  setViewingAccounts,
  setSelectedPaymentMethod,
  setFactoringRequestTab,
  setSelectedCompany,
  setSelectedCompanyCheckResult,
  resetShippersList,
  setShowSuccessMessage,
  setSearchQuery,
} = factoringSlice.actions

export default factoringSlice.reducer
