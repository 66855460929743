import { CatchError, formatAxiosErrorToPayload } from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { api } from '../api/api'
import { CarrierCompanyRecovery, PaginatedArray, RecoveryDetail, RootState } from '../common/types'
import { keysToCamel } from '../common/utils'

export const getCarrierCompanyRecoveries = createAsyncThunk(
  'carrierCompanyRecoveries/getCarrierCompanyRecoveries',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { limit, offset } = (getState() as RootState).carrierCompanyRecoveries
      return api
        .get('/billing/api/carrier-company-recovery/', {
          params: {
            limit,
            offset,
          },
        })
        .then(({ data }) => keysToCamel(data) as PaginatedArray<CarrierCompanyRecovery>)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getRecoveryDetails = createAsyncThunk(
  'loadFactoringRequests/getRecoveryDetails',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      return api
        .get(`/billing/api/carrier-recovery-detail/${id}/`)
        .then(({ data }) => keysToCamel(data))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export type CarrierCompanyRecoveriesState = {
  count: {
    carrierCompanyRecoveries: number
    recoveries: number
  }
  carrierCompanyRecoveries: CarrierCompanyRecovery[]
  recoveries: RecoveryDetail[]
  recoveryDetails: CarrierCompanyRecovery | null
  loading: {
    list: boolean
    recoveryDetails: boolean
  }
  offset: number
  limit: number
}

const initialState: CarrierCompanyRecoveriesState = {
  carrierCompanyRecoveries: [],
  recoveries: [],
  recoveryDetails: null,
  count: {
    carrierCompanyRecoveries: 0,
    recoveries: 0,
  },
  loading: {
    list: false,
    recoveryDetails: false,
  },
  offset: 0,
  limit: 10,
}

export const carrierCompanyRecoveriesSlice = createSlice({
  name: 'carrierCompanyRecoveries',
  initialState,
  reducers: {
    setOffset(state, { payload }: PayloadAction<number>) {
      state.offset = payload
    },
    setLimit(state, { payload }: PayloadAction<number>) {
      state.limit = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCarrierCompanyRecoveries.pending, state => {
        state.loading.list = true
      })
      .addCase(getRecoveryDetails.pending, state => {
        state.loading.recoveryDetails = true
      })
      .addCase(getRecoveryDetails.fulfilled, (state, action) => {
        let balance = parseFloat(action.payload.balance)
        const amountToRecover = parseFloat(action.payload.amountToRecover)
        const amountPerFactoredLoad = parseFloat(action.payload.repaymentAmountPerLoad)
        const recoveries: RecoveryDetail[] = action.payload.recoveries

        const estimatedFactoredLoads = Math.ceil(amountToRecover / amountPerFactoredLoad)

        action.payload.estimatedFactoredLoads = estimatedFactoredLoads

        state.recoveryDetails = action.payload
        state.loading.recoveryDetails = false

        // Handling recoveries
        recoveries.map((recovery, idx) => {
          recovery.id = idx
          recovery.balance = balance
          balance = balance + recovery.amount

          recovery.hasAmountTooltip = recovery.amount < amountPerFactoredLoad
        })
        state.recoveries = recoveries
        state.count.recoveries = action.payload.recoveries.length
      })
      .addCase(getCarrierCompanyRecoveries.fulfilled, (state, action) => {
        const data = action.payload
        state.carrierCompanyRecoveries = data.results as CarrierCompanyRecovery[]
        state.count.carrierCompanyRecoveries = data.count ?? 0
        state.loading.list = false
      })
  },
})

export const { setOffset, setLimit } = carrierCompanyRecoveriesSlice.actions

export default carrierCompanyRecoveriesSlice.reducer
